import React, { createContext, useContext, useState } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'


export const alertContext = createContext();

export const AlertProvider = ({ children }) => {
	const [options, setOptions] = useState({
		message: "",
		severity: "success",
		open: false
	})

	const setAlert = (open, message, severity) => {
		setOptions({
			"message": message,
			"severity": severity,
			"open": open
		})
	}

	return (
		<alertContext.Provider value={[options, setAlert]}>
			{children}
		</alertContext.Provider>
	)
}

export const RenderAlert = ({ children }) => {
	const [options, setAlert] = useContext(alertContext)

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setAlert(false)
	}


	return (
		<React.Fragment>
			{options.severity &&
				<Snackbar
					open={options.open}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					style={{ top: "50px" }}
					autoHideDuration={1700}
					onClose={handleClose}
				>
					<Alert
						elevation={6}
						variant="filled"
						onClose={handleClose}
						severity={options.severity}>
						{options.message}
					</Alert>
				</Snackbar>
			}
			{children}
		</React.Fragment>
	)
}

const useAlert = () => {
	const [options, setAlert] = useContext(alertContext)

	return [
		options,
		setAlert
	]
}
export default useAlert;