import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useEffect, useState } from 'react';


const LoginRoute = ({ component: Component, user, setReload, ...rest }) => {
    const [role, setRole] = useState("")

    useEffect(() => {
        if (localStorage.getItem("bearer_fit_token") && user) {
            setRole(user?.role?.tier)
        }
    }, [user])

    
    const SwitchRedirect = () => {
        switch (role) {
            case "BO_LIM":
                return <Redirect to="/backoffice-lite/export" />
            case "BO_COM":
                return <Redirect to="/backoffice/users" />
            default:
                return <Redirect to="/corsi" />

        }
    }

    return (
        <Route
            {...rest}
            render={props =>
                role === "" ? (
                    <Component setReload={setReload} {...props} />
                ) : (
                        <SwitchRedirect />
                    )

            }


        />
    )
}
export default LoginRoute;