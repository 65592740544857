import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Grid, Button, Link, Breadcrumbs } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import Builder from '../QuestionBuilder'
import BallotOutlinedIcon from '@material-ui/icons/BallotOutlined'
import serviceAdmin from '../../services/serviceAdmin'
import Optional from './Optional'
import { tabsStyle } from '../../classes'
import useColor from '../../context/themeContext'



const TabNewTest = (props) => {
  const { COLORS } = useColor()
  const useStyles = makeStyles({
    ...tabsStyle(COLORS),
    arrow: {
      width: "100%",
      height: "48px",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      cursor: "pointer"
    },
    arrowicon: {
      color: "#999"
    }
  });
  const classes = useStyles();

  const [input, setInput] = useState({})
  const [startCreate, setStartCreate] = useState(false)
  const [survey, setSurvey] = useState({})

  const handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const isOnlyNumber = name === ("max_retries" || "question_bank_question_number")
    if (isOnlyNumber) {
      const reg = /^[0-9\b]+$/;
      if (!reg.test(value) && value !== "") {
        value = "0"
      }
    }

    if(name == "retries_not_limited")
    {
      if(value)
      {
        setInput({ ...input, [name]: value, "max_retries": -1 })
      }
      else
      {
        setInput({ ...input, [name]: value })
      }
      return
    }

    setInput({ ...input, [name]: value })
    if (value === "" && isOnlyNumber) value = "0";
  }
  const handleChangeSlide = (e, value) => {
    setInput({ ...input, "perc_correct_questions": value })
  }

  const fetchDataDetails = (id) => {
    const url = props.isTest === 'EVALUATION' ? "evaluations" : "surveys";
    serviceAdmin.getService(`v1/${url}/${id}/`)
      .then(res => setSurvey(res.data.data))
      .catch(err => console.log(err))
  }

  const createTest = () => {

    let params = input
    if (props.isTest === 'EVALUATION') {
      // EVALUATIONS
      params = input
      delete params.created
      delete params.updated
      delete params.questions
      if (params?.max_retries) {
        params.max_retries = Number(params.max_retries)
      }
      if (params?.question_bank_question_number) {
        params.question_bank_question_number = Number(params.question_bank_question_number)
      }
    } else {
      // SURVEYS
      params = {
        name: input.name,
        is_editable: input.is_editable,
        is_sequential: input.is_sequential
      }
    }

    const url = props.isTest === 'EVALUATION' ? `v1/evaluations/` : `v1/surveys/`;

    serviceAdmin.postService(url, input)
      .then(res => {
        if (res?.status === 201) {
          setSurvey(res.data.data)
          setStartCreate(true)
          props.setReload();
        }
      }).catch(err => {
        console.log(err)
      })
  };

  const n_questions = input.questions ? Object.keys(input?.questions)?.length : 0;

  return (
    <React.Fragment>
      {!startCreate ?
        <Paper className={classes.root}>
          <div className={classes.arrow}>
            <ArrowBackIosIcon onClick={props.handleClose} className={classes.arrowicon} />
          </div>
          <div className={classes.content}>
            <h2 className={classes.title}>{props.isTest === "EVALUATION" ? 'Crea una nuova Verifica' : 'Crea un nuovo Sondaggio'}</h2>
            <div style={{ paddingTop: "20px" }}>
              <Grid container spacing={3}>
                <Grid container spacing={3} item xs={12}>
                  <Optional n_questions={n_questions} handleChangeSlide={handleChangeSlide} isEvaluation={props.isTest === "EVALUATION" ? 1 : 0} handleChangeInput={handleChange} input={input} />
                </Grid>
              </Grid>
            </div>

            <Paper elevation={3} className={classes.save}>
              <Button onClick={props.handleClose} className={classes.btn_del}>Annulla</Button>
              <Button disabled={input.name ? false : true} onClick={createTest} className={classes.btn_save}>Inizia</Button>
            </Paper>
          </div>
        </Paper>
        :
        <Paper className={classes.root}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" onClick={props.handleClose} className={classes.link}>
              <BallotOutlinedIcon className={classes.icon} />
              <span className={classes.span}>{props.isTest === "EVALUATION" ? "Verifiche" : "Sondaggi"} </span>
            </Link>
            <Link color="inherit" className={classes.link_active}>
              <span className={classes.span}>
                {survey?.name}
              </span>
            </Link>
          </Breadcrumbs>
          <Builder
            dataBuilder={survey}
            fetchDataDetails={fetchDataDetails}
            setReload={props.setReload}
            isEvaluation={props.isTest === "EVALUATION" ? 1 : 0}
            setCustomAlert={props.setCustomAlert}
          />
        </Paper>
      }
    </React.Fragment>
  )
}

export default TabNewTest;