import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import serviceAdmin from '../../services/serviceAdmin'
import DoneAllIcon from '@material-ui/icons/DoneAll';
import AntSwitch from '../Switch/AntSwitch'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import ModalMedia from '../Modal/ModalMedia'
import PropTypes from 'prop-types';
import Paper from "@material-ui/core/Paper"
import { modalClass } from '../../classes';
import useColor from '../../context/themeContext';
import useAlert from '../../hooks/useAlert';
import FullTextField from '../StyledComponent/FullTextField'

function TabPanel(props) {
  
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ModalModifyLesson(props) {
  const [option, setAlert] = useAlert()
  const { COLORS } = useColor()
  const useStyles = makeStyles((theme) => (modalClass(COLORS)));
  const classes = useStyles();

  const inputRef = useRef();
  const [input, setInput] = useState({});
  const [photo, setPhoto] = useState(undefined);
  const [teachers, setTeachers] = useState([])
  const [surveys, setSurveys] = useState([])
  const [evaluations, setEvaluations] = useState([])
  const [mandatorySections, setMandatorySections] = useState([])
  const [timeUpload, setTimeUpload] = useState(null);
  const [checked, setChecked] = useState(false)
  const [checkedActive, setCheckedActive] = useState(false)
  const [buttonShown, setButtonShown] = useState(false)
  const [mediaType, setMediaType] = useState(null);
  const [thumb, setThumb] = useState(null);
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);

  const setreload = () => {
    setReload(!reload)
  }

  const setopen = (input) => {
    setOpen(input)
  }
  const handleCloseMedia = () => {
    setOpen(false)
  }
  const handleChange = (event, newValue) => {
    props.setValue(newValue);
  };

  const setchecked = () => {
    setChecked(!checked)
  }

  const setcheckedactive = () => {
    setCheckedActive(!checkedActive)
  }

  const setButtonVisibility = () => {
    setButtonShown(state => !state)
  }

  const options = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total)
      setTimeUpload(`Upload immagine ${percent}%`);
    }
  }

  let slugRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;

  useEffect(() => {
    if (props.lesson) {
      serviceAdmin.getService(`v1/course-sections/${props.lesson.id}/`)
        .then(res => {
          const lesson = res.data.data;
          setInput({
            ...input,
            "name": lesson.name,
            "description": lesson.description,
            "slug": lesson.slug,
            "teacher_id": lesson.holder_id,
            "duration": lesson?.duration,
            "mandatory_section_id": lesson.mandatory_section ? lesson.mandatory_section.id : null,
            "survey_id": lesson?.survey_id,
            "evaluation_id": lesson?.evaluation_id,
            "category": lesson.category
          })
          setButtonShown(lesson?.has_button)
          setMediaType(lesson?.media_file)
          setThumb(lesson?.thumbnail)
          setChecked(lesson.is_mandatory)
          setCheckedActive(lesson.is_active)
          serviceAdmin.getService(`v1/courses/${props.course.id}/course-sections/`)
            .then(res => {
              if (res.data.data) {

                const newArray = res.data.data.filter((e) => e.id !== lesson.id ? e : null)

                setMandatorySections(newArray);
              }
            }).catch(err => {
              console.log(err)
            })

        }).catch(err => {
          console.log(err)
        })



      serviceAdmin.getService('v1/teachers/')
        .then(res => {
          setTeachers(res.data.data)
        }).catch(err => {
          console.log(err)
        })
      serviceAdmin.getService('v1/surveys/')
        .then(res => {
          setSurveys(res.data.data)
        }).catch(err => {
          console.log(err)
        })
      serviceAdmin.getService('v1/evaluations/')
        .then(res => {
          setEvaluations(res.data.data)
        }).catch(err => {
          console.log(err)
        })
    }
    return () => {
      setInput({})
      setMediaType(false)
      setThumb(false)
      setChecked(false)
      setPhoto(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, reload])


  const setImage = () => {
    inputRef.current.click()
  }


  const removeImage = () => {
    setPhoto(null)
  }

  function fileSelected(event) {
    const isImage = file => file["type"].includes("image")
    let input = event.target
    if (input.files && input.files[0]) {
      if (isImage(input.files[0])) {
        let reader = new FileReader()
        reader.onload = e => {
          setPhoto(e.target.result)
        }
        reader.readAsDataURL(input.files[[0]])
      } else {
        alert('foto non caricata')
      }
    }
  }
  const changeValue = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInput({ ...input, [name]: value })
  }

  function clean(obj) {
    for (let propName in obj) {
      if (obj[propName] === "" || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
  }

  const modifyLesson = () => {
    const params = {
      ...input,
      "thumbnail": photo,
      "is_active": checkedActive,
      "is_mandatory": checked,
      "has_button": buttonShown
    }
    clean(params);

    serviceAdmin.putService(`v1/course-sections/${props.lesson.id}/`, params, options)
      .then(res => {
        if (res.status === 201) {
          setAlert(true, "Lezione aggiornata con successo!", "success")
          setTimeUpload(null)
          props.setReload();
          handleClose();
        }
      }).catch(err => {
        console.log(err)
      })
  }

  const handleClose = () => {
    props.setOpen(false);
  };
  return (

    <Drawer
      anchor={"right"}
      open={props.open}
      onClose={handleClose}
    >
      <div className={"drawer-container"}>


        <Tabs className={classes.tabs + " title-drawer-container"} value={props.value} onChange={handleChange} aria-label="simple tabs example">
          <Tab
            onClick={handleClose}
            icon={
              <CloseIcon style={{ cursor: "pointer", height: "30px", width: "30px" }} />
            }
            className={classes.tab}
            {...a11yProps(0)}
          />
          <Tab className={classes.tab} label="Dettaglio lezione" {...a11yProps(1)} />
        </Tabs>

        <div className={"drawer-content-container"}>
          <TabPanel value={props.value} index={1}>
            <div style={{ width: "100%", padding: "30px 30px" }}>
              <Grid spacing={3} container>
                <Grid item xs={6}>
                  <InputLabel className={classes.label}>
                    Nome lezione
              </InputLabel>
                  <FullTextField name="name" onChange={changeValue} value={input.name || ""} placeholder="Nome della lezione" variant="outlined" inputProps={{ className: classes.input }} />
                </Grid>
                <Grid item xs={6}>

                </Grid>
                <Grid item xs={12}>
                  <InputLabel className={classes.label}>
                    Descrizione
                  </InputLabel>
                  <FullTextField name="description" onChange={changeValue} value={input.description || ""} placeholder="Descrizione lezione" multiline rows={3} variant="outlined" inputProps={{ className: classes.textarea }} />
                </Grid>

                <Grid item xs={6}>
                  <InputLabel className={classes.label}>
                    Insegnanti
                  </InputLabel>
                  <Select name="teacher_id" value={input.teacher_id || undefined} onChange={changeValue} variant="outlined" className={classes.select}>
                    <MenuItem className={classes.selected}>Nessun insegnante</MenuItem>
                    {
                      teachers.map((teacher, index) => {
                        return <MenuItem className={classes.selected} key={index} value={teacher.id}>{teacher.first_name} {teacher.last_name}</MenuItem>
                      })
                    }

                  </Select>
                </Grid>

                <Grid item xs={6}>
                  <InputLabel className={classes.label}>
                    Sezione di accesso
                  </InputLabel>
                  <Select name="mandatory_section_id" value={input.mandatory_section_id || null} onChange={changeValue} variant="outlined" className={classes.select}>
                    <MenuItem className={classes.selected} value={null}>Nessuna lezione di accesso</MenuItem>
                    {
                      mandatorySections.map((section, index) => {
                        return <MenuItem className={classes.selected} key={index} value={section.id}>{section.name}</MenuItem>
                      })
                    }

                  </Select>
                </Grid>

                {props.lesson.category === "LESSON" &&
                  <Grid item xs={6} md={6}>
                    <InputLabel className={classes.label}>
                      Durata
                    </InputLabel>
                    <FullTextField
                      variant="outlined"
                      type="time"
                      name="duration"
                      value={input.duration || ""}
                      onChange={changeValue}
                      className={classes.textField}
                      inputProps={{ className: classes.inputNumber, step: "1" }}
                    />
                  </Grid>
                }


                <Grid item xs={6} md={3}>
                  <InputLabel className={classes.label}>
                    Obbligatoria
                  </InputLabel>
                  <AntSwitch
                    checked={checked}
                    setChecked={setchecked}
                  />
                </Grid>

                <Grid item xs={6} md={3}>
                  <InputLabel className={classes.label}>
                    Attiva
                  </InputLabel>
                  <AntSwitch
                    checked={checkedActive}
                    setChecked={setcheckedactive}
                  />
                </Grid>

                {["SURVEY", "EVALUATION"].includes(input.category) &&
                <Grid item xs={3}>
                    <InputLabel className={classes.label}>
                      Mostra pulsante 
                      <small className={classes.specialTextInLabel}>
                        Continua con le lezioni
                      </small>
                    </InputLabel>
                    <AntSwitch
                      checked={buttonShown}
                      setChecked={setButtonVisibility}
                    />
                </Grid>
                }
                {!thumb ?
                  <Grid item xs={6}>

                    <InputLabel className={classes.label}>
                      Thumbnail
                  </InputLabel>
                    <div onClick={setImage} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                      {!timeUpload ?
                        <React.Fragment>
                          <input ref={inputRef} style={{ visibility: "hidden", width: "1px" }} type="file" onChange={fileSelected} />
                          {photo ? "Cambia" : "Aggiungi"} immagine
                        {!photo ?
                            <AddCircleIcon className={classes.color} style={{ cursor: "pointer", paddingLeft: "10px", width: "40px", height: "40px" }} />
                            :
                            <DoneAllIcon style={{ paddingLeft: "10px", width: "40px", height: "40px" }} className={classes.color} />
                          }
                        </React.Fragment>
                        :
                        <p>
                          {timeUpload}
                        </p>
                      }
                    </div>

                  </Grid>
                  :
                  <Grid item xs={12} md={6} style={{ paddingTop: "20px", display: "flex", alignItems: "center", justifyContent: "center" }} >
                    <div>
                      <InputLabel className={classes.label}>
                        Thumbnail
                    </InputLabel>
                      <Button onClick={removeImage} style={photo === null ? { backgroundColor: "#3AAC4D", color: "#fff" } : { backgroundColor: COLORS.primary, color: "#fff" }}>
                        {photo === null ? "Immagine rimossa" : "Rimuovi immagine"}
                      </Button>
                    </div>
                    <div onClick={setImage} style={{ cursor: "pointer" }}>
                      {!timeUpload ?
                        <React.Fragment>
                          <input ref={inputRef} style={{ visibility: "hidden", width: "1px" }} type="file" onChange={fileSelected} />
                          {!photo ?
                            <AddCircleIcon className={classes.color} style={{ cursor: "pointer", paddingLeft: "10px", width: "40px", height: "40px" }} />
                            :
                            <DoneAllIcon style={{ paddingLeft: "10px", width: "40px", height: "40px" }} className={classes.color} />
                          }
                        </React.Fragment>
                        :
                        <p>
                          {timeUpload}
                        </p>
                      }
                    </div>
                  </Grid>
                }


                <Grid item xs={6} md={6}>
                  <InputLabel className={classes.label}>
                    Video / File
                  </InputLabel>
                  <div onClick={() => { setopen(true) }} style={{ display: "flex", alignItems: "center" }}>
                    {mediaType ? "Modifica media" : "Aggiungi media"}
                    <AddCircleIcon className={classes.color} style={{ cursor: "pointer", paddingLeft: "10px", width: "40px", height: "40px" }} />
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <InputLabel className={classes.label}>
                    Categoria lezione
                  </InputLabel>
                  <Select name="category" value={input.category || null} onChange={changeValue} variant="outlined" className={classes.select}>
                    <MenuItem className={classes.selected} value={"LESSON"}>Lezione</MenuItem>
                    <MenuItem className={classes.selected} value={"SURVEY"}>Sondaggio</MenuItem>
                    <MenuItem className={classes.selected} value={"EVALUATION"}>Verifica</MenuItem>
                  </Select>
                </Grid>

                {input.category === "SURVEY" &&
                  <Grid item xs={6}>
                    <InputLabel className={classes.label}>
                      Sondaggio
                  </InputLabel>
                    <Select name="survey_id" value={input.survey_id || null} onChange={changeValue} variant="outlined" className={classes.select}>
                      <MenuItem className={classes.selected} value={null}>Nessun sondaggio</MenuItem>
                      {
                        surveys.map((survey, index) => {
                          return <MenuItem className={classes.selected} key={index} value={survey.id}>{survey.name}</MenuItem>
                        })
                      }

                    </Select>
                  </Grid>
                }
                {input.category === "EVALUATION" &&
                  <Grid item xs={6}>
                    <InputLabel className={classes.label}>
                      Verifiche
                  </InputLabel>
                    <Select name="evaluation_id" value={input.evaluation_id || null} onChange={changeValue} variant="outlined" className={classes.select}>
                      <MenuItem className={classes.selected} value={null}>Nessuna Verifica</MenuItem>
                      {
                        evaluations.map((evaluation, index) => {
                          return <MenuItem className={classes.selected} key={index} value={evaluation.id}>{evaluation.name}</MenuItem>
                        })
                      }

                    </Select>
                  </Grid>
                }

              </Grid>
            </div>


          </TabPanel>


          <ModalMedia
            handleClose={handleCloseMedia}
            setCustomAlert={props.setCustomAlert}
            module_id={props.module_id}
            lesson={props.lesson}
            module={props.module}
            mediaType={mediaType}
            open={open}
            setReload={setreload}
            setOpen={setopen}
          />
        </div>

        <Paper elevation={3} className={classes.save + " button-container"}>

          <Button onClick={() => { props.setDelete(true) }} className={classes.btn_del_user}>Elimina</Button>
          <Button onClick={handleClose} className={classes.btn_del}>Annulla</Button>
          <Button disabled={(slugRegex.test(input.slug) || input.slug === "") && input.category && input.name ? false : true} onClick={modifyLesson} className={classes.btn_save}>Modifica</Button>

        </Paper>
      </div>

    </Drawer>
  );
}