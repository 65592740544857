import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteDialog from '../Dialog/DeleteDialog'
import moment from 'moment'
import 'moment/locale/it';
moment.locale('it')

const useStyles = makeStyles({
  table: {
    
  },
});



export default function TableTeachers(props) {
  const classes = useStyles();

  const [rows, setRows] = useState([])
  const [deleteMaterials, setDelete] = React.useState(false);
  const [material, setMaterial] = React.useState({});

  const setdelete = (input) => { setDelete(input) }


  useEffect(() => {
    if (props.materials.length >= 0) {
      setRows(
        props.materials.map((material) => {
          return {
            "id": material.id,
            "name": material.name,
            "type": material.type,
            "course_id": material.course_id,
            "created": moment(material.created).format('DD-MM-YYYY'),
          }
        })
      )

    }
  }, [props.materials])


  const [anchorEl, setAnchorEl] = useState(null);

  const modifyMaterial = (event, row) => {
    setAnchorEl(event.currentTarget);
    setMaterial(row)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={"table-label"} align="left">Nome</TableCell>
            <TableCell className={"table-label"} align="left">Tipo</TableCell>
            <TableCell className={"table-label"} align="left">Data creazione</TableCell>
            <TableCell className={"table-label"} align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow hover key={row.name}>
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="left">{row.type}</TableCell>
              <TableCell align="left">{row.created}</TableCell>
              <TableCell align="right">
                <MoreHorizIcon onClick={(e) => modifyMaterial(e, row)} style={{ width: "30px", height: "30px", color: "#999", cursor: "pointer" }} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem className="menu-table-del" onClick={() => setdelete(true)} >Elimina materiale</MenuItem>
        </Menu>
      </Table>

      <DeleteDialog
        deleteLesson={deleteMaterials}
        setDelete={setdelete}
        textDelete={"questo materiale"}
        handleCloseMenu={handleCloseMenu}
        lesson={material}
        setConfirm={props.setConfirm}
      />

    </TableContainer>
  );
}