import React, { useEffect, useState, useRef } from 'react';
import Sidebar from '../componentsAdmin/Sidebar/Sidebar';
import AdminRoutes from '../adminroutes';
import serviceAdmin from '../services/serviceAdmin';
import Alert from '../componentsAdmin/CollapseAlert/Alert';
import { SyncDetailExport } from '../componentsAdmin/Export/Utilsfunctions'

const Admin = (props) => {

  const isMount = useRef(true);

  const [state, setState] = useState({ "left": true });
  const [users, setUsers] = useState([]);
  const [courses, setCourses] = useState([]);
  const [groups, setGroups] = useState([]);
  const [roles, setRoles] = useState([]);
  const [reloadUser, setReloadUser] = useState(false)
  const [pageUser, setPageUser] = useState(1)
  const [rowsUser, setRowsUser] = useState(20)
  const [count, setCount] = useState(Number)
  const [searchString, setSearchString] = useState("")
  const [loading, setLoading] = useState(false)
  const [role_id, setRole_id] = useState("")
  const [group_id, setGroup_id] = useState("")
  const [ordering, setOrdering] = useState("")
  const [closeBack, setcloseBack] = useState(false)
  const [checkedActive, setCheckedActive] = useState(false)
  const [checkedStaff, setCheckedStaff] = useState(false)
  const [checkedGenerate, setCheckedGenerate] = useState(false)
  const [page, setPage] = useState(0);
  const [backdrop, setBackdrop] = useState(false)
  const [openAlert, setOpenAlert] = useState({});
  const [me, setMe] = useState(null)
  const [selected, setSelected] = useState([])
  const [all, setAll] = useState(false)
  const [annotation, setAnnotation] = useState("")

  useEffect(() => {
    serviceAdmin.getService("/v1/users/me/")
      .then(res => {
        if (res?.data?.data) {
          setMe(res?.data?.data)
        }
      }).catch(err => {
        console.log(err)
      })
  }, [])

  const setpage = (input) => { setPage(input) }
  const setbackdrop = (input) => { setBackdrop(input) }
  const setcheckedgenerate = () => { setCheckedGenerate(!checkedGenerate) }
  const setcheckedstaff = () => { setCheckedStaff(!checkedStaff) }
  const setcheckedactive = (input) => { setCheckedActive(input) }
  const setcloseback = () => { setcloseBack(!closeBack) }
  const setrole_id = (input) => { setRole_id(input) }

  const setgroup_id = (input) => {
    setGroup_id(input)
  }

  const setordering = (input) => { setOrdering(input) }
  const setsearchString = (input) => { setSearchString(input) }
  const setpageuser = (input) => { setPageUser(input) }
  const setrowsuser = (input) => { setRowsUser(input) }
  const changeReloadUser = () => { setReloadUser(!reloadUser) }
  const setcourses = (input) => { setCourses(input) }
  const setgroups = (input) => { setGroups(input) }

  const resetfilter = () => {
    setOrdering("");
    setCheckedActive(false);
    setRole_id("");
    setGroup_id("");
  }


  const setCustomAlert = (value, two_value, three_value) => {
    setOpenAlert({
      ...openAlert,
      "state": value,
      "severity": two_value,
      "message": three_value
    })
  };

  const exportTable = (info, callback, setComplete) => {
    const params = {
      "audit_filters": {
        "filters": []
      },
      "filters": {},
      "export_fmt": info
    }
    if (annotation) params.annotation = annotation;

    if (selected.length > 0 && !all) {
      params.filters.users_ids = selected;
    } else {
      if (group_id === "NO_GROUP") {
        params.filters.groups_empty = true;
        params.audit_filters.filters.push({ "label": "Utenti senza gruppo: ", "value": true, "type": "bool" })
      } else if (group_id) {
        params.filters.groups_ids = [group_id];
        const g = groups.find(group => group.id === group_id);
        params.audit_filters.filters.push({ "label": "Filtrato per gruppi: ", "value": g?.name })
      }
      if (role_id) {
        params.filters.roles_ids = [role_id];
        const r = roles.find(role => role.id === role_id);
        params.audit_filters.filters.push({ "label": "Filtrato per ruoli: ", "value": r?.name })
      }
      if (checkedActive === "false" || checkedActive === "true") {
        params.filters.is_active = checkedActive;
        params.audit_filters.filters.push({ "label": "Utenti attivi: ", "value": checkedActive, "type": "bool" })
      }
    }

    SyncDetailExport('/custom/v1/exports/users/', params, callback, setComplete, setCustomAlert)
  }

  useEffect(() => {
    const timeSearch = setTimeout(() => {
      if (searchString === "") {
        let url = `/v1/users/?page=${pageUser}&page_items=${rowsUser}`;

        if (group_id === "NO_GROUP") {
          url += `&groups_empty=true`;
        } else if (group_id) {
          url += `&groups=${group_id}`;
        }
        if (role_id) url += `&role=${role_id}`;
        if (ordering) url += `&ordering=${ordering}`;
        if (checkedActive === "false" || checkedActive === "true") url += `&is_active=${checkedActive}`;
        if (checkedStaff) url += `&is_staff=${checkedStaff}`;
        if (checkedGenerate) url += `&is_generated=${checkedGenerate}`;
        serviceAdmin.getService(url)
          .then(res => {
            if (isMount.current) {
              if (res.data.data) {
                setCount(res.data.meta.pagination.items_count)
                setUsers(res.data.data)
                setLoading(true)
                setPage(pageUser - 1)
                setBackdrop(false);
              }
            }
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        let url = `/v1/users/?page=${pageUser}&page_items=${rowsUser}&search=${searchString}`;

        if (group_id === "NO_GROUP") {
          url += `&groups_empty=true`;
        } else if (group_id) {
          url += `&groups=${group_id}`;
        }
        if (role_id) url += `&role=${role_id}`;
        if (ordering) url += `&ordering=${ordering}`;
        if (checkedActive) url += `&is_active=${checkedActive}`;
        if (checkedStaff) url += `&is_staff=${checkedStaff}`;
        if (checkedGenerate) url += `&is_generated=${checkedGenerate}`;
        serviceAdmin.getService(url)
          .then(res => {
            if (isMount.current) {
              if (res.data.data) {
                setCount(res.data.meta.pagination.items_count)
                setUsers(res.data.data)
                setPage(pageUser - 1)
                setLoading(true)
                setBackdrop(false);
              }
            }
          })
          .catch(err => {
            if (err.status === 404) {
              setBackdrop(false);
            }
          })
      }
    }, 250);

    return () => {
      clearTimeout(timeSearch)
    }
  }, [reloadUser, pageUser, rowsUser, searchString, role_id, group_id, ordering, checkedActive, checkedStaff, checkedGenerate])

  useEffect(() => {

    serviceAdmin.getService("v1/courses/")
      .then(res => {
        if (isMount.current) {
          if (res.status === 200) {
            setCourses(res.data.data)
          }
        }
      }).catch(err => {
        console.log(err)
      })


    serviceAdmin.getService("v1/groups/")
      .then(res => {
        if (isMount.current) {
          if (res.status === 200) {
            setGroups(res.data.data)
          }
        }
      }).catch(err => {
        console.log(err)
      })

    serviceAdmin.getService("v1/roles/")
      .then(res => {
        if (isMount.current) {
          if (res.status === 200) {
            setRoles(res.data.data)
          }
        }
      }).catch(err => {
        console.log(err)
      })

    return () => {
      isMount.current = false;
    }
  }, [])



  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, "left": open });
  };


  return (

    <div className="container-admin">
      <Sidebar setCloseBack={setcloseback} userMe={me} state={state} toggleDrawer={toggleDrawer} history={props.hist} {...props} />

      <div className="content-admin" style={state.left ? { paddingLeft: "122px" } : { paddingLeft: "20px" }}>
        <Alert
          setOpenAlert={setCustomAlert}
          severity={openAlert?.severity}
          openAlert={openAlert?.state}
          message={openAlert?.message}
          noAutoHide
        />
        <AdminRoutes
          {...props}
          setAnnotation={setAnnotation}
          annotation={annotation}
          setAll={setAll}
          all={all}
          setSelected={setSelected}
          exportTable={exportTable}
          showReset={(checkedActive || role_id || group_id || ordering) ? true : false}
          resetFilter={resetfilter}
          setCustomAlert={setCustomAlert}
          backdrop={backdrop}
          setBackdrop={setbackdrop}
          setPage={setpage}
          page={page}
          pageUser={pageUser}
          checkedGenerate={checkedGenerate}
          setCheckedGenerate={setcheckedgenerate}
          checkedActive={checkedActive}
          setCheckedActive={setcheckedactive}
          checkedStaff={checkedStaff}
          setCheckedStaff={setcheckedstaff}
          ordering={ordering}
          setOrdering={setordering}
          closeBack={closeBack}
          setRole_id={setrole_id}
          setGroup_id={setgroup_id}
          role_id={role_id}
          group_id={group_id}
          setSearchString={setsearchString}
          searchString={searchString}
          count={count}
          setPageUser={setpageuser}
          setRowsUser={setrowsuser}
          setCourses={setcourses}
          setGroups={setgroups}
          setAdmin={props.setAdmin}
          history={props.hist}
          users={users}
          groups={groups}
          roles={roles}
          courses={courses}
          changeReloadUser={changeReloadUser}
          loading={loading}
        />

      </div>

    </div>

  );
}

export default Admin;