import React, { useState } from 'react'
import { Toolbar, Typography, Button, Menu, MenuItem } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import useColor from '../../../context/themeContext'
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles((theme) => ({
  input: {
    padding: "12px 20px"
  },
  toolbar: {
    width: "100%"
  }
}))


const CssTextField = withStyles({
  root: {
    border: "1px solid #ccc",
    width: "100%",
    maxWidth: "300px",
    borderRadius: "20px",
    padding: "0",
    margin: "20px 0",
    marginRight: "20px",

    '& .MuiInput-underline:after': {
      border: 'none',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
  },
})(TextField);
const EnhancedTableToolbar = (props) => {

  const { COLORS } = useColor();
  const classes = useStyles();

  const { all, setAll, numSelected, removeSelected, closeExport, openExport, anchorEl, labelSing, labelArt, labelPlu, exportTable, setAnnotation, annotation } = props;

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(null)
  const [info, setInfo] = useState("")

  function exportData(info) {
    setInfo(info)
    exportTable(info, setData, setLoading);
    closeExport();
  }

  function selectAll() {
    setAll(true)
  }

  function deselectAll() {
    removeSelected();
    setAll(false)
  }

  return (
    <Toolbar className={classes.toolbar}>
      {numSelected > 0 ? (
        <React.Fragment>
          {!all ?
            <Typography color="inherit" variant="subtitle1" component="div">
              {numSelected === 1 ? `${numSelected + " " + labelSing} selezionato` : `${numSelected + " " + labelPlu} selezionati`}
            </Typography>
            :
            <Typography color="inherit" variant="subtitle1" component="div">
              {`Tutti ${labelArt} selezionati`}
            </Typography>
          }
          <Button onClick={deselectAll} style={{ marginLeft: "auto", marginRight: "20px" }}>
            Deseleziona tutti
            </Button>
          <Button onClick={selectAll} style={{ marginRight: "20px", border: "1px solid " + COLORS.primary }}>
            Seleziona tutti
          </Button>

          <CssTextField
            placeholder="Etichetta export"
            variant="outlined"
            onChange={(e) => setAnnotation(e.target.value)}
            value={annotation || ""}
            inputProps={{ className: classes.input }}
          />
          <div
            style={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
            }}>

            {!data ?

              <Button
                endIcon={<ArrowDropDownIcon />}
                onClick={openExport}
                //onMouseOver={openExport}
                style={{ background: "#3fbe54", color: "#fff", marginRight: "20px", padding: "8px 15px" }}>
                Esporta
            </Button>

              :
              <Button
                onClick={() => {
                  setData(null);
                }}
                download
                href={info === "json" ? "data:application/json," + data : data}
                style={{ background: "#3fbe54", color: "#fff", marginRight: "20px", padding: "8px 15px" }}>
                Scarica
            </Button>

            }

            {loading &&
              <CircularProgress
                size={24}
                style={{
                  color: "#fff",
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -12,
                  marginLeft: -12,
                }} />
            }

          </div>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={closeExport}
            MenuListProps={{ onMouseLeave: closeExport }}
          >
            <MenuItem onClick={() => exportData("json")}>Esporta come Json</MenuItem>
            <MenuItem onClick={() => exportData("yaml")}>Esporta come Yaml</MenuItem>
            <MenuItem onClick={() => exportData("excel")}>Esporta come Excel</MenuItem>
            <MenuItem onClick={() => exportData("csv")}>Esporta come Csv</MenuItem>
          </Menu>
        </React.Fragment>
      ) : null}

    </Toolbar>
  );
};

export default EnhancedTableToolbar;