import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import PrivateRoute from '../components/Privateroute/Privateroute'
import PrivateLimRoute from '../components/Privateroute/PrivateLimRoute'
import { createBrowserHistory } from 'history'
import Admin from './Admin'
import Learning from './Learning'
import LiteLayout from './LiteLayout'
import { AlertProvider, RenderAlert } from '../hooks/useAlert'
import { Helmet } from "react-helmet"
import { themeContext } from '../context/themeContext'
import switchLayoutsHelper from "./helpers/switchLayouts"
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core'
import { ModalAlertProvider, RenderModalAlert } from '../hooks/useModalAlert'
import { pink } from '@material-ui/core/colors'

const hist = createBrowserHistory()

const SwitchLayouts = () => {
    const [title, setTitle] = React.useState("")
    const [description, setDescription] = React.useState("")
    const [font, setFont] = React.useState({})
    const [favicon, setFavicon] = React.useState("")
    const [loading, setLoading] = React.useState(true)
    const [themeOptions, setThemeOptions] = React.useState({
        primary: "#ccc"
    })

    const theme = createMuiTheme({
        fontFamily: font.name || "Lato",
        typography: {
            fontFamily: font.name || "Lato"
        },
        overrides: {
            MuiPickersClock: {
                pin:{
                    color: themeOptions.primary,
                    backgroundColor: themeOptions.primary,
                },
              },
            MuiPickersClockPointer:{
                pointer:{
                    color: themeOptions.primary,
                    backgroundColor: themeOptions.primary, 
                },
                thumb:{
                    backgroundColor: themeOptions.primary, 
                    borderColor:  themeOptions.primary
                },
                noPoint:{
                    backgroundColor: themeOptions.primary, 
                }
            },
            MuiPickersToolbar: {
                toolbar: {
                  backgroundColor: themeOptions.primary,
                },
              },
              MuiPickersCalendarHeader: {
                switchHeader: {
                    //backgroundColor: themeOptions.primary,
                    //color: "white",
                },
              },
              MuiPickersYear:{
                  yearSelected:{
                    color: themeOptions.primary,
                  }
              },
              MuiPickersDay: {
                day: {
                  color: themeOptions.primary,
                },
                daySelected: {
                  backgroundColor: themeOptions.primary,
                },
                dayDisabled: {
                  color: themeOptions.primary,
                },
                current: {
                  color: themeOptions.primary,
                },
              },
              MuiPickersModal: {
                dialogAction: {
                  color: themeOptions.primary,
                },
                withAdditionalAction: {
                    color: themeOptions.primary,
                }
              },
              MuiPickerDTTabs:{
                  tabs:{
                      backgroundColor: themeOptions.primary
                  }
              },
              MuiTabs:{
                  indicator:{
                      backgroundColor: "#4c23f1"
                  }
              },
              MuiButton:{
                textPrimary: {
                    color: themeOptions.primary
                }
              }
        }
    });

    React.useEffect(() => {
        switchLayoutsHelper.fetchPersonalizationTheme()
            .then(theme => {
                setTitle(theme.headers.title)
                setDescription(theme.headers.description)
                let logoUrl = "";
                let brand_logo = "";
                let brand_logo_nav = "";
                if (theme.hasOwnProperty("images")) {
                    theme.images.map(item => {
                        if (item.tag == "favicon") {
                            setFavicon(item.src.original)
                        }
                        if (item.tag == "logo_main") {
                            logoUrl = item.src.original;
                        }
                        if (item.tag == "brand_logo") {
                            brand_logo = item.src.original
                        }
                        if (item.tag == "brand_logo_nav") {
                            brand_logo_nav = item.src.original
                        }
                    })
                }
                if (theme.hasOwnProperty("font")) {
                    setFont(theme.font)
                }
                setThemeOptions({ ...themeOptions, "content": theme.content, "primary": theme.colors.primary, "logoUrl": logoUrl, "brand_logo": brand_logo, "brand_logo_nav": brand_logo_nav })
                setLoading(false)
            })
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <themeContext.Provider value={[themeOptions, setThemeOptions]}>
                <Helmet>
                    <title>{title}</title>
                    <link rel="icon" href={favicon} />
                    <link href={font.url || "https://fonts.googleapis.com/css?family=Lato:400,600,700"} rel="stylesheet"></link>
                    <meta name="description" content={description} />
                </Helmet>
                <Router history={hist}>
                    {!loading &&

                        <AlertProvider>
                            <RenderAlert />
                            <Switch>
                                <PrivateLimRoute path='/backoffice-lite' component={() => <LiteLayout hist={hist} />} />
                                <PrivateRoute path='/backoffice' component={() => <Admin hist={hist} />} />
                                <Route path='/' component={() => <Learning hist={hist} />} />
                            </Switch>
                        </AlertProvider>

                    }
                </Router>
            </themeContext.Provider>
        </ThemeProvider>
    )
}
export default SwitchLayouts