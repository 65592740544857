import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import useColor from '../../../context/themeContext';




const InfoBox = (props) => {
	const { COLORS } = useColor()
	const useStyles = makeStyles((theme) => ({
		flex_align: {
			display: "flex",
			flexDirection: "row",
			padding: "15px 0px",
		},
		containIcon: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "flex-start"
		},
		containTitle: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "flex-start",
			marginLeft: 10
		},
		title: {
			color: COLORS.primary,
			fontSize: "18px",
			fontWeight: "bold"
		},
		subtitle: {
			fontSize: 20
		},
		icon: {
			fontSize: 30
		}
	}));
	const classes = useStyles();
	const { icon, title, subtitle } = props;

	const Icon = icon;

	return (
		<div className={classes.flex_align}>
			<div className={classes.containIcon}>
				<Icon className={classes.icon} />
			</div>
			<div className={classes.containTitle}>
				<span className={classes.subtitle}>{subtitle}</span>
				<p className={classes.title}>{title}</p>
			</div>
		</div>
	)
}
export default InfoBox;