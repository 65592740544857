import React from "react"
import useColor from '../../context/themeContext'

export default function Statistics(props) {
    
    const { COLORS } = useColor()
    const color = { color: COLORS.primary }

    return (
        <div className={"box-statistics"}>
            <p className={"title-statistics"}>{props.title}</p>
            <div className={"view-value-stat"}>
                <span style={color} className={"value-statistics"}>{props.dayValue} <span className={"label-statistics"}>oggi</span></span>
                <span style={color} className={"value-statistics"}>{props.weekValue} <span className={"label-statistics"}>settimanali</span></span>
            </div>
        </div>
    )
}
