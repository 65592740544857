import React from 'react'
import { BarChart, CartesianGrid, YAxis, XAxis, Legend, Bar, Tooltip } from 'recharts'
import useColor from '../../context/themeContext'

const BarCharts = () => {
	

	const { COLORS } = useColor()

	const data = [
		{
			"name": "CORSO EXTRA",
			"iscritti": 30000
		},
		{
			"name": "CORSO TEST EX",
			"iscritti": 24000,
		},
		{
			"name": "CORSO DOGE",
			"iscritti": 30000
		}
	]

	return (
		<BarChart style={{ marginTop: "20px" }} layout={"horizontal"} width={400} height={350} data={data}>
			<XAxis dataKey="name" />
			<YAxis />
			<Tooltip />
			<Legend />
			<Bar dataKey="iscritti" fill={COLORS.primary} />
		</BarChart>
	)
}

export default BarCharts;