import React from 'react';
import {
    Grid, Container, Button, CardMedia,
    Paper, IconButton, Collapse
} from '@material-ui/core/'
import Test from '../components/Test/Test'
import LessonBox from "../components/VideoCourse/LessonBox"
import VideoStart from "../components/VideoCourse/VideoStart"
import TeachingMaterial from "../components/VideoCourse/TeachingMaterial"
import RelatedColumn from '../components/VideoCourse/relatedColumn';
import HolderBox from "../components/Course/HolderBox"
import { useEffect, useState, useRef } from 'react';
import Service from '../services/services';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import TrackableVideo from "../components/VideoPlayer"
import FileSession from "../components/FileSession/FileSession"
import services from '../services/services';
import useColor from '../context/themeContext'
import RootRef from '@material-ui/core/RootRef';
import { useParams } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/it';
moment.locale('it')

const SectionPreview = (props) => {

    const { COLORS } = useColor()

    const { id } = useParams()
    const id_silent = props.location?.state?.course_id
    const isMount = useRef(true);
    const colRef = useRef()

    const [colHeight, setHeight] = useState(0)
    const [loading, setloading] = useState(true)
    const [loadingModuls, setloadingModuls] = useState(true)
    const [thematics, setThematics] = useState([])
    const [teachers, setTeachers] = useState({})
    const [lessons, setLessons] = useState(0)
    const [currentTime, setcurrentTime] = useState(null)
    const [course, setCourse] = useState({})
    const [lessonCurrent, setLessonCurrent] = useState(null)
    const [url, setUrl] = useState("")
    const [width, setwidth] = useState(window.innerWidth)
    const [lessonSelected, setlessonSelected] = useState(null)
    const [materials, setMaterials] = useState([])
    const [sub_id, setSub_id] = useState(null)
    const [reloadComplete, setReloadComplete] = useState(false)
    const [sections, setSections] = useState(null)
    const [passedsections, setPassedSections] = useState(0)
    const [mandatoryCourse, setMandatoryCourse] = useState(false)
    const [date, setDate] = useState(null)
    const [start, setStart] = useState(false)
    const [passed, setPassed] = useState(false)
    const [openAlert, setOpenAlert] = useState(false)
    const [subExpired, setSub_expired] = useState(false)
    const [expired_date, setExpired_date] = useState("")

    const checkExist = () => {
        if (colRef?.current)
            return true
        return false
    }

    const setreloadComplete = () => { setReloadComplete(!reloadComplete) }

    const Backfunc = () => { props.history.push(`/backoffice/courses?course=${id || id_silent}`) }

    const getWidth = () => window.innerWidth;

    const handleResize = () => {
        if (checkExist())
            setHeight(colRef?.current.offsetHeight)
    }

    useEffect(() => {
        if (id) {
            Service.getService(`v1/courses/${id}/`)
                .then(res => {
                    if (res.data.data) {
                        setCourse(res.data.data)
                    }

                }).catch(err => {
                    console.log(err)
                })

            Service.getService(`v1/courses/${id}/teaching-materials/`)
                .then(res => {
                    if (isMount.current) {
                        if (res.data.data) {
                            setMaterials(res.data.data)
                        }
                    }
                }).catch(err => {
                    console.log(err);

                })


            Service.getService(`v1/courses/${id}/course-themes/`)
                .then(res => {
                    if (isMount.current) {
                        if (res.data.data) {
                            const thematicsMapped = res.data.data.map(item => {
                                return {
                                    ...item,
                                    openAccordion: true
                                }
                            })
                            setThematics(thematicsMapped);
                            setloadingModuls(false);
                        }
                    }
                }).catch(err => {
                    console.log(err);

                })
        }
    }, [id])

    function setLessoncurrent(input, play) {
        setStart(play)
        setlessonSelected(input.slug)
        setTeachers(input.teacher)
        setloading(false)

        //user_url
        const course_section_url = `v1/course-modules/${input.module_id || input.module?.id}/sections/${input?.id}/`

        Service.getService(course_section_url)
            .then(res => {
                setLessonCurrent(res.data.data);
                const time = moment.duration(res.data.data?.duration).asSeconds();
                if (Number(res.data.data?.step_time) <= time) {
                    setcurrentTime(Number(res.data.data?.step_time))
                } else {
                    setcurrentTime(0)
                }
                const media = res.data.data.media_file?.link;
                if (res.data.data.media_file === undefined || res.data.data.media_file === null) {
                    setUrl("");

                } else { setUrl(media) }

                handleResize()
            }).catch(err => {
                console.log(err)
            })

    }
    let calcLesson = 0;

    const setlessons = (input) => {
        calcLesson += input;
        setLessons(calcLesson)
    }

    const setNextLesson = () => {
        if (lessonCurrent.next_section_id) {
            const next_url = `v1/preview/course-sections/${lessonCurrent?.id}/next/`
            services.getService(next_url)
                .then(res => {
                    if (res?.data?.data?.is_accessible) {
                        setLessoncurrent(res.data.data);
                    }
                }).catch(err => {
                    console.log('[Video.setNext]', err)
                })
        }
    }

    useEffect(() => {
        const resizeListener = () => {
            setwidth(getWidth())

            handleResize()
        };
        window.addEventListener("resize", resizeListener)

        return () => {

            isMount.current = false;
            window.removeEventListener("resize", resizeListener)
            setLessonCurrent(null)
        }
    }, [])


    const backButtonDesktop = () => {
        if (props.isMoreCourses) {
            return (
                <div className="backCourse">
                    <Button style={{ background: COLORS.primary, color: "#fff" }} onClick={Backfunc}>
                        <ArrowBackIcon />
                        <h2>Torna alla modifica del corso</h2>
                    </Button>
                </div>
            )
        }
        return null
    }

    const backButtonMobile = () => {
        const isMobile = width <= 500
        const isLesson = lessonCurrent?.category === "LESSON"
        if (props.isMoreCourses && isMobile && isLesson) {
            return (
                <div className="backCourse">
                    <Button className="button_back" onClick={Backfunc}>
                        <ArrowBackIcon />
                        <h2>Torna alla modifica del corso</h2>
                    </Button>
                </div>
            )
        }
        return null
    }


    return (
        <React.Fragment>
            <React.Fragment>
                {id ?
                    <React.Fragment>
                        <div style={{ height: "40px", background: COLORS.primary }}></div>
                        <div style={{ height: "30px", background: "#f2f3f4" }}></div>

                        <Container style={{ maxWidth: "1400px" }}>
                            <Collapse in={openAlert}>
                                <Alert
                                    style={{ marginBottom: "10px" }}
                                    severity="warning"
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                                setOpenAlert(false);
                                            }}
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                >
                                    Connessione interrotta, riprova
                                </Alert>
                            </Collapse>
                            {/* BACK TO COURSES __Desktop*/}

                            <Grid style={{ justifyContent: "space-between", alignItems: "center", paddingRight: 20 }} item container xs={12} xl={8} lg={8} md={7}>
                                {backButtonDesktop()}
                                <h1 style={{color: "red", fontSize: 20, fontWeight: "bold"}}>SEI IN MODALITÀ ANTEPRIMA</h1>
                            </Grid>
                            {lessonCurrent?.category === "LESSON" ? <div className={"conditional_height"}></div> : null}
                            <Grid direction={"row"} alignItems="flex-start" container spacing={2}>
                                <RootRef rootRef={colRef}>
                                    <Grid style={{ margin: 0 }} item container spacing={2} xs={12} xl={8} lg={8} md={7}>

                                        {(lessonCurrent?.category === "LESSON" && (lessonCurrent?.media_file?.type === "VIDEO" || lessonCurrent?.media_file?.type === "YT" || lessonCurrent?.media_file?.type === "VIMEO")) &&

                                            <Grid style={width <= 500 ? { position: "fixed", zIndex: "2", top: "50px", left: "0", padding: 0 } : null} container item xs={12}>

                                                <TrackableVideo
                                                    handleResize={handleResize}
                                                    is_preview={true}
                                                    onStart={() => { }}
                                                    onEnd={() => {
                                                        setreloadComplete();
                                                        setNextLesson();
                                                    }}
                                                    onPlay={() => { }}
                                                    onPause={() => { }}
                                                    hasAutoplay={start}
                                                    src={url}
                                                    currentTime={currentTime}
                                                    section={lessonCurrent}
                                                    setReloadComplete={setreloadComplete}
                                                />
                                            </Grid>

                                        }

                                        {(lessonCurrent?.category === "SURVEY" || lessonCurrent?.category === "EVALUATION") &&

                                            <Grid container item xs={12}>
                                                <Test
                                                    handleResize={handleResize}
                                                    is_preview={true}
                                                    setReloadComplete={setreloadComplete}
                                                    is_passed={lessonCurrent?.is_passed}
                                                    next={lessonCurrent?.next_section_id}
                                                    setLesson={setLessoncurrent}
                                                    lesson_id={lessonCurrent.id}
                                                    module_id={lessonCurrent.module.id}
                                                    category={lessonCurrent?.category}
                                                    has_button={lessonCurrent?.has_button}
                                                />
                                            </Grid>

                                        }

                                        {(lessonCurrent?.category === "LESSON" && lessonCurrent?.media_file?.type === "FILE") &&

                                            <Grid container item xs={12}>
                                                <FileSession
                                                    is_preview={true}
                                                    name={lessonCurrent?.media_file?.name}
                                                    src={lessonCurrent?.media_file?.link}
                                                    setReloadComplete={setreloadComplete}
                                                    is_passed={lessonCurrent?.is_passed}
                                                    next={lessonCurrent?.next_section_id}
                                                    setLesson={setLessoncurrent}
                                                    lesson_id={lessonCurrent.id}
                                                    module_id={lessonCurrent.module.id}
                                                    category={lessonCurrent?.category}
                                                    duration={lessonCurrent?.duration}
                                                    onEnd={() => {
                                                        setreloadComplete();
                                                        setNextLesson();
                                                    }}
                                                />
                                            </Grid>

                                        }
                                        {!lessonCurrent ?
                                            <Grid item xs={12}>
                                                {course.thumbnail ?
                                                    <CardMedia
                                                        style={{
                                                            height: "50vh"
                                                        }}
                                                        image={course.thumbnail?.large || ""}
                                                    />
                                                    : null}
                                                <VideoStart
                                                    is_preview={true}
                                                    id={id}
                                                    show_expiration={course.show_expiration_alert}
                                                    sub_expiration_date={expired_date}
                                                    course_expiration_date={course.expiration_date}
                                                    has_blocking_subscription={course.has_blocking_subscriptions}
                                                    has_expired={course.has_expired}
                                                    sub_expired={subExpired}
                                                    sub_id={sub_id}
                                                    title={module.module_title}
                                                    setLesson={setLessoncurrent}
                                                    lessonCurrent={lessonCurrent}
                                                    sections={sections}
                                                    passedsections={passedsections}
                                                />
                                                {course?.additional_text && width >= 960 ?
                                                    <Paper className="module-box" style={{ marginTop: "10px", borderRadius: "0px" }}>
                                                        <p
                                                            dangerouslySetInnerHTML={{
                                                                __html: course?.additional_text
                                                            }}
                                                            style={{
                                                                textAlign: "center", fontSize: "14px", color: COLORS.primary
                                                            }}>
                                                        </p>
                                                    </Paper>
                                                    : null}

                                            </Grid>
                                            : null}



                                        {width < 960 && lessonCurrent ? null :
                                            <Grid container item xs={12}>


                                                {lessonCurrent && width >= 960 ?

                                                    <Grid item xs={12}>

                                                        <LessonBox
                                                            loading={loading}
                                                            module_title={lessonCurrent?.name}
                                                            module_teacher={"lessonCurrent?.teacher"}
                                                            module_text={lessonCurrent?.description}
                                                        />
                                                        {course?.additional_text ?
                                                            <Paper className="module-box" style={{ marginTop: "10px", borderRadius: "0px" }}>
                                                                <p dangerouslySetInnerHTML={{ __html: course?.additional_text }} style={{ textAlign: "center", fontSize: "14px", color: COLORS.primary }}></p>
                                                            </Paper>
                                                            : null}
                                                    </Grid>
                                                    : null}

                                            </Grid>}


                                        <React.Fragment>
                                            <Grid item xs={12} xl={6} lg={6} md={6}>

                                                {materials?.length > 0 ?
                                                    <TeachingMaterial
                                                        documents={materials}
                                                    />
                                                    : null}

                                            </Grid>

                                            <Grid item xs={12} xl={6} lg={6} md={6}>
                                                {teachers?.length > 0 ?
                                                    <HolderBox
                                                        teachers={teachers}
                                                    />
                                                    : null
                                                }
                                            </Grid>
                                        </React.Fragment>


                                    </Grid>
                                </RootRef>
                                <Grid style={{ margin: 0 }} item container spacing={2} xs={12} md={4}>
                                    <Grid item xs={12}>
                                        {/* BACK TO COURSES __Mobile*/}
                                        {backButtonMobile()}
                                        <RelatedColumn
                                            colHeight={colHeight}
                                            is_preview={true}
                                            has_blocking_subscription={course.has_blocking_subscriptions}
                                            passed={passed}
                                            has_expired={course.has_expired}
                                            sub_expired={subExpired}
                                            date={date}
                                            sections={sections}
                                            passedsections={passedsections}
                                            mandatoryCourse={mandatoryCourse}
                                            course_description={course.description}
                                            reloadComplete={reloadComplete}
                                            sub_id={sub_id}
                                            course_code={course.code}
                                            has_certification={course.has_certification}
                                            course_id={course.id}
                                            thematics={thematics}
                                            lessons={lessons}
                                            setLesson={setLessoncurrent}
                                            loading={loadingModuls}
                                            width={width}
                                            setLessons={setlessons}
                                            courseName={course.name}
                                            lessonSelected={lessonSelected}
                                            lessonCurrent={lessonCurrent}
                                        />
                                        {width <= 500 && course?.additional_text ?
                                            <Paper className="module-box" style={{ marginTop: "10px", borderRadius: "0px" }}>
                                                <p dangerouslySetInnerHTML={{ __html: course?.additional_text }} style={{ textAlign: "center", fontSize: "14px", color: COLORS.primary }}></p>
                                            </Paper>
                                            : null}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <div className="line-spacing"></div>
                        </Container>
                    </React.Fragment>
                    : null}
            </React.Fragment>
        </React.Fragment>

    )



}

export default SectionPreview;