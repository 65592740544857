import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Utente from "./Utente"
import Corsi from "./Corsi"
import RecentActorsIcon from '@material-ui/icons/RecentActorsOutlined'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'
import { tabsStyle } from '../../classes'
import CustomTooltip from '../StyledComponent/CustomTooltip'
import useColor from '../../context/themeContext'



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div p={3}>
          {children}
        </div>
      )}
    </div>
  );
}


TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CenteredTabs(props) {
  const { COLORS } = useColor();
  const useStyles = makeStyles(tabsStyle(COLORS));
  const classes = useStyles();
  const [value, setValue] = React.useState(props.value);



  const handleText = (value) => {
    switch (value) {
      case "user":
        return "Qui puoi modificare i dati di un utente"
      case "sub":
        return "Qui puoi iscrivere un utente ad un corso e vedere i corsi a cui è iscritto"
      default:
        break;
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <React.Fragment>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href={"#"} onClick={props.handleClose} className={classes.link}>
          <RecentActorsIcon className={classes.icon} />
          <span className={classes.span}>Utenti</span>
        </Link>
        <Link color="inherit" className={classes.link_active}>
          <span className={classes.span}>

            {props.user.firstname + " " + props.user.lastname}
          </span>
        </Link>
      </Breadcrumbs>
      <Paper className={classes.root}>

        <Tabs
          className={classes.tabs}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          scrollButtons="auto"
          variant="scrollable"
        >
          <CustomTooltip enterDelay={1000} title={`${handleText("user")}`} placement={"top"}>
            <Tab className={classes.tab} label="Dati utente" {...a11yProps(0)} />
          </CustomTooltip>
          <CustomTooltip enterDelay={1000} title={`${handleText("sub")}`} placement={"top"}>
            <Tab className={classes.tab} label="Iscrizioni"       {...a11yProps(1)} />
          </CustomTooltip>
        </Tabs>
      </Paper>
      <div className={"content-menu"}>
        <TabPanel value={value} index={0}>

          <Utente setCustomAlert={props.setCustomAlert} setdelete={props.setdelete} changeReloadUser={props.changeReloadUser} handleClose={props.handleClose} user={props.user} />

        </TabPanel>
        <TabPanel value={value} index={1}>

          <Corsi setCustomAlert={props.setCustomAlert} courses={props.courses} handleClose={props.handleClose} user={props.user} />

        </TabPanel>
      </div>
    </React.Fragment>
  );
}