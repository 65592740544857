import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import TableTeachers from '../Table/TableTeachers';
import { useEffect } from 'react';
import serviceAdmin from '../../services/serviceAdmin'
import useColor from '../../context/themeContext'




const Teachers = (props) => {
    const { COLORS } = useColor();
    const useStyles = makeStyles({
        title: {
            color: "#4c23f1",
            fontSize: "20px",
            fontWeight: "bold",
            paddingBottom: "10px"
        },
        subtitle: {
            fontWeight: "bold",
            paddingBottom: "10px"
        },
        select: {
            width: "100%",
            maxWidth: "235px",
            margin: "30px 0"
        },
        color: {
            color: COLORS.primary + "!important"
        }

    });
    const classes = useStyles();

    const [open, setOpen] = useState(false)
    const [openModify, setOpenModify] = useState(false);
    const [reload, setReload] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [teacher_id, setTeacher_id] = useState(null)

    const setconfirm = (input, teacher) => {
        setConfirm(input)
        setTeacher_id(teacher.id)
    }

    const setreload = () => {
        setReload(true)
    }

    const setopen = () => {
        setOpen(true)
    }
    const setopenmodify = (input) => {
        setOpenModify(input)
    }

    useEffect(() => {
        if (reload) {
            serviceAdmin.getService(`v1/teachers/`)
                .then(res => {
                    props.setTeachers(res.data.data)
                    setReload(false)
                }).catch(err => {
                    console.log(err)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload])

    useEffect(() => {
        if (confirm && teacher_id) {
            serviceAdmin.deleteService(`v1/teachers/${teacher_id}/`)
                .then(res => {
                    if (res.status === 200) {
                        setReload(true);
                        props.setCustomAlert(true, "success", "Insegnante eliminato correttamente!");
                        setOpenModify(false)
                        setConfirm(false)
                        setTeacher_id(null)
                    }
                }).catch(err => {
                    console.log(err)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teacher_id])

    return (
        <div>
            <TableTeachers open={openModify} setOpen={setopenmodify} setCustomAlert={props.setCustomAlert} setReload={setreload} setConfirm={setconfirm} teachers={props.teachers} />
        </div>
    )

}

export default Teachers;