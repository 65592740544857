import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TableCorsi from '../Table/TableCorsi';
import TableCoursesCategories from '../Table/TableCoursesCategories';
import TableCoursesTypologies from '../Table/TableCoursesTypologies';
import TransitionModal from '../Modal/Modal';
import useColor from '../../context/themeContext'
import serviceAdmin from '../../services/serviceAdmin';
import { tabsStyle } from '../../classes'
import Teachers from '../TabsCoursesModal/Teachers';
import { useParams } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/it';
moment.locale('it')

function TabPanel (props) {
  const { children, value, index, ...other } = props;

  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div p={3}>
          {children}
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps (index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const CoursesMenu = (props) => {
  const { COLORS } = useColor()
  const useStyles = makeStyles(tabsStyle(COLORS));
  const classes = useStyles();
  const [valueModal, setValueModal] = useState(0);
  const [course, setCourse] = useState(null)
  const [confirmCat, setConfirmCat] = useState(false)
  const [category_id, setCategory_id] = useState(null)
  const [confirmTypo, setConfirmTypo] = useState(false)
  const [typology_id, setTypology_id] = useState(null)
  const [teachers, setTeachers] = useState([])

  ///** ID DEL CORSO IN PREVIEW */
  const id = getIdFromUrl()

  function getIdFromUrl() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    return params.course;
  }

  const setconfirmCat = (input, category) => {
    setConfirmCat(input)
    setCategory_id(category.id)
  }

  const setconfirmTypo = (input, typology) => {
    setConfirmTypo(input)
    setTypology_id(typology.id)
  }

  const handleChange = (event, newValue) => {
    props.setValue(newValue);
  };

  const setteachers = (input) => {
    setTeachers(input)
  }

  const setopen = (input) => { props.setOpen(input) }

  useEffect(()=>{     
    if(id && props.courses){
      var copy_courses = [...props.courses];
      var preview_course = copy_courses.filter(x => x.id == id ).map((course) => {
        return {
          "created": moment(course.created).format('DD-MM-YYYY'),
          "is_mandatory": course.is_mandatory,
          "subscriptions_count": course.subscriptions_count,
          "code": course.code,
          "thumbnail": course?.thumbnail?.thumbnail,
          "slug": course.slug,
          "name": course.name,
          "state": course.status,
          "id": course.id,
          "parent_category": course.category?.parent ? course.category.parent.name : course.category?.name,
          "category": course.category?.parent ? course.category?.name : null,
        }
      })
      
      if(preview_course.length > 0){
        setopen(true)
        setCourse(preview_course[0])
        setValueModal(1);
      }
    }
  },[id, props.courses])


  useEffect(() => {
    serviceAdmin.getService(`v1/teachers/`)
        .then(res => {
          setTeachers(res.data.data)
        }).catch(err => {
          console.log(err)
        })

  }, [])

  useEffect(() => {
    if (confirmCat && category_id) {
      serviceAdmin.deleteService(`v1/course-categories/${category_id}/`)
        .then(res => {
          props.setreloadCat();
        }).catch(err => {
          console.log(err)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category_id])

  useEffect(() => {
    if (confirmTypo && typology_id) {
      serviceAdmin.deleteService(`v1/course-typologies/${typology_id}/`)
        .then(res => {
          props.setreloadTypo();
        }).catch(err => {
          console.log(err)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typology_id])

  return (
    <React.Fragment>
      {!props.open ?
        <Paper className={classes.container + " " + classes.paperPad}>
          <Tabs
            className={classes.tabs}
            value={props.value}
            onChange={handleChange}
            scrollButtons="auto"
            variant="scrollable"
          >
            <Tab className={classes.tab} label="Corsi" {...a11yProps(0)} />
            <Tab className={classes.tab} label="Categorie"      {...a11yProps(1)} />
            <Tab className={classes.tab} label="Tipologie"       {...a11yProps(2)} />
            <Tab className={classes.tab} label="Insegnanti" {...a11yProps(3)} />
          </Tabs>
          <div>
            <TabPanel value={props.value} index={0}>
              <TableCorsi
                {...props}
                count={props.count}
                rowsCourse={props.rowsCourse}
                setRowsCourse={props.setRowsCourse}
                setReload={props.setReload}
                categories={props.categories}
                setCustomAlert={props.setCustomAlert}
                setModifyCourse={props.setModifyCourse}
                setValue={setValueModal}
                courses={props.courses}
                course={course}
                setCourse={setCourse}
                open={props.open}
                setOpen={props.setOpen}
                setPage={props.setPage}
                page={props.page}
                setPageCourse={props.setPageCourse}
                pageCourse={props.pageCourse}
              />
            </TabPanel>
            <TabPanel value={props.value} index={1}>
              <TableCoursesCategories
                setCustomAlert={props.setCustomAlert}
                setConfirm={setconfirmCat}
                setValue={setValueModal}
                categories={props.categories}
                filter={props.filter}
                open={props.open}
                setOpen={props.setOpen}
                setReloadCat={props.setreloadCat}
              />
            </TabPanel>
            <TabPanel value={props.value} index={2}>
              <TableCoursesTypologies
                setCustomAlert={props.setCustomAlert}
                setConfirm={setconfirmTypo}
                setValue={setValueModal}
                typologies={props.typologies}
                filter={props.filter}
                open={props.open}
                setOpen={props.setOpen}
                setReloadTypo={props.setreloadTypo}
              />
            </TabPanel>

            <TabPanel value={props.value} index={3}>
              <Teachers setCustomAlert={props.setCustomAlert} setTeachers={setteachers} teachers={teachers} />
            </TabPanel>
          </div>
        </Paper>
        :
        <TransitionModal
          history={props.history}
          setAlertCsv={props.setAlertCsv}
          setReload={props.setReload}
          setCustomAlert={props.setCustomAlert}
          course={course}
          open={props.open}
          setOpen={setopen}
          value={valueModal}
          isCourses={true}
        />
      }
    </React.Fragment >

  )

}

export default CoursesMenu;