import React, { useEffect } from "react";
import SearchInput from "../componentsAdmin/Search/SearchInput"
import RoundButton from "../componentsAdmin/Button/RoundButton"
import { LineChart, Line, CartesianGrid, XAxis, Tooltip, PieChart, Pie } from 'recharts';
import Paper from "@material-ui/core/Paper";
import Statistics from "../components/Statistics/Statistics";
import useColor from '../context/themeContext'
import BarCharts from '../componentsAdmin/Charts/BarCharts'
import serviceAdmin from '../services/serviceAdmin'


const Dashboard = () => {
    const { COLORS } = useColor()
    const color = { color: COLORS.primary }
    const data = [{ name: '1', value: 400 }, { name: '5', value: 0 }, { name: '10', value: 200 }, { name: '15', value: 0 }, {
        name: '20',
        value: 100
    }, { name: '25', value: 0 }, { name: '31', value: 0 }];

    const renderLineChart = (
        <LineChart width={600} height={250} data={data}>
            <Line type="linear" dataKey="value" stroke={COLORS.primary} strokeWidth={3} dot={false} />
            <XAxis dataKey="name" axisLine={false} tickLine={false} tick={{ stroke: '#f8f8f8' }} tickMargin={15} />
            <CartesianGrid vertical={false} />
            <Tooltip />
        </LineChart>
    );

    const renderCircleChart = (
        <PieChart width={300} height={250}>
            <Pie data={data} dataKey="value" nameKey="name" innerRadius={80} outerRadius={100} fill={COLORS.primary} />
            <Pie data={data} dataKey="value" nameKey="name" innerRadius={60} outerRadius={80} fill="#d142f5" />
            <Tooltip />
        </PieChart>
    )

    return (
        <div>
            <div className={"view-search"}>
                <SearchInput placeholder={"Cerca nella dashboard..."} />
                <RoundButton filter={true} get={true} />
            </div>

            <div className={"view-group-cards"}>

                <Paper elevation={2} className={"first-group-card"}>
                    <div>
                        <p style={color} className={"label-attivita"}>Attività recenti</p>
                        <p className={"month-label"}>Maggio</p>
                        {renderLineChart}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Statistics title={"Numero sessioni"} dayValue={10} weekValue={204} />
                        <Statistics title={"Completamento corso"} dayValue={19} weekValue={107} />
                        <Statistics title={"Iscrizioni"} dayValue={31} weekValue={53} />
                    </div>
                </Paper>

                <div className={"last-two-cards"}>

                    <Paper elevation={2} className={"cards-half"}>
                        <p style={color} className={"student-state-label"}>Stato dello studente e corsi</p>
                        {renderCircleChart}
                    </Paper>

                    <Paper elevation={2} className={"cards-half"}>
                        <p style={color} className={"student-state-label"}>Corsi più popolari</p>
                        <BarCharts />
                    </Paper>
                </div>
            </div>

        </div>
    );


}

export default Dashboard;
