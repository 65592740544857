import React from 'react';
import Paper from '@material-ui/core/Paper';


const LessonBox = (props) => {
    

    return (


        <Paper className="module-box" style={{ borderRadius: "0" }}>

            <h4 style={{ textAlign: "center" }}>
                {props.module_title}
            </h4>

            <p>{props.module_text}</p>

        </Paper>




    );
};

export default LessonBox;