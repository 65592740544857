import React, { useState, useEffect, useRef, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { InputLabel, Paper, Grid, Select, MenuItem, Fade, Button } from '@material-ui/core'
import DeleteDialog from '../Dialog/DeleteDialog'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import FromDate from '../Export/ExportFilter/FromDate'
import AntSwitch from "../Switch/AntSwitch"
import useColor from '../../context/themeContext'
import serviceAdmin from '../../services/serviceAdmin'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import IconButton from '@material-ui/core/IconButton'
import CssTextField from '../StyledComponent/CssTextField'
import TextAreaField from '../StyledComponent/TextAreaField'
import { tabsStyle } from '../../classes'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import useAlert from '../../hooks/useAlert'
import moment from 'moment'
import 'moment/locale/it'
moment.locale('it')





const TabModifyCourse = (props) => {
    const { COLORS } = useColor();
    const useStyles = makeStyles(tabsStyle(COLORS));
    const classes = useStyles();

    const [opt, setAlert] = useAlert();

    const inputImageRef = useRef();
    const isMount = useRef(true);

    const [currentCertification, setCurrentCertification] = useState(null)
    const [course, setCourse] = useState(null);
    const [certifications, setCertifications] = useState([])
    const [typologies, setTypologies] = useState([])
    const [categories, setCategories] = useState([])
    const [subcategories, setSubCategories] = useState([])
    const [category_id, setCategoryId] = useState("")
    const [subcategory_id, setSubCategoryId] = useState("")
    const [input, setInput] = useState({})
    const [expiration, setExpiration] = useState({})
    const [checked, setchecked] = useState(false)
    const [checkedAccess, setcheckedAccess] = useState(false)
    const [checkedActive, setcheckedActive] = useState(false)
    const [checkedCertificate, setCheckedCertificate] = useState(false)
    const [image, setImage] = useState(undefined)
    const [timeUpload, setTimeUpload] = useState(null);
    const [loading, setLoading] = useState(false);
    const [deleteCourse, setDelete] = useState(false);
    const [confirm, setConfirm] = useState(false)
    const [course_id, setCourse_id] = useState(null)
    const [isSub, setIsSub] = useState(false);

    const options = {
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total)
            setTimeUpload(`Upload immagine ${percent}%`);
        }
    }
    const setdelete = (input) => { setDelete(input) }
    const setChecked = () => { setchecked(!checked) }
    const setCheckedAccess = () => { setcheckedAccess(!checkedAccess) }
    const setcheckedcertificate = () => { setCheckedCertificate(!checkedCertificate) }
    const setCheckedActive = () => { setcheckedActive(!checkedActive) }

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setInput({ ...input, [name]: value })
    }

    const handleExpiration = (value) => {
        const name = "expiration_date";
        setExpiration({ [name]: value })
    }

    const handleChangeSubCategory = (e) => {
        const value = e.target.value;
        setSubCategoryId(value)
    }

    const handleChangeCategory = (e) => {
        const value = e.target.value;
        const category = categories.find(x => x.id === value);
        setCategoryId(value)

        if (category && !category?.parent) {
            serviceAdmin.getService(`v1/course-categories/${value}/children/`)
                .then(res => {
                    if (isMount.current) {
                        if (res.data.data) {
                            setSubCategories(res.data.data)
                            setIsSub(true);
                        }
                    }
                }).catch(err => {
                    console.log(err);
                })
        } else {
            setIsSub(false);
            setSubCategories([])
        }
    }


    const disableExpiration = () => {
        setExpiration({ "expiration_date": null })
    }


    const setconfirm = (input, course) => {
        setConfirm(input)
        setCourse_id(course.id)
    }

    useEffect(() => {
        if (props.course.id) {
            serviceAdmin.getService(`v1/courses/${props.course.id}/`)
                .then(res => {
                    if (isMount.current) {
                        setCourse(res.data.data)
                    }
                }).catch(err => {
                    console.log(err);
                });

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.course.id])

    useEffect(() => {
        serviceAdmin.getService("/v1/certification-templates/")
        .then((res)=>{
            setCertifications(res.data.data)
        }).catch((err)=>{
            console.log(err)
        })
        serviceAdmin.getService("v1/course-typologies/")
            .then(res => {
                if (isMount.current) {
                    setTypologies(res.data.data)
                }
            }).catch(err => {
                console.log(err);
            });
        serviceAdmin.getService("v1/course-categories/parents/")
            .then(res => {
                if (isMount.current) {
                    setCategories(res.data.data)
                }
            }).catch(err => {
                console.log(err);
            });

        return () => {
            isMount.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (course) {
            if (course?.category?.parent) {
                setCategoryId(course?.category?.parent?.id);
                setSubCategoryId(course?.category?.id);
                serviceAdmin.getService(`v1/course-categories/${course?.category?.parent?.id}/children/`)
                    .then(res => {
                        if (isMount.current) {
                            if (res.data.data) {
                                setSubCategories(res.data.data)
                                setIsSub(true);
                            }
                        }
                    }).catch(err => {
                        console.log(err);
                    })
            } else if (course.category) {
                setIsSub(false);
                setCategoryId(course?.category?.id);
                serviceAdmin.getService(`v1/course-categories/${course?.category?.id}/children/`)
                    .then(res => {
                        if (isMount.current) {
                            if (res.data.data) {
                                setSubCategories(res.data.data)
                                setIsSub(true);
                            }
                        }
                    }).catch(err => {
                        console.log(err);
                    })
            }
            setCurrentCertification(course.certification_template)
            setInput({
                ...input,
                "certification_template_id": course.certification_template?.id,
                "name": course.name,
                "code": course.code,
                "slug": course.slug,
                "description": course.description,
                "additional_text": course.additional_text,
                "status": course.status,
                "typology_id": course.typology?.id ? course.typology?.id : null,
            });
            setExpiration({ "expiration_date": course.expiration_date ? moment(course.expiration_date).format('YYYY-MM-DD') : null, })
            setcheckedAccess(course.is_mandatory);
            setchecked(course.has_blocking_subscriptions);
            setcheckedActive(course.show_expiration_alert);
            setCheckedCertificate(course.has_certification)
            setLoading(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [course])

    const setImageRef = () => {
        inputImageRef.current.click()
    }

    ////////IMAGE SELECT

    function imageSelected(event) {
        const isImage = file => file["type"].includes("image")
        let input = event.target
        if (input.files && input.files[0]) {
            if (isImage(input.files[0])) {
                let reader = new FileReader()
                reader.onload = e => {
                    setImage(e.target.result)
                }
                reader.readAsDataURL(input.files[[0]])
            } else {
                alert('foto non caricata')
            }
        }
    }

    function clean(obj) {
        for (let propName in obj) {
            if (obj[propName] === undefined) {
                delete obj[propName];
            }
        }
    }


    const ModifyCourse = () => {
        let params;
        if (!subcategory_id) {
            params = {
                ...input,
                "is_mandatory": checkedAccess,
                "has_blocking_subscriptions": checked,
                "show_expiration_alert": checkedActive,
                "thumbnail": image,
                "has_certification": checkedCertificate,
                "category_id": category_id,
                "expiration_date": expiration?.expiration_date ? expiration?.expiration_date : null
            }
        } else if (category_id && subcategory_id) {
            params = {
                ...input,
                "is_mandatory": checkedAccess,
                "has_blocking_subscriptions": checked,
                "show_expiration_alert": checkedActive,
                "thumbnail": image,
                "has_certification": checkedCertificate,
                "category_id": subcategory_id,
                "expiration_date": expiration?.expiration_date ? expiration?.expiration_date : null
            }
        } else {
            params = {
                ...input,
                "is_mandatory": checkedAccess,
                "has_blocking_subscriptions": checked,
                "show_expiration_alert": checkedActive,
                "thumbnail": image,
                "has_certification": checkedCertificate,
                "expiration_date": expiration?.expiration_date ? expiration?.expiration_date : null
            }
        }
        clean(params);

        serviceAdmin.putService(`v1/courses/${props.course.id}/`, params, options)
            .then(res => {
                setAlert(true, "Corso aggiornato correttamente!", "success")
                props.setReload();
                props.handleClose();
            }).catch(err => {
                console.log(err)
            })
    }

    const removeImage = () => {
        setImage(null)
    }

    useEffect(() => {
        if (confirm && course_id) {
            serviceAdmin.deleteService(`v1/courses/${course_id}/`)
                .then(res => {
                    setAlert(true, "Corso eliminato correttamente!", "success");
                    props.setReload();
                    props.handleClose();

                }).catch(err => {
                    console.log(err)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [course_id])

    return (
        <Fade in={loading}>
            <div style={{ paddingBottom: "50px" }}>
                <Grid container spacing={3}>
                    <Grid spacing={3} container item md={12} lg={6} >

                        <Grid className="flex-center" item xs={12} md={6}>
                            <InputLabel className={classes.label}>
                                Nome*
                            </InputLabel>
                            <CssTextField name="name" value={input.name || ""} onChange={handleChange} placeholder="nome del corso" variant="outlined" inputProps={{ className: classes.input }} />
                        </Grid>
                        <Grid className="flex-center" item xs={12} md={6}>
                            <InputLabel className={classes.label}>
                                Codice*
                            </InputLabel>
                            <CssTextField name="code" value={input.code || ""} onChange={handleChange} placeholder="Codice del corso" variant="outlined" inputProps={{ className: classes.input }} />
                        </Grid>

                        <Grid className="flex-center" item xs={12} md={6}>
                            <InputLabel className={classes.label}>
                                Tipologia
                            </InputLabel>
                            <Select name="typology_id" value={input.typology_id || ""} onChange={handleChange} variant="outlined" className={classes.select}>
                                <MenuItem className={classes.selected} value={null}>Nessuna tipologia</MenuItem>
                                {
                                    typologies.length > 0 ?
                                        typologies.map((typology, index) => {
                                            return (
                                                <MenuItem className={classes.selected} key={index} value={typology.id}>{typology.name}</MenuItem>
                                            )
                                        })
                                        : null
                                }

                            </Select>
                        </Grid>

                        {!course?.thumbnail ?

                            <Grid className="flex-center" item xs={12} md={6} style={{ paddingTop: "20px" }}>

                                <InputLabel className={classes.label}>
                                    Thumbnail
                            </InputLabel>
                                <div onClick={setImageRef} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                                    {!timeUpload ?
                                        <React.Fragment>
                                            <input ref={inputImageRef} style={{ visibility: "hidden", width: "1px" }} type="file" onChange={imageSelected} />
                                            {image ? "Cambia" : "Aggiungi"} immagine
                            {!image ?
                                                <AddCircleIcon className={classes.color} style={{ cursor: "pointer", paddingLeft: "10px", width: "40px", height: "40px" }} />
                                                :
                                                <DoneAllIcon style={{ paddingLeft: "10px", width: "40px", height: "40px" }} className={classes.color} />
                                            }
                                        </React.Fragment>
                                        :
                                        <p>
                                            {timeUpload}
                                        </p>
                                    }
                                </div>

                            </Grid>
                            :
                            <Grid className="flex-center" item xs={12} md={6} style={{ paddingTop: "20px", display: "flex", alignItems: "center", justifyContent: "center" }} >
                                <div>
                                    <InputLabel className={classes.label}>
                                        Thumbnail
                                    </InputLabel>
                                    <Button onClick={removeImage} style={image === null ? { backgroundColor: "#3AAC4D", color: "#fff" } : { backgroundColor: COLORS.primary, color: "#fff" }}>
                                        {image === null ? "Immagine rimossa" : "Rimuovi immagine"}
                                    </Button>
                                </div>
                                <div onClick={setImageRef} style={{ cursor: "pointer" }}>
                                    {!timeUpload ?
                                        <React.Fragment>
                                            <input ref={inputImageRef} style={{ visibility: "hidden", width: "1px" }} type="file" onChange={imageSelected} />
                                            {!image ?
                                                <AddCircleIcon className={classes.color} style={{ cursor: "pointer", paddingLeft: "10px", width: "40px", height: "40px" }} />
                                                :
                                                <DoneAllIcon style={{ paddingLeft: "10px", width: "40px", height: "40px" }} className={classes.color} />
                                            }
                                        </React.Fragment>
                                        :
                                        <p>
                                            {timeUpload}
                                        </p>
                                    }
                                </div>
                            </Grid>
                        }

                        <Grid className="flex-center" item xs={12} md={6} lg={12} style={{ paddingTop: "20px" }}>
                            <InputLabel className={classes.label}>
                                Descrizione
                            </InputLabel>
                            <TextAreaField name="description" value={input.description || ""} onChange={handleChange} placeholder="Descrizione del corso" multiline rows={5} variant="outlined" inputProps={{ className: classes.textarea }} />
                        </Grid>
                        <Grid className="flex-center" item xs={12} md={6} lg={12} style={{ paddingTop: "20px" }}>
                            <InputLabel className={classes.label}>
                                Info aggiuntive
                            </InputLabel>
                            <TextAreaField name="additional_text" value={input.additional_text || ""} onChange={handleChange} placeholder="Info del corso" multiline rows={5} variant="outlined" inputProps={{ className: classes.textarea }} />
                        </Grid>

                    </Grid>

                    <Grid style={{marginBottom:50}} spacing={3} container item md={12} lg={6}>

                        <Grid className="flex-center" item xs={12} md={6}>
                            <InputLabel className={classes.label}>
                                Categoria
                            </InputLabel>
                            <Select name="category_id" value={category_id || ""} onChange={handleChangeCategory} variant="outlined" className={classes.select}>
                                <MenuItem className={classes.selected} value="">Nessuna categoria</MenuItem>
                                {
                                    categories.length > 0 ?
                                        categories.map((category, index) => {
                                            return (
                                                <MenuItem className={classes.selected} key={index} value={category.id}>{category.name}</MenuItem>
                                            )
                                        })
                                        : null
                                }

                            </Select>
                        </Grid>
                        {isSub ?
                            <Grid className="flex-center" item xs={12} md={6}>
                                <InputLabel className={classes.label}>
                                    Sottocategoria
                            </InputLabel>
                                <Select name="category_id" value={subcategory_id || ""} onChange={handleChangeSubCategory} variant="outlined" className={classes.select}>
                                    <MenuItem className={classes.selected} value="">Nessuna categoria</MenuItem>
                                    {
                                        subcategories.length > 0 ?
                                            subcategories.map((category, index) => {
                                                return (
                                                    <MenuItem className={classes.selected} key={index} value={category.id}>{category.name}</MenuItem>
                                                )
                                            })
                                            : null
                                    }

                                </Select>
                            </Grid>
                            :
                            <Grid className="flex-center" item xs={12} md={6}></Grid>
                        }

                        <Grid className="flex-center" item xs={12} md={6}>
                            <InputLabel className={classes.label}>
                                Attestato {
                                    (currentCertification && (currentCertification?.id == input.certification_template_id)) ? 
                                        <a 
                                        href={currentCertification.html_template || ""}
                                        target='_blank'
                                        style={{cursor: "pointer", textDecoration: "none", color:"inherit"}}>
                                        <PictureAsPdfIcon/>
                                        </a>
                                        : null
                                    }
                            </InputLabel>
                            <Select name="certification_template_id" value={input.certification_template_id || ""} onChange={handleChange} variant="outlined" className={classes.select}>
                                <MenuItem className={classes.selected} value="">Nessun attestato</MenuItem>
                                {
                                    certifications.length > 0 ?
                                        certifications.map((certification, index) => {
                                            return (
                                                <MenuItem className={classes.selected} key={index} value={certification.id}>{certification.name}</MenuItem>
                                            )
                                        })
                                        : null
                                }

                            </Select>
                        </Grid>

                        <Grid className="flex-center" item xs={12} md={6}>
                            <InputLabel className={classes.label}>
                                Stato
                            </InputLabel>
                            <Select name="status" value={input.status || ""} onChange={handleChange} variant="outlined" className={classes.select}>
                                <MenuItem className={classes.selected} value="RDY">Bozza</MenuItem>
                                <MenuItem className={classes.selected} value="STR">Pubblicato</MenuItem>
                                <MenuItem className={classes.selected} value="END">Terminato</MenuItem>
                            </Select>
                        </Grid>
                        <Grid className="flex-center" item xs={12} md={6}>
                            <InputLabel className={classes.label}>
                                Scadenza
                            </InputLabel>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <FromDate
                                    inputProps={`${classes.input}`}
                                    value={expiration.expiration_date}
                                    handleChange={handleExpiration}
                                    />
                                <IconButton style={{ padding: "0px" }} onClick={disableExpiration}>
                                    <HighlightOffIcon />
                                </IconButton>
                            </div>
                        </Grid>
                        <Grid className="flex-center" item xs={12} md={6}></Grid>



                        <Grid className="flex-center" item xs={12} md={6}>
                            <InputLabel className={classes.label}>
                                Rilascia attestato
                            </InputLabel>
                            <AntSwitch checked={checkedCertificate} setChecked={setcheckedcertificate} />
                        </Grid>

                        <Grid className="flex-center" item xs={12} md={6}>
                            <InputLabel className={classes.label}>
                                Rendi la scadenza delle iscrizioni bloccante
                            </InputLabel>
                            <AntSwitch checked={checked} setChecked={setChecked} />
                        </Grid>
                        <Grid className="flex-center" item xs={12} md={6}>
                            <InputLabel className={classes.label}>
                                Obbligatorio
                            </InputLabel>
                            <AntSwitch checked={checkedAccess} setChecked={setCheckedAccess} />
                        </Grid>

                        <Grid className="flex-center" item xs={12} md={6}>
                            <InputLabel className={classes.label}>
                                Mostra messaggio scadenza
                            </InputLabel>
                            <AntSwitch checked={checkedActive} setChecked={setCheckedActive} />
                        </Grid>
                    </Grid>
                </Grid>

                <DeleteDialog
                    deleteLesson={deleteCourse}
                    setDelete={setdelete}
                    textDelete={"il corso"}
                    handleCloseMenu={() => { }}
                    setConfirm={setconfirm}
                    lesson={course}
                />

                <Paper elevation={3} className={classes.save}>
                    <Button onClick={() => setdelete(true)} className={classes.btn_del_user}>Elimina</Button>
                    <Button onClick={props.handleClose} className={classes.btn_del}>Annulla</Button>
                    <Button disabled={input.name && input.slug && input.code ? false : true} onClick={ModifyCourse} className={classes.btn_save}>Aggiorna</Button>
                </Paper>

            </div>
        </Fade>
    )
}

export default TabModifyCourse;