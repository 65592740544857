import React, { useRef } from "react";
import SearchInput from "../componentsAdmin/Search/SearchInput"
import RoundButton from "../componentsAdmin/Button/RoundButton"
import CoursesMenu from "../componentsAdmin/Courses/CoursesMenu"
import TransitionsModal from "../componentsAdmin/Modal/Modal"
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import useColor from '../context/themeContext'
import { useEffect } from "react";
import serviceAdmin from "../services/serviceAdmin";
import Fade from '@material-ui/core/Fade';
import Alert from '../componentsAdmin/CollapseAlert/Alert';
import Zoom from '@material-ui/core/Zoom';
import FilterListIcon from '@material-ui/icons/FilterList';
import Tooltip from '@material-ui/core/Tooltip';
import { Grid } from '@material-ui/core';
import Filters from '../componentsAdmin/Filter/Filters'
import { SyncDetailExport } from '../componentsAdmin/Export/Utilsfunctions'
import { listMenu, filterIcon, filterIconActive } from '../classes'




const Courses = (props) => {
    const { COLORS } = useColor();
    const useStyles = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        filterIcon,
        ...listMenu(COLORS),
        ...filterIconActive(COLORS)
    }));


    const CustomTooltip = withStyles({
        tooltip: {
            fontSize: "14px",
            backgroundColor: COLORS.primary,
            color: "#fff",
            textAlign: "center",
            borderRadius: "10px",
            fontWeight: "bolder"
        }
    })(Tooltip);
    const classes = useStyles();
    const timeout = useRef(null);
    const isMount = useRef(true);

    const [searchString, setSearchString] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [modifyCourse, setModifyCourse] = React.useState(false);
    const [newopen, setNewopen] = React.useState(false);
    const [newCategory, setNewCategory] = React.useState(false);
    const [newTypology, setNewTypology] = React.useState(false);
    const [newTeacher, setNewTeacher] = React.useState(false);
    const [buttons, setButtons] = React.useState(false);
    const [reload, setReload] = React.useState(false);
    const [reloadCat, setReloadCat] = React.useState(false);
    const [reloadTypo, setReloadTypo] = React.useState(false);
    const [reloadTeach, setReloadTeach] = React.useState(false);
    const [categories, setCategories] = React.useState([]);
    const [typologies, setTypologies] = React.useState([]);
    const [teachers, setTeachers] = React.useState([]);
    const [course, setCourse] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState({});
    const [rowsCourse, setRowsCourse] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [pageCourse, setPageCourse] = React.useState(1);
    const [count, setCount] = React.useState(0);
    const [backdrop, setBackdrop] = React.useState(false)
    const [show, setShow] = React.useState(false);
    const [ordering, setOrdering] = React.useState("")
    const [checkedActive, setCheckedActive] = React.useState("")
    const [checkedMandatory, setCheckedMandatory] = React.useState(false)
    const [checkedCertification, setCheckedCertification] = React.useState(false)
    const [value, setValue] = React.useState(0);
    const [selected, setSelected] = React.useState([])
    const [all, setAll] = React.useState(false)
    const [annotation, setAnnotation] = React.useState("")

    const setvalue = (input) => { setValue(input) }
    const setordering = (input) => { setOrdering(input) }
    const setcheckedactive = (input) => { setCheckedActive(input) }
    const setcheckedcertification = (input) => { setCheckedCertification(input) }
    const setcheckedmandatory = (input) => { setCheckedMandatory(input) }
    const setmodifycourse = (input, course) => { setModifyCourse(input); setCourse(course) };
    const setnewopen = (input) => { setNewopen(input) };
    const setnewCategory = (input) => { setNewCategory(input) };
    const setnewTypology = (input) => { setNewTypology(input) };
    const setnewTeacher = (input) => { setNewTeacher(input) };
    const setbuttons = () => { setButtons(!buttons) };
    const setclosebuttons = () => { setButtons(false) };
    const setreload = () => { setReload(!reload) };
    const setreloadCat = () => { setReloadCat(!reloadCat) };
    const setreloadTypo = () => { setReloadTypo(!reloadTypo) };
    const setreloadTeach = () => { setReloadTeach(!reloadTeach) };
    const setpage = (input) => { setPage(input) };
    const setpagecourse = (input) => { setPageCourse(input) };
    const setrowscourse = (input) => { setRowsCourse(input) };
    const setbackdrop = (input) => { setBackdrop(input) }

    const showFilter = () => {
        setShow(!show);
    }

    const resetfilter = () => {
        setOrdering(null);
        setCheckedActive(false);
        setCheckedMandatory(false);
        setCheckedCertification(false);
    }

    const exportTable = (info, callback, setComplete) => {
        const params = {
            "audit_filters": {
                "filters": []
            },
            "filters": {},
            "export_fmt": info
        }
        if (annotation) params.annotation = annotation;

        if (selected.length > 0 && !all) {
            params.filters.courses_ids = selected;
        } else {
            if (checkedActive === "false" || checkedActive === "true") {
                params.filters.is_active = checkedActive;
                params.audit_filters.filters.push({ "label": "Corsi attivi: ", "value": checkedActive, "type": "bool" })
            }
            if (checkedMandatory === "false" || checkedMandatory === "true") {
                params.filters.is_mandatory = checkedMandatory;
                params.audit_filters.filters.push({ "label": "Corsi obbligatori: ", "value": checkedMandatory, "type": "bool" })
            }
            if (checkedCertification === "false" || checkedCertification === "true") {
                params.filters.has_cerification = checkedCertification;
                params.audit_filters.filters.push({ "label": "Corsi con certificato: ", "value": checkedCertification, "type": "bool" })
            }
        }

        SyncDetailExport('/custom/v1/exports/courses/', params, callback, setComplete, props.setCustomAlert)
    }

    useEffect(() => {
        if (isMount.current) {
           
            let url = `/v1/courses/?page=${pageCourse}&page_items=${rowsCourse}`;
            if (ordering) url += `&ordering=${ordering}`;
            if (checkedActive === "false" || checkedActive === "true") url += `&is_active=${checkedActive}`;
            if (checkedMandatory === "false" || checkedMandatory === "true") url += `&is_mandatory=${checkedMandatory}`;
            if (checkedCertification === "false" || checkedCertification === "true") url += `&has_certification=${checkedCertification}`;
            timeout.current = setTimeout(() => {
                if (searchString !== '') url += `&search=${searchString}`;
                serviceAdmin.getService(url)
                    .then(res => {
                        if (isMount.current) {
                            setCount(res.data?.meta?.pagination?.items_count)
                            props.setCourses(res.data.data)
                            setLoading(true)
                            setPage(pageCourse - 1)
                            setBackdrop(false)
                        }
                    }).catch(err => {
                        if (err?.status === 404) {
                            setBackdrop(false);
                        }
                    })
            }, 250)
        }

        return () => {
            if (timeout.current)
            {
               clearTimeout(timeout.current)
               timeout.current = null
            }   
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload, searchString, pageCourse, rowsCourse, ordering, checkedActive, checkedMandatory, checkedCertification])

    useEffect(() => {
        serviceAdmin.getService("v1/course-categories/")
            .then(res => {
                if (isMount.current) {
                    setCategories(res.data.data)
                }

            }).catch(err => {
                console.log(err)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadCat])

    useEffect(() => {
        serviceAdmin.getService("v1/course-typologies/")
            .then(res => {
                setTypologies(res.data.data)
            }).catch(err => {
                console.log(err)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadTypo])

    useEffect(() => {
        serviceAdmin.getService("v1/teachers/")
            .then(res => {
                setTeachers(res.data.data)
            }).catch(err => {
                console.log(err)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadTeach])

    useEffect(() => {
        return () => {
            isMount.current = false;
            setAnnotation("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setNewopen(false);
        setNewCategory(false);
        setNewTypology(false);
        setNewTeacher(false);
        setOpen(false);
        setModifyCourse(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.closeBack])

    const setCustomAlert = (value, two_value, three_value) => {
        setOpenAlert({
            ...openAlert,
            "state": value,
            "severity": two_value,
            "message": three_value
        })
    };

    const history = props.history

    return (
        <React.Fragment>

            <Alert
                setOpenAlert={setCustomAlert}
                severity={openAlert?.severity}
                openAlert={openAlert?.state}
                message={openAlert?.message}
            />

            {!open && !newopen && !modifyCourse && !newCategory && !newTypology && !newTeacher ?
                <React.Fragment>

                    <div style={value === 0 ? { display: "flex", alignItems: "center" } : { display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        {value === 0 &&
                            <Grid container spacing={3}>
                                <Grid item style={{ display: "flex", alignItems: "center" }}>
                                    <SearchInput searchString={searchString} placeholder={"Cerca tra i corsi..."} onChange={event => setSearchString(event.target.value)} />
                                    <Zoom in={true} timeout={{ appear: 400, enter: 500, exit: 500 }}>
                                        <CustomTooltip className={classes.tooltip} title={show ? "Nascondi filtri" : "Mostra filtri"} placement="top">
                                            <FilterListIcon onClick={showFilter} className={show ? classes.filterIconActive : classes.filterIcon} />
                                        </CustomTooltip>
                                    </Zoom>
                                </Grid>
                            </Grid>
                        }
                        <RoundButton add={true} setbuttons={setbuttons} />
                    </div>

                    <Filters
                        showReset={(checkedMandatory || checkedCertification || checkedActive || ordering) ? true : false}
                        resetFilter={resetfilter}
                        checkedActive={checkedActive}
                        setCheckedActive={setcheckedactive}
                        checkedCertification={checkedCertification}
                        setCheckedCertification={setcheckedcertification}
                        checkedMandatory={checkedMandatory}
                        setCheckedMandatory={setcheckedmandatory}
                        course={true}
                        show={show}
                        setPageUser={setpagecourse}
                        setOrdering={setordering}
                        ordering={ordering}
                    />
                </React.Fragment>
                : null}

            {!newopen && !modifyCourse && !newCategory && !newTypology && !newTeacher ?
                <Fade in={loading} timeout={{ appear: 100, enter: 300, exit: 300 }}>
                    <div style={{ maxWidth: "100%" }}>
                        <CoursesMenu
                            history={history}
                            setAnnotation={setAnnotation}
                            annotation={annotation}
                            setSelected={setSelected}
                            setAll={setAll}
                            all={all}
                            exportTable={exportTable}
                            setValue={setvalue}
                            value={value}
                            setBackdrop={setbackdrop}
                            backdrop={backdrop}
                            count={count}
                            setPage={setpage}
                            page={page}
                            setPageCourse={setpagecourse}
                            setRowsCourse={setrowscourse}
                            rowsCourse={rowsCourse}
                            pageCourse={pageCourse}
                            setCustomAlert={setCustomAlert}
                            setAlertCsv={props.setCustomAlert}
                            setModifyCourse={setmodifycourse}
                            setreloadTypo={setreloadTypo}
                            setreloadCat={setreloadCat}
                            typologies={typologies}
                            categories={categories}
                            setReload={setreload}
                            courses={props.courses}
                            open={open}
                            setOpen={setOpen}
                        />
                    </div>
                </Fade>
                : null
            }

            {modifyCourse ?
                <TransitionsModal
                    history={history}
                    setCustomAlert={setCustomAlert}
                    course={course}
                    open={modifyCourse}
                    setOpen={setmodifycourse}
                    isModifyCourse={true}
                    setReload={setreload}
                />
                : null}

            {newopen ?
                <TransitionsModal
                    history={history}
                    setCustomAlert={setCustomAlert}
                    open={newopen}
                    setOpen={setnewopen}
                    isNewCourse={true}
                    setReload={setreload}
                />
                : null}

            {newCategory ?
                <TransitionsModal
                    history={history}
                    setCustomAlert={setCustomAlert}
                    open={newCategory}
                    setOpen={setnewCategory}
                    isNewCategory={true}
                    categories={categories}
                    setReload={setreloadCat}
                />
                : null}

            {newTypology ?
                <TransitionsModal
                    history={history}
                    setCustomAlert={setCustomAlert}
                    open={newTypology}
                    setOpen={setnewTypology}
                    isNewTypology={true}
                    setReload={setreloadTypo}
                />
                : null}

            {newTeacher ?
                <TransitionsModal
                    history={history}
                    setCustomAlert={setCustomAlert}
                    open={newTeacher}
                    setOpen={setnewTeacher}
                    isNewTeacher={true}
                    setReload={setreloadTeach}
                />
                : null}

            <Backdrop className={classes.backdrop} open={buttons} onClick={setclosebuttons}>
                <ul className={classes.listMenu}>
                    <li onClick={() => setnewopen(true)}>Nuovo corso</li>
                    <li onClick={() => setnewCategory(true)}>Nuova categoria</li>
                    <li onClick={() => setnewTypology(true)}>Nuova tipologia</li>
                    <li onClick={() => setNewTeacher(true)}>Nuovo insegnante</li>
                </ul>
            </Backdrop>
        </React.Fragment>
    );


}

export default Courses;
