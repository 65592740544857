import React, { useEffect, useState, useRef } from 'react'
import Button from '@material-ui/core/Button'
import { Link, useParams } from 'react-router-dom'
import services from '../services/services'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import moment from 'moment'
import 'moment/locale/it'
import useColor from '../context/themeContext'
moment.locale('it')

export default function Certificate(props) {

    const {COLORS} = useColor()

    const isMount = useRef(true);

    const { id } = useParams();

    const [sub, setSub] = useState(null)

    useEffect(() => {
        if (id) {
            services.getService(`v1/users/me/subscriptions/${id}/`)
                .then(res => {
                    if (isMount.current) {
                        const data = res.data.data;
                        if (data) {
                            if (data?.is_passed) {
                                setSub(data)
                            }
                        }
                    }
                }).catch(err => {
                    console.log(err);
                })
        }

        return () => {
            isMount.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const certification_link = sub?.certification;
    const certification_name = `certification-${id}`;

    const pdfViewerStyles = {
        height: 750,
        width: '80%',
        margin: 'auto',
        display: 'block'
    }

    const downloadBtnStyles = {
        padding: '12px 40px',
        background: '#3aac4d',
        color: '#ffffff',
        marginTop: 20,
        marginBottom: 50
    }


    return (
        <div style={{paddingTop: 30, paddingBottom: 50}}>
            {sub ?
                <>
                    <div style={{ display: "flex", alignItems: "center", flexDirection:"column" }}>
                        <Button
                            target='_blank'
                            href={certification_link}
                            download={certification_name}
                            variant={"contained"}
                            style={downloadBtnStyles} 
                            >
                            Scarica PDF
                        </Button>
                    </div>
                
                    <iframe 
                        style={pdfViewerStyles} 
                        src={certification_link}>
                    </iframe>
                </>
            : null}

        </div>
    )
}
