import React, { useState, useEffect } from 'react';
import Routes from '../routes';
import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'
import Service from '../services/services'

const Learning = (props) => {

  const [verify, setverify] = useState(false);
  const [me, setMe] = useState(null)
  const [reload, setReload] = useState(false)

  const setreload = () => setReload(!reload);

  useEffect(() => {
    if (localStorage.getItem("bearer_fit_token") || localStorage.getItem("course_fit_id")) {
      Service.getService("/v1/users/me/")
        .then(res => {
          if (res?.data?.data) {
            setMe(res?.data?.data)
          }
        }).catch(err => {
          console.log(err)
        })
    }
  }, [reload])

  const setVerify = (input) => {
    setverify(input)
  }

  useEffect(() => {
    if (localStorage.getItem("token_fit_l")) { setVerify(true) };
  }, [verify])



  return (

    <React.Fragment>
      <div className="container">
        <Navbar history={props.hist} verify={verify} setVerify={setVerify} user={me} />
        <Routes setReload={setreload} user={me} history={props.hist} setAdmin={props.setAdmin} verify={verify} setVerify={setVerify} />
      </div>
      <Footer />

    </React.Fragment>

  );
}

export default Learning;
