import React from 'react';




const NoAccess = () => {

    return (
        <div className="login-form">
            <div
                style={{
                    height: "300px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "30px",
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "#5c5c5c",
                    padding: "20px"
                }}>
                Non sei autorizzato ad accedere
            </div>
        </div>
    )


}

export default NoAccess