import React from 'react'
import { List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import { Phone, Mail, Room } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles'
import { ContactClass } from '../../classes'
import useColor from '../../context/themeContext'



const Contact = (props) => {
	
	const { content } = props
	const { COLORS } = useColor()
	const useStyles = makeStyles(ContactClass(COLORS))
	const classes = useStyles()

	return (
		<div className={classes.contact_box}>
			{content?.contacts ?
				<React.Fragment>
					<h2 className={classes.title}>
						{content?.contacts?.title}
					</h2>
					<List className={classes.root}>

						{content?.contacts?.phone &&
							<ListItem>
								<ListItemAvatar>
									<Phone className={classes.color} />
								</ListItemAvatar>
								<ListItemText primary={content?.contacts?.phone} />
							</ListItem>
						}
						{content?.contacts?.email &&
							<ListItem>
								<ListItemAvatar>
									<Mail className={classes.color} />
								</ListItemAvatar>
								<ListItemText primary={content?.contacts?.email} />
							</ListItem>
						}
						{content?.contacts?.location &&
							<ListItem>
								<ListItemAvatar>
									<Room className={classes.color} />
								</ListItemAvatar>
								<ListItemText primary={content?.contacts?.location} />
							</ListItem>
						}
					</List>
				</React.Fragment>
				: null}
		</div>
	)
}

export default Contact;