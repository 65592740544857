import config from "../../config"
import socketClient from "../../services/socketClient"

/**
 * Funzione che costruisce il socket della sessione
 * @param {Number} lessonId
 * @param {Function} onClose 
 */
async function buildSessionSocket(lessonId, onClose, onError) {
    try {
        const s = await socketClient.asyncInit({ baseUrl: config.WS_URL, authToken: localStorage.getItem("bearer_fit_token"), lessonId: lessonId }, ()=> true, onError, onClose)
        const resp = await socketClient.asyncReadMessage(s)
        if (!resp.meta.error) {
            return s
        } else {
            throw Error("Cannot connect to websocket")
        }
    } catch (error) {
        console.log('[helpers.buildSessionSocket]', error)
    }
}

const setCookie = (name, value) => {
    document.cookie = name + '=' + escape(value) + '; path=/';
}


const readCookie = (name) => {
    if (document.cookie.length > 0) {
        var inizio = document.cookie.indexOf(name + "=");
        if (inizio !== -1) {
            inizio = inizio + name.length + 1;
            var fine = document.cookie.indexOf(";", inizio);
            if (fine === -1) fine = document.cookie.length;
            return unescape(document.cookie.substring(inizio, fine));
        } else {
            return "";
        }
    }
    return "";
}


const helpers = {
    buildSessionSocket,
    setCookie,
    readCookie
}

export default helpers;