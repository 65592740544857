import React, { useState, useEffect } from 'react'
import { TextField, Paper, Grid, InputLabel, InputAdornment, IconButton, Tooltip, Button, Chip } from '@material-ui/core/'
import { Visibility, VisibilityOff, Info, Done } from '@material-ui/icons/';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Alert from '../componentsAdmin/CollapseAlert/Alert'
import Service from '../services/services'
import { changePasswordClass } from '../classes';
import useColor from '../context/themeContext'


const CustomTooltip = withStyles({
  tooltip: {
    fontSize: "14px"
  }
})(Tooltip);

const CssTextField = withStyles({
  root: {
    border: "none",
    width: "100%",
    '& .MuiInput-underline:after': {
      border: 'none',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
  },
})(TextField);

const ChangePassword = (props) => {

  const { COLORS } = useColor();
  const useStyles = makeStyles((theme) => (changePasswordClass(COLORS)));
  const classes = useStyles();


  const [password, setPassword] = useState("")
  const [passwordc, setPasswordC] = useState("")
  const [deleteChip, setDeleteChip] = useState(false)
  const [passwordConflict, setPasswordConflict] = useState(false)
  const [passwordConfirm, setPasswordConfirm] = useState(false)
  const [values, setValues] = useState({ showPassword: false, showPasswordC: false });
  const [openAlert, setOpenAlert] = useState({});

  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  const queryParams = new URLSearchParams(window.location.search)
  const course_id = queryParams?.get("next")

  const forcedChangePassword = () => {
    Service.putService("/v1/auth/recover/password/", { "password": password })
      .then(res => {
        if (res.status === 200) {
          if (course_id) {
            props.history.push({ pathname: `/corsi/${course_id}`, state: { "course_id": course_id } })
          } else {
            props.history.push(`/corsi`)
          }
        }
      }).catch(err => {
        if (err?.status === 401) {
          props.history.push(`/no-access/`)
        } else {
          console.log(err)
        }
      })
  }

  useEffect(() => {
    Service.getService(`v1/users/me/`)
      .then(res => {
        if (res?.status === 200) {
          if (res.data.data.trigger_change_password === false && localStorage.getItem("bearer_fit_token")) {
            switch (res.data.data.role.tier) {
              case "BO_LIM":
                return props.history.push("/backoffice-lite/export");
              case "BO_COM":
                return props.history.push("/backoffice/users");
              default:
                return props.history.push("/corsi");
            }
          } else if (res.data.data.trigger_change_password === false && !localStorage.getItem("bearer_fit_token")) {
            props.history.push("/login");
          }
        }
      }).catch(err => {
        if (err?.status === 401) {
          props.history.push("/no-access");
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setCustomAlert = (value, two_value, three_value) => {
    setOpenAlert({
      ...openAlert,
      "state": value,
      "severity": two_value,
      "message": three_value
    })
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!regex.test(password) && password) {
        setPasswordConflict(true)
      } else {
        setPasswordConflict(false)
      }
      if (password !== passwordc) {
        setPasswordConfirm(true)
      } else {
        setPasswordConfirm(false)
      }
    }, 200)

    return () => {
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordc, password])


  const onKeyLogin = (event) => {
    if (event.key === "Enter") {
      forcedChangePassword();
    }
  }

  const handleClickShowPasswordC = () => {
    setValues({ ...values, showPasswordC: !values.showPasswordC });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const pastePasswordC = (e) => {
    e.preventDefault();
    setCustomAlert(true, "warning", "La funzione 'incolla' è disabilitata");
    return false;
  }

  const pastePassword = (e) => {
    e.preventDefault();
    setCustomAlert(true, "warning", "La funzione 'incolla' è disabilitata");
    return false;
  }

  const deleteChipfunc = () => { setDeleteChip(true) }

  return (
    <div className={classes.container} onKeyDown={onKeyLogin}>
      <Alert
        setOpenAlert={setCustomAlert}
        severity={openAlert?.severity}
        openAlert={openAlert?.state}
        message={openAlert?.message}
      />
      <Paper className={classes.paper}>
        {!deleteChip &&
          <Grid container justify="center">
            <Chip
              className={classes.chip_message}
              label="Dopo il primo accesso è necessario impostare una nuova password"
              onDelete={deleteChipfunc}
              deleteIcon={<Done />}
            />
          </Grid>
        }
        <h2 className={classes.title}>Cambio password</h2>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ padding: "0 100px" }}>
            <InputLabel className={classes.label}>
              Nuova Password
                    <CustomTooltip
                placement={"right-end"}
                title="La password deve contenere almeno 8 caratteri tra cui almeno una maiuscola, un numero ed un carattere speciale"
              >
                <Info style={{ fontSize: "19px" }} />
              </CustomTooltip>

            </InputLabel>
            <CssTextField
              onPaste={pastePassword}
              type={values.showPassword ? 'text' : 'password'}
              variant="outlined"
              value={password}
              onChange={(e) => { setPassword(e.target.value) }}
              error={passwordConflict ? true : false}
              helperText={passwordConflict ? "Password inserita non valida" : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
                className: classes.input_show
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ padding: "0 100px" }}>
            <InputLabel className={classes.label}>
              Conferma nuova password
                  </InputLabel>
            <CssTextField
              onPaste={pastePasswordC}
              variant="outlined"
              type={values.showPasswordC ? 'text' : 'password'}
              value={passwordc}
              onChange={(e) => { setPasswordC(e.target.value) }}
              error={passwordConfirm ? true : false}
              helperText={passwordConfirm ? "Le password non corrispondono" : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordC}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPasswordC ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
                className: classes.input_show
              }}
            />
          </Grid>
        </Grid>
        <div className={classes.container_btn}>
          <Button tabIndex="0" disabled={regex.test(password) && password && passwordc === password ? false : true} onClick={forcedChangePassword} className={classes.btn_save}>Accedi</Button>
        </div>

      </Paper>
    </div>
  )
}


export default ChangePassword;