import React, { useState } from "react";
import { Button, Grid, InputLabel, Select, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import useColor from "../../../context/themeContext";


const Groups = (props) => {
    
    const { COLORS } = useColor()
    const useStyles = makeStyles((theme) => ({
        root: {
            maxWidth: "300px",
            width: "100%",
            margin: "20px auto",
            '& .MuiSelect-root': {
                borderRadius: "20px",
                backgroundColor: "#fff!important",
                border: "1px solid #ccc"
            }
        },
        color_label: {
            '&.Mui-selected': {
                color: COLORS.primary + "!important"
            }
        }
    }));
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(false)

    const open = () => {
        setAnchorEl(true)
    }
    const close = () => {
        setAnchorEl(false)
    }

    return (
        <Grid item xs={12} md={6} xl={6} style={{ textAlign: "center" }}>
            <InputLabel style={{ margin: "0 auto", fontSize: "17px" }}>
                {props.label}
            </InputLabel>
            <Select
                onClick={open}
                MenuProps={{ getContentAnchorEl: () => null }}
                multiple
                open={Boolean(anchorEl)}
                onClose={close}
                value={props?.input || []}
                name={props.name}
                onChange={props.handleChange}
                variant="outlined"
                className={classes.root}
                displayEmpty
                renderValue={(selected) => {
                    if (selected.length === 0) {
                        return <em>{props.all_label}</em>;
                    }
                    return selected.map(item => props.isUsers ? `${item.first_name} ${item.last_name}` : item.name ).join(', ')
                }}
            >
                <MenuItem value={"ALL"} onClick={() => props.resetChange(props.name)} className={classes.color_label}>{props.all_label}</MenuItem>
                {
                    props.data?.map((d, index) => {
                        return <MenuItem className={classes.color_label} key={index} value={d}>{props.isUsers ? `${d.first_name} ${d.last_name}` : d.name}</MenuItem>
                    })
                }


                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button onClick={(e) => { e.preventDefault(); e.stopPropagation(); close(); }} style={{ marginRight: "5px", color: COLORS.primary }}>
                        ok
                    </Button>
                </div>

            </Select>
        </Grid>
    )
}

export default Groups;