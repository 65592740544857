import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import useColor from '../../context/themeContext'

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        justifyContent: "left",
        marginBottom: "20px",
        marginLeft: "40px",
        padding: "40px"
    },
}));


const Single = (props) => {

    const { COLORS } = useColor()
    const BlueRadio = withStyles({
        root: {
            color: COLORS.primary,
            '&$checked': {
                color: COLORS.primary,
            },
        },
        checked: {},
    })((props) => <Radio color="default" {...props} />);
    const classes = useStyles();

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        props.setPrevResponse({ ...props.prevResponse, [name]: Number(value) })
        props.setData({ ...props.data, [name]: Number(value) })
    }

    useEffect(() => {
        if (props.prevResponse[props.index]) {
            props.setResponse(true);
            props.setallresponse(true, props.index)
        } else {
            props.setResponse(false);
            props.setallresponse(false, props.index)
        }

        return () => {
            props.setResponse(false);
        }
    }, [props.prevResponse])

    return (

        <Paper style={{ minHeight: "400px", borderRadius: "0px", paddingBottom: "20px" }}>

            <h3 style={{ padding: "40px", textAlign: "left" }}>{props?.question}</h3>

            <div className={classes.root} onKeyDown={props.getKey}>

                <RadioGroup
                    aria-label="gender"
                    name={props.index}
                    value={props.prevResponse[props.index] || ""}
                    onChange={handleChange}
                >
                    {
                        props.answers.map((answer, index) => {
                            return <FormControlLabel key={index} label={answer.text} value={answer.id} control={<BlueRadio color="primary" />} />
                        })
                    }
                </RadioGroup>


            </div>
        </Paper>

    )

}
export default Single;