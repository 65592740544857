import React from "react";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import HelpOutline from '@material-ui/icons/HelpOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LockIcon from '@material-ui/icons/Lock';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import Zoom from '@material-ui/core/Zoom';
import { withStyles, makeStyles, lighten, darken } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import useColor from "../../context/themeContext";



const RelatedBox = (props) => {

    const { COLORS } = useColor()
    const useStyles = makeStyles(() => ({
        selected_lesson: {
            backgroundColor: darken(COLORS.primary, 0.1)
        }
    }));

    const BlueTooltip = withStyles({
        tooltip: {
            fontSize: "14px",
            color: COLORS.primary,
            backgroundColor: "#fff"
        }
    })(Tooltip);

    const classes = useStyles()

    const related = {
        ...props.related,
        module_id: props.module_id
    }

    const selectSection = () => {
        const justSelected = props.name === props.lessonSelected;

        if ((!props.block && !props.has_expired) && !justSelected) {
            if (!props.sub_expired || (props.sub_expired && !props.has_blocking_subscription)) {
                props.clickLesson(related);
            }
        }
    }

    const FolderIcon = (className) => {
        return <svg className={className} xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><g><rect fill="none" height="24" width="24" /></g><g><path d="M20,6h-8l-2-2H4C2.9,4,2.01,4.9,2.01,6L2,18c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V8C22,6.9,21.1,6,20,6z M20,18L4,18V6h5.17 l2,2H20V18z M9.41,14.42L11,12.84V17h2v-4.16l1.59,1.59L16,13.01L12.01,9L8,13.01L9.41,14.42z" /></g></svg>
    }

    return (
        <React.Fragment>
            {!props.hide ?
                <React.Fragment>
                    <li onClick={selectSection} name={props.name} className={props.name === props.lessonSelected ? classes.selected_lesson : ""}>
                        <section className={"section_container"} style={{cursor: (props.block || props.has_expired) || (props.sub_expired && props.has_blocking_subscription) ? "default" : "pointer", paddingLeft: !props.hiddenModule ? 46 : 15 }} >
                            {!props.block ?
                                <div className="related_icon">
                                    {props.finish ?
                                        <React.Fragment>
                                            <CheckCircleIcon className={"icon_play_related"} style={{ color: "#3fbe54", borderRadius: "20px", background: "#fff", height: 20, width: 20 }} />
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            {props.category === "LESSON" ? <PlayCircleOutlineIcon className={"icon_play_related"} /> : <HelpOutline className={"icon_play_related"} />}
                                        </React.Fragment>
                                    }
                                </div>
                                :
                                <div className="related_icon">

                                    <React.Fragment>
                                        <LockIcon className={"icon_play_related"} />
                                    </React.Fragment>

                                </div>
                            }
                            <div className="related_title">

                                <h4>{props.title}</h4>
                                <h5>{props.teacher?.first_name ? props.teacher?.first_name + " " + props.teacher?.last_name : null}</h5>
                            </div>

                            {props.is_mandatory ?
                                <div className="related_cup">
                                    <BlueTooltip TransitionComponent={Zoom} title="Sezione obbligatoria per il completamento del corso">
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <EmojiEventsIcon style={{ color: darken(COLORS.primary, 0.5) }} />
                                        </div>
                                    </BlueTooltip>
                                </div>
                                : null}
                        </section>


                    </li>
                    {/*<div style={{ borderBottom: "1px solid rgba(255,255,255,0.1)", width: "90%", margin: "0 auto", clear: "left" }}></div>*/}
                </React.Fragment>
                :
                null
            }
        </React.Fragment>

    )

}

export default RelatedBox;