import serviceAdmin from "../../services/serviceAdmin"


/** 
 * Creazione di una domanda
 * @param {Number} id - id del Survey o Evaluation
 * @param {Object} object - oggetto contenente la domanda selezionata
 * @param {Boolean} isEvaluation - gestisce l'endpoint della chiamata
 */
async function createQuestion(id, object, isEvaluation) {
    
    const endpoint = isEvaluation ? 'evaluations' : 'surveys'
    let data = {
        "text": object.text,
        "order": object.key,
        "question_config": object.question_config
    };
    if (isEvaluation) data.is_active = object.is_active;

    try {
        const response = await serviceAdmin.postService(`v1/${endpoint}/${id}/questions/`, data)
        if (!response.data.meta.error) {
            return response
        } else {
            throw Error(response.data.message)
        }
    } catch (error) {
        console.log('[helpers.createQuestion]', error)
    }
}

/**
 * Recupero lista domande
 * @param {Number} id - id del Survey o Evaluation
 * @param {Boolean} isEvaluation - gestisce l'endpoint della chiamata
 */
async function fetchQuestions(idSurvey, isEvaluation) {
    const endpoint = isEvaluation ? 'evaluations' : 'surveys'
    try {
        const response = await serviceAdmin.getService(`v1/${endpoint}/${idSurvey}/`)
        if (!response.data.meta.error) {
            return response
        } else {
            throw Error(response.data.message)
        }
    } catch (error) {
        console.log('[helpers.fetchQuestions]', error)
    }
}

/**
 * Rimuove le domanda
 * @param {Number} id - id del Survey o Evaluation
 * @param {Boolean} isEvaluation - gestisce l'endpoint della chiamata 
 */
async function removeQuestion(id, isEvaluation) {
    const endpoint = isEvaluation ? 'evaluation-questions' : 'survey-questions'
    if (id) {
        try {
            const response = await serviceAdmin.deleteService(`v1/${endpoint}/${id}/`)
            if (!response.data.meta.error) {
                return response
            } else {
                throw Error(response.data.message)
            }
        } catch (error) {
            console.log('[helpers.removeQuestion]', error)
        }
    }
}

/**
 * Modifica la domanda
 * @param {Number} id - id del Survey o Evaluation 
 * @param {String} textQuestion - testo della domanda
 * @param {Boolean} isEvaluation - gestisce l'endpoint della chiamata
 */
async function updateQuestion(id, textQuestion, isEvaluation) {
    const endpoint = isEvaluation ? 'evaluation-questions' : 'survey-questions'
    try {
        const response = await serviceAdmin.putService(`v1/${endpoint}/${id}/`, { "text": textQuestion })
        if (!response.data.meta.error) {
            return response
        } else {
            throw Error(response.data.message)
        }
    } catch (error) {
        console.log('[helpers.updateQuestion]', error)
    }
}

/**
 * Modifica l'ordine della domanda
 * @param {Number} id - id del Survey o Evaluation 
 * @param {Number} index - indice di posizione
 * @param {Boolean} isEvaluation - gestisce l'endpoint della chiamata
 */
async function orderQuestion(id, index, isEvaluation) {
    const endpoint = isEvaluation ? 'evaluation-questions' : 'survey-questions'
    try {
        const response = await serviceAdmin.putService(`v1/${endpoint}/${id}/`, { "order": index })
        if (!response.data.meta.error) {
            return response
        } else {
            throw Error(response.data.message)
        }
    } catch (error) {
        console.log('[helpers.orderQuestion]', error)
    }
}

/**
 * Modifica il campo active della domanda
 * @param {Number} id - id del Survey o Evaluation 
 * @param {Boolean} active - Indica se la domanda è attiva
 */
async function activeQuestion(id, name, checked) {
    try {
        const response = await serviceAdmin.putService(`v1/evaluation-questions/${id}/`, { [name]: checked })
        if (!response.data.meta.error) {
            return response
        } else {
            throw Error(response.data.message)
        }
    } catch (error) {
        console.log('[helpers.activeQuestion]', error)
    }
}

/**
 * Creazione di una risposta
 * @param {Number} id - id del Survey o Evaluation 
 * @param {Object} answer - Answer Object
 * @param {Boolean} isEvaluation - gestisce l'endpoint della chiamata
 */
async function createAnswer(id, answer, isEvaluation) {
    const endpoint = isEvaluation ? 'evaluation-questions' : 'survey-questions'
    let data = {
        "text": answer.text,
        "order": answer.key
    }
    if (isEvaluation) data.is_correct = (answer.is_correct ? true : false);

    try {
        const response = await serviceAdmin.postService(`v1/${endpoint}/${id}/answers/`, data)
        if (!response.data.meta.error) {
            return response
        } else {
            throw Error(response.data.message)
        }
    } catch (error) {
        console.log('[helpers.createAnswer]', error)
    }
}

/**
 * Modifica di una risposta
 * @param {Number} id - id del Survey o Evaluation 
 * @param {Object} answer - Answer Object
 * @param {Boolean} isEvaluation - gestisce l'endpoint della chiamata
 */
async function updateAnswer(id, updatedAnswer, isEvaluation) {
    const endpoint = isEvaluation ? 'evaluation-answers' : 'survey-answers'

    try {
        const response = await serviceAdmin.putService(`v1/${endpoint}/${id}/`, updatedAnswer)
        if (!response.data.meta.error) {
            return response
        } else {
            throw Error(response.data.message)
        }
    } catch (error) {
        console.log('[helpers.updateAnswer]', error)
    }
}

/**
 * Modifica di una risposta
 * @param {Number} id - id del Survey o Evaluation 
 * @param {Boolean} isEvaluation - gestisce l'endpoint della chiamata
 */
async function deleteAnswer(question_id, answer_id, isEvaluation) {
    const endpoint = isEvaluation ? 'evaluation-questions' : 'survey-questions'
    try {
        const response = await serviceAdmin.deleteService(`v1/${endpoint}/${question_id}/answers/${answer_id}/`)
        if (!response.data.meta.error) {
            return response
        } else {
            throw Error(response.data.message)
        }
    } catch (error) {
        console.log('[helpers.deleteAnswer]', error)
    }
}


const helpers = {
    createQuestion,
    fetchQuestions,
    removeQuestion,
    updateQuestion,
    orderQuestion,
    activeQuestion,
    createAnswer,
    updateAnswer,
    deleteAnswer
}
export default helpers;

