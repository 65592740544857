import React, { useState, useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';
import serviceAdmin from '../../services/serviceAdmin';
import useColor from '../../context/themeContext'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info';


const CustomTooltip = withStyles({
    tooltip: {
        fontSize: "14px"
    }
})(Tooltip);



const AddGroup = (props) => {
    const { COLORS } = useColor();
    const useStyles = makeStyles({
        title: {
            color: "#4c23f1",
            fontSize: "20px",
            fontWeight: "bold",
            paddingBottom: "10px"
        },
        subtitle: {
            fontWeight: "bold",
            paddingBottom: "10px"
        },
        select: {
            width: "100%",
            maxWidth: "235px",
            margin: "30px 0"
        },
        selected: {
            '&.Mui-selected': {
                color: COLORS.primary + "!important"
            }
        },
        color: {
            color: COLORS.primary + "!important"
        },
        btn_save: {
            backgroundColor: COLORS.primary + "!important",
            fontWeight: "bold",
            color: "#fff",
            margin: "0 10px",
            width: "90px",
            height: "35px",
            borderRadius: "20px",
            '&:hover': {
                backgroundColor: COLORS.primary + "!important",
            }
        },
        container_btn: {
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }

    });
    const classes = useStyles();





    const isMount = useRef(true);

    const [groups, setGroups] = useState([])
    const [loading, setLoading] = useState(false)
    const [group_ids, setGroup_ids] = useState([]);


    useEffect(() => {
        serviceAdmin.getService('v1/groups/')
            .then(res => {
                if (isMount.current) {
                    setLoading(true);
                    if (res.data.data) {
                        setGroups(res.data.data);
                    }
                }
            }).catch(err => {
                setLoading(true);
                console.log(err);
            })

        return () => {
            isMount.current = false;
        }
    })

    const changeGroup = (e) => {
        setGroup_ids(e.target.value);
    }

    const createSubscriptions = () => {
        const params = {
            "groups_ids": group_ids,
            "course_id": props.course.id
        };

        serviceAdmin.postService("v1/subscriptions/groups/", params)
            .then(res => {
                if (res.status === 201) {
                    props.setCustomAlert(true, "success", "Gruppo iscritto correttamente!");
                    setGroup_ids([]);
                }
            }).catch(err => {
                console.log(err);
            })
    };


    return (
        <div>
            {loading &&
                <div style={{ width: "100%", padding: "20px 30px", marginBottom: "80px" }}>

                    {groups.length > 0 ?
                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <h2 className="title_corsi_admin">Aggiungi gli utenti di un gruppo al corso <span className={classes.color}>{props.course.name}</span></h2>
                            </Grid>

                            <Grid item xs={12} md={8} lg={6}>
                                <Select MenuProps={{ getContentAnchorEl: () => null }} multiple name="group_ids" value={group_ids || []} onChange={changeGroup} variant="outlined" className={classes.select}>
                                    {
                                        groups.length > 0 ?
                                            groups.map((group, index) => {
                                                return (
                                                    <MenuItem className={classes.selected} key={index} value={group.id}>{group.name}</MenuItem>
                                                )
                                            })
                                            : null
                                    }

                                </Select>

                                <Button disabled={group_ids.length > 0 ? false : true} onClick={createSubscriptions} className={classes.btn_save}>Aggiungi</Button>

                            </Grid>

                        </Grid>
                        :
                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <h2 className="title_corsi_admin">Non hai gruppi da aggiungere al corso
                    <span className={classes.color}>
                                        {props.course.name}
                                        <CustomTooltip
                                            title="Inizia creando un nuovo gruppo nella sezione Gruppi"
                                        >
                                            <InfoIcon style={{ fontSize: "19px" }} />
                                        </CustomTooltip>
                                    </span></h2>
                            </Grid>
                        </Grid>
                    }

                </div>}

        </div>
    )

}

export default AddGroup;