import React from 'react'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import useColor from '../../context/themeContext'

const Switch = (props) => {
	
	const { COLORS } = useColor();
	const { checked, handleToggleChecked, enableLabel, disableLabel } = props

	return (
		<ButtonGroup variant="contained" color="primary">
			<Button style={!checked ? { background: "grey" } : { background: COLORS.primary }} onClick={handleToggleChecked}>{enableLabel}</Button>
			<Button style={checked ? { background: "grey" } : { background: COLORS.primary }} onClick={handleToggleChecked}>{disableLabel}</Button>
		</ButtonGroup>
	)
}

export default Switch