import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import FromDate from '../Export/ExportFilter/FromDate';
import Grid from '@material-ui/core/Grid'
import serviceAdmin from '../../services/serviceAdmin'
import { useEffect } from 'react';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from '../../componentsAdmin/CollapseAlert/Alert';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import { modalClass } from '../../classes';
import useColor from '../../context/themeContext'
import useAlert from '../../hooks/useAlert'
import FullTextField from '../StyledComponent/FullTextField'



export default function ModalTeacher(props) {
  
  const [options, setAlert] = useAlert()
  const { COLORS } = useColor()
  const useStyles = makeStyles((theme) => (modalClass(COLORS)));
  const classes = useStyles();

  const [input, setInput] = useState({})
  const [expiration, setExpiration] = useState({})
  const [users, setUsers] = useState([])
  const [checkedActive, setcheckedActive] = useState(false)
  const [search, setSearch] = useState("")
  const [openAlert, setOpenAlert] = useState({});

  const setCustomAlert = (value, two_value, three_value) => {
    setOpenAlert({
      ...openAlert,
      "state": value,
      "severity": two_value,
      "message": three_value
    })
  };

  useEffect(() => {
    if (openAlert.state) {
      const timer = setTimeout(() => {
        setOpenAlert({ "state": false })
      }, 5000)

      return () => {
        clearTimeout(timer);
      }
    }
  }, [openAlert.state])


  const setCheckedActive = (input) => { setcheckedActive(input) }


  const changeExpiration = (value) => {
    const name = "expiration_date";
    setExpiration({ [name]: value });
  }

  const handleChangeAuto = (e, newvalue) => {
    setInput({ ...input, "user_id": newvalue?.id })
  }
  const handleChangeSearch = (e, newvalue) => {
    setSearch(e.target.value);
  }

  const handleClose = () => {
    setInput({});
    props.setOpen(false);
  };


  useEffect(() => {
    if (search.length > 0) {
      serviceAdmin.getService(`v1/users/?search=${search}`)
        .then(res => {
          if (res.data.data) {
            setUsers(res.data.data);
          }
        }).catch(err => {
          console.log(err);
        })
    }
  }, [search])

  function clean(obj) {
    for (let propName in obj) {
      if (obj[propName] === "" || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
  };

  const createSubscription = () => {
    const params = {
      ...input,
      "is_active": checkedActive,
      "course_id": props.course_id,
      "expiration_date": expiration?.expiration_date ? expiration?.expiration_date : null
    }

    clean(params);

    serviceAdmin.postService("v1/subscriptions/", params)
      .then(res => {
        if (res.status === 201) {
          setAlert(true, "Utente iscritto correttamente!", "success")
          props.setReload();
          handleClose();
        }
      }).catch(err => {
        setAlert(true, "L'Utente è già iscritto al corso!", "warning")
      })
  };

  const disableExpiration = () => {
    setExpiration({ "expiration_date": null })
  }

  return (

    <Drawer
      anchor={"right"}
      open={props.open}
      onClose={handleClose}
    >
      <Alert
        setOpenAlert={setCustomAlert}
        severity={openAlert?.severity}
        openAlert={openAlert?.state}
        message={openAlert?.message}
      />

      <div className={"drawer-container"}>
        <div className={classes.closeicon + " title-drawer-container"}>
          <CloseIcon style={{ cursor: "pointer", height: "30px", width: "30px" }} onClick={handleClose} />
          <h2 className={classes.title}> Aggiungi utenti al corso </h2>
        </div>
        <div className={"drawer-content-container"} style={{ padding: "20px 30px" }}>

          <Grid container spacing={3}>

            <Grid item xs={6}>

              <InputLabel className={classes.label}>
                Utente
              </InputLabel>

              <Autocomplete
                onChange={handleChangeAuto}
                options={users}
                getOptionLabel={(option) => option?.first_name + " " + option?.last_name}
                renderInput={(params) => <FullTextField style={{ background: "#f1f1f1", minWidth: "180px", backgroundColor: "#f1f1f1", borderRadius: "20px" }} onChange={handleChangeSearch} {...params} label="Cerca..." variant="outlined" />}
              />

            </Grid>

            <Grid item xs={6}>

              <InputLabel className={classes.label}>
                Scadenza
              </InputLabel>
              <div style={{ display: "flex", alignItems: "center" }}>
                <FromDate
                  inputProps={`${classes.input}`}
                  value={expiration.expiration_date}
                  handleChange={changeExpiration}
                />
                <IconButton onClick={disableExpiration}>
                  <HighlightOffIcon />
                </IconButton>
              </div>
            </Grid>

            <Grid item xs={12}>
              <InputLabel className={classes.label}>
                Attivo-disattivo
              </InputLabel>
              <ButtonGroup variant="contained" color="primary">
                <Button style={!checkedActive ? { background: "grey" } : { background: COLORS.primary }} onClick={() => setCheckedActive(true)}>Attivo</Button>
                <Button style={checkedActive ? { background: "grey" } : { background: COLORS.primary }} onClick={() => setCheckedActive(false)}>Disattivo</Button>
              </ButtonGroup>
            </Grid>

          </Grid>
        </div>
        <div className={classes.container_btn}>
          <Button onClick={handleClose} className={classes.btn_del}>Annulla</Button>
          <Button onClick={createSubscription} className={classes.btn_save}>Aggiungi</Button>
        </div>
      </div>
    </Drawer>

  );
}