import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import Paper from '@material-ui/core/Paper'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import FromDate from '../Export/ExportFilter/FromDate'
import Grid from "@material-ui/core/Grid"
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Button from '@material-ui/core/Button'
import AntSwitch from "../Switch/AntSwitch"
import serviceAdmin from '../../services/serviceAdmin'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import IconButton from '@material-ui/core/IconButton'
import { tabsStyle } from '../../classes'
import CssTextField from '../StyledComponent/CssTextField'
import useAlert from '../../hooks/useAlert'
import useColor from '../../context/themeContext'

const TabsnewCourse = (props) => {
    
    const { COLORS } = useColor();
    const useStyles = makeStyles({
        ...tabsStyle(COLORS),
        arrow: {
            width: "100%",
            height: "48px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            cursor: "pointer"
        },
        arrowicon: {
            color: "#999"
        }
    });
    const classes = useStyles();

    const [opt, setAlert] = useAlert();

    const inputImageRef = useRef();

    const [certifications, setCertifications] = useState([])
    const [typologies, setTypologies] = useState([])
    const [categories, setCategories] = useState([])
    const [subCategories, setSubCategories] = useState([])
    const [input, setInput] = useState({})
    const [expiration, setExpiration] = useState({})
    const [checked, setchecked] = useState(false)
    const [checkedAccess, setcheckedAccess] = useState(false)
    const [checkedActive, setcheckedActive] = useState(false)
    const [checkedCertificate, setcheckedcertificate] = useState(false)
    const [image, setImage] = useState()
    const [timeUpload, setTimeUpload] = useState(null);
    const [conflict, setConflict] = useState(false);
    const [isSub, setIsSub] = useState(false);
    const [category_id, handlechangeCategory] = useState("");
    const [subcategory_id, handlechangeSubCategory] = useState("");

    let slugRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;

    const options = {
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total)
            if (image) {
                setTimeUpload(`Upload immagine ${percent}%`);
            }
        }
    }

    const setChecked = () => { setchecked(!checked) }
    const setCheckedAccess = () => { setcheckedAccess(!checkedAccess) }
    const setCheckedCertificate = () => { setcheckedcertificate(!checkedCertificate) }
    const setCheckedActive = () => { setcheckedActive(!checkedActive) }

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setInput({ ...input, [name]: value })
    }
    const handleExpiration = (value) => {
        const name = "expiration_date";
        setExpiration({ [name]: value })
    }

    const handleChangeSubCategory = (e) => {
        const value = e.target.value;
        handlechangeSubCategory(value);
    }

    const handleChangeCategory = (e) => {
        const value = e.target.value;
        const category = categories.find(x => x.id === value);
        handlechangeCategory(value);

        if (category && !category?.parent) {
            serviceAdmin.getService(`v1/course-categories/${value}/children/`)
                .then(res => {
                    if (res.data.data) {
                        setSubCategories(res.data.data)
                        setIsSub(true);
                    }
                }).catch(err => {
                    console.log(err);
                })
        } else {
            setIsSub(false);
            setSubCategories([])
        }
    }

    const disableExpiration = () => {
        setExpiration({ "expiration_date": null })
    }

    useEffect(() => {

        serviceAdmin.getService("v1/course-typologies/")
            .then(res => {
                setTypologies(res.data.data)
            }).catch(err => {
                console.log(err);
            });
        serviceAdmin.getService("v1/course-categories/parents/")
            .then(res => {
                setCategories(res.data.data)
            }).catch(err => {
                console.log(err);
            });

        serviceAdmin.getService("/v1/certification-templates/")
            .then((res)=>{
                setCertifications(res.data.data)
            }).catch((err)=>{
                console.log(err)
            })

    }, [])

    const setImageRef = () => {
        inputImageRef.current.click()
    }

    ////////IMAGE SELECT

    function imageSelected(event) {
        const isImage = file => file["type"].includes("image")
        let input = event.target
        if (input.files && input.files[0]) {
            if (isImage(input.files[0])) {
                let reader = new FileReader()
                reader.onload = e => {
                    setImage(e.target.result)
                }
                reader.readAsDataURL(input.files[[0]])
            } else {
                alert('foto non caricata')
            }
        }
    }
    function clean(obj) {
        for (let propName in obj) {
            if (obj[propName] === "" || obj[propName] === undefined) {
                delete obj[propName];
            }
        }
    }


    const createCourse = () => {
        let params;
        if (!subcategory_id) {
            params = {
                ...input,
                "certification_template_id": input.certification_id,
                "is_mandatory": checkedAccess,
                "has_blocking_subscriptions": checked,
                "show_expiration_alert": checkedActive,
                "thumbnail": image,
                "has_certification": checkedCertificate,
                "category_id": category_id,
                "expiration_date": expiration?.expiration_date ? expiration?.expiration_date : null
            }
        } else if (category_id && subcategory_id) {
            params = {
                ...input,
                "certification_template_id": input.certification_id,
                "is_mandatory": checkedAccess,
                "has_blocking_subscriptions": checked,
                "show_expiration_alert": checkedActive,
                "thumbnail": image,
                "has_certification": checkedCertificate,
                "category_id": subcategory_id,
                "expiration_date": expiration?.expiration_date ? expiration?.expiration_date : null
            }
        } else {
            params = {
                ...input,
                "certification_template_id": input.certification_id,
                "is_mandatory": checkedAccess,
                "has_blocking_subscriptions": checked,
                "show_expiration_alert": checkedActive,
                "thumbnail": image,
                "has_certification": checkedCertificate,
                "expiration_date": expiration?.expiration_date ? expiration?.expiration_date : null
            }
        }
        clean(params);


        serviceAdmin.postService("v1/courses/", params, options)
            .then(res => {
                if (res.status === 201) {
                    setAlert(true, "Corso creato correttamente!", "success");
                    props.setReload();
                    props.handleClose();
                    setConflict(false)
                }
            }).catch(err => {
                if (err.status === 400 || err.status === 409) {
                    if (err.data.reason.includes("slug")) {
                        setConflict(true)
                    } else {
                        setConflict(false)
                    }
                }
            })
    }

    return (
        <React.Fragment>
            <Paper className={classes.root}>
                <div className={classes.arrow} onClick={props.handleClose}>
                    <ArrowBackIosIcon className={classes.arrowicon} />
                </div>
                <div className={classes.content}>
                    <h2 className={classes.title}>Crea un nuovo corso</h2>

                    <div style={{ paddingTop: "20px" }}>
                        <Grid container spacing={3}>
                            <Grid spacing={3} container item md={12} lg={6}>

                                <Grid className="flex-center" item xs={12} md={6}>
                                    <InputLabel className={classes.label}>
                                        Nome*
                                    </InputLabel>
                                    <CssTextField name="name" value={input.name || ""} onChange={handleChange} placeholder="nome del corso" variant="outlined" inputProps={{ className: classes.input }} />
                                </Grid>

                                <Grid className="flex-center" item xs={12} md={6}>
                                    <InputLabel className={classes.label}>
                                        Tipologia
                                    </InputLabel>
                                    <Select name="typology_id" value={input.typology_id || ""} onChange={handleChange} variant="outlined" className={classes.select}>
                                        <MenuItem className={classes.selected} value="">Nessuna tipologia</MenuItem>
                                        {
                                            typologies.length > 0 ?
                                                typologies.map((typology, index) => {
                                                    return (
                                                        <MenuItem className={classes.selected} key={index} value={typology.id}>{typology.name}</MenuItem>
                                                    )
                                                })
                                                : null
                                        }

                                    </Select>
                                </Grid>
                                <Grid className="flex-center" item xs={12} md={6}>
                                    <InputLabel className={classes.label}>
                                        Attestato
                                    </InputLabel>
                                    <Select name="certification_id" value={input.certification_id || ""} onChange={handleChange} variant="outlined" className={classes.select}>
                                        <MenuItem className={classes.selected} value="">Nessun attestato</MenuItem>
                                        {
                                            certifications.length > 0 ?
                                                certifications.map((certificate, index) => {
                                                    return (
                                                        <MenuItem className={classes.selected} key={index} value={certificate.id}>{certificate.name}</MenuItem>
                                                    )
                                                })
                                                : null
                                        }

                                    </Select>
                                </Grid>
                               
                                <Grid className="flex-center" item xs={12} md={6} style={{ paddingTop: "20px" }}>

                                    <InputLabel className={classes.label}>
                                        Thumbnail
                                    </InputLabel>
                                    <div onClick={setImageRef} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                                        {!timeUpload ?
                                            <React.Fragment>
                                                <input ref={inputImageRef} style={{ visibility: "hidden", width: "1px" }} type="file" onChange={imageSelected} />
                                                {image ? "Cambia" : "Aggiungi"} immagine
                            {!image ?
                                                    <AddCircleIcon className={classes.color} style={{ cursor: "pointer", paddingLeft: "10px", width: "40px", height: "40px" }} />
                                                    :
                                                    <DoneAllIcon style={{ paddingLeft: "10px", width: "40px", height: "40px" }} className={classes.color} />
                                                }
                                            </React.Fragment>
                                            :
                                            <p>
                                                {timeUpload}
                                            </p>
                                        }
                                    </div>

                                </Grid>
                                <Grid container item spacing={3}>
                                    <Grid className="flex-center" item xs={12} md={6} style={{ paddingTop: "20px" }}>
                                        <InputLabel className={classes.label}>
                                            Descrizione
                                        </InputLabel>
                                        <CssTextField name="description" value={input.description || ""} onChange={handleChange} placeholder="Descrizione del corso" multiline rows={5} variant="outlined" inputProps={{ className: classes.textarea }} />
                                    </Grid>
                                    <Grid className="flex-center" item xs={12} md={6} style={{ paddingTop: "20px" }}>
                                        <InputLabel className={classes.label}>
                                            Info aggiuntive
                                        </InputLabel>
                                        <CssTextField name="additional_text" value={input.additional_text || ""} onChange={handleChange} placeholder="Info del corso" multiline rows={5} variant="outlined" inputProps={{ className: classes.textarea }} />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid spacing={3} container item md={12} lg={6}>

                                <Grid className="flex-center" item xs={12} md={6}>
                                    <InputLabel className={classes.label}>
                                        Codice*
                                    </InputLabel>
                                    <CssTextField name="code" value={input.code || ""} onChange={handleChange} placeholder="Codice del corso" variant="outlined" inputProps={{ className: classes.input }} />
                                </Grid>
                                <Grid className="flex-center" item xs={12} md={6}>
                                    <InputLabel className={classes.label}>
                                        Slug
                                    </InputLabel>
                                    <CssTextField helperText={conflict ? "Slug già esistente" : ""} error={conflict ? true : false} name="slug" value={input.slug || ""} onChange={handleChange} placeholder="Slug del corso" variant="outlined" inputProps={{ className: classes.input }} />
                                </Grid>
                                <Grid className="flex-center" item xs={12} md={6}>
                                    <InputLabel className={classes.label}>
                                        Categoria
                                    </InputLabel>
                                    <Select name="category_id" value={category_id || ""} onChange={handleChangeCategory} variant="outlined" className={classes.select}>
                                        <MenuItem className={classes.selected} value="">Nessuna categoria</MenuItem>
                                        {
                                            categories.length > 0 ?
                                                categories.map((category, index) => {
                                                    return (
                                                        <MenuItem className={classes.selected} key={index} value={category.id}>{category.name}</MenuItem>
                                                    )
                                                })
                                                : null
                                        }

                                    </Select>
                                </Grid>
                                {
                                    isSub ?
                                        <Grid className="flex-center" item xs={12} md={6}>

                                            <InputLabel className={classes.label}>
                                                Sottocategoria
                                            </InputLabel>

                                            <Select name="subcategory_id" value={subcategory_id || ""} onChange={handleChangeSubCategory} variant="outlined" className={classes.select}>
                                                <MenuItem className={classes.selected} value="">Nessuna sottocategoria</MenuItem>
                                                {
                                                    subCategories.map((category, index) => {
                                                        return (
                                                            <MenuItem className={classes.selected} key={index} value={category.id}>{category.name}</MenuItem>
                                                        )
                                                    })
                                                }

                                            </Select>

                                        </Grid>
                                        :

                                        <Grid className="flex-center" item xs={12} md={6}></Grid>

                                }
                                <Grid className="flex-center" item xs={12} md={6}>
                                    <InputLabel className={classes.label}>
                                        Stato
                                    </InputLabel>
                                    <Select name="status" value={input.status || "RDY"} onChange={handleChange} variant="outlined" className={classes.select}>
                                        <MenuItem className={classes.selected} value="RDY">Bozza</MenuItem>
                                        <MenuItem className={classes.selected} value="STR">Pubblicato</MenuItem>
                                        <MenuItem className={classes.selected} value="END">Terminato</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid className="flex-center" item xs={12} md={6} lg={6} style={{ minWidth: "260px" }}>
                                    <InputLabel className={classes.label}>
                                        Scadenza
                                    </InputLabel>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                    <FromDate
                                        inputProps={`${classes.input}`}
                                        value={expiration.expiration_date}
                                        handleChange={handleExpiration}
                                    />
                                        <IconButton onClick={disableExpiration}>
                                            <HighlightOffIcon />
                                        </IconButton>
                                    </div>
                                </Grid>
                                <Grid className="flex-center" item xs={12} md={6}>
                                    <InputLabel className={classes.label}>
                                        Rilascia attestato
                                    </InputLabel>
                                    <AntSwitch checked={checkedCertificate} setChecked={setCheckedCertificate} />
                                </Grid>
                                <Grid className="flex-center" item xs={12} md={6}>
                                    <InputLabel className={classes.label}>
                                        Rendi la scadenza delle iscrizioni bloccante
                                    </InputLabel>
                                    <AntSwitch checked={checked} setChecked={setChecked} />
                                </Grid>
                                <Grid className="flex-center" item xs={12} md={6}>
                                    <InputLabel className={classes.label}>
                                        Obbligatorio
                                    </InputLabel>
                                    <AntSwitch checked={checkedAccess} setChecked={setCheckedAccess} />
                                </Grid>
                                <Grid className="flex-center" item xs={12} md={6}>
                                    <InputLabel className={classes.label}>
                                        Mostra messaggio scadenza
                                    </InputLabel>
                                    <AntSwitch checked={checkedActive} setChecked={setCheckedActive} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>

                    <div className={classes.container_btn}>
                        <Button onClick={props.handleClose} className={classes.btn_del}>Annulla</Button>
                        <Button disabled={(slugRegex.test(input.slug) || input.slug === "") && input.name && input.code ? false : true} onClick={createCourse} className={classes.btn_save}>Crea</Button>
                    </div>
                </div>
            </Paper>
        </React.Fragment>
    )
}

export default TabsnewCourse;