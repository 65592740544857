import React from 'react';
import { Redirect, Route } from 'react-router-dom';


const Privateroute = ({ component: Component, ...rest }) => {

    
    return (
        <Route
            {...rest}
            render={props =>
                localStorage.getItem("bearer_fit_token") && localStorage.getItem("admin_lim_fit_id") ? (
                    <Component {...props} />
                ) : (
                        <Redirect to="/login" />
                    )

            }


        />
    )
}
export default Privateroute
