import { w3cwebsocket } from "websocket"


async function asyncInit({ baseUrl, authToken, lessonId }, onOpen = (socket) => false, onError = (err) => true, onClose = ()=>{}) {
    return new Promise(function (res, rej) {
        const s = new w3cwebsocket(`${baseUrl}v1/course-sections/${lessonId}/?token=${authToken}`)
        const handleOpen = (evt) => {
            console.log("handleOpen", evt)
            onOpen(s)
            res(s)
            s.removeEventListener('open', handleOpen)
        }
        const handleError = (err) => {
            console.log("ERROR", "ASYNC INIT")
            onError(err)
            rej(err)
            s.removeEventListener('error', handleError)
        }
        const handleClose = () => {
            s?.removeEventListener('close', handleClose)
            onClose()
        }

        s?.addEventListener('close', handleClose)
        s.addEventListener('open', handleOpen)
        s.addEventListener('error', handleError)
    })
}

function spawnPing(socket) {
    socket.pinger = setInterval(() => asyncSend(socket, { event: "ping" }), 19500)
    return socket.pinger
}

function despawnPing(socket) {
    return clearInterval(socket.pinger)
}

async function asyncDisconnect(socket, onClose = (evt) => {}) {
    return new Promise((res, rej) => {
        const handleClose = (e) => {
            try {
                clearInterval(socket.pinger)
                onClose(e)
                res(e)
                socket.removeEventListener('close', handleClose)
            }
            catch (e) {
                console.log("Errore nella chiusura del socket: handleClose")
            }
        }
        const handleError = (e) => {
            try {
                rej(e)
                socket.removeEventListener('error', handleError)
            }
            catch (e) {
                console.log("Errore nella chiusura del socket: handleError")
            }
        }
        try{
        socket?.addEventListener('close', handleClose)
        socket?.addEventListener('error', handleError)
        socket?.close(1000, "CLOSED_NO_CONTENT")
        }catch(e){
            console.log("Socket close", e)
        }
    })
}


async function asyncSend(socket, { event, additionalData }) {
    return new Promise((res, rej) => {
        const handleRes = (e) => {
            res(JSON.parse(e.data))
            socket.removeEventListener('message', handleRes)
        }
        const handleRej = (e) => {
            rej(e)
        
            console.log("Error", e)
            socket.removeEventListener('error', handleRej)
        }
        if (socket?.readyState === 1) {
            socket.addEventListener('message', handleRes)
            socket.addEventListener('error', handleRej)
            socket.send(JSON.stringify({ event, additional_data: additionalData }))
        } else {
            rej(TypeError('Socket not open'))
        }
    })
}

async function asyncReadMessage(socket) {
    return new Promise((res, rej) => {
        const handleRes = (e) => {
            res(JSON.parse(e.data))
            socket.removeEventListener('message', handleRes)
        }
        const handleRej = (e) => {
            rej(e)
            socket.removeEventListener('error', handleRej)
        }
        if (socket?.readyState === 1) {
            socket.addEventListener('message', handleRes)
            socket.addEventListener('error', handleRej)
        } else {
            rej(TypeError('Socket not open'))
        }
    })
}

async function sendPlayMedia(socket) {
    return await asyncSend(socket, { event: "play_media" })
}


async function sendStopMedia(socket, sectionStepTime) {
    return await asyncSend(socket, { event: "stop_media", additionalData: { "section_step_time": sectionStepTime } })
}


async function sendRead(socket) {
    return await asyncSend(socket, { event: "read" })
}


async function sendEndSection(socket, watchedMediaDuration) {
    return await asyncSend(socket, { event: "end_section", additionalData: { "watched_media_duration": watchedMediaDuration } })
}


const socketClient = {
    asyncInit,
    asyncDisconnect,
    asyncSend,
    asyncReadMessage,
    sendPlayMedia,
    sendStopMedia,
    sendEndSection,
    sendRead,
    spawnPing,
    despawnPing
}

export default socketClient;
