import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AccordionSummary, Accordion, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RelatedModules from './RelatedModules'
import Paper from '@material-ui/core/Paper'
import Stepper from './Stepper'
import SchoolIcon from '@material-ui/icons/School';
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'
import 'moment/locale/it';
import useColor from '../../context/themeContext';
moment.locale('it')


const RelatedColumn = (props) => {

    const { COLORS } = useColor()

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const useStyles = makeStyles((theme) => ({
        button: {
            margin: "0 0 20px",
            backgroundColor: "#3aac4d",
            color: "#fff!important",
            width: "100%",
            '&:active': {
                backgroundColor: "#3aac4d",
                color: "#fff"
            },
            '&:hover': {
                backgroundColor: "#3aac4d",
            }
        },
        collapse_content: {
            alignItems: "center",
            flexDirection: "column",
            background: COLORS.primary,
            padding: 0
        },
        collapse_text: {
            backgroundColor: "#fff",
            padding: " 0 20px",
            color: COLORS.primary,
            fontWeight: "bold",
            bordeBbottom: "2px solid " + COLORS.primary,
            flexDirection: "row-reverse"
        }
    }));
    const classes = useStyles();

    const { is_preview, colHeight, width } = props

    const [hide, sethide] = useState(true)
    const [expanded, setExpanded] = React.useState(0);

    const setHide = () => { sethide(!hide) };

    const route = "/certificate/:id";

    const handleAccordion = (thematic) => {
        thematic.openAccordion = !thematic.openAccordion
        forceUpdate()
    }


    return (
        <section className="related_content">
            <div style={{ width: "100%", clear: "both" }}>

                <ul>

                    <Paper style={{ borderRadius: "0px", padding: "15px", display: "flex", width: "100%", background: "#fff", marginLeft: "0px", marginBottom: "20px" }}>
                        {props.passed ?
                            <React.Fragment>
                                <div style={{ width: "100%" }}>
                                    <Stepper is_preview={is_preview} complete={props.passed} courseMandatory={props.mandatoryCourse} course_code={props.course_code} course={props.courseName} relatedsComplete={props.passedsections} relateds={props.sections} />
                                    <p className={"activeD"} style={{ fontSize: "18px", textAlign: "center", fontWeight: "bold", color: "#3aac4d", padding: "20px 0px" }}>
                                        {props.date ? "Hai completato il corso in data: " + moment(props?.date).format('DD/MM/YYYY') : "Hai completato il corso"}
                                    </p>
                                </div>
                            </React.Fragment>
                            :
                            <Stepper is_preview={is_preview} complete={props.passed} courseMandatory={props.mandatoryCourse} course_code={props.course_code} course={props.courseName} relatedsComplete={props.passedsections} relateds={props.sections} />}

                    </Paper>

                    {props.mandatoryCourse && props.has_certification ?



                        <Link target={"_blank"} style={{ color: "#1976d2", fontWeight: "bold" }} to={{ pathname: route.replace(':id', props.sub_id) }}>

                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                className={classes.button}
                                startIcon={<SchoolIcon />}
                            >
                                Vai all'attestato
                            </Button>
                        </Link>


                        : null}

                    <div style={{ maxHeight: 470 }} className="scrollable-desktop-column">

                        {props.thematics.map((thematic, index) => {

                            return (
                                <Accordion expanded={thematic.openAccordion} onChange={() => handleAccordion(thematic)} key={index} className="collapse_modulo" style={{padding: "0 3px 3px 3px"}}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: "#ccc" }}/>} IconButtonProps={{
                                        edge: "start",
                                        disableRipple: true
                                    }} className={classes.collapse_text}>
                                        <h2>{thematic.name}</h2>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.collapse_content}>

                                        <RelatedModules
                                            is_preview={is_preview}
                                            setExpandedThematic={setExpanded}
                                            lessonCurrent={props.lessonCurrent}
                                            has_blocking_subscription={props.has_blocking_subscription}
                                            sub_expired={props.sub_expired}
                                            has_expired={props.has_expired}
                                            reloadComplete={props.reloadComplete}
                                            sub_id={props.sub_id}
                                            lessonSelected={props.lessonSelected}
                                            first={index}
                                            id={thematic.id}
                                            hide={hide}
                                            setHide={setHide}
                                            setLesson={props.setLesson}
                                            setLessons={props.setLessons}
                                            selectfirst={props.selectfirst}
                                        />

                                    </AccordionDetails>
                                </Accordion>
                            )
                        })
                        }
                    </div>


                </ul>

            </div>
        </section>
    )
}

export default RelatedColumn;