import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import serviceAdmin from '../../services/serviceAdmin'
import { modalClass } from '../../classes';
import useColor from '../../context/themeContext';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import useAlert from '../../hooks/useAlert'
import FullTextField from '../StyledComponent/FullTextField'
import AntSwitch from '../Switch/AntSwitch'


export default function ModalSubscription(props) {

  const [opt, setAlert] = useAlert()
  const { COLORS } = useColor();
  const useStyles = makeStyles((theme) => (modalClass(COLORS)));
  const classes = useStyles();

  const inputRef = useRef();
  const [input, setInput] = useState({});
  const [photo, setPhoto] = useState(null);
  const [checkedHidden, setCheckedHidden] = useState(false);
  const [timeUpload, setTimeUpload] = useState(null);
  const [conflict, setConflict] = useState(false);

  const options = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total)
      setTimeUpload(`Upload immagine ${percent}%`);
    }
  }


  const setImage = () => {
    inputRef.current.click()
  }

  function fileSelected(event) {
    const isImage = file => file["type"].includes("image")
    let input = event.target
    if (input.files && input.files[0]) {
      if (isImage(input.files[0])) {
        let reader = new FileReader()
        reader.onload = e => {
          setPhoto(e.target.result)
        }
        reader.readAsDataURL(input.files[[0]])
      } else {
        alert('foto non caricata')
      }
    }
  }
  const changeValue = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInput({ ...input, [name]: value })
  }
  function clean(obj) {
    for (let propName in obj) {
      if (obj[propName] === "" || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
  }

  const createModule = () => {
    let params;
    if (input.pass_mode) {
      params = {
        ...input,
        "course_id": props.course.id,
        "course_theme_id": props.courseThemeId,
        "thumbnail": photo,
        "is_hidden": checkedHidden
      }
    } else {
      params = {
        ...input,
        "course_id": props.course.id,
        "course_theme_id": props.courseThemeId,
        "thumbnail": photo,
        "is_hidden": checkedHidden
      }
    }
    clean(params);

    serviceAdmin.postService(`v1/course-themes/${props.courseThemeId}/modules/`, params, options)
      .then(res => {
        props.setReload();
        setAlert(true, "Modulo creato correttamente!", "success");
        handleClose();
        setConflict(false)
      }).catch(err => {
        if (err.status === 400 || err.status === 409) {
          if (err.data.reason.includes("slug")) {
            setConflict(true)
          } else {
            setConflict(false)
          }
        }
      })
  }

  const handleClose = () => {
    setPhoto(null);
    setInput({});
    props.setOpen(false);
  };

  const handleChecked = (e) => {
    setCheckedHidden(e.target.checked)
  }

  return (

    <Drawer
      anchor={"right"}
      open={props.open}
      onClose={handleClose}
    >

      <div className={"drawer-container"}>
        <div className={classes.closeicon + " title-drawer-container"}>
          <CloseIcon style={{ cursor: "pointer", height: "30px", width: "30px" }} onClick={handleClose} />
          <h2 className={classes.title}> Crea un nuovo modulo </h2>
        </div>
        <div className={"drawer-content-container"} style={{ padding: "20px 30px" }}>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <InputLabel className={classes.label}>
                Nome argomento*
              </InputLabel>
              <FullTextField name="name" onChange={changeValue} value={input.name || ""} placeholder="Nome dell'argomento" variant="outlined" inputProps={{ className: classes.input }} />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.label}>
                Descrizione
              </InputLabel>
              <FullTextField name="description" onChange={changeValue} value={input.description || ""} placeholder="Descrizione argomento" multiline rows={4} variant="outlined" inputProps={{ className: classes.textarea }} />
            </Grid>

            <Grid item xs={6} lg={6}>

              <InputLabel className={classes.label}>
                Thumbnail
              </InputLabel>
              <div onClick={setImage} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                {!timeUpload ?
                  <React.Fragment>
                    <input ref={inputRef} style={{ visibility: "hidden", width: "1px" }} type="file" onChange={fileSelected} />
                    {photo ? "Cambia" : "Aggiungi"} immagine
                    {!photo ?
                      <AddCircleIcon className={classes.color} style={{ cursor: "pointer", paddingLeft: "10px", width: "40px", height: "40px" }} />
                      :
                      <DoneAllIcon style={{ paddingLeft: "10px", width: "40px", height: "40px" }} className={classes.color} />
                    }
                  </React.Fragment>
                  :
                  <p>
                    {timeUpload}
                  </p>
                }
              </div>
            </Grid>
          </Grid>
          <Grid item xs={6} lg={6}>
            <InputLabel className={classes.label}>
              Nascondi intestazione argomento
            </InputLabel>
            <AntSwitch checked={checkedHidden} setChecked={handleChecked} />
          </Grid>

        </div>
        <div className={classes.container_btn + " button-container"}>
          <Button onClick={handleClose} className={classes.btn_del}>Annulla</Button>
          <Button disabled={input.name ? false : true} onClick={createModule} className={classes.btn_save}>Crea</Button>
        </div>
      </div>
    </Drawer>
  );
}