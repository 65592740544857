import React from "react";
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  
  search: {
    position: 'relative',
    borderRadius: "25px",
    backgroundColor: "#fff",
    marginRight: "auto",
    marginLeft: 0,
    width: '300px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
    },
  },
  searchNoMargin:{
    position: 'relative',
    borderRadius: "25px",
    backgroundColor: "#fff",
    width: '300px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
    },
  },
  searchIcon: {
    color: "#999",
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: "15px 10px",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  }
}));


const SearchInput = (props) => {

  const classes = useStyles();

  return (
    <div style={{display: "inline-flex", alignItems:"center"}}>
    <div className={props.noMargin ? classes.searchNoMargin : classes.search} style={props.background ? { backgroundColor: "#ddd", marginLeft: "0px" } : {}}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder={props.placeholder}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        value={props.searchString || ""}
        onChange={event => props.onChange(event)}
        inputProps={{ 'aria-label': 'search' }}
      />
    </div>
    {props.children}
    </div>
  )

}

export default SearchInput;
