import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';


const BorderTextField = withStyles({
	
	root: {
		border: "1px solid #ccc",
		width: "100%",
		maxWidth:"300px",
		borderRadius:"20px",
		padding:"0",
		margin:"20px 0",

		'& .MuiInput-underline:after': {
				border: 'none',
		},
		'& .MuiOutlinedInput-root': {
				'& fieldset': {
						border: 'none',
				},
		},
},
})(TextField);

export default BorderTextField;