import React, { useEffect, useState, useRef } from 'react';
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import useColor, { useLogo, useContent } from '../context/themeContext';
import InputLabel from '@material-ui/core/InputLabel'
import Grid from '@material-ui/core/Grid'
import serviceAdmin from '../services/serviceAdmin'
import Button from '@material-ui/core/Button'
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import { loginClass } from '../classes';


const CssTextField = withStyles({
  root: {
    border: "none",
    width: "100%",
    '& .MuiInput-underline:after': {
      border: 'none',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
  },
})(TextField);

const Login = (props) => {
  const { COLORS } = useColor()
  const { brand_logo } = useLogo();
  const { Content } = useContent()
  const useStyles = makeStyles((theme) => (loginClass(COLORS)));
  const classes = useStyles();
  const isMount = useRef(true);

  const [input, setInput] = useState({})
  const [anauthorized, setAnauthorized] = useState(false)
  const [values, setValues] = useState({ showPassword: false });


  const changeValue = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInput({ ...input, [name]: value })
  }

  useEffect(() => { return () => isMount.current = false }, [])

  function removeURLParameter (url, parameter) {
    var urlparts = url.split('?');
    if (urlparts.length >= 2) {
      var prefix = encodeURIComponent(parameter) + '=';
      var pars = urlparts[1].split(/[&;]/g);
      for (var i = pars.length; i-- > 0;) {
        if (pars[i].lastIndexOf(prefix, 0) !== -1) {
          pars.splice(i, 1);
        }
      }
      url = urlparts[0] + '?' + pars.join('&');
      return url;
    } else {
      return url;
    }
  }

  const Login = () => {
    localStorage.clear();
    serviceAdmin.postService("v1/auth/login/", input)
      .then(res => {
        if (isMount.current) {
          if (res.data.data.user.role.tier === "BO_COM") {

            localStorage.setItem("time_fit_token", new Date().getTime());
            localStorage.setItem("bearer_fit_token", res.data.data.auth.token);
            localStorage.setItem("refresh_fit_token", res.data.data.auth.refresh);
            localStorage.setItem("courses_fit_id", true);
            localStorage.setItem("admin_fit_id", true);

            const searchString = window.location.search
            const urlParams = new URLSearchParams(searchString);
            const isOutfile = urlParams.get('next') === "outfile";
            if (isOutfile) {
              let queryParameters = ""
              if (searchString.includes("export")) {
                queryParameters = "?export=" + urlParams.get('export')
              } else if (searchString.includes("import")) {
                queryParameters = "?import=" + urlParams.get('import')
              } else if (searchString.includes("batch_operation")) {
                queryParameters = "?batch_operation=" + urlParams.get('batch_operation')
              }
              props.history.push("/backoffice/outfile" + queryParameters)
            } else {
              if (res.data.data.user.trigger_change_password) {
                props.history.push("/reset")
              } else {
                props.history.push("/backoffice/users")
              }
            }
            props.setReload();
            setAnauthorized(false);

          } else if (res.data.data.user.role.tier === "BASE_LOG") {
            localStorage.setItem("time_fit_token", new Date().getTime());
            localStorage.setItem("bearer_fit_token", res.data.data.auth.token);
            localStorage.setItem("refresh_fit_token", res.data.data.auth.refresh);
            localStorage.setItem("courses_fit_id", true);
            if (res.data.data.user.trigger_change_password) {
              props.history.push("/reset")
            } else {
              props.history.push("/corsi")
            }

            props.setReload();
            setAnauthorized(false);
          } else if (res.data.data.user.role.tier === "BO_LIM") {

            localStorage.setItem("time_fit_token", new Date().getTime());
            localStorage.setItem("bearer_fit_token", res.data.data.auth.token);
            localStorage.setItem("refresh_fit_token", res.data.data.auth.refresh);
            localStorage.setItem("courses_fit_id", true);
            localStorage.setItem("admin_lim_fit_id", true);
            if (res.data.data.user.trigger_change_password) {
              props.history.push("/reset")
            } else {
              props.history.push("/backoffice-lite/export")
            }
            props.setReload();
            setAnauthorized(false);
          } else {
            setAnauthorized(true);
          }
        }
      })
      .catch(err => {
        setAnauthorized(true)
      })
  }
  const onkeyLogin = (event) => {
    if (event.key === "Enter") {
      Login();
    }
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  return (
    <div style={{ display: "flex", flexDirection: "column" }} className={classes.container}>
      <Paper className={classes.paper} onKeyDown={onkeyLogin}>
        <h2 className={classes.title}>Accedi</h2>
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ padding: "0 100px" }}>
              <InputLabel className={classes.label}>
                Username
              </InputLabel>
              <CssTextField name="username" onChange={changeValue} value={input.username || ""} placeholder="username" variant="outlined" inputProps={{ className: classes.input }} />
            </Grid>
            <Grid item xs={12} style={{ padding: "0 100px" }}>
              <InputLabel className={classes.label}>
                Password
                        </InputLabel>
              <CssTextField
                autoComplete="on"
                variant="outlined"
                type={values.showPassword ? 'text' : 'password'}
                error={anauthorized ? true : false}
                helperText={anauthorized ? "Controlla di aver inserito bene username e password" : ""}
                name="password"
                value={input.password || ""}
                onChange={changeValue}
                placeholder={"password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  className: classes.input_show
                }}
              />

            </Grid>
          </Grid>
        </form>
        <div className={classes.containerRecovery}>
          <span className={classes.labelRecovery} onClick={() => props.history.push("/forgot-password")}>Hai dimenticato la password?</span>
        </div>
        <div className={classes.container_btn}>
          <Button tabIndex="0" disabled={input.username && input.password ? false : true} onClick={Login} className={classes.btn_save}>Accedi</Button>
        </div>
      </Paper>


      {brand_logo &&
        <a target="_blank" href={Content.brand_link} style={{ backgroundImage: `url(${brand_logo})` }} className="logo-brand-login" />
      }
    </div>
  )
}
export default Login;