import React, { useEffect, useState } from 'react';
import ModalLesson from '../Modal/ModalLesson'
import { makeStyles } from '@material-ui/core/styles';
import { Container, Draggable } from "react-smooth-dnd";
import { ListItem, ListItemIcon, ListItemText, Avatar } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import serviceAdmin from '../../services/serviceAdmin';
import DeleteDialog from '../Dialog/DeleteDialog'
import arrayMove from "array-move";
import ModalModifyLesson from '../Modal/ModalModifyLesson'
import useColor from '../../context/themeContext';


const ListLessons = (props) => {

  const { COLORS } = useColor();
  
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    label_cell: {
      flex: "0 0 auto",
      paddingLeft: "15px",
      color: "#333!important",
      fontSize: "15px!important",
    },
    label_cell_icon: {
      marginLeft: "auto",
      color: "#333!important",
      fontSize: "15px!important",
    },
    check: {
      paddingRight: "20px!important"
    },
    menu_table: {
      color: COLORS.primary + "!important"
    },
    menu_table_del: {
      color: "red"
    },
    list: {
      border: "1px solid rgba(224, 224, 224, 0.8)",
      backgroundColor: "#fff",
      borderRadius: "12px",
      width: "98%",
      marginLeft: "auto",
      display: "flex",
      justifyContent: "flex-start",
    },
    list_moduls: {
      background: "rgba(47,146,214,1)"
    },
    listItem: {
      "& span": {
        fontSize: "18px",
        fontWeight: "bold"
      }
    }, preview: {
      backgroundColor: "rgba(150, 150, 200, 0.1)",
      border: "1px dashed #abc",
      borderRadius: "12px",
    }
  }));
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [confirm, setConfirm] = useState(false)
  const [lesson_id, setLesson_id] = useState(null)
  const [openModify, setOpenModify] = useState(false)
  const [lesson, setLesson] = useState("");
  const [deleteLesson, setDelete] = useState(false);
  const [value, setValue] = useState(1);

  const setopenmodify = (input) => {
    setOpenModify(input);
  }

  const setconfirm = (input, lesson) => {
    setConfirm(input)
    setLesson_id(lesson.id)
  }

  const setdelete = (input) => {
    setDelete(input)
  }

  const setvalue = (input) => {
    setValue(input)
  }

  useEffect(() => {
    if (confirm && lesson_id) {
      serviceAdmin.deleteService(`v1/course-sections/${lesson_id}/`)
        .then(res => {
          if (res.status === 200) {
            props.setReload();
            props.setCustomAlert(true, "success", "Lezione eliminata correttamente!")
            setOpenModify(false);
          }
        }).catch(err => {
          console.log(err)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lesson_id])

  useEffect(() => {
    setRows(props.sections);
  }, [props.sections])


  const handleOpenMenu = (event, row) => {
    setLesson(row);
    event.stopPropagation();
    setValue(1);
    setOpenModify(true)
  };

  const onDropLesson = (e, module_id) => {
    if (e.addedIndex == null && e.removedIndex !== null) {
      const newRows = arrayMove(rows, e.removedIndex, e.addedIndex);
      setRows(newRows.filter(v => v.id !== e.payload.id))
      return;
    } else if (e.addedIndex !== null && e.removedIndex == null) {
      let newRows = [...rows];
      newRows.splice(e.addedIndex, 0, e.payload);
      setRows(newRows)
      if (newRows.length > 0) {
        newRows.map((row, index) => {
          serviceAdmin.putService(`v1/course-sections/${row.id}/`,
            {
              "module_id": module_id,
              "order": index,
              "name": row.name
            }
          )
            .then(res => {
              if (res.status === 201) {
                props.setCustomAlert(true, "success", "Ordinamento aggiornato!")
              }
            }).catch(err => {
              console.log(err);
            })
        })
      }
    } else {
      const newRows = arrayMove(rows, e.removedIndex, e.addedIndex);
      if (JSON.stringify(newRows) !== JSON.stringify(rows)) {
        setRows(newRows);
        if (newRows.length > 0) {
          newRows.map((row, index) => {
            serviceAdmin.putService(`v1/course-sections/${row.id}/`,
              {
                "module_id": props.module_id,
                "order": index,
                "name": row.name
              }
            )
              .then(res => {
                if (res.status === 201) {
                  props.setCustomAlert(true, "success", "Ordinamento aggiornato!")
                }
              }).catch(err => {
                console.log(err);
              })
          })
        }
      }
    }
  }

  return (

    <Container
      groupName="lesson"
      dragHandleSelector=".lesson-drag-handle"
      style={{ marginTop: "15px", marginBottom: "15px", width: "100%" }}
      lockAxis="y"
      onDrop={(e) => onDropLesson(e, props.module_id)}
      getChildPayload={(i) => rows[i]}
      dropPlaceholder={{
        animationDuration: 150,
        showOnTop: true,
        className: classes.preview
      }}
      dropPlaceholderAnimationDuration={200}
    >
      {rows.map((section, index) => {

        return (
          <Draggable style={{ marginTop: "2px" }} key={index}>
            <ListItem
              className={classes.list + " table-label-cell lesson-drag-handle grab"}
            >
              <ListItemText className={classes.label_cell}>
                {section?.thumbnail &&
                  <Avatar src={section?.thumbnail?.thumbnail} variant="square" className={classes.square} />
                }
              </ListItemText>

              <ListItemText className={classes.label_cell}>
                {section.name}
              </ListItemText>

              <ListItemIcon className={classes.label_cell_icon}>
                <IconButton onClick={(e) => { handleOpenMenu(e, section) }}>
                  <MoreHorizIcon style={{ width: "30px", height: "30px", color: "#999" }} />
                </IconButton>
              </ListItemIcon>

            </ListItem>
          </Draggable>
        )
      })}
      <DeleteDialog
        deleteLesson={deleteLesson}
        setDelete={setdelete}
        textDelete={"la lezione"}
        handleCloseMenu={() => { }}
        setConfirm={setconfirm}
        lesson={lesson}
      />

      <ModalModifyLesson
        module_id={props.module_id}
        setValue={setvalue}
        value={value}
        setDelete={setdelete}
        setCustomAlert={props.setCustomAlert}
        lesson={lesson}
        setReload={props.setReload}
        module={props.module}
        course={props.course}
        open={openModify}
        setOpen={setopenmodify}
      />

      <ModalLesson
        setCustomAlert={props.setCustomAlert}
        open={props.openLesson}
        setOpen={props.setOpenLesson}
        moduls={props.moduls}
        module_id={props.module?.id}
        course_id={props.course.id}
        setReload={props.setReload}
      />
    </Container>
  )

}

export default ListLessons;