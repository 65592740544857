import React from 'react'
import { RadialBarChart, RadialBar, PolarAngleAxis, ResponsiveContainer } from 'recharts';
import PropTypes from 'prop-types'


const RadialChartCustom = (props) => {
	return (
		<ResponsiveContainer width={"100%"} height={"100%"}>
			<RadialBarChart
				width={730}
				height={250}
				innerRadius="20%"
				outerRadius="80%"
				data={props.data}
				startAngle={90}
				endAngle={-270}
				barCategoryGap="5%"
			>
				<PolarAngleAxis type="number" domain={[0, props.total]} dataKey={props.dataKey} angleAxisId={0} tick={false} />
				<RadialBar background dataKey="value" angleAxisId={0} data={props.data} />
			</RadialBarChart>
		</ResponsiveContainer>
	)
}

RadialChartCustom.propTypes = {
	data: PropTypes.array,
	total: PropTypes.number,
	dataKey: PropTypes.string,
}

export default RadialChartCustom