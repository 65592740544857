import React from 'react';
import { Redirect, Route } from 'react-router-dom';


const PrivateRoute = ({ component: Component, ...rest }) => {

    const isOutfile = window.location.pathname === "/backoffice/outfile"

    const queryString = window.location.search

    return (
        <Route
            {...rest}
            render={props =>
                localStorage.getItem("bearer_fit_token") && localStorage.getItem("admin_fit_id") ? (
                    <Component {...props} />
                ) : (
                        isOutfile ? <Redirect to={"/login" + queryString + "&next=outfile"} /> : <Redirect to="/login" />
                    )

            }


        />
    )
}
export default PrivateRoute


