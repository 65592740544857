import React, { useEffect, useState, useRef } from 'react'
import { Radio, TextField, IconButton, Button, Checkbox, FormControlLabel } from '@material-ui/core'
import { Container, Draggable } from "react-smooth-dnd"
import { makeStyles, withStyles } from '@material-ui/core/styles'
import arrayMove from "array-move"
import AddIcon from '@material-ui/icons/Add'
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined'
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import helpers from './helpers'
import AnswersCheck from './AnswersCheck'
import { answerClass } from '../../classes'
import useColor from '../../context/themeContext'
import QuestionTextField from './UtilityComponent/QuestionTextField'

const WhiteCheckbox = withStyles({
	
	root: {
		color: "#fff",
		'&$checked': {
			color: "#fff",
		},
	},
	checked: {},
})((props) => <Checkbox color="default" {...props} />);

const WhiteRadio = withStyles({
	root: {
		color: "#fff",
		'&$checked': {
			color: "#fff",
		},
	},
	checked: {},
})((props) => <Radio color="default" {...props} />);

const AnswersBuilder = (props) => {
	const { COLORS } = useColor()

	const useStyles = makeStyles(() => (answerClass(COLORS)))
	const classes = useStyles();

	const { answers } = props;
	const timerRef = useRef(null);

	const [newAnswers, setNewAnswers] = useState([])
	const [input, setInput] = useState({})
	const [object, setObject] = useState({})
	const [show, setShow] = useState(false)

	useEffect(() => {
		const array = answers.map((answer, index) => {
			return {
				"text": answer.text,
				"id": answer.id,
				"key": index,
				"is_correct": answer.is_correct
			}
		})
		setInput(answers)
		setNewAnswers(array)
		setShow(false);
		setObject({});
	}, [answers])

	const objectChange = (e, key) => {
		const value = e.target.value;
		setObject({ ...object, "text": value, "key": key });
	}

	const hideObject = () => {
		setShow(false)
		setObject({})
	}

	const handleCreateAnswer = () => {
		if (!show) {
			setShow(true)
		} else {
			if (object.text && object.text?.replace(/\s/g, '') !== "") {
				if (props.config === "single" && props.isEvaluation) {
					const correctAnswer = answers.filter((answer) => answer.is_correct)
					if (correctAnswer && object.is_correct) {
						helpers.updateAnswer(correctAnswer?.[0]?.id, { "is_correct": false }, props.isEvaluation)
							.then(res => {
								helpers.createAnswer(props.question_id, object, props.isEvaluation)
									.then(res => {
										props.setReload();
										props.setCustomAlert(true, "success", "Risposta creata con successo!");
									})
							})
					} else {
						helpers.createAnswer(props.question_id, object, props.isEvaluation)
							.then(res => {
								props.setReload();
								props.setCustomAlert(true, "success", "Risposta creata con successo!");
							})
					}
				} else {
					if (props.config === "open") object.text = object?.text?.trim();
					helpers.createAnswer(props.question_id, object, props.isEvaluation)
						.then(res => {
							props.setReload();
							props.setCustomAlert(true, "success", "Risposta creata con successo!");
						})
				}
			} else {
				props.setCustomAlert(true, "warning", "La risposta non puo essere vuota!")
			}
		}

	}

	const deleteAnswer = (id) => {
		helpers.deleteAnswer(props.question_id, id, props.isEvaluation)
			.then(res => {
				props.setReload()
				props.setCustomAlert(true, "success", "Risposta eliminata!")
			})
	}

	const handleChange = (e, key) => {
		let value = e.target.value;
		setInput({ ...input, [key]: { ...input[key], "text": value } })

		if (timerRef.current) clearTimeout(timerRef.current);

		timerRef.current = setTimeout(() => {
			if (props.config === "open") value = value.trim();
			helpers.updateAnswer(input[key].id, { "text": value }, props.isEvaluation)
				.then(res => {
					props.setCustomAlert(true, "success", "Risposta aggiornata!")
				})
		}, 700)
	}

	const onAnswerDrop = ({ removedIndex, addedIndex }) => {
		const news = arrayMove(newAnswers, removedIndex, addedIndex);
		if (JSON.stringify(news) !== JSON.stringify(newAnswers)) {
			setNewAnswers(news)

			if (news?.length > 1) {
				news.map((answer, index) => {
					helpers.updateAnswer(answer.id,
						{
							"text": answer.text,
							"order": index
						}, props.isEvaluation)
						.then((res) => {
							props.setCustomAlert(true, "success", "Risposta aggiornata!");
						})

				})
			}
		}
	}

	const addKeyAnswer = (e) => {
		if (e.key === "Enter") {
			handleCreateAnswer();
		}
	};

	const handleChangeCheck = (e) => {
		const checked = e.target.checked;
		setObject({ ...object, "is_correct": checked });
	}

	const handleChangeUpdate = (e) => {
		const id = e.target.name
		const checked = e.target.checked
		helpers.updateAnswer(id, { "is_correct": checked }, props.isEvaluation)
			.then((res) => {
				props.setCustomAlert(true, "success", "Risposta aggiornata!");
				props.setReload();
			})
	}

	const handleChangeRadio = (e) => {
		const id = e.target.name
		const checked = e.target.checked
		const correctAnswer = answers.filter((answer) => answer.is_correct)

		if (checked && correctAnswer.length > 0) {
			helpers.updateAnswer(correctAnswer[0].id, { "is_correct": false }, props.isEvaluation)
				.then(res => {
					helpers.updateAnswer(id, { "is_correct": checked }, props.isEvaluation)
						.then(res => {
							props.setCustomAlert(true, "success", "Risposta aggiornata!");
							props.setReload();
						})
				})
		} else if (checked) {
			helpers.updateAnswer(id, { "is_correct": checked }, props.isEvaluation)
				.then(res => {
					props.setCustomAlert(true, "success", "Risposta aggiornata!");
					props.setReload();
				})
		}
	}


	return (
		<div style={{ width: "100%", padding: "10px 20px" }}>
			<Container
				orientation="vertical"
				style={{
					minHeight: 0,
				}}
				onDrop={onAnswerDrop}
				dragHandleSelector=".column-drag-handle"
			>
				{newAnswers.map(answer => {

					return (
						<Draggable style={{ display: "block" }} key={answer.id}>
							<div className={classes.answer}>
								<div className={"column-drag-handle"}>
									<DragIndicatorIcon className={classes.icon_drag} color="inherit" />
								</div>

								<AnswersCheck
									isEvaluation={props.isEvaluation}
									answer={answer}
									onChange={handleChangeUpdate}
									onChangeRadio={handleChangeRadio}
									config={props.config}
								/>

								<QuestionTextField
									color={COLORS.primary}
									multiline
									autoComplete={"off"}
									value={input[answer.key].text || ""}
									onChange={(e) => handleChange(e, answer.key)}
									variant={"outlined"}
								/>
								<IconButton onClick={() => deleteAnswer(answer.id)} style={{ marginLeft: "5px" }} className={classes.icon} color="inherit">
									<ClearOutlinedIcon className={classes.icon_font} color="inherit" />
								</IconButton>
							</div>
						</Draggable>
					)
				})
				}
				{show &&
					<Draggable key={newAnswers.length} onKeyDown={addKeyAnswer}>
						<div className={classes.answer}>
							{props.isEvaluation === 1 && props.config !== "open" &&
								< FormControlLabel
									style={{ minWidth: "200px" }}
									value="Risposta corretta"
									control={
										<WhiteCheckbox
											checked={object.is_correct || false}
											onChange={handleChangeCheck}
											color="primary"
										/>

									}
									label="Risposta corretta"
								/>
							}
							<QuestionTextField
								color={COLORS.primary}
								multiline
								autoComplete={"off"}
								value={object?.text || ""}
								onChange={(e) => objectChange(e, newAnswers.length)}
								variant={"outlined"}
							/>
							<IconButton onClick={handleCreateAnswer} style={{ marginLeft: "5px" }} className={classes.icon} color="inherit">
								<SaveOutlinedIcon className={classes.icon_font} color="inherit" />
							</IconButton>
							<IconButton onClick={hideObject} style={{ marginLeft: "5px" }} className={classes.icon} color="inherit">
								<ClearOutlinedIcon className={classes.icon_font} color="inherit" />
							</IconButton>
						</div>
					</Draggable>
				}
			</Container>
			{(props.config !== "likert_question") && !show &&
				<div className={classes.container_add}>

					<Button
						disabled={show && !object?.text ? true : false}
						onClick={handleCreateAnswer}
						className={classes.button_add_answer}
						color="inherit"
						variant="contained"
						endIcon={!show ? <AddIcon className={classes.icon_font} color="inherit" /> : <SaveOutlinedIcon className={classes.icon_font} color="inherit" />}
					>
						{!show ?
							(props.config !== "likert" ? "Risposta" : "Aggiungi livello scala")
							:
							'Salva'
						}
					</Button>

				</div>
			}
		</div>
	)
}

export default AnswersBuilder;