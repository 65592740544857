import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import User from './viewsAdmin/User.jsx';
import Group from './viewsAdmin/Group.jsx';
import Courses from './viewsAdmin/Courses.jsx';
import Surveys from './viewsAdmin/Surveys.jsx';
import Csv from './viewsAdmin/Exports.jsx';
import Import from './viewsAdmin/Import.jsx';
import Dashboard from './viewsAdmin/Dashboard.jsx';
import Outfile from './viewsAdmin/Outfile.jsx';

const AdminRoutes = (props) => {

    const history = props.history

    return (
        <Switch>
            <Route exact path="/backoffice">
                <Redirect to="/backoffice/users" />
            </Route>
            <Route exact path="/backoffice/dashboard" render={() => <Dashboard {...props} />} />
            <Route exact path="/backoffice/users" render={() => <User {...props} />} />
            <Route exact path="/backoffice/group" render={() => <Group {...props} />} />
            <Route exact path="/backoffice/courses" render={() => <Courses {...props} history={history} />} />
            {/*<Route exact path="/backoffice/courses/:id" render={() => <Courses {...props} history={history} />} />*/}
            <Route exact path="/backoffice/surveys" render={() => <Surveys {...props} />} />
            <Route exact path="/backoffice/export" render={() => <Csv  {...props} />} />
            <Route exact path="/backoffice/import" render={() => <Import       {...props} />} />
            <Route exact path="/backoffice/outfile" render={() => <Outfile       {...props} />} />
        </Switch>

    )

}

export default AdminRoutes;