import React from 'react';
import Fab from '@material-ui/core/Fab';
import Zoom from '@material-ui/core/Zoom';
import AddIcon from '@material-ui/icons/Add';
import FilterListIcon from '@material-ui/icons/FilterList';
import PublishIcon from '@material-ui/icons/Publish';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import useColor from '../../context/themeContext'
import Tooltip from '@material-ui/core/Tooltip';


const RoundButton = (props) => {
    
    const { COLORS } = useColor();
    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: "#fff",
            width: "65px",
            height: "65px",
            margin: "0px 12px"
        },
        root_icon: {
            color: COLORS.primary,
            width: "40px",
            height: "40px"
        },
        root_add: {
            backgroundColor: COLORS.primary,
            width: "65px",
            height: "65px",
            margin: "0px 12px",
            "& :hover": {
                color: COLORS.primary,
            },
        },
        root_addicon: {
            color: "#fff",
            width: "40px",
            height: "40px"
        }

    }));

    const CustomTooltip = withStyles({
        tooltip: {
            fontSize: "14px",
            backgroundColor: COLORS.primary,
            color: "#fff",
            textAlign: "center",
            borderRadius: "10px",
            fontWeight: "bolder"
        }
    })(Tooltip);

    const classes = useStyles();

    return (
        <div style={{ display: "flex" }}>

            { props.filter ?
                <Zoom in={true} timeout={{ appear: 400, enter: 500, exit: 500 }}>
                    <CustomTooltip className={classes.tooltip} title="Aggiungi Filtri" placement="top">
                        <Fab className={classes.root} aria-label="filter" onClick={props.filterClick}>
                            <FilterListIcon className={classes.root_icon} />
                        </Fab>
                    </CustomTooltip>
                </Zoom>
                : null
            }
            { props.get ?
                <Zoom in={true} timeout={{ appear: 300, enter: 500, exit: 500 }}>
                    
                        <Fab onClick={props.publishClick} className={classes.root} aria-label="get">
                            <PublishIcon className={classes.root_icon} />
                        </Fab>
                   
                </Zoom>
                : null
            }
            { props.add ?
                <Zoom in={true} timeout={{ appear: 600, enter: 700, exit: 700 }}>
                    <Fab className={classes.root_add} aria-label="add" onClick={props.setbuttons}>
                        <AddIcon className={classes.root_addicon} />
                    </Fab>
                </Zoom>
                : null
            }

        </div>
    )

}

export default RoundButton;