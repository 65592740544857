import React from "react";
import Skeleton from '@material-ui/lab/Skeleton';




const SkeletonRelated = (props) => {
    

    return (
        <React.Fragment>

            {!props.white ?

                <div style={{ display: "flex", justifyContent: "center", width: "100%", padding: "0 35px", alignItems: "center", margin: "10px 0" }}>
                    {props.square ?
                        <Skeleton variant={"rect"} style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }} width={"20%"} height={40} />
                        :
                        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                            <Skeleton style={{ backgroundColor: "rgba(22, 122, 198,0.4)" }} width={"100%"} height={20} />
                            <Skeleton style={{ backgroundColor: "rgba(22, 122, 198,0.4)" }} width={"100%"} height={20} />
                            <Skeleton style={{ backgroundColor: "rgba(22, 122, 198,0.4)" }} width={"100%"} height={20} />
                        </div>
                    }
                    {props.square ?
                        <div style={{ display: "flex", flexDirection: "column", width: "100%", paddingLeft: "30px" }}>

                            <Skeleton style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }} width={"80%"} height={20} />
                            <Skeleton style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }} width={"80%"} height={20} />

                        </div>
                        : null}
                </div>

                :
                <React.Fragment>
                    <div style={{ display: "flex", justifyContent: "center", width: "100%", padding: "0 35px", alignItems: "center", margin: "5px 0" }}>
                        <Skeleton variant={"rect"} style={{ backgroundColor: "rgba(22, 122, 198,0.4)" }} width={"20%"} height={40} />
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "100%", padding: "0 5px", alignItems: "center", margin: "10px 0" }}>
                            <Skeleton style={{ backgroundColor: "rgba(22, 122, 198,0.4)" }} width={"80%"} height={20} />
                            <Skeleton style={{ backgroundColor: "rgba(22, 122, 198,0.4)" }} width={"80%"} height={20} />
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", width: "100%", padding: "0 35px", alignItems: "center", margin: "5px 0" }}>
                        <Skeleton variant={"rect"} style={{ backgroundColor: "rgba(22, 122, 198,0.4)" }} width={"20%"} height={40} />
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "100%", padding: "0 5px", alignItems: "center", margin: "10px 0" }}>
                            <Skeleton style={{ backgroundColor: "rgba(22, 122, 198,0.4)" }} width={"80%"} height={20} />
                            <Skeleton style={{ backgroundColor: "rgba(22, 122, 198,0.4)" }} width={"80%"} height={20} />
                        </div>
                    </div>
                </React.Fragment>
            }
        </React.Fragment>
    )

}

export default SkeletonRelated;