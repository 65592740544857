import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import AntSwitch from '../Switch/AntSwitch'
import { modalClass } from '../../classes';
import useColor from '../../context/themeContext'
import useAlert from '../../hooks/useAlert'
import serviceAdmin from '../../services/serviceAdmin';
import TableSessions from '../Table/TableSessions';
import Grid from '@material-ui/core/Grid';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import FullTextField from '../StyledComponent/FullTextField'
import moment from 'moment'
import 'moment/locale/it';
import FromDate from '../Export/ExportFilter/FromDate';
moment.locale('it')


export default function ModalSubscription(props) {

  const [options, setAlert] = useAlert()
  const { COLORS } = useColor()
  const useStyles = makeStyles((theme) => (modalClass(COLORS)));
  const classes = useStyles();


  const [input, setInput] = useState({})
  const [sections, setSections] = useState([])
  const [reload, setReload] = useState(false)
  const [is_in_history, setIsInHistory] = useState(false)
  const [expiration, setExpiration] = useState({})
  const [additionalData, setAdditionalData] = useState("")


  const setreload = () => {
    setReload(!reload)
  }

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setInput({ ...input, [name]: value })
  }

  const handleExpiration = (value) => {
    const name = "expiration_date";

    setExpiration({ [name]: value })
  }

  const handleAdditional = (e) => {
    const value = e.target.value;

    setAdditionalData(value)
  }

  const disableExpiration = () => {
    setExpiration({ "expiration_date": null })
  }

  useEffect(() => {
    if (props.sub?.sub_id) {
      serviceAdmin.getService(`v1/subscriptions/${props.sub?.sub_id}/sections/`).then(res => {
        setSections(res.data.data)
      }).catch(err => {
        console.log(err);
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sub, reload])

  useEffect(() => {
    if (props.open) {
      serviceAdmin.getService(`/v1/subscriptions/${props.sub.sub_id}/`).then(res => {
        if (res.data.data) {
          const subscription = res.data.data;
          setInput({
            ...input,
            "status_etiquette": subscription.status_etiquette
          })
          setIsInHistory(subscription?.is_in_history)
          setExpiration({ "expiration_date": subscription.expiration_date ? moment(subscription.expiration_date).format('YYYY-MM-DD') : null, })
          setAdditionalData(subscription.additional_data)
        }
      }).catch(err => {
        console.log(err);
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sub])

  const modifySubscription = () => {
    const params = {
      ...input,
      "expiration_date": expiration?.expiration_date ? moment(expiration?.expiration_date).toISOString() : null,
      "additional_data": additionalData,
      "is_in_history": is_in_history
    }

    serviceAdmin.putService(`/custom/v1/subscriptions/${props.sub.sub_id}/`, params)
      .then(res => {
        if (res.status === 200) {
          setAlert(true, "Modifica dell'iscrizione avvenuta correttamente!", "success");
          props.setReload();
          handleClose();
        }
      }).catch(err => {
        console.log(err)
      })

  }

  const default_etiquette = [
    { "RDY": "DA AVVIARE" },
    { "RUN": "IN CORSO" },
    { "OFF": "SOSPESO" },
    { "END": "FINITO" }
  ];

  return (

    <Drawer
      anchor={"right"}
      open={props.open}
      onClose={handleClose}
    >
      <div className={"drawer-container"}>
        <div className={classes.closeicon + " title-drawer-container"}>
          <CloseIcon style={{ cursor: "pointer", height: "30px", width: "30px" }} onClick={handleClose} />
          <h2 className={classes.title}> Modifica iscrizione </h2>
        </div>
        <div className={"drawer-content-container"} style={{ padding: "20px 30px" }}>

          <Grid container spacing={3}>
            <Grid item xs={12} >
              <h2 className={classes.title_course}>{` Corso: ${props.course?.name}` || ""} </h2>
            </Grid>

            <Grid item xs={12} md={6}>
              <InputLabel className={classes.label}>
                Scadenza
              </InputLabel>
              <div style={{ display: "flex", alignItems: "center" }}>
                <FromDate
                  inputProps={`${classes.input}`}
                  value={expiration.expiration_date}
                  handleChange={handleExpiration}
                />
                <IconButton onClick={disableExpiration}>
                  <HighlightOffIcon />
                </IconButton>
              </div>
            </Grid>
            {props.etiquette?.length > 0 &&
              <Grid item xs={12} md={6}>
                <InputLabel className={classes.label}>
                  Stato utente
                </InputLabel>
                <Select name="status_etiquette" value={input.status_etiquette || null} onChange={handleChange} variant="outlined" className={classes.select}>
                  {props.etiquette ?
                    props.etiquette.map((et, i) => {
                      let k = Object.keys(et)
                      return (
                        <MenuItem className={classes.selected} key={i} value={k[0]}>{et[k]}</MenuItem>
                      )
                    })
                    :
                    default_etiquette.map((et, i) => {
                      let k = Object.keys(et)
                      return (
                        <MenuItem className={classes.selected} key={i} value={k[0]}>{et[k]}</MenuItem>
                      )
                    })
                  }

                </Select>
              </Grid>
            }

            <Grid item xs={12} md={6}>
              <InputLabel className={classes.label}>
                Dati aggiuntivi
              </InputLabel>
              <FullTextField
                name="additional_data"
                value={additionalData || ""}
                onChange={handleAdditional}
                multiline
                variant="outlined"
                inputProps={{ className: classes.input }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <InputLabel className={classes.label}>
                Storico
              </InputLabel>
              <AntSwitch
                name="is_in_history"
                checked={is_in_history}
                setChecked={() => setIsInHistory(!is_in_history)}
              />
            </Grid>


            <Grid item xs={12}>
              <InputLabel className={classes.label}>
                Sezioni iscrizione
              </InputLabel>
              <TableSessions
                sub={props.sub}
                input={input}
                setCustomAlert={props.setCustomAlert}
                sections={sections}
                sub_id={props.sub?.sub_id}
                setReload={setreload}
                setReloadSub={props.setReload}
              />
            </Grid>

          </Grid>
        </div>

        <Paper elevation={3} className={classes.save + " button-container"}>

          <Button onClick={() => { props.setDelete(true) }} className={classes.btn_del_user}>Elimina</Button>
          <Button onClick={handleClose} className={classes.btn_del}>Annulla</Button>
          <Button onClick={modifySubscription} className={classes.btn_save}>Aggiorna</Button>

        </Paper>

      </div>
    </Drawer>

  );
}