import React, { useEffect, useState } from "react"
import { IconButton, Modal, Fade } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';
import useColor from '../../context/themeContext'





const ModalFilterExport = (props) => {
    
    const { COLORS } = useColor();
    const useStyles = makeStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: "100%",
            height: "100%"
        },
        paper: {
            maxWidth: "900px",
            minWidth: "450px",
            maxHeight: "750px",
            minHeight: "450px",
            backgroundColor: '#ffffff',
            padding: '30px',
            borderRadius: "20px",
        },
        container: {
            maxHeight: "500px",
            minHeight: "100px",
        },
        title_label: {
            fontSize: "18px",
            color: COLORS.primary,
            marginBottom: "8px"
        },
        value_label: {
            fontSize: "16px",
            marginBottom: "20px"
        }
    })
    const classes = useStyles();

    const { openModal, handleCloseModal, row } = props;

    const [audit, setAudit] = useState([])

    useEffect(() => {
        if (openModal) {
            const audit_filt = JSON.parse(row.audit_filters);
            setAudit(audit_filt.filters)

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [row])

    return (
        <Modal
            className={classes.modal}
            open={openModal}
            onClose={handleCloseModal}
            closeAfterTransition
        >
            <Fade in={openModal}>
                <div className={classes.paper}>
                    <div>
                        <IconButton onClick={handleCloseModal}>
                            <ArrowBack style={{ color: COLORS.primary }} />
                        </IconButton>
                    </div>
                    {audit.length > 0 &&

                        <div>
                            {audit.map((f, index) => {

                                return (
                                    <div key={index}>
                                        <h6 className={classes.title_label}>
                                            {f.label}
                                        </h6>
                                        {f.type === "bool" ?

                                            <p className={classes.value_label}>
                                                {f.value == "true" ? "si" : "no"}
                                            </p>
                                            :
                                            <p className={classes.value_label}>
                                                {f.value[f.value?.length - 2] === "," ? f.value.slice(0, -2) : f.value}
                                            </p>

                                        }
                                    </div>
                                )
                            })}
                        </div>


                    }

                </div>
            </Fade>
        </Modal>
    )

}
export default ModalFilterExport;