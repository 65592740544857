import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import useColor from '../../context/themeContext';

export default function DialogD(props) {
  
  const { COLORS } = useColor()
  const { callback, handleClose, deleteDialog, textDelete } = props

  const deleteItem = () => {
    callback();
  }

  return (

    <Dialog
      open={deleteDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Sei sicuro di voler eliminare " + textDelete + "?"}</DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} style={{ color: COLORS.primary }} >
          Annulla
          </Button>
        <Button onClick={deleteItem} color="secondary" autoFocus>
          Elimina
          </Button>
      </DialogActions>
    </Dialog>

  );
}