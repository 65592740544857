import React from 'react'
import PropTypes from 'prop-types'
import { lighten, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import useColor from '../../context/themeContext'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import ModalModifySessions from '../Modal/ModalModifySessions'
import { tableStyle } from '../../classes'
import moment from 'moment'
import 'moment/locale/it';
import { useEffect } from 'react'
moment.locale('it')



function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Tematica' },
  { id: 'course_module_name', numeric: false, disablePadding: false, label: 'Argomento' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Sezione' },
  { id: 'is_section_passed', numeric: false, disablePadding: false, label: 'Sezione superata' },
  { id: 'duration', numeric: false, disablePadding: false, label: 'Durata sessione' },
  { id: 'updated', numeric: false, disablePadding: false, label: 'Ultima modifica' },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const useStyles = makeStyles((theme) => (tableStyle));

export default function EnhancedTable(props) {
  const { COLORS } = useColor();
  const classes = useStyles();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = React.useState(false);
  const [session_id, setSessionId] = React.useState("");

  const setopen = (input) => { setOpen(input) };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, id) => {
    setSessionId(id);
    setOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function createData(name, is_passed, duration, updated, id, course_module_name, course_theme_name, session) {
    return { name, is_passed, duration, updated, id, course_module_name, course_theme_name, session };
  }
  const rows = props.sections.map(section => {
    return createData(
      section.name, section?.session?.is_section_passed, 
      section?.session?.duration?.split('.')[0], 
      section.session?.updated, section?.session?.id, 
      section?.course_module_name, section?.course_theme_name, 
      section?.session
    )
  })

  useEffect(() => {
    props.setReload()
  }, [open])


  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);


  return (
    <div className={classes.root}>

      <TableContainer className={classes.container}>
        <Table
          stickyHeader
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    className={classes.highselected}
                    hover={row.session}
                    onClick={(event) => { 
                      if(row.session)
                        handleClick(event, row.id)
                    }}
                    style={row.session ? { cursor: "pointer" } : { backgroundColor: "#f1f1f1", opacity: 0.6}}
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                  >
                     <TableCell component="th" id={labelId} scope="row">
                      {row.course_theme_name}
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row">
                      {row.course_module_name}
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="center">{row.is_passed ? <CheckCircleIcon style={{ color: COLORS.primary }} /> : <HighlightOffIcon style={{ color: COLORS.primary }} />}</TableCell>
                    <TableCell align="left">{row.duration}</TableCell>
                    <TableCell align="left">{moment(row.updated).format('DD-MM-YYYY HH:mm')}</TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 33 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={props.sections?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        classes={{ menuItem: classes.selected }}
        labelRowsPerPage={"Righe per pagina: "}
      />

      <ModalModifySessions input={props.input} course={props.sub.course_name} setReloadSub={props.setReloadSub} setCustomAlert={props.setCustomAlert} setReload={props.setReload} sub={props.sub} sub_id={props.sub_id} session_id={session_id} setOpen={setopen} open={open} />

    </div>
  );
}