import React, { useEffect, useState } from 'react';
import SearchInput from "../componentsAdmin/Search/SearchInput"
import RoundButton from "../componentsAdmin/Button/RoundButton"
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import TransitionsModal from "../componentsAdmin/Modal/Modal"
import serviceAdmin from '../services/serviceAdmin'
import TableSurveys from '../componentsAdmin/Table/TableSurveys'
import Alert from '../componentsAdmin/CollapseAlert/Alert'
import Fade from '@material-ui/core/Fade';
import { listMenu } from '../classes'
import useColor from '../context/themeContext'



const Test = (props) => {

    const { COLORS } = useColor();
    const useStyles = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        ...listMenu(COLORS)
    }));

    const classes = useStyles();

    const [count, setCount] = useState(0)
    const [searchString, setSearchString] = useState("")
    const [newopen, setNewopen] = useState("");
    const [buttons, setButtons] = useState(false);
    const [data, setData] = useState([])
    const [backdrop, setBackdrop] = useState(false)
    const [rows, setRows] = useState(10)
    const [page, setPage] = useState(0)
    const [pageSurveys, setPageSurveys] = useState(1)
    const [reload, setReload] = useState(false)
    const [editor, setEditor] = useState(false)
    const [value, setValue] = useState(0);
    const [openAlert, setOpenAlert] = useState({});
    const [loading, setLoading] = useState(false)

    const setvalue = (input) => { setValue(input) }
    const setpagesurveys = (input) => { setPageSurveys(input) }
    const setpage = (input) => { setPage(input) }
    const setrows = (input) => { setRows(input) }
    const setnewopen = (input) => { setNewopen(input) };
    const setclosebuttons = () => { setButtons(false) };
    const setbuttons = () => { setButtons(!buttons) };
    const setbackdrop = (input) => { setBackdrop(input) }
    const setreload = () => { setReload(!reload) }

    useEffect(() => {
        let timeout;
        let url = value ? `v1/evaluations/` : `v1/surveys/`;
        url += `?page=${pageSurveys}&page_items=${rows}`;

        if (searchString) {
            url += `&search=${searchString}`;
            timeout = setTimeout(() => {
                getData(url)
            }, 400)
        } else {
            getData(url)
        }

        return () => {
            if (timeout) clearTimeout(timeout);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchString, pageSurveys, value, rows, reload])


    const getData = (url) => {
        serviceAdmin.getService(url)
            .then(res => {

                if (res?.status === 200) {
                    setCount(res.data.meta.pagination.items_count)
                    setPage(pageSurveys - 1)
                    setBackdrop(false);
                    setData(res.data.data)
                    setLoading(true)
                }

            }).catch(err => {
                if (err?.status === 404) {
                    setBackdrop(false)
                }
            })
    }

    const setCustomAlert = (value, two_value, three_value) => {
        setOpenAlert({
            ...openAlert,
            "state": value,
            "severity": two_value,
            "message": three_value
        })
    };

    return (
        <React.Fragment>
            <Alert
                setOpenAlert={setCustomAlert}
                severity={openAlert?.severity}
                openAlert={openAlert?.state}
                message={openAlert?.message}
            />

            {!newopen && !editor &&
                <div style={{ display: "flex", alignItems: "center" }}>
                    <SearchInput searchString={searchString} placeholder={value ? "Cerca tra le verifiche" : "Cerca tra i sondaggi"} onChange={event => { setPageSurveys(1); setSearchString(event.target.value) }} />
                    <RoundButton add setbuttons={setbuttons} />
                </div>
            }

            {!newopen &&

                <Fade in={loading} timeout={{ appear: 100, enter: 300, exit: 300 }}>
                    <div style={{ paddingTop: "50px", maxWidth: "100%" }}>
                        <TableSurveys
                            setCustomAlert={setCustomAlert}
                            editor={editor}
                            setEditor={setEditor}
                            handleClose={props.handleClose}
                            setReload={setreload}
                            count={count}
                            setPageSurveys={setpagesurveys}
                            page={page}
                            setValue={setvalue}
                            value={value}
                            pageSurveys={pageSurveys}
                            setPage={setpage}
                            setRows={setrows}
                            rows={rows}
                            backdrop={backdrop}
                            setBackdrop={setbackdrop}
                            headcell={["Nome", "Data creazione", "Editabile"]}
                            dataTable={data}
                        />
                    </div>
                </Fade>
            }

            {
                newopen ?
                    <TransitionsModal
                        setCustomAlert={setCustomAlert}
                        open={newopen}
                        setOpen={setnewopen}
                        courses={props.courses}
                        course={props.courses[0]}
                        setReload={setreload}
                        isTest={newopen}
                    />
                    : null
            }

            <Backdrop className={classes.backdrop} open={buttons} onClick={setclosebuttons}>
                <ul className={classes.listMenu}>
                    <li onClick={() => setnewopen("SURVEY")}>Nuovo Sondaggio</li>
                    <li onClick={() => setnewopen("EVALUATION")}>Nuova Verifica</li>
                </ul>
            </Backdrop>

        </React.Fragment >
    )

}

export default Test;
