import config from "../../config"
import socketClient from "../../services/socketClient"

/**
 * Funzione che costruisce il socket della sessione
 * @param {Number} lessonId
 * @param {Function} onClose 
 */

async function buildSessionSocket (lessonId, onClose) {
    try {
        const s = await socketClient.asyncInit({ baseUrl: config.WS_URL, authToken: localStorage.getItem("bearer_fit_token"), lessonId: lessonId })
        s.addEventListener('close', onClose)
        const resp = await socketClient.asyncReadMessage(s)
        if (!resp.meta.error) {
            return s
        } else {
            throw Error("Cannot connect to websocket")
        }
    } catch (error) {
        console.log('[helpers.buildSessionSocket]', error)
    }
}

function convertTimeStringToSeconds (timeString) {
    var splitTimeString = timeString.split(':')
    var seconds = (+splitTimeString[0]) * 60 * 60 + (+splitTimeString[1]) * 60 + (+splitTimeString[2]);
    return seconds
}

export default {
    buildSessionSocket,
    convertTimeStringToSeconds
}