import React, { useEffect } from 'react'
import Courses from "../components/Activities/Courses"
import Statistics from "../components/Activities/Statistics"
import { Tabs, Tab, Paper, makeStyles } from "@material-ui/core"
import useColor from '../context/themeContext'
import activitiesHelpers from './helpers/activities'


function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<div p={3}>
					{children}
				</div>
			)}
		</div>
	);
}

export default function Activities() {
	const { COLORS } = useColor()
	const useStyles = makeStyles({
		root: {
			maxWidth: "1400px",
			margin: "40px",
			padding: "20px",
			margin: "30px auto"
		},
		tabs: {
			paddingBottom: "30px",

			"& .MuiTabs-indicator": {
				backgroundColor: COLORS.primary + "!important"
			}
		},
		tab: {
			color: "#999",
			fontWeight: "bold",
			fontSize: "20px",

			"&.Mui-selected": {
				color: COLORS.primary + "!important"
			}
		}
	})

	const [indexTab, setIndexTab] = React.useState(0)
	const [activities, setActivities] = React.useState([])
	const [subscriptionsAchievements, setSubscriptionsAchievements] = React.useState({})
	const [totalSubsAchievements, setTotalSubsAchievements] = React.useState(0)
	const [achievements, setAchievements] = React.useState({})
	const classes = useStyles()

	const handleChange = (event, newValue) => {
		setIndexTab(newValue)
	}

	useEffect(() => {
		activitiesHelpers.fetchDashboardMe().then((fetchData) => {

			setAchievements(fetchData)
			////Set formatted Month
			const formattedActivities = fetchData.activities.map((activity) => {
				activity.month = activitiesHelpers.getMonth(activity.month)
				return activity
			})
			setActivities(formattedActivities.reverse())
			setSubscriptionsAchievements(activitiesHelpers.formatSubsAchievement(fetchData?.subscriptions_achievements, COLORS.primary))
			setTotalSubsAchievements(fetchData?.subscriptions_achievements.total)

			console.log(fetchData)
		}).catch(err => {
			console.log('[Views,[Activities]]', err)
		})
	}, [])



	return (
		<Paper className={classes.root}>
			<Tabs
				className={classes.tabs}
				value={indexTab}
				onChange={handleChange}
				indicatorColor="primary"
				scrollButtons="auto"
				variant="scrollable"
			>
				<Tab className={classes.tab} label="STATISTICHE" {...a11yProps(0)} />
				<Tab className={classes.tab} label="CORSI" {...a11yProps(1)} />
			</Tabs>
			<TabPanel value={indexTab} index={0}>
				<Statistics
					achievements={achievements}
					totalSubsAchievements={totalSubsAchievements}
					subscriptionsAchievements={subscriptionsAchievements}
					activities={activities}
				/>
			</TabPanel>
			<TabPanel value={indexTab} index={1}>
				<Courses />
			</TabPanel>
		</Paper>
	)
}