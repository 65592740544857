import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button';
import serviceAdmin from '../../services/serviceAdmin';
import Grid from "@material-ui/core/Grid";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { modalClass } from '../../classes'
import useColor from '../../context/themeContext'
import FullTextField from '../StyledComponent/FullTextField'

export default function ModalCategory(props) {
  
  const { COLORS } = useColor();
  const useStyles = makeStyles((theme) => (modalClass(COLORS)));
  const classes = useStyles();
  const [input, setInput] = useState({})
  const [categories, setCategories] = useState([])

  let slugRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;

  useEffect(() => {
    if (props.category) {
      setInput({
        ...input,
        "name": props.category.name,
        "slug": props.category.slug,
        "description": props.category.description,
        "parent_id": props.category.parent_id
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.category])

  useEffect(() => {

    serviceAdmin.getService("v1/course-categories/parents/")
      .then(res => {
        if (res.status === 200) {
          setCategories(res.data.data)
        }
      }).catch(err => {
        console.log(err)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInput({ ...input, [name]: value })
  }


  const modifyGroup = () => {
    serviceAdmin.putService(`v1/course-categories/${props.category.id}/`, input)
      .then(res => {
        props.setReload();
        handleClose();
      }).catch(err => {
        console.log(err)
      })
  }

  return (

    <Drawer
      anchor={"right"}
      open={props.open}
      onClose={handleClose}
    >

      <div className={"drawer-container"}>
        <div className={classes.closeicon}>
          <CloseIcon style={{ cursor: "pointer", height: "30px", width: "30px" }} onClick={handleClose} />
          <h2 className={classes.title}> Modifica categoria </h2>
        </div>
        <div style={{ width: "100%", padding: "20px 30px", marginBottom: "80px" }}>
          <h2 className={classes.title}> Modifica categoria </h2>
          <Grid container spacing={3}>

            <Grid item xs={12} md={6}>
              <InputLabel className={classes.label}>
                Nome
              </InputLabel>
              <FullTextField name="name" value={input.name || ""} onChange={handleChange} placeholder="Nome della categoria" variant="outlined" inputProps={{ className: classes.input }} />
            </Grid>

            {props?.category?.parent_id &&
              <Grid item xs={12} md={6}>
                <InputLabel className={classes.label}>
                  Categoria genitore
              </InputLabel>
                <Select name="parent_id" value={input.parent_id || null} onChange={handleChange} variant="outlined" className={classes.select}>
                  <MenuItem className={classes.selected} value={null}>Nessuna categoria</MenuItem>
                  {
                    categories.length > 0 ?
                      categories.map((category, index) => {
                        return (
                          <MenuItem className={classes.selected} key={index} value={category.id}>{category.name}</MenuItem>
                        )
                      })
                      : null
                  }
                </Select>
              </Grid>
            }

            <Grid item xs={12} style={{ paddingTop: "20px" }}>
              <InputLabel className={classes.label}>
                Descrizione
              </InputLabel>
              <FullTextField name="description" value={input.description || ""} onChange={handleChange} placeholder="Descrizione della categoria" multiline rows={5} variant="outlined" inputProps={{ className: classes.textarea }} />
            </Grid>

          </Grid>

        </div>
        <div className={classes.container_btn}>
          <Button onClick={handleClose} className={classes.btn_del}>Annulla</Button>
          <Button disabled={slugRegex.test(input.slug) && input.name && input.slug ? false : true} onClick={modifyGroup} className={classes.btn_save}>Aggiorna</Button>
        </div>
      </div>
    </Drawer>

  );
}