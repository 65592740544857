import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Nav from '../Navbar/Nav';
import PeopleIcon from '@material-ui/icons/PeopleOutline';
import BookIcon from '@material-ui/icons/BookOutlined';
import AssignmentReturnedOutlinedIcon from '@material-ui/icons/AssignmentReturnedOutlined';
import RecentActorsIcon from '@material-ui/icons/RecentActorsOutlined';
import BallotOutlinedIcon from '@material-ui/icons/BallotOutlined'
import useColor from '../../context/themeContext'
import SideItem from './SideItem'
import { switchRoute } from './utils'

export default function Sidebar(props) {
  
  const { COLORS } = useColor();
  const useStyles = makeStyles({
    list: {
      width: 100,
    },
    colorList: {
      border: "1px solid " + COLORS.primary
    },
    color: {
      "&>div": {
        backgroundColor: COLORS.primary
      }
    },
    listItem: {
      '&.Mui-selected': {
        color: COLORS.primary + "!important",
        backgroundColor: "#fff!important",
        '& .list .listIcon, & .list .listText': {
          color: COLORS.primary + "!important",
        }
      }
    }
  });
  const classes = useStyles();
  const [selected, setSelected] = React.useState(null);

  useEffect(() => {
    switchRoute(setSelected)
  }, [])

  const setListLink = (link, n) => {
    props.history.push(link);
    setSelected(n)
    props.setCloseBack();
  }

  const list = () => (
    <div
      className={clsx(classes.list)}
      role="presentation"
    >
      <List className={'fullList ' + clsx(classes.colorList)}>
        {props.noAdmin ? null :
          <React.Fragment>

            <SideItem
              setLink={() => setListLink("/backoffice/users", 1)}
              selected={selected}
              value={1}
              classes={classes}
              text={"Utenti"}
              Icon={<RecentActorsIcon className={"listIcon"} />}
            />

            <SideItem
              setLink={() => setListLink("/backoffice/group", 2)}
              selected={selected}
              value={2}
              classes={classes}
              text={"Gruppi"}
              Icon={<PeopleIcon className={"listIcon"} />}
            />

            <SideItem
              setLink={() => setListLink("/backoffice/courses", 3)}
              selected={selected}
              value={3}
              classes={classes}
              text={"Corsi"}
              Icon={<BookIcon className={"listIcon"} />}
            />

            <SideItem
              setLink={() => setListLink("/backoffice/surveys", 4)}
              selected={selected}
              value={4}
              classes={classes}
              text={"Questionari"}
              Icon={<BallotOutlinedIcon className={"listIcon"} />}
            />

          </React.Fragment>
        }
        {props.noAdmin ?
          <SideItem
            setLink={() => setListLink("/backoffice-lite/export", 5)}
            selected={selected}
            value={5}
            classes={classes}
            text={"Report"}
            Icon={<AssignmentReturnedOutlinedIcon className={"listIcon"} />}
          />
          :
          <SideItem
            setLink={() => setListLink("/backoffice/export", 7)}
            selected={selected}
            value={7}
            classes={classes}
            text={"Report"}
            Icon={<AssignmentReturnedOutlinedIcon className={"listIcon"} />}
          />
        }
      </List>
    </div>
  );

  return (
    <div>
      <React.Fragment key={"left"}>
        <SwipeableDrawer
          className={"sidebar " + clsx(classes.color)}
          variant="persistent"
          anchor={"left"}
          open={props.state["left"]}
          onClose={props.toggleDrawer(false)}
          onOpen={props.toggleDrawer(true)}
        >
          {list("left")}
        </SwipeableDrawer>
      </React.Fragment>
      <Nav userMe={props.userMe} noAdmin={props.noAdmin} history={props.history} toggleDrawer={props.toggleDrawer} open={props.state} />
    </div>
  );
}
