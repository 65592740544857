import React from 'react'
import { MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';


const MenuIcon = (props) => {

	const { routeAction, Icon, text, textColor } = props;

	return (
		<React.Fragment>
			
			<MenuItem onClick={routeAction} value={0}>
				<ListItemIcon>
					{Icon ? Icon : null}
				</ListItemIcon>
				<ListItemText style={{ color: textColor }} primary={text} />
			</MenuItem>
			
		</React.Fragment>
	)

}


export default MenuIcon;