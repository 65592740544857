import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button';
import useColor from '../../context/themeContext';
import { modalGroup } from '../../classes'
import serviceAdmin from '../../services/serviceAdmin';
import Grid from "@material-ui/core/Grid";
import TableSearchGroup from '../Table/TableSearchGroup'
import CircularProgress from '@material-ui/core/CircularProgress';
import InfoIcon from '@material-ui/icons/Info';
import ButtonGroup from '@material-ui/core/ButtonGroup'
import useAlert from '../../hooks/useAlert'
import FullTextField from '../StyledComponent/FullTextField'
import CustomTooltip from '../StyledComponent/CustomTooltip'



export default function ModalGroup(props) {
  

  const [options, setAlert] = useAlert()
  const { COLORS } = useColor();
  const useStyles = makeStyles((theme) => (modalGroup(COLORS)));
  const classes = useStyles();
  const isMount = useRef(true);

  const [users, setUsers] = useState([])
  const [roles, setRoles] = useState([])
  const [groups, setGroups] = useState([])
  const [search, setSearch] = useState("")
  const [count, setCount] = useState(0)
  const [selectedIds, setSelectedIds] = useState([])
  const [pageUser, setPageUser] = useState(1)
  const [page, setPage] = useState(0)
  const [rowsUser, setRowsUser] = useState(10)
  const [checked, setChecked] = useState(false)
  const [checkedActive, setCheckedActive] = useState(false)
  const [groupsIds, setGroupsIds] = useState([])
  const [rolesIds, setRolesIds] = useState("")
  const [filter, setFilter] = useState(true);
  const [timeUpload, setTimeUpload] = useState(false)
  const [backdrop, setBackdrop] = useState(false)
  const [selected, setSelected] = useState([]);
  const [reload, setReload] = useState(false);


  const handleChangeGroups = (e) => {
    if (!e.target.value.find(el => el === "ALL")) {
      setGroupsIds(e.target.value)
    }
  }

  const setAllGroups = () => {
    const g_ids = groups.map(group => group.id);
    setGroupsIds(g_ids);
  }

  const handleChangeRoles = (e) => {
    setRolesIds(e.target.value)
  }
  const setselected = (input) => {
    if (input?.length > 100) {
      setAlert(true, "Limite di 100 utenti raggiunto", "warning")
    } else {
      setSelected(input)
    }
  }
  const setchecked = () => {
    setChecked(!checked);
  }

  const setcheckedactive = () => {
    setCheckedActive(!checkedActive);
  }

  const setpageuser = (input) => {
    setPageUser(input)
  }

  const setpage = (input) => {
    setPage(input)
  }
  const setbackdrop = (input) => {
    setBackdrop(input)
  }

  const setrowsuser = (input) => {
    setRowsUser(input)
  }

  const setusersids = (input) => {
    setSelectedIds(input)
  }

  const handleChangeSearch = (e, newvalue) => {
    setSearch(e.target.value);
    setPageUser(1);
  }

  const handleClose = () => {
    setSelectedIds([]);
    props.setOpen(false);
  };


  useEffect(() => {
    if (checkedActive || rolesIds || groupsIds.length > 0) {
      setChecked(false)
    }
  }, [checkedActive, rolesIds, groupsIds])

  useEffect(() => {
    if (checked) {
      setCheckedActive(false);
      setRolesIds("");
      setGroupsIds([]);
    }
  }, [checked])

  useEffect(() => {
    if (selectedIds.length > 0) {
      setChecked(false);
      setCheckedActive(false);
      setRolesIds("");
      setGroupsIds([]);
    }
  }, [selectedIds])

  useEffect(() => {
    if (!props.open) {
      setGroupsIds([]);
      setChecked(false);
      setCheckedActive(false);
      setRolesIds("");
      setSearch("");
    }
  }, [props.open])

  useEffect(() => {

    serviceAdmin.getService(`v1/roles/`)
      .then(res => {
        if (isMount.current) {
          if (res.data.data) {
            setRoles(res.data.data);
          }
        }
      }).catch(err => {
        console.log(err);
      })
    serviceAdmin.getService(`v1/groups/`)
      .then(res => {
        if (isMount.current) {
          if (res.data.data) {
            setGroups(res.data.data);
          }
        }
      }).catch(err => {
        console.log(err);
      })

    return () => {
      setTimeUpload(false);
      isMount.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (props.group.id) {
      const groups_id = [props.group.id];
      serviceAdmin.getService(`v1/users/?groups=${groups_id}&page=${pageUser}&page_items=${rowsUser}`)
        .then(res => {
          if (isMount.current) {
            if (res.data.data) {
              setUsers(res.data.data);
              setCount(res.data.meta.pagination.items_count)
              setPage(pageUser - 1)
              setBackdrop(false)
            }
          }
        }).catch(err => {
          if (err?.status === 404) {
            setBackdrop(false)
          }
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.group.id, reload])

  useEffect(() => {

    const searchTime = setTimeout(() => {
      const groups_id = [props.group.id];
      if ((search.length > 0 || search === "") && props.group.id) {
        serviceAdmin.getService(`v1/users/?search=${search}&groups=${groups_id}&page=${pageUser}&page_items=${rowsUser}`)
          .then(res => {
            if (isMount.current) {
              if (res.data.data) {
                setUsers(res.data.data);
                setCount(res.data.meta.pagination.items_count)
                setPage(pageUser - 1)
                setBackdrop(false)
              }
            }
          }).catch(err => {
            if (err.status === 404) {
              setBackdrop(false)
            }
          })
      }
    }, 200)

    return () => {
      clearTimeout(searchTime)
    }
  }, [search, pageUser, rowsUser, props.group])


  function clean(obj) {
    for (let propName in obj) {
      if ((obj[propName] === "" || obj[propName] === false) || (Array.isArray(obj[propName]) && obj[propName].length === 0)) {
        delete obj[propName];
      }
    }
  }

  const deleteUsers = () => {
    let params;

    if (selectedIds.length > 0) {
      params = {
        "users_ids": selectedIds,
      }
    } else if (rolesIds === "" && groupsIds.length === 0 && !checkedActive) {
      params = {
        "all_users": true,
      }
    } else {
      params = {
        "all_users": true,
        "filters": {
          "role_id": rolesIds,
          "groups_ids": groupsIds,
          "users_is_active": checkedActive
        }
      }
      clean(params?.filters)
    }
    setTimeUpload(true)
    serviceAdmin.deleteService(`v1/groups/${props.group.id}/users/`, params)
      .then(res => {
        if (res.status === 200) {
          props.setReloadCount();
          setTimeUpload(false)
          const deleted = count - res.data?.data?.goup_users_count
          setAlert(true, `${deleted === 1 ? deleted + " Utente rimosso" : deleted + " Utenti rimossi"} dal gruppo`, "success");
          props.setReload();
          props.setreload();
          setSelected([])
          setReload(!reload)
        }
      }).catch(err => {
        console.log(err);
      })
  }

  return (

    <div>

      <Grid container spacing={3}>
        <Grid item xs={12}>

          <Grid item xs={12} container justify="center">
            <ButtonGroup>
              <Button className={filter ? classes.btn_active : classes.btn_not_active} onClick={() => { setFilter(true); setselected([]) }}>Filtra utenti da rimuovere</Button>
              <Button className={!filter ? classes.btn_active : classes.btn_not_active} onClick={() => { setFilter(false); }}>Cerca utenti da rimuovere</Button>
            </ButtonGroup>
          </Grid>

          <Grid style={{ marginTop: "30px" }} item xs={12} container justify="center">
            <span style={{ color: "#0000008A" }}>
              {count + " utenti nel gruppo"}
            </span>
          </Grid>

          {!filter &&
            <div style={{ display: "flex" }}>
              <FullTextField style={{ marginTop: "30px", background: "#f1f1f1", minWidth: "180px", backgroundColor: "#f1f1f1", borderRadius: "20px" }} onChange={handleChangeSearch} label="Cerca..." variant="outlined" />
            </div>
          }

          <TableSearchGroup
            selected={selected}
            setSelected={setselected}
            checkedActive={checkedActive}
            checked={checked}
            setChecked={setchecked}
            setCheckedActive={setcheckedactive}
            setPageUser={setpageuser}
            setPage={setpage}
            page={page}
            backdrop={backdrop}
            setBackdrop={setbackdrop}
            setRowsUser={setrowsuser}
            users={users}
            count={count}
            setUsersIds={setusersids}
            handleChangeRoles={handleChangeRoles}
            handleChangeGroups={handleChangeGroups}
            roles={roles}
            groups={groups}
            role={rolesIds}
            group={groupsIds}
            filter={filter}
            setAllGroups={setAllGroups}

          />

        </Grid>
      </Grid>

      <Paper elevation={3} className={classes.save}>

        <Button onClick={handleClose} className={classes.btn_del}>Annulla</Button>
        <div className={classes.wrapper}>
          <Button onClick={deleteUsers} disabled={timeUpload || selectedIds.length > 0 || rolesIds || groupsIds.length > 0 || checked || checkedActive ? false : true} className={classes.btn_save}>Rimuovi</Button>
          {timeUpload && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
        {timeUpload &&
          <CustomTooltip
            title="Se sono stati selezionati molti utenti potrebbe volerci un pò, ma nel frattempo puoi fare altro l'operazione continuerà"
          >
            <InfoIcon style={{ fontSize: "24px", color: COLORS.primary }} />
          </CustomTooltip>
        }
      </Paper>
    </div>


  );
}