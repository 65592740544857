import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import EventIcon from '@material-ui/icons/Event';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import InfoBox from './partials/InfoBox'
import { StatisticsClass } from '../../classes'
import RadialChartCustom from './partials/RadialChartCustom'
import RadialLegend from './partials/RadialLegend'
import LineChartCustom from './partials/LineChartCustom'
import moment from 'moment'
import 'moment/locale/it';

moment.locale('it')

const useStyles = makeStyles((theme) => (StatisticsClass));


const Statistics = (props) => {

	const classes = useStyles();

	const { achievements } = props

	const name = achievements.user?.first_name ? achievements.user?.first_name + " " + achievements.user?.last_name : "N/D";
	const created_at = achievements.user?.created ? moment(achievements.user?.created).format("YYYY/MM/DD - HH:mm:ss") : "N/D";
	const last_login = achievements.user?.last_login ? moment(achievements.user?.last_login).format("YYYY/MM/DD - HH:mm:ss") : "N/D";
	const total_duration = achievements.subscriptions_duration ? moment({}).seconds(achievements.subscriptions_duration).format("H[ore] mm[min]") : "N/D";
	const email = achievements.user?.email ? achievements.user?.email : "N/D";
	const avatar = achievements.user?.avatar?.thumbnail || "";
	const username = achievements.user?.username || "";


	return (
		<Grid container className={classes.padding_grid + " " + classes.max_grid}>

			<Grid container xs={12} md={6}>
				<Grid xs={12} md={4} className={classes.vertical_alignment}>
					<Avatar src={avatar} className={classes.circleAvatar} />
				</Grid>
				<Grid xs={12} md={8} className={classes.vertical_alignment + " " + classes.padding_grid}>
					<h2 className={classes.title}>{name}</h2>
					<h3 className={classes.subtitle}>{username}</h3>
					<div className={classes.flex_align}>
						<p>Email</p>
						<p>{email || ""}</p>
					</div>
					<div className={classes.flex_align}>
						<p>Gruppi</p>
						{achievements.user?.groups.map((group, index) => (
							<p> {group.name + ((achievements.user?.groups.length > 1 && achievements.user?.groups.length !== index + 1) ? ", " : "")}</p>
						))}
					</div>
				</Grid>
			</Grid>

			<Grid container xs={12} md={6} className={classes.padding_grid}>

				<Grid xs={12} sm={6}>
					<InfoBox icon={PersonAddIcon} title={created_at} subtitle={"Data di iscrizione"} />
				</Grid>
				<Grid xs={12} sm={6}>
					<InfoBox icon={EventIcon} title={last_login} subtitle={"Data ultimo accesso"} />
				</Grid>
				<Grid xs={12} sm={6}>
					<InfoBox icon={AccessTimeIcon} title={total_duration} subtitle={"Tempo totale"} />
				</Grid>
				<Grid xs={12} sm={6}>
					<InfoBox icon={CollectionsBookmarkIcon} title={props.totalSubsAchievements} subtitle={"Corsi attivi"} />
				</Grid>

			</Grid>

			<Grid container xs={12} md={6}>
				<h2 className={classes.title}>Avanzamento</h2>
				<Grid xs={12} md={6}>
					<RadialChartCustom
						dataKey={"value"}
						total={props.totalSubsAchievements}
						data={props.subscriptionsAchievements}
					/>
				</Grid>
				<Grid xs={12} md={6}>
					<RadialLegend
						total={props.totalSubsAchievements}
						data={achievements}
					/>
				</Grid>
			</Grid>

			<Grid xs={12} md={6}>
				<h2 className={classes.title} style={{ marginLeft: 60 }}>Attività degli ultimi 12 mesi</h2>
				<LineChartCustom
					positionLabel={"insideBottom"}
					data={props.activities}
					dataKeyXAxis={"month"}
					dataKeyLine={"sessions_count"}
				/>
			</Grid>

		</Grid>
	)
}

export default Statistics;