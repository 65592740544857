import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DeleteDialog from '../Dialog/DeleteDialog'
import ModalModifyTeacher from '../Modal/ModalModifyTeacher'
import { tableStyle } from '../../classes'
import useColor from '../../context/themeContext'
import moment from 'moment'
import 'moment/locale/it';
moment.locale('it')




export default function TableTeachers(props) {
  const { COLORS } = useColor()
  const useStyles = makeStyles({
    ...tableStyle(COLORS),
    table: {
    },
  });
  
  const classes = useStyles();

  const [rows, setRows] = useState([])
  const [deleteTeacher, setDelete] = React.useState(false);
  const [teacher, setTeacher] = React.useState({});


  const setdelete = (input) => { setDelete(input) }


  useEffect(() => {
    if (props.teachers.length >= 0) {
      setRows(
        props.teachers.map((teacher) => {
          return {
            "id": teacher.id,
            "first_name": teacher?.first_name,
            "last_name": teacher?.last_name,
            "slug": teacher.slug,
            "subscription": moment(teacher.created).format('DD-MM-YYYY'),
            "info": teacher?.additional_info,
            "avatar": teacher?.avatar,
          }
        })
      )

    }
  }, [props.teachers])

  const modifyTeacher = (event, row) => {
    setTeacher(row)
    props.setOpen(true);
  }


  return (
    <TableContainer className={classes.container}>
      <Table size={"small"} className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell width="30%" className={classes.table_label} align="left">Nome</TableCell>
            <TableCell width="30%" className={classes.table_label} align="left">Cognome</TableCell>
            <TableCell className={classes.table_label+" hide-column-md"} align="left">Data creazione</TableCell>
            <TableCell className={classes.table_label+" hide-column-md"} align="left">Info</TableCell>
            <TableCell  className={classes.table_label} align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow hover key={index}>
              <TableCell align="left">{row.first_name}</TableCell>
              <TableCell align="left">{row.last_name}</TableCell>
              <TableCell align="left" className="hide-column-md">{row.subscription}</TableCell>
              <TableCell align="left" className="hide-column-md">{row.info}</TableCell>
              <TableCell align="right">
                <MoreHorizIcon onClick={(e) => modifyTeacher(e, row)} style={{ width: "30px", height: "30px", color: "#999", cursor: "pointer" }} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <DeleteDialog
        deleteLesson={deleteTeacher}
        setDelete={setdelete}
        textDelete={"l'insegnante"}
        handleCloseMenu={() => { }}
        lesson={teacher}
        setConfirm={props.setConfirm}
      />

      <ModalModifyTeacher
        setDelete={setdelete}
        setCustomAlert={props.setCustomAlert}
        teacher={teacher}
        setReload={props.setReload}
        course={props.course}
        open={props.open}
        setOpen={props.setOpen}
      />

    </TableContainer>
  );
}