import React from 'react'
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Line, ResponsiveContainer, Label } from 'recharts';
import useColor from '../../../context/themeContext';
import PropTypes from 'prop-types'



const LineChartCustom = (props) => {
    const { COLORS } = useColor()
    return (
        <div style={{ marginTop: "20px" }}>
            <ResponsiveContainer width={"100%"} height={340}>
                <LineChart width={730} height={250} data={props.data}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={props.dataKeyXAxis}>
                        <Label value={props.label} offset={0} position={props.positionLabel} />
                    </XAxis>
                    <YAxis />
                    <Tooltip />
                    <Line type="linear" strokeWidth={3} dataKey={props.dataKeyLine} stroke={COLORS.primary} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

LineChartCustom.propTypes = {
    data: PropTypes.array,
    dataKeyLine: PropTypes.string,
    dataKeyXAxis: PropTypes.string,
    label: PropTypes.string,
    positionLabel: PropTypes.string,
}

export default LineChartCustom