import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import serviceAdmin from '../../services/serviceAdmin';
import Grid from "@material-ui/core/Grid";
import Switch from "../../componentsAdmin/Switch/Switch"
import { modalClass } from '../../classes';
import useColor from '../../context/themeContext'
import FullTextField from '../StyledComponent/FullTextField'

export default function ModalTypology(props) {

  const { COLORS } = useColor()
  const useStyles = makeStyles((theme) => (modalClass(COLORS)));
  const classes = useStyles();

  const [input, setInput] = useState({})

  let slugRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInput({ ...input, [name]: value })
  }

  useEffect(() => {
    if (props.typology) {
      setInput({
        ...input,
        "name": props.typology.name,
        "slug": props.typology.slug,
        "description": props.typology.description,
        "is_ascending_course_ordering": props.typology.is_ascending_course_ordering
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.typology])
  

  const modifyTypology = () => {
    serviceAdmin.putService(`v1/course-typologies/${props.typology.id}/`, input)
      .then(res => {
        props.setReload();
        handleClose();
      }).catch(err => {
        console.log(err)
      })
  }

  return (

    <Drawer
      anchor={"right"}
      open={props.open}
      onClose={handleClose}
    >

      <div className={"drawer-container"}>
        <div className={classes.closeicon}>
          <CloseIcon style={{ cursor: "pointer", height: "30px", width: "30px" }} onClick={handleClose} />
          <h2 className={classes.title}> Modifica tipologia </h2>
        </div>
        <div style={{ width: "100%", padding: "20px 30px", marginBottom: "80px" }}>

          <Grid container spacing={3}>

            <Grid item xs={12} md={6}>
              <InputLabel className={classes.label}>
                Nome
              </InputLabel>
              <FullTextField name="name" value={input.name || ""} onChange={handleChange} placeholder="Nome della tipologia" variant="outlined" inputProps={{ className: classes.input }} />
            </Grid>

            <Grid item xs={12} style={{ paddingTop: "20px" }}>
              <InputLabel className={classes.label}>
                Descrizione
              </InputLabel>
              <FullTextField name="description" value={input.description || ""} onChange={handleChange} placeholder="Descrizione della tipologia" multiline rows={5} variant="outlined" inputProps={{ className: classes.textarea }} />
            </Grid>

            <Grid item xs={12} style={{ paddingTop: "20px" }}>
              <InputLabel className={classes.label}>
                Ordinamento corsi
              </InputLabel>
              <Switch name={"is_ascending_course_ordering"} enableLabel={"CRESCENTE"} disableLabel={"DECRESCENTE"} checked={input.is_ascending_course_ordering} handleToggleChecked={() => setInput({...input, is_ascending_course_ordering: !input.is_ascending_course_ordering})} />
            </Grid>

          </Grid>

        </div>
        <div className={classes.container_btn}>
          <Button onClick={handleClose} className={classes.btn_del}>Annulla</Button>
          <Button disabled={slugRegex.test(input.slug) && input.name && input.slug ? false : true} onClick={modifyTypology} className={classes.btn_save}>Aggiorna</Button>
        </div>
      </div>
    </Drawer>

  );
}