import { Button, darken, makeStyles, Paper } from "@material-ui/core"
import React from "react"
import useColor from "../context/themeContext";
import serviceAdmin from "../services/serviceAdmin";

const useStyles = makeStyles({
    container: {
        minHeight: 600,
        borderRadius: 20,
        padding: 20,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    buttonDownload: {
        background: "#3fbe54",
        color: "#ffffff",
        marginTop: 30,
        '&:hover': {
            background: darken("#3fbe54", 0.10),
            color: "#ffffff",
        }
    }
});

const Outfile = (props) => {
    const [endpoint, setEndpoint] = React.useState("")
    const [value, setValue] = React.useState("")
    const [keyValue, setKeyValue] = React.useState("")
    const { COLORS } = useColor();
    const classes = useStyles()

    React.useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const searchString = window.location.search
        if (searchString.includes("export")) {
            setEndpoint("data-exports")
            setValue(urlParams.get("export"))
            setKeyValue("file_output")
        } else if (searchString.includes("import")) {
            setEndpoint("data-imports")
            setValue(urlParams.get("import"))
            setKeyValue("file_source")
        } else if (searchString.includes("batch_operation")) {
            setEndpoint("batch-codes")
            setValue(urlParams.get("batch_operation"))
            setKeyValue("exit_jobs_file")
        }
    }, [])


    const downloadFile = () => {
        serviceAdmin.getService(`v1/synchronization/${endpoint}/${value}/`)
            .then(res => {
                const resource = res.data.data
                const url = resource[keyValue]
                var link = document.createElement('a');
                link.href = url;
                link.click();
            })
            .catch(err => {
                console.error(err)
            })
    }

    return (
        <Paper elevation={2} className={classes.container}>
            <h2 style={{ color: COLORS.primary }} className={"label-attivita"}>Download file</h2>
            <Button className={classes.buttonDownload} onClick={downloadFile}>Download</Button>
        </Paper>
    )
}

export default Outfile