import React from 'react'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { TableCell, TableRow, Checkbox } from '@material-ui/core';
import { tableStyle } from '../../../classes'
import { makeStyles } from '@material-ui/core/styles';
import useColor from '../../../context/themeContext'


const CustomTableRow = (props) => {
	
	const { COLORS } = useColor();
	const useStyles = makeStyles((theme) => (tableStyle(COLORS)));

	const classes = useStyles();

	const { etiquette, headCells, row, selected, handleDetail, handleCheck } = props;

	const isSelected = (id) => selected.indexOf(id) !== -1 || props.all;

	const isItemSelected = isSelected(row.id);

	const default_etiquette = {
		"RDY": "DA AVVIARE",
		"RUN": "IN CORSO",
		"OFF": "SOSPESO",
		"END": "FINITO"
	};


	return (
		<TableRow
			className={classes.rowSelected}
			hover
			role="checkbox"
			aria-checked={isSelected}
			tabIndex={-1}
			selected={isItemSelected}
		>

			{headCells.map((cell, index) => {
				if (cell.ischeckbox) {
					return (
						<TableCell key={index} className={cell.class + " " + classes.label_cell} padding="checkbox">
							<Checkbox
								disabled={props.all}
								className={classes.check}
								onClick={(event) => { handleCheck(event, row.id) }}
								checked={isItemSelected}
							/>
						</TableCell>
					)
				} else if (cell.isdetail) {
					return (
						<TableCell key={index} className={cell.class + " " + classes.label_cell} align="left">
							<MoreHorizIcon onClick={(event) => { handleDetail(event, row) }} style={{
								width: "30px",
								height: "30px",
								color: "#999",
								cursor: "pointer"
							}} />
						</TableCell>
					)
				} else if (cell.boolean) {
					return (
						<TableCell key={index} className={cell.class + " " + classes.label_cell} align="left">
							{row.stato ?
								<CheckCircleIcon style={{ color: COLORS.primary, marginLeft: "15px" }} />
								:
								<HighlightOffIcon style={{ color: COLORS.primary, marginLeft: "15px" }} />
							}
						</TableCell>
					)
				} else if (cell.status_etiquette) {
					return (
						<TableCell className={"hide-column-xs"} align="left">
							{etiquette ? etiquette[row.status] : default_etiquette[row.status]}
						</TableCell>
					)

				} else if (cell.id === "username") {
					return (
						<TableCell
							style={{cursor: "pointer"}}
							onClick={(event) => { handleDetail(event, row) }}
							key={index}
							className={cell.class + " " + classes.label_cell}
							align={cell.align}>
		
							<span style={{color: COLORS.primary}}>
								{row[cell.id]}
							</span>
							
						</TableCell>
					)

				} else {
					return (
						<TableCell
							key={index}
							className={cell.class + " " + classes.label_cell}
							align={cell.align}>

							{row[cell.id]}

						</TableCell>
					)
				}
			})}
		</TableRow>
	)
}

export default CustomTableRow;