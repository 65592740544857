import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Csv from './viewsAdmin/Exports.jsx';
import Dashboard from './viewsAdmin/Dashboard'

const AdminMinRoutes = (props) => {

    return (
        <Switch>
            <Route exact path="/backoffice-lite">
                <Redirect to="/backoffice-lite/export" />
            </Route>
            <Route exact path="/backoffice-lite/dashboard" render={() => <Dashboard {...props} />} />
            <Route exact path="/backoffice-lite/export" render={() => <Csv {...props} />} />
        </Switch>
    )

}
export default AdminMinRoutes;