import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TabsModal from '../TabsModal/TabsModal';
import TabsCoursesModal from '../TabsCoursesModal/TabsCoursesModal'
import TabsnewCourse from '../TabsnewCourse/TabsnewCourse'
import TabNewCategory from '../TabNewCategory/TabNewCategory'
import TabNewTypology from '../TabNewTypology/TabNewTypology'
import TabsnewGroup from '../TabsnewGroup/TabsnewGroup'
import TabNewUser from '../TabNewUser/TabNewUser'
import TabModifyCourse from '../TabModifyCourse/TabModifyCourse'
import TabNewTest from '../TabNewTest/TabNewTest'
import ModalGroup from '../Modal/ModalGroup';
import { borderRadius } from '../../classes'
import TabNewTeacher from '../TabNewTeacher/TabNewTeacher';

const useStyles = makeStyles((theme) => ({
  modal: {
    zIndex: "0!important",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    position: "relative!important",
    width: "100%",
    marginLeft: "0",
    marginRight: "60px",
    minHeight: "480px",
    borderRadius: borderRadius,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  }
}));

export default function TransitionsModal(props) {
  
  const classes = useStyles();


  const handleClose = () => {
    props.setOpen(false);
  };

  const history = props.history;

  return (
    <div className={classes.paper}>
      {props.isUser ? <TabsModal setCustomAlert={props.setCustomAlert} handleCloseMenu={props.handleCloseMenu} courses={props.courses} handleClose={handleClose} changeReloadUser={props.changeReloadUser} user={props.rowDetail} value={props.value} /> : null}
      {props.isCourses ? <TabsCoursesModal {...props} history={history} setAlertCsv={props.setAlertCsv} setReload={props.setReload} setCustomAlert={props.setCustomAlert} course={props.course} handleClose={handleClose} user={props.user} value={props.value} /> : null}
      {props.isNewCourse ? <TabsnewCourse {...props} setCustomAlert={props.setCustomAlert} setReload={props.setReload} handleClose={handleClose} /> : null}
      {props.isModifyCourse ? <TabModifyCourse {...props} history={props.history} setCustomAlert={props.setCustomAlert} course={props.course} setReload={props.setReload} handleClose={handleClose} /> : null}
      {props.isNewGroup ? <TabsnewGroup setCustomAlert={props.setCustomAlert} setReload={props.setReload} handleClose={handleClose} /> : null}
      {props.isNewCategory ? <TabNewCategory categories={props.categories} setCustomAlert={props.setCustomAlert} setReload={props.setReload} handleClose={handleClose} /> : null}
      {props.isNewTypology ? <TabNewTypology setCustomAlert={props.setCustomAlert} setReload={props.setReload} handleClose={handleClose} /> : null}
      {props.isNewTeacher ? <TabNewTeacher setCustomAlert={props.setCustomAlert} setReload={props.setReload} handleClose={handleClose} /> : null}
      {props.isNewUser ? <TabNewUser setCustomAlert={props.setCustomAlert} setReload={props.setReload} handleClose={handleClose} /> : null}
      {props.isTest ? <TabNewTest {...props} setCustomAlert={props.setCustomAlert} course={props.course} courses={props.courses} setReload={props.setReload} handleClose={handleClose} /> : null}
      {props.isModifyGroup && <ModalGroup deleteGroup={props.deleteGroup} setConfirm={props.setConfirm} handleCloseMenu={props.handleCloseMenu} value={props.value} setValue={props.setValue} setDelete={props.setDelete} setCustomAlert={props.setCustomAlert} reload={props.reload} setreload={props.setreload} setReload={props.setReload} group={props.group} setOpen={props.setOpen} open={props.open} />}
    </div>

  );
}