import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteDialog from '../Dialog/DeleteDialog';
import ModalCategory from '../Modal/ModalCategory';
import Chip from '@material-ui/core/Chip'
import { tableStyle } from '../../classes'
import useColor from '../../context/themeContext'


export default function TableCorsi(props) {

  
  const { COLORS } = useColor();
  const useStyles = makeStyles(tableStyle(COLORS));
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [rows, setRows] = React.useState([]);
  const [searchString, setSearchString] = React.useState("")
  const [deleteCourse, setDelete] = useState(false);
  const [course, setCourse] = useState({});
  const [open, setOpen] = useState(false);

  React.useEffect(() => {
    setSearchString(props.filter)
  }, [props.filter])


  React.useEffect(() => {
    if (props.categories.length >= 0) {
      setRows(
        props.categories.map((category) => {
          return {
            "slug": category.slug,
            "name": category.name,
            "description": category.description,
            "id": category.id,
            "parent_id": category?.parent?.id
          }
        })
      )

    }
  }, [props.categories])




  const items = rows.filter(item => {
    return () => {
      if (!searchString) {
        return item
      } else if (item.code.includes(searchString)) {
        return item
      }
    }
  })

  const setdelete = (input) => { setDelete(input) }
  const setopen = (input) => { setOpen(input) }

  const modifyCourse = (event, row) => {
    setAnchorEl(event.currentTarget)
    setCourse(row)

  }

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleopen = (e) => {
    e.stopPropagation();
    setopen(true);
    setAnchorEl(null);
  }


  return (
    <React.Fragment>

      <TableContainer className={classes.container}>
        <Table size={"small"} className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.table_label} align="left"></TableCell>
              <TableCell className={classes.table_label} align="left">Nome categoria</TableCell>

              <TableCell className={classes.table_label} align="left">Slug</TableCell>
              <TableCell className={classes.table_label+" hide-column-md"} align="left">Descrizione</TableCell>
              <TableCell className={classes.table_label} align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row, index) => (
              <TableRow hover key={index} className={"table-label-cell"}>
                <TableCell className={classes.label_cell} align="left">{row.parent_id ? <Chip className={classes.chip} label="Sottocategoria" size={"small"} /> : <Chip className={classes.chip} label="Categoria" size={"small"} />}</TableCell>
                <TableCell className={classes.label_cell} align="left">{row.name}</TableCell>
                <TableCell className={classes.label_cell} align="left">{row.slug}</TableCell>
                <TableCell className={classes.label_cell+" hide-column-md"} align="left">
                  {
                    ((row.description)?.length > 40) ?
                      (false ? row.description : ((row.description)?.substring(0, 40 - 3) + "...")) :
                      row.description
                  }
                </TableCell>
                <TableCell className={classes.label_cell} align="left">
                  <MoreHorizIcon onClick={(e) => modifyCourse(e, row)} style={{ width: "30px", height: "30px", color: "#999", cursor: "pointer" }} />
                </TableCell>
              </TableRow>
            ))}
            <TableRow style={{ padding: "20px" }}>
              <TableCell style={{ borderBottom: "none" }} align="left">Totali {rows.length}</TableCell>
            </TableRow>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              <MenuItem className={classes.menu_table} value={4} onClick={handleopen}>Modifica categoria</MenuItem>
              <MenuItem className={classes.menu_table_del} onClick={() => setdelete(true)}>Elimina categoria</MenuItem>
            </Menu>
            <DeleteDialog
              deleteLesson={deleteCourse}
              setDelete={setdelete}
              textDelete={"la categoria"}
              handleCloseMenu={handleCloseMenu}
              setConfirm={props.setConfirm}
              lesson={course}
            />
          </TableBody>
        </Table>
      </TableContainer>

      <ModalCategory setReload={props.setReloadCat} category={course} setOpen={setopen} open={open} />
    </React.Fragment>
  );
}