import React from 'react'
import { Radio, Checkbox, FormControlLabel } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';

const WhiteCheckbox = withStyles({
	root: {
		color: "#fff",
		'&$checked': {
			color: "#fff",
		},
	},
	checked: {},
})((props) => <Checkbox color="default" {...props} />);

const WhiteRadio = withStyles({
	
	root: {
		color: "#fff",
		'&$checked': {
			color: "#fff",
		},
	},
	checked: {},
})((props) => <Radio color="default" {...props} />);

const AnswersCheck = (props) => {
	const { isEvaluation, answer, config, onChange, onChangeRadio } = props;


	if (isEvaluation && config === "multiple") {
		return (
			<FormControlLabel
				style={{ minWidth: "200px" }}
				value="Risposta corretta"
				control={
					<WhiteCheckbox
						checked={answer.is_correct || false}
						onChange={onChange}
						color="primary"
						name={answer.id}
					/>
				}
				label="Risposta corretta"
			/>
		)
	} else if (isEvaluation && config === "single") {
		return (
			<FormControlLabel
				style={{ minWidth: "200px" }}
				value="Risposta corretta"
				control={
					<WhiteRadio
						checked={answer.is_correct}
						onChange={onChangeRadio}
						name={answer.id}
						inputProps={{ 'aria-label': 'A' }}
					/>
				}
				label="Risposta corretta"
			/>
		)
	} else {
		return null
	}
}

export default AnswersCheck;