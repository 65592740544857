import React, { useEffect } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import useColor from '../../context/themeContext';
import { Tooltip, Zoom } from '@material-ui/core';

const title_preview = "Invio dei dati non disponibile in modalità anteprima"

export default function ProgressMobileStepper(props) {

  const { COLORS } = useColor();

  const BlueTooltip = withStyles({
    tooltip: {
      fontSize: "14px",
      color: "#fff",
      backgroundColor: COLORS.primary
    }
  })(Tooltip);

  
  const useStyles = makeStyles({
    root: {
      width: "100%",
      flexGrow: 1,
      boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
      background: "white",
      '& .MuiLinearProgress-root': {
        width: "35%!important"
      }
    },
    button: {
      paddingLeft: "15px",
      fontWeight: "bold",
      backgroundColor: COLORS.primary,
      color: "#fff",
      '&:hover': {
        backgroundColor: COLORS.primary + "!important",
        color: "#fff!important"
      },
      '&:active': {
        backgroundColor: COLORS.primary + "!important",
        color: "#fff!important"
      }
    },
    button_send: {
      paddingLeft: "15px",
      fontWeight: "bold",
      backgroundColor: "#3fbe54",
      color: "#fff",
      '&:hover': {
        backgroundColor: "#3fbe54!important",
        color: "#fff!important"
      },
      '&:active': {
        backgroundColor: "#3fbe54!important",
        color: "#fff!important"
      }
    }
  });
  const classes = useStyles();
  const theme = useTheme();
  const [disabled, setDisabled] = React.useState(true)

  const handleNext = () => {
    props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    props.setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (props.singleLayout) {
      let n = 0;
      for (let o in props.allchecked) {
        if (props.allchecked[o] === true) {
          n++;
          if (n === Object.keys(props.allchecked).length) {
        
            setDisabled(false)
            
          } else {
            setDisabled(true)
          }
        }
      }
    }
  }, [props.allchecked])

  return (
    <React.Fragment>

      {!props.singleLayout ?

        <MobileStepper
          style={props.finish ? { display: "none" } : null}
          variant="progress"
          steps={props.steps}
          position="static"
          activeStep={props.activeStep}
          className={classes.root}
          nextButton={
            <React.Fragment>
              {props.activeStep === props.steps - 1 ?
             
                <BlueTooltip placement='left' disableFocusListener={!props.is_preview} disableTouchListener={!props.is_preview} disableHoverListener={!props.is_preview} title={props.is_preview ? title_preview : null} TransitionComponent={Zoom}>
                  <Button className={classes.button_send} size="small" onClick={props.setFinish} disabled={(!props.response)}>
                    Invia
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                  </Button>
                </BlueTooltip>
                :
                <Button className={classes.button} size="small" onClick={handleNext} disabled={!props.response || props.activeStep === props.steps - 1}>
                  Avanti
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </Button>
              }
            </React.Fragment>
          }
          backButton={
            <Button size="small" onClick={handleBack} disabled={props.activeStep === 0}>
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          Indietro
          </Button>
          }
        />

        :

        <MobileStepper
          style={props.finish ? { display: "none" } : null}
          variant="progress"
          steps={props.steps}
          position="static"
          activeStep={props.activeStep}
          className={classes.root}
          nextButton={
            <BlueTooltip placement='left' disableFocusListener={!props.is_preview} disableTouchListener={!props.is_preview} disableHoverListener={!props.is_preview} title={props.is_preview ? title_preview : null} TransitionComponent={Zoom}>
              <Button className={classes.button_send} size="small" onClick={props.setFinish} disabled={disabled}>
                Invia risposte
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
              </Button>
            </BlueTooltip>
          }
          backButton={<button></button>}
        />

      }
    </React.Fragment>
  );
}