import { Facebook, Instagram, LinkedIn, YouTube, Twitter } from '@material-ui/icons';


export const SwitchIcon = (social) => {
	switch (social) {
		case "instagram":
			return (
				<Instagram />
			)
		case "linkedin":
			return (
				<LinkedIn />
			)
		case "facebook":
			return (
				<Facebook />
			)
		case "twitter":
			return (
				<Twitter />
			)
		case "youtube":
			return (
				<YouTube />
			)
		default:
			return null;
	}
}