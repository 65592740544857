import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import CloseIcon from '@material-ui/icons/Close'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import serviceAdmin from '../../services/serviceAdmin'
import { modalClass } from '../../classes'
import useColor from '../../context/themeContext'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import useAlert from '../../hooks/useAlert'
import FullTextField from '../StyledComponent/FullTextField'

export default function ModalLesson(props) {

  const [opt, setAlert] = useAlert()
  
  const { COLORS } = useColor()
  const useStyles = makeStyles((theme) => (modalClass(COLORS)));
  const classes = useStyles();

  const inputFileRef = useRef();
  const [inputs, setInput] = useState({})
  const [file, setFile] = useState()
  const [timeUpload, setTimeUpload] = useState(null);

  const options = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total)
      setTimeUpload(`Upload file ${percent}%`);
    }
  }

  useEffect(() => {
    setInput({ ...inputs, "type": "LINK" })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const handleClose = () => {
    props.setOpen(false);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setInput({ ...inputs, [name]: value })
  }

  const createMaterial = () => {
    let formData = new FormData();
    formData.append("name", inputs.name || "")
    formData.append("type", inputs.type || "")
    formData.append("file", file)

    serviceAdmin.multipartService(`v1/courses/${props.course_id}/teaching-materials/`, formData, options)
      .then(res => {
        if (res.status === 201) {
          setAlert(true, "Materiale aggiunto con successo!", "success")
          props.setReload();
          handleClose();
          setTimeUpload(null);
        }
      }).catch(err => {
        console.log(err)
      })
  }
  const createLink = () => {
    let formData = new FormData();
    formData.append("name", inputs.name || "")
    formData.append("type", inputs.type || "")
    formData.append("link", inputs.link || "")

    serviceAdmin.multipartService(`v1/courses/${props.course_id}/teaching-materials/`, formData)
      .then(res => {
        if (res.status === 201) {
          props.setReload();
          handleClose();
        }
      }).catch(err => {
        console.log(err)
      })
  }

  const setFileRef = () => {
    inputFileRef.current.click()
  }

  ////////FILE SELECT
  function fileSelected(event) {
    let input = event.target;
    if (input.files[0]) {
      setFile(input.files[0])
    } else {
      alert("Controlla di aver inserito la risorsa corretta")
    }
  }

  return (

    <Drawer
      anchor={"right"}
      open={props.open}
      onClose={handleClose}
    >

      <div className={"drawer-container"}>
        <div className={classes.closeicon + " title-drawer-container"}>
          <CloseIcon style={{ cursor: "pointer", height: "30px", width: "30px" }} onClick={handleClose} />
          <h2 className={classes.title}> Crea materiale </h2>
        </div>
        <div className={"drawer-content-container"} style={{ padding: "20px 30px" }}>


          <Grid container spacing={3}>
            <Grid item xs={6}>
              <InputLabel className={classes.label}>
                Nome materiale*
              </InputLabel>
              <FullTextField name="name" value={inputs.name || ""} onChange={handleChange} placeholder="Nome del materiale" variant="outlined" inputProps={{ className: classes.input }} />
            </Grid>

            <Grid item xs={6}>
              <InputLabel className={classes.label}>
                Tipologia*
              </InputLabel>
              <Select name="type" value={inputs.type || "LINK"} onChange={handleChange} variant="outlined" className={classes.select}>
                <MenuItem className={classes.selected} value={"LINK"}>LINK</MenuItem>
                <MenuItem className={classes.selected} value={"FILE"}>FILE</MenuItem>
                <MenuItem className={classes.selected} value={"ZIP"}>ZIP</MenuItem>
              </Select>
            </Grid>
            {inputs.type === "ZIP" || inputs.type === "FILE" ?
              <Grid item xs={6}>

                <InputLabel className={classes.label}>
                  {inputs.type === "FILE" ? "File" : "Zip"}
                </InputLabel>
                <div onClick={setFileRef} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                  {!timeUpload ?
                    <React.Fragment>
                      <input ref={inputFileRef} style={{ visibility: "hidden", width: "1px" }} type="file" onChange={fileSelected} />
                      {file ? "Cambia" : "Aggiungi"} file
                          {!file ?
                        <AddCircleIcon className={classes.color} style={{ cursor: "pointer", paddingLeft: "10px", width: "40px", height: "40px" }} />
                        :
                        <DoneAllIcon style={{ paddingLeft: "10px", width: "40px", height: "40px" }} className={classes.color} />
                      }
                    </React.Fragment>
                    :
                    <p>
                      {timeUpload}
                    </p>
                  }
                </div>

              </Grid>
              :
              <Grid item xs={12}>
                <InputLabel className={classes.label}>
                  Link
                </InputLabel>
                <FullTextField name="link" value={inputs.link || ""} onChange={handleChange} placeholder="Link del materiale" variant="outlined" inputProps={{ className: classes.inputLink }} />
              </Grid>
            }
          </Grid>


        </div>

        <div className={classes.container_btn}>
          <Button onClick={handleClose} className={classes.btn_del}>Annulla</Button>
          <Button disabled={inputs.name ? false : true} onClick={inputs.type === "LINK" ? createLink : createMaterial} className={classes.btn_save}>Crea</Button>
        </div>
      </div>
    </Drawer>

  );
}