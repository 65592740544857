import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import serviceAdmin from '../../services/serviceAdmin'
import Paper from '@material-ui/core/Paper'
import { modalClass } from '../../classes';
import useColor from '../../context/themeContext';
import useAlert from '../../hooks/useAlert';
import FullTextField from '../StyledComponent/FullTextField'
import EditIcon from '@material-ui/icons/Edit';
import { Avatar } from '@material-ui/core';

export default function ModalModifyLesson (props) {

  const [options, setAlert] = useAlert()
  const { COLORS } = useColor();
  const useStyles = makeStyles((theme) => (modalClass(COLORS)));
  const classes = useStyles();

  const inputRef = useRef();
  const [input, setInput] = useState({});
  const [photo, setPhoto] = useState(null);
  

  let slugRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;

  useEffect(() => {
    if (props.teacher.id) {
      serviceAdmin.getService(`v1/teachers/${props.teacher.id}/`)
        .then(res => {
          setInput({
            ...input,
            "first_name": res.data.data.first_name,
            "last_name": res.data.data.last_name,
            "additional_info": res.data.data.additional_info,
            "description": res.data.data.description,
            "slug": res.data.data.slug,
            "contact_email": res.data.data.contact_email,
          });
        }).catch(err => {
          console.log(err)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.teacher.id])


  const setImage = () => {
    inputRef.current.click()
  }

  function fileSelected (event) {
    const isImage = file => file["type"].includes("image")
    let input = event.target
    if (input.files && input.files[0]) {
      if (isImage(input.files[0])) {
        let reader = new FileReader()
        reader.onload = e => {
          setPhoto(e.target.result)
        }
        reader.readAsDataURL(input.files[[0]])
      } else {
        alert('foto non caricata')
      }
    }
  }
  const changeValue = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInput({ ...input, [name]: value })
  }
  function clean (obj) {
    for (let propName in obj) {
      if (obj[propName] === "" || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
  }

  const modifyLesson = () => {
    const params = {
      ...input,
      "avatar": photo
    }
    clean(params);

    serviceAdmin.putService(`v1/teachers/${props.teacher.id}/`, params)
      .then(res => {
        if (res.status === 201) {
          setAlert(true, "Insegnante aggiornato con successo!", "success")
          props.setReload();
          handleClose();

        }
      }).catch(err => {
        console.log(err)
      })
  }

  const handleClose = () => {
    props.setOpen(false);
  };

  return (

    <Drawer
      anchor={"right"}
      open={props.open}
      onClose={handleClose}
    >
      <div className={"drawer-container"}>

        <div className={classes.closeicon + " title-drawer-container"}>
          <CloseIcon style={{ cursor: "pointer", height: "30px", width: "30px" }} onClick={handleClose} />
          <h2 className={classes.title}> Modifica Insegnante </h2>
        </div>
        <div className={"drawer-content-container"} style={{ padding: "20px 30px" }}>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <InputLabel className={classes.label}>
                Nome
              </InputLabel>
              <FullTextField name="first_name" onChange={changeValue} value={input.first_name || ""} placeholder="Nome dell'insegnante" variant="outlined" inputProps={{ className: classes.input }} />
            </Grid>
            <Grid item xs={6}>
              <InputLabel className={classes.label}>
                Cognome
              </InputLabel>
              <FullTextField name="last_name" onChange={changeValue} value={input.last_name || ""} placeholder="Cognome dell'insegnante" variant="outlined" inputProps={{ className: classes.input }} />
            </Grid>
            <Grid item xs={6}>

              <InputLabel className={classes.label}>
                Contatto email
              </InputLabel>
              <FullTextField name="contact_email" onChange={changeValue} value={input.contact_email || ""} placeholder="Email insegnante" variant="outlined" inputProps={{ className: classes.input }} />

            </Grid>
            <Grid item xs={6}>

            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.label}>
                Descrizione
              </InputLabel>
              <FullTextField name="description" onChange={changeValue} value={input.description || ""} placeholder="Descrizione insegnante" multiline rows={3} variant="outlined" inputProps={{ className: classes.textarea }} />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.label}>
                Info Insegnante
              </InputLabel>
              <FullTextField name="additional_info" onChange={changeValue} value={input.additional_info || ""} placeholder="Info insegnante" multiline rows={3} variant="outlined" inputProps={{ className: classes.textarea }} />
            </Grid>

            <Grid item xs={6}>
              <InputLabel className={classes.label}>
                Avatar
              </InputLabel>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input ref={inputRef} style={{ visibility: "hidden", width: "1px" }} type="file" onChange={fileSelected} />
                {photo ? "Cambia foto" : "Aggiungi foto"}
                {photo ? <EditIcon className={classes.color} onClick={setImage} style={{ cursor: "pointer", paddingLeft: "10px", width: "40px", height: "40px" }} /> :
                  <AddCircleIcon className={classes.color} onClick={setImage} style={{ cursor: "pointer", paddingLeft: "10px", width: "40px", height: "40px" }} />}
              </div>
              <div>
                {photo && <Avatar src={photo} />}
              </div>
            </Grid>

          </Grid>

        </div>

        <Paper elevation={3} className={classes.save + " button-container"}>

          <Button onClick={() => { props.setDelete(true) }} className={classes.btn_del_user}>Elimina</Button>
          <Button onClick={handleClose} className={classes.btn_del}>Annulla</Button>
          <Button disabled={slugRegex.test(input.slug) && input.first_name && input.last_name && input.slug ? false : true} onClick={modifyLesson} className={classes.btn_save}>Modifica</Button>

        </Paper>
      </div>
    </Drawer>
  );
}