import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import serviceAdmin from '../../services/serviceAdmin'
import DoneAllIcon from '@material-ui/icons/DoneAll';
import Paper from '@material-ui/core/Paper';
import { modalClass } from '../../classes';
import useColor from '../../context/themeContext';
import useAlert from '../../hooks/useAlert'
import FullTextField from '../StyledComponent/FullTextField'
import AntSwitch from '../Switch/AntSwitch'



export default function ModalModifyModule(props) {


  const [options, setAlert] = useAlert()
  const { COLORS } = useColor()
  const useStyles = makeStyles((theme) => (modalClass(COLORS)));
  const classes = useStyles();

  const inputRef = useRef();
  const [input, setInput] = useState({});
  const [photo, setPhoto] = useState(undefined);
  const [timeUpload, setTimeUpload] = useState(null);
  const [checkedHidden, setCheckedHidden] = useState(false);
  const [thumb, setThumb] = useState(null);

  const opt = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total)
      setTimeUpload(`Upload immagine ${percent}%`);
    }
  }

  let slugRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;

  useEffect(() => {
    if (props.module) {
      setInput({
        ...input,
        "name": props.module?.name,
        "description": props.module?.description,
        "slug": props.module?.slug,
      })
      setThumb(props.module?.thumbnail)
      setCheckedHidden(props.module?.is_hidden)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.module])

  const setImage = () => {
    inputRef.current.click()
  }

  const removeImage = () => {
    setPhoto(null)
  }

  function fileSelected(event) {
    const isImage = file => file["type"].includes("image")
    let input = event.target
    if (input.files && input.files[0]) {
      if (isImage(input.files[0])) {
        let reader = new FileReader()
        reader.onload = e => {
          setPhoto(e.target.result)
        }
        reader.readAsDataURL(input.files[[0]])
      } else {
        alert('foto non caricata')
      }
    }
  }
  const changeValue = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInput({ ...input, [name]: value })
  }
  function clean(obj) {
    for (let propName in obj) {
      if (obj[propName] === "" || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
  }

  const modifyModule = () => {
    const params = {
      ...input,
      "course_id": props.course.id,
      "thumbnail": photo,
      "is_hidden": checkedHidden
    }
    clean(params);

    serviceAdmin.putService(`v1/course-modules/${props.module.id}/`, params, opt)
      .then(res => {
        if (res.status === 201) {
          setAlert(true, "Modulo aggiornato con successo!", "success")
          setTimeUpload(null)
          props.setReload();
          handleClose();

        }
      }).catch(err => {
        console.log(err)
      })
  }

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleChecked = e => {
    setCheckedHidden(e.target.checked)
  }

  return (

    <Drawer
      anchor={"right"}
      open={props.open}
      onClose={handleClose}
    >
      <div className={"drawer-container"}>
        <div className={classes.closeicon + " title-drawer-container"}>
          <CloseIcon style={{ cursor: "pointer", height: "30px", width: "30px" }} onClick={handleClose} />
          <h2 className={classes.title}> Modifica argomento </h2>
        </div>
        <div className={"drawer-content-container"} style={{ padding: "20px 30px" }}>

          <Grid container spacing={3}>

            <Grid item xs={6}>
              <InputLabel className={classes.label}>
                Nome argomento
              </InputLabel>
              <FullTextField name="name" onChange={changeValue} value={input.name || ""} placeholder="Nome dell'argomento" variant="outlined" inputProps={{ className: classes.input }} />
            </Grid>

            <Grid item xs={6}>

            </Grid>

            <Grid item xs={12}>
              <InputLabel className={classes.label}>
                Descrizione
              </InputLabel>
              <FullTextField name="description" onChange={changeValue} value={input.description || ""} placeholder="Descrizione argomento" multiline rows={3} variant="outlined" inputProps={{ className: classes.textarea }} />
            </Grid>

            {!thumb ?
              <Grid item xs={6} lg={3}>
                <InputLabel className={classes.label}>
                  Thumbnail
                </InputLabel>
                <div onClick={setImage} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                  {!timeUpload ?
                    <React.Fragment>
                      <input ref={inputRef} style={{ visibility: "hidden", width: "1px" }} type="file" onChange={fileSelected} />
                      {photo ? "Cambia" : "Aggiungi"} immagine
                      {!photo ?
                        <AddCircleIcon className={classes.color} style={{ cursor: "pointer", paddingLeft: "10px", width: "40px", height: "40px" }} />
                        :
                        <DoneAllIcon style={{ paddingLeft: "10px", width: "40px", height: "40px" }} className={classes.color} />
                      }
                    </React.Fragment>
                    :
                    <p>
                      {timeUpload}
                    </p>
                  }
                </div>
              </Grid>
              :
              <Grid item xs={12} md={6} style={{ paddingTop: "20px", display: "flex", alignItems: "center", justifyContent: "center" }} >
                <div>
                  <InputLabel className={classes.label}>
                    Thumbnail
                  </InputLabel>
                  <Button onClick={removeImage} style={photo === null ? { backgroundColor: "#3AAC4D", color: "#fff" } : { backgroundColor: COLORS.primary, color: "#fff" }}>
                    {photo === null ? "Immagine rimossa" : "Rimuovi immagine"}
                  </Button>
                </div>
                <div onClick={setImage} style={{ cursor: "pointer" }}>
                  {!timeUpload ?
                    <React.Fragment>
                      <input ref={inputRef} style={{ visibility: "hidden", width: "1px" }} type="file" onChange={fileSelected} />
                      {!photo ?
                        <AddCircleIcon className={classes.color} style={{ cursor: "pointer", paddingLeft: "10px", width: "40px", height: "40px" }} />
                        :
                        <DoneAllIcon style={{ paddingLeft: "10px", width: "40px", height: "40px" }} className={classes.color} />
                      }
                    </React.Fragment>
                    :
                    <p>
                      {timeUpload}
                    </p>
                  }
                </div>
              </Grid>
            }
          </Grid>
          <Grid item xs={6} lg={6}>
            <InputLabel className={classes.label}>
              Nascondi intestazione argomento
            </InputLabel>
            <AntSwitch checked={checkedHidden} setChecked={handleChecked} />
          </Grid>

        </div>
        <Paper elevation={3} className={classes.save + " button-container"}>

          <Button onClick={() => { props.setDelete(true) }} className={classes.btn_del_user}>Elimina</Button>
          <Button onClick={handleClose} className={classes.btn_del}>Annulla</Button>
          <Button disabled={slugRegex.test(input.slug) && input.name && input.slug ? false : true} onClick={modifyModule} className={classes.btn_save}>Modifica</Button>

        </Paper>
      </div>
    </Drawer>
  );
}