import React from "react"
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { Grid, InputLabel, Paper, TextField, Button } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import useColor from "../context/themeContext"
import validator from "validator"
import serviceAdmin from "../services/serviceAdmin"
import { forgotPasswordClass } from "../classes"



const CssTextField = withStyles({
    root: {
        border: "none",
        width: "100%",
        '& .MuiInput-underline:after': {
            border: 'none',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none',
            },
            '&:hover fieldset': {
                borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'green',
            },
        },
    },
})(TextField)

export default function ForgotPassword(props) {
    const { COLORS } = useColor()
    const useStyles = makeStyles((theme) => (forgotPasswordClass(COLORS)))
    const classes = useStyles()

    const [isSendEmail, setIsSendEmail] = React.useState(false)
    const [email, setEmail] = React.useState("")

    const sendEmailToRecoveryPassword = () => {
        serviceAdmin.postService("/v1/auth/recover/mail/", {
            email
        })
            .then(res => {
                setIsSendEmail(true)
            })
            .catch(err => console.log(err.message))
    }

    const handleChangeEmail = (event) => {
        setEmail(event.target.value)
    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter" && validator.isEmail(email)) {
            sendEmailToRecoveryPassword()
        }
    }

    const renderFormRecoveryPassword = () => {
        return (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12} style={{ padding: "0 100px" }}>
                        <InputLabel className={classes.label}>
                            Email
                        </InputLabel>
                        <CssTextField onChange={handleChangeEmail} value={email || ""} placeholder="email" variant="outlined" inputProps={{ className: classes.input }} />
                    </Grid>
                </Grid>
                <div className={classes.containerWithPadding}>
                    <Button tabIndex="0" disabled={!validator.isEmail(email)} onClick={sendEmailToRecoveryPassword} className={classes.btn_custom}>Invia email</Button>
                </div>
                <div className={classes.containerWithPadding}>
                    <span className={classes.labelBack} onClick={() => props.history.push("/login")}>Torna al login</span>
                </div>
            </React.Fragment>
        )
    }

    const renderSendEmailCompleted = () => {
        return (
            <div className={classes.containerSendEmail}>
                <CheckCircleIcon className={classes.iconChecked} />
                <div className={classes.containerWithPadding}>
                    <span className={classes.textSend}>Verrà inviato un link al seguente indirizzo <strong>{email || "..."}</strong> se presente nel nostro sistema. Controlla la tua casella di posta e reimposta la password.</span>
                </div>
                <div className={classes.containerWithPadding}>
                    <Button tabIndex="0" onClick={() => props.history.push("/login")} className={classes.btn_custom}>Vai al login</Button>
                </div>
            </div>
        )
    }


    return (
        <div className={classes.container}>
            <Paper className={classes.paper} onKeyDown={handleKeyDown}>
                <h2 className={classes.title}>{isSendEmail ? "Email inviata" : "Recupera password"}</h2>
                {isSendEmail ? renderSendEmailCompleted() : renderFormRecoveryPassword()}
            </Paper>
        </div>
    )
}