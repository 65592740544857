import serviceAdmin from '../../services/serviceAdmin'
import { usersStatusExportEventSource, usersStatusDetailEventSource } from '../../services/sseClient'

export async function SyncExport(url, data, setData, setCustomAlert, setComplete, setLoading, setReload) {

  
  await serviceAdmin.postService(url, data)
    .then(res => {

      if (res.data.data) {
        const id = res.data.data?.resource?.id;
        const sse_url = res.data.data.sse_url
        setLoading(true)
        usersStatusExportEventSource(id, sse_url, setCustomAlert, setLoading, setData, setComplete, setReload)
      }
    }).catch(err => {
      console.log(err)
    })
};

export async function SyncDetailExport(url, data, setData, setLoading, setCustomAlert) {

  await serviceAdmin.postService(url, data)
    .then(res => {
      if (res.data.data) {
        const id = res.data.data?.resource?.id;
        const sse_url = res.data.data.sse_url
        setLoading(true)
        usersStatusDetailEventSource(id, sse_url, setCustomAlert, setLoading, setData)
      }
    }).catch(err => {
      console.log(err)
    })
};
