import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import serviceAdmin from '../../services/serviceAdmin';
import Grid from '@material-ui/core/Grid';
import TableSessions from '../Table/TableSessions';
import moment from 'moment'
import 'moment/locale/it';
import { modalClass } from '../../classes';
import useColor from '../../context/themeContext'
moment.locale('it')





export default function ModalSubscription(props) {
  
  const { COLORS } = useColor()
  const useStyles = makeStyles((theme) => (modalClass(COLORS)));
  const classes = useStyles();

  const [sections, setSections] = useState([])
  const [reload, setReload] = useState(false)

  const setreload = () => {
    setReload(!reload)
  }
  const handleClose = () => {
    props.setOpen(false);
  };


  useEffect(() => {
    if (props.sub?.sub_id) {
      serviceAdmin.getService(`v1/subscriptions/${props.sub?.sub_id}/sections/`).then(res => {
        setSections(res.data.data)
      }).catch(err => {
        console.log(err);
      })
    }
  }, [props.sub, reload])

  return (

    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      closeAfterTransition
    >
      <Fade in={props.open}>
        <div className={classes.paper}>
          <div className={classes.closeicon}>
            <CloseIcon style={{ cursor: "pointer", height: "30px", width: "30px" }} onClick={handleClose} />
          </div>
          <div style={{ padding: "0 20px" }}>
            <h2 className={classes.title}> Sezioni iscrizione </h2>
            <Grid container spacing={3}>
              <TableSessions
                sub={props.sub}
                setCustomAlert={props.setCustomAlert}
                sections={sections}
                sub_id={props.sub?.sub_id}
                setReload={setreload}
              />
            </Grid>
          </div>
        </div>
      </Fade>
    </Modal>

  );
}