import axios from 'axios';
import { createBrowserHistory } from 'history';

    var ES6Promise = require("es6-promise");
    ES6Promise.polyfill();

    require('dotenv').config();

    const history = createBrowserHistory()
    const base  =  process.env.REACT_APP_BASE_URL;
    const token = process.env.REACT_APP_TOKEN;

    const axiosConfig = axios.create({
        baseURL: base,
        headers:{
            "Authorization" : "Basic "+token
        }
    });

    axiosConfig.interceptors.response.use(
        async response => response,
        async error =>{
            if(error.response.status === 403){
                history.push("/no-access");
                window.location.reload();
                
            }else if(localStorage.getItem("refresh_fit_token") && error.response.status === 401 && error.config.headers.Authorization === "Basic "+token ){
                
                const params = {
                    "refresh_token" : localStorage.getItem("refresh_fit_token")
                }

                return await axios.post(base+"v1/auth/refresh/",params,{
                    headers:{
                        "Authorization": "Basic "+token
                    }
                })
                .then(res=>{

                    localStorage.setItem("time_fit_token",new Date().getTime());
                    localStorage.setItem("bearer_fit_token",res.data.data.token);
                    localStorage.setItem("refresh_fit_token",res.data.data.refresh);
                    
                    error.response.config.headers['Authorization'] = 'Bearer ' + res.data.data.token;
                    return axios.request(error.config)
                
                }).catch(err=>{
                    localStorage.clear();
                    history.push("/login");
                    window.location.reload();
    
                })

            }else{
                return Promise.reject(error);
            }
        }
    )

    axiosConfig.interceptors.request.use((config) => {
        if(localStorage.getItem("bearer_fit_token") && 
        !((new Date().getTime() - localStorage.getItem("time_fit_token")) > 86400000)){

            config.headers.Authorization = "Bearer "+localStorage.getItem("bearer_fit_token");
        }
        
        return config;
        }, (error) => {
            
        return Promise.reject(error);
    });

    ////////////////////////////////////////////////SERVICES////////////////////////////////////////////////

    class serviceAdmin{

        static getService = async ( url ) => {

            return await axiosConfig
            .get( url ) 
            .then(async (response) => {
                
                return await Promise.resolve(response);
            })
            .catch(async (error) => {
             
                return await Promise.reject(error.response);
                
            });
        };


        static postService = async (url, params, options) => {

            return await axiosConfig
            .post( url, params, options)
            .then(async (response) => {

                return await Promise.resolve(response);
                
            })
            .catch(async (error) => {

                return await Promise.reject(error.response);
                
            });
        };

        static deleteService = async (url, params) => {

            return await axiosConfig
            .delete(url,{data : params})
            .then(async (response) => {

                return await Promise.resolve(response);

            })
            .catch(async (error) => {

                return await Promise.reject(error.response);
                
            });
        };

        static putService = async (url, params, options) => {

            return await axiosConfig
            .put( url, params, options)
            .then(async (response) => {

                return await Promise.resolve(response);

            })
            .catch(async (error) => {

                return await Promise.reject(error.response);
                
            });
        };

        static multipartService = async (url, params, options) => {

            return await axiosConfig
            .post( url, params, options )
            .then(async (response) => {

                return await Promise.resolve(response);

            })
            .catch(async (error) => {

                return await Promise.reject(error.response);
                
            });
        };

    };

export default serviceAdmin;