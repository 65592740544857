import React, { useEffect, useState, useRef, Fragment} from 'react'
import CheckIcon from '@material-ui/icons/Check'
import ErrorIcon from '@material-ui/icons/Clear'

//DATA CLASS ShowQuestion
class ShowQuestion {
    constructor(name, is_correct, i){
        this.name = name
        this.is_correct = is_correct
        this.index = i
    }

    errorStyle = {
        style: { fill: "red", marginLeft: "12px" }
    }
    doneStyle = {
        style: { fill: "#3fbe54", marginLeft: "12px" }
    }

    stylizedQuestion(){
        if(this.is_correct)
        {
            return (
                <div className='question-result-container'>
                    <div className='correct-question-result'>
                        {this.index + ")  " +this.name}
                    </div>
                    <div>
                        <CheckIcon {...this.doneStyle}/>
                    </div>
                </div>
            )
        }
        else
        {
            return (
                <div className='question-result-container'>
                    <div className='not-correct-question-result'>
                        {this.index + ")  " +this.name}
                    </div>
                    <div>
                        <ErrorIcon  {...this.errorStyle}/>
                    </div>
                </div>
            )
        }
      
    }
}


export default function ShowQuestionsResult(props){
    ///TIMER
    const timer = useRef(null)

    ///ShowQuestion array state
    const [ show_questions, setShowQuestions ] = useState([])
    ///LOADING POINTS
    const [ points, setPoints ] = useState("")

    const { registry } = props

    const mapRegistries = (question_registries) => {
        const new_show_questions = question_registries.map( (q,i) =>{ 
            try{

                const question_name = q.question.text
                const question_is_passed = q.is_passed
                const index = String(i+1)

                return new ShowQuestion(
                    question_name,
                    question_is_passed,
                    index
                )

            }catch(e){
                console.error(e)
                return null
            }
        })
        return new_show_questions
    }

    useEffect(()=>{
        timer.current = setInterval( pointsLoad, 300 )
        //ON ANMOUNT
        return () => {
            setShowQuestions([])
            if(timer.current)
                clearInterval(timer.current)
        }
    },[])

    useEffect(()=>{

        if(registry != null)
        {   
            if(timer.current){
                clearInterval(timer.current)
                timer.current = null
            }
            

            const question_registries = registry.question_registries

            //CREATE ARRAY OF ShowQuestion DATA CLASS
            const new_show_questions = mapRegistries(question_registries)

            ///SET NEW show_questions STATE
            setShowQuestions(new_show_questions)
       
        }
    },[registry])


    const exist_show_questions = show_questions.length > 0


    const pointsLoad = () => {
        setPoints( prev_points => {
            if(prev_points.length === 3)
            {
                return ""
            }
            return prev_points + "."
        })
    }

    if(exist_show_questions)
    {
        return (
            <Fragment>
                <h3 className="result-title-questions">Resoconto:</h3>
                {
                    show_questions.map( q => {
                        return q.stylizedQuestion()
                    })
                }
            </Fragment>
        )
    }
    else
    {
        return (
            <Fragment>
                <div className="loading-questions-result"> Caricamento domande{points}</div>
            </Fragment>
        )
    }
  
}