import React, { useState, useEffect } from 'react';
import RelatedBox from './RelatedBox';
import Service from '../../services/services'
import SkeletonRelated from './SkeletonRelated';


const Related = (props) => {
    
    const [lessons, setLessons] = useState(null);

    const clickLesson = (e, input) => { props.setLesson(e, input) }

    const { is_preview } = props;

    const url = is_preview ? 
    `/v1/course-modules/${props.id}/sections/` : 
    `v1/users/me/course-modules/${props.id}/course-sections/`;
    
    useEffect(() => {
        Service.getService(url)
            .then(res => {
                setLessons(res.data.data);
                props.setLessons(res.data.data.length);
            }).catch(err => {
                console.log(err);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.reloadComplete])

    if (lessons) {
        return (
            <React.Fragment>
                {
                    lessons?.map((related, index) => {

                        if (window.innerWidth < 960 & index < 6 || window.innerWidth >= 960) {
                            return (
                                <RelatedBox
                                    hiddenModule={props.hiddenModule}
                                    has_blocking_subscription={props.has_blocking_subscription}
                                    sub_expired={props.sub_expired}
                                    has_expired={props.has_expired}
                                    block={!related?.is_accessible && !is_preview}
                                    lessonSelected={props.lessonSelected}
                                    pushSelect={props.pushSelect}
                                    name={related.slug}
                                    module_id={props.id}
                                    id={related.id}
                                    related={related}
                                    teacher={related?.teacher}
                                    clickLesson={clickLesson}
                                    finish={related.is_passed}
                                    key={index}
                                    title={related.name}
                                    category={related.category}
                                    is_mandatory={related.is_mandatory}
                                />
                            )
                        } else {
                            return (
                                <RelatedBox
                                    hiddenModule={props.hiddenModule}
                                    has_blocking_subscription={props.has_blocking_subscription}
                                    sub_expired={props.sub_expired}
                                    has_expired={props.has_expired}
                                    module_id={props.id}
                                    id={related.id}
                                    name={related.slug}
                                    finish={related.is_passed}
                                    teacher={related?.teacher}
                                    clickLesson={clickLesson}
                                    hide={props.hide}
                                    key={index}
                                    title={related.name}
                                    block={!related?.is_accessible && !is_preview}
                                    category={related.category}
                                    is_mandatory={related.is_mandatory}
                                />
                            )
                        }
                    })
                }
                {   window.innerWidth < 960 & lessons?.length > 6
                    ?
                    <button className={"show-lesson"} onClick={props.setHide}>{props.hide ? "Mostra tutte le lezioni del modulo" : "Mostra meno lezioni"}</button>
                    :
                    null
                }
            </React.Fragment>
        )
    } else {
        return <SkeletonRelated square={true} />
    }
}

export default Related;