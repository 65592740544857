import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemIcon, ListItemText, Avatar } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ListLessons from './ListLessons'
import ModalModifyTheme from "../Modal/ModalModifyTheme"
import serviceAdmin from '../../services/serviceAdmin';
import DeleteDialog from '../Dialog/DeleteDialog'
import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import ArgumentsIcon from '@material-ui/icons/FormatListBulleted'
import useColor from '../../context/themeContext';
import useDrag, { DropElement, DropContainer } from '../../hooks/useDrag'



export default function ListThematics(props) {
  
  const { COLORS } = useColor()

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    label_cell: {
      flex: "0 0 auto",
      color: "#fff!important",
      fontSize: "15px!important",
      paddingLeft: "15px",
    },
    label_cell_icon: {
      marginLeft: "auto",
      color: "#fff!important",
      fontSize: "15px!important",
    },
    check: {
      paddingRight: "20px!important"
    },
    menu_table: {
      color: COLORS.primary + "!important"
    },
    menu_table_del: {
      color: "red"
    },
    list: {
      borderBottom: `1px solid ${COLORS.primary}`,
    },
    list_moduls: {
      background: COLORS.primary,
      borderRadius: "12px",
      margin: "10px 0",
      "& .MuiAccordionSummary-content": {
        margin: 0
      }
    },
    listItem: {
      "& span": {
        fontSize: "14px",
        fontWeight: "bold"
      }
    }, expansion: {
      width: "100%"
    },
    preview: {
      backgroundColor: "rgba(150, 150, 200, 0.1)",
      border: "1px dashed #abc",
      borderRadius: "12px"
    }
  }));

  const CustomTooltip = withStyles({
    tooltip: {
      fontSize: "14px",
      backgroundColor: COLORS.primary,
      color: "#fff",
      textAlign: "center",
      borderRadius: "10px",
      fontWeight: "bolder"
    }
  })(Tooltip);

  const classes = useStyles();

  const [rows, setRows] = React.useState([]);
  const [thematic, setThematic] = React.useState({});
  const [deleteThematic, setDelete] = React.useState(false);

  const { drag_class, handleDrop } = useDrag("module-drag-handle", rows, setRows, handleDropUpdate, props.setReload)

  const setdelete = (input) => { setDelete(input) }


  useEffect(() => {
    if (props.thematics.length > 0) {
        setRows(props.thematics)
    }
  }, [props.thematics])


  const setopen = (input) => { props.setOpenModify(input) }

  const handleOpenMenu = (event, row) => {
    event.stopPropagation();
    setThematic(row);
    setopen(true)
  };

  async function handleDropUpdate(el, index) {
    try {
      const data = await serviceAdmin.putService(`/v1/courses/${props.course.id}/course-themes/${el.id}/`,
        {
          "course_id": props.course.id,
          "order": index,
          "name": el.name
        }
      )
      return data;
    } catch (err) {
      console.log(err)
    }
  }

  const openModule = (event, thematic) =>{
    event.stopPropagation();
    props.setCourseThemeId(thematic.id)
    props.setCourseTheme(thematic)
    props.setModules(thematic.modules)
    props.setShowModules(true)
  }


  return (
    <div className={classes.root}>
      <React.Fragment>

        <List>
          <DropContainer handleDrop={handleDrop} drag_class={drag_class}>
            {rows.map((row, index) => {

              return (
                <DropElement key={row.id}>
                    
                    <div onClick={(e) => openModule(e, row)}>
                      <ListItem
                        className={"grab " + drag_class}
                        style={{
                            borderRadius: 12,
                            marginBottom: 12,
                            backgroundColor: COLORS.primary,
                            marginLeft: "auto",
                            display: "flex",
                            justifyContent: "flex-start"
                        }}
                      >

                        <ListItemText className={classes.label_cell}>
                          {row.name}
                        </ListItemText>

                        <ListItemIcon className={classes.label_cell_icon}>
                          <CustomTooltip className={classes.tooltip} title="Modifica tematica" placement="top">
                            <IconButton onClick={(e) => { handleOpenMenu(e, row) }}>
                              <CreateIcon style={{ width: "30px", height: "30px", color: "#fff" }} />
                            </IconButton>
                          </CustomTooltip>
                        </ListItemIcon>

                        <ListItemIcon>
                          <CustomTooltip className={classes.tooltip} title="Vedi argomenti" placement="top">
                            <IconButton onClick={(e) => { openModule(e, row) }}>
                              <ArgumentsIcon style={{ width: "30px", height: "30px", color: "#fff" }} />
                            </IconButton>
                          </CustomTooltip>
                        </ListItemIcon>

                      </ListItem>

                      </div>
                </DropElement>
              );
            })}

          </DropContainer>
        </List>



        <Table>
          <TableBody>
            <TableRow className="table-label-cell" style={{ width: "100%" }}>
              <TableCell style={{ borderBottom: "none" }} align="left">Totali {rows.length}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <DeleteDialog
          deleteLesson={deleteThematic}
          setDelete={setdelete}
          textDelete={"la tematica"}
          handleCloseMenu={() => { }}
          lesson={thematic}
          setConfirm={props.setConfirm}
        />
      </React.Fragment>

      <ModalModifyTheme
        setDelete={setdelete}
        setCustomAlert={props.setCustomAlert}
        theme={thematic}
        setReload={props.setReload}
        course={props.course}
        open={props.openModify}
        setOpen={props.setOpenModify}
      />
    </div>
  );
}
