import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import useColor from '../../context/themeContext';

export default function DeleteDialog(props) {
  
  const { COLORS } = useColor()
  return (

    <Dialog
      open={props.deleteLesson}
      onClose={() => props.setDelete(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Sei sicuro di voler eliminare " + props.textDelete + "?"}</DialogTitle>
      <DialogActions>
        <Button onClick={() => { props.setDelete(false); props.handleCloseMenu() }} style={{ color: COLORS.primary }} >
          Annulla
          </Button>
        <Button onClick={() => { props.setConfirm(true, props.lesson); props.setDelete(false); props.handleCloseMenu() }} color="secondary" autoFocus>
          Elimina
          </Button>
      </DialogActions>
    </Dialog>

  );
}