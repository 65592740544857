import React, { useState, useRef } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { Grid, Button, InputLabel, Paper, Avatar } from "@material-ui/core"
import serviceAdmin from '../../services/serviceAdmin'
import { tabsStyle } from '../../classes'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import EditIcon from '@material-ui/icons/Edit'
import useColor from '../../context/themeContext'
import FullTextField from '../StyledComponent/FullTextField'
import useAlert from '../../hooks/useAlert'



const TabNewTeacher = (props) => {
    const [options, setAlert] = useAlert()
    const inputImageRef = useRef();
    const [input, setInput] = useState({})
    const [image, setImage] = useState()
    const [errorSlug, setErrorSlug] = useState(false)

    const { COLORS } = useColor();
    const useStyles = makeStyles({
        ...tabsStyle(COLORS),
        arrow: {
            width: "100%",
            height: "48px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            cursor: "pointer"
        },
        arrowicon: {
            color: "#999"
        }
    });

    const classes = useStyles();

    let slugRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;

    const setImageRef = () => {
        inputImageRef.current.click()
    }

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setInput({ ...input, [name]: value })
    }

    function clean (obj) {
        for (let propName in obj) {
            if (obj[propName] === "" || obj[propName] === undefined) {
                delete obj[propName];
            }
        }
    };

    ////////IMAGE SELECT

    function imageSelected (event) {
        const isImage = file => file["type"].includes("image")
        let input = event.target
        if (input.files && input.files[0]) {
            if (isImage(input.files[0])) {
                let reader = new FileReader()
                reader.onload = e => {
                    setImage(e.target.result)
                }
                reader.readAsDataURL(input.files[[0]])
            } else {
                alert('foto non caricata')
            }
        }
    }

    const createTeacher = () => {
        const params = {
            ...input,
            "avatar": image
        }
        clean(params);

        serviceAdmin.postService("v1/teachers/", params)
            .then(res => {
                if (res.status === 201) {
                    props.setCustomAlert(true, "success", "Insegnante creato correttamente!")
                    props.setReload();
                    props.handleClose();
                    setErrorSlug(false);
                }
            }).catch(err => {
                if (err.data.reason) {
                    setErrorSlug(true);
                }
            })
    };

    return (
        <React.Fragment>
            <Paper className={classes.root}>
                <div className={classes.arrow} onClick={props.handleClose}>
                    <ArrowBackIosIcon className={classes.arrowicon} />
                </div>
                <div className={classes.content}>
                    <h2 className={classes.title}>Crea un nuovo insegnante</h2>
                    <div style={{ paddingTop: "20px" }}>
                        <Grid container spacing={3}>
                            <Grid spacing={3} container item xs={12} lg={12}>

                                <Grid className="flex-center" item xs={12} md={3}>
                                    <InputLabel className={classes.label}>
                                        Nome insegnante*
                                    </InputLabel>
                                    <FullTextField name="first_name" value={input.first_name || ""} onChange={handleChange} placeholder="Nome dell'insegnante" variant="outlined" inputProps={{ className: classes.input }} />
                                </Grid>
                                <Grid  className="flex-center" item xs={12} md={3}>
                                    <InputLabel className={classes.label}>
                                        Cognome insegnante*
                                    </InputLabel>
                                    <FullTextField name="last_name" value={input.last_name || ""} onChange={handleChange} placeholder="Cognome dell'insegnante" variant="outlined" inputProps={{ className: classes.input }} />
                                </Grid>

                                <Grid className="flex-center" item xs={12} md={3}>
                                    <InputLabel className={classes.label}>
                                        Contatto email
                                    </InputLabel>
                                    <FullTextField name="contact_email" value={input.contact_email || ""} onChange={handleChange} placeholder="Email dell'insegnante" variant="outlined" inputProps={{ className: classes.input }} />
                                </Grid>

                                <Grid className="flex-center" item xs={12} md={3}>
                                    <InputLabel className={classes.label}>
                                        Slug
                                    </InputLabel>
                                    <FullTextField name="slug" value={input.slug || ""} onChange={handleChange} placeholder="Slug insegnante" variant="outlined" inputProps={{ className: classes.input }} />
                                    {errorSlug ? <p style={{ fontSize: "14px", color: "red", paddingLeft: "10px" }}>Questo slug potrebbe essere già in uso</p> : null}
                                </Grid>

                                <Grid className="flex-center" item xs={12} md={12}>
                                    <InputLabel className={classes.label}>
                                        Avatar
                                     </InputLabel>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <input ref={inputImageRef} style={{ visibility: "hidden", width: "1px" }} type="file" onChange={imageSelected} />
                                        {image ? "Cambia foto" : "Aggiungi foto"}
                                        {image ? <EditIcon className={classes.color} onClick={setImageRef} style={{ cursor: "pointer", paddingLeft: "10px", width: "40px", height: "40px" }} /> :
                                        <AddCircleIcon className={classes.color} onClick={setImageRef} style={{ cursor: "pointer", paddingLeft: "10px", width: "40px", height: "40px" }} /> }
                                    </div>
                                    <div>
                                    {image && <Avatar src={image}/>}
                                    </div>
                                </Grid>

                                <Grid className="flex-center" item xs={12} md={8} style={{ paddingTop: "20px" }}>
                                    <InputLabel className={classes.label}>
                                        Info insegnante
                                    </InputLabel>
                                    <FullTextField name="additional_info" value={input.additional_info || ""} onChange={handleChange} placeholder="Info sull'insegnante" multiline rows={3} variant="outlined" inputProps={{ className: classes.textarea }} />
                                </Grid>

                                <Grid className="flex-center" item xs={12} md={8}>
                                    <InputLabel className={classes.label}>
                                        Descrizione
                                </InputLabel>
                                    <FullTextField name="description" value={input.description || ""} onChange={handleChange} placeholder="Descrizione dell'insegnante" multiline rows={3} variant="outlined" inputProps={{ className: classes.textarea }} />
                                </Grid>

                            </Grid>

                        </Grid>
                    </div>

                    <div className={classes.container_btn}>
                        <Button onClick={props.handleClose} className={classes.btn_del}>Annulla</Button>
                        <Button disabled={slugRegex.test(input.slug) && input.first_name && input.last_name ? false : true} onClick={createTeacher} className={classes.btn_save}>Crea</Button>
                    </div>
                </div>
            </Paper>
        </React.Fragment>
    )
}

export default TabNewTeacher;