import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'


const CssTextField = withStyles({
	
	root: {
			border: "none",
			minWidth: "235px",
			'& .MuiInput-underline:after': {
					border: 'none',
			},
			'& .MuiOutlinedInput-root': {
					'& fieldset': {
							border: 'none',
					},
					'&:hover fieldset': {
							borderColor: 'yellow',
					},
					'&.Mui-focused fieldset': {
							borderColor: 'green',
					},
			},
	},
})(TextField);


export default CssTextField;
