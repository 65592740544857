import React from 'react';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import useColor from '../../context/themeContext';


const StartSurvey = (props) => {

    const { COLORS } = useColor()
    
    const useStyles = makeStyles({
        root: {
            position: "relative",
            minHeight: "400px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
        },
        title: {
            fontSize: "25px",
            marginBottom: "25px"
        },
        button_container: {
            display: "flex",
            flexDirection: "column"

        },
        button: {
            marginTop: "20px",
            marginBottom: "20px",
            fontWeight: "bold",
            backgroundColor: "#3fbe54!important",
            color: "#fff!important",
            '&:hover': {
                backgroundColor: "#3fbe54",
                color: "#fff"
            },
            '&:active': {
                backgroundColor: "#3fbe54!important",
                color: "#fff"
            }
        },
        skip_button: {
            fontWeight: "bold",
            backgroundColor: COLORS.primary + "!important",
            color: "#fff!important",
            '&:hover': {
                backgroundColor: COLORS.primary,
                color: "#fff"
            },
            '&:active': {
                backgroundColor: COLORS.primary + "!important",
                color: "#fff"
            }
        }
    });
    const classes = useStyles();

    const { category, loading } = props;


    return (
        <Paper className={classes.root}>
            <h2 className={classes.title}>{props.survey_name}</h2>
            <div className={classes.button_container}>
                {loading &&
                    <Button className={classes.button} onClick={props.startSurvey}>
                        {category === "EVALUATION" ? "Inizia la verifica" : "Inizia il sondaggio"}
                    </Button>
                }
            </div>
        </Paper>
    )
}

export default StartSurvey;