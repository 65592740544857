import React from 'react'
import { List, Avatar, ListItem, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ContactClass } from '../../classes'
import useColor, { useContent } from '../../context/themeContext'
import { SwitchIcon } from './helpers'


const SocialBox = () => {
	
	const { COLORS } = useColor()
	const { Content } = useContent()
	const useStyles = makeStyles(ContactClass(COLORS))
	const classes = useStyles()


	return (
		<div className={classes.social_box}>
			{Content?.socials?.length > 0 &&
				<h2 className={classes.title}>
					I nostri social
			</h2>
			}
			<List>
				{
					Content?.socials?.map((social) => {
						return (
							<ListItem>
								<Link target="_blank" href={social?.url} color="inherit">
									<Avatar>
										{SwitchIcon(social?.name)}
									</Avatar>
								</Link>
							</ListItem>
						)
					})
				}
			</List>
		</div>
	)
}

export default SocialBox;