import React from 'react'
import { TableHead, TableRow, TableCell, Checkbox } from '@material-ui/core'
import useColor from '../../../context/themeContext'

const EnhancedTableHead = (props) => {
  const { COLORS } = useColor();
  const { haveCheckbox, classes, onSelectAllClick, rows, selected, headCells, all } = props;
  const allPage = rows.every(r => selected.includes(r)) || all;

  return (
    <TableHead>
      
      <TableRow>
        {haveCheckbox &&
          <TableCell className={classes.label_cell} padding="checkbox">
            <Checkbox
              disabled={all}
              style={{ color: COLORS.primary }}
              className={classes.check}
              indeterminate={allPage}
              checked={allPage}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'Seleziona tutti gli utenti nella pagina' }}
            />
          </TableCell>
        }
        {headCells.map((headCell) => {
          if (headCell.label) {
            return (

              <TableCell
                className={headCell.class + classes.table_label}
                title={headCell.alt}
                key={headCell.id}
                align={"left"}
                padding={headCell.disablePadding ? 'none' : 'default'}
              >
                {
                  ((headCell.label)?.length > 20) ?
                    (false ? headCell.label : ((headCell.label)?.substring(0, 20 - 3) + "...")) :
                    headCell.label
                }
              </TableCell>
            )
          } else {
            return null
          }
        })}
        <TableCell
          align={"left"}
        ></TableCell>
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;