import React from 'react';
import useColor, { useContent } from '../../context/themeContext'
import { List, Avatar, ListItem, Link } from '@material-ui/core'
import { SwitchIcon } from '../Profile/helpers'


const Footer = () => {

    const { Content } = useContent()
    const { COLORS } = useColor()

    return (
        <footer>
            <p style={{color: COLORS.primary}}>
                {Content?.footer?.text}
            </p>
            <List className="footer-social">
                {
                    Content?.socials?.map((social) => {
                        return (
                            <ListItem style={{ width: "auto" }}>
                                <Link target="_blank" href={social?.url} color="inherit">
                                    <Avatar>
                                        {SwitchIcon(social?.name)}
                                    </Avatar>
                                </Link>
                            </ListItem>
                        )
                    })
                }
            </List>
        </footer>

    )



}

export default Footer