import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'
import serviceAdmin from '../../services/serviceAdmin'
import { modalClass } from '../../classes';
import useColor from '../../context/themeContext';
import useAlert from '../../hooks/useAlert'
import FullTextField from '../StyledComponent/FullTextField'


export default function ModalTheme(props) {
  
  const [option, setAlert] = useAlert()
  const { COLORS } = useColor();
  const useStyles = makeStyles((theme) => (modalClass(COLORS)));
  const classes = useStyles();

  const [input, setInput] = useState({});
  const [conflict, setConflict] = useState(false);

  const changeValue = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInput({ ...input, [name]: value })
  }
  function clean(obj) {
    for (let propName in obj) {
      if (obj[propName] === "" || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
  }

  const createTheme = () => {
    let params;
    if (input.pass_mode) {
      params = {
        ...input,
        "course_id": props.course.id
      }
    } else {
      params = {
        ...input,
        "course_id": props.course.id
      }
    }
    clean(params);

    serviceAdmin.postService(`v1/courses/${props.course.id}/course-themes/`, params)
      .then(res => {
          props.setReload();
          setAlert(true, "Tematica creata correttamente!", "success");
          handleClose();
          setConflict(false)
      }).catch(err => {
        if (err.status === 400 || err.status === 409) {
          if (err.data.reason.includes("slug")) {
            setConflict(true)
          } else {
            setConflict(false)
          }
        }
      })
  }

  const handleClose = () => {
    setInput({});
    props.setOpen(false);
  };
  return (

    <Drawer
      anchor={"right"}
      open={props.open}
      onClose={handleClose}
    >

      <div className={"drawer-container"}>
        <div className={classes.closeicon + " title-drawer-container"}>
          <CloseIcon style={{ cursor: "pointer", height: "30px", width: "30px" }} onClick={handleClose} />
          <h2 className={classes.title}> Crea una nuova tematica </h2>
        </div>
        <div className={"drawer-content-container"} style={{ padding: "20px 30px" }}>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <InputLabel className={classes.label}>
                Nome tematica*
              </InputLabel>
              <FullTextField name="name" onChange={changeValue} value={input.name || ""} placeholder="Nome della tematica" variant="outlined" inputProps={{ className: classes.input }} />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.label}>
                Descrizione
              </InputLabel>
              <FullTextField name="description" onChange={changeValue} value={input.description || ""} placeholder="Descrizione tematica" multiline rows={4} variant="outlined" inputProps={{ className: classes.textarea }} />
            </Grid>
          </Grid>



        </div>
        <div className={classes.container_btn + " button-container"}>
          <Button onClick={handleClose} className={classes.btn_del}>Annulla</Button>
          <Button disabled={input.name ? false : true} onClick={createTheme} className={classes.btn_save}>Crea</Button>
        </div>
      </div>
    </Drawer>
  );
}