import React, { useState } from 'react'
import { ExitToApp, BookOutlined, BarChart, PermIdentity, Dashboard } from '@material-ui/icons/'
import MenuIcon from './MenuIcon'
import { Skeleton } from '@material-ui/lab'
import { IconButton, Menu, Divider, Avatar } from '@material-ui/core'
import useColor, { useContent, useLogo } from '../../context/themeContext'

const Navbar = (props) => {
    
    const { COLORS } = useColor();
    const { logoUrl, brand_logo_nav } = useLogo();
    const { Content } = useContent();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleopen = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const Logout = () => {
        localStorage.clear();
        props.history.push("/login");
        handleCloseMenu();
    };

    const GoBackoffice = () => {
        props.history.push("/backoffice/users")
    }

    const GoBackofficeLite = () => {
        props.history.push("/backoffice-lite/export")
    }

    const GoCourses = () => {
        props.history.push("/corsi");
        handleCloseMenu();
    };

    const GoActivities = () => {
        props.history.push("/attivita");
        handleCloseMenu();
    };

    const GoProfile = () => {
        props.history.push("/profilo");
        handleCloseMenu();
    };

    if (props.verify) {
        return (

            <React.Fragment>
                <ul className={"nav " + (false ? "toggle-link" : "")}>
                    <li className="logo"></li>

                </ul>
                <div className="clear"></div>
            </React.Fragment>

        )
    } else {
        return (
            <React.Fragment>
                <ul className={"nav " + (false ? "toggle-link" : "")}>
                    <li style={{ backgroundImage: `url(${logoUrl})` }} className="logo"></li>
                    <li style={{ backgroundImage: `url(${brand_logo_nav})` }} className="logo-brand"><a target={"_blank"} href={Content?.brand_link} /> </li>
                    {window.location.pathname === "/login" || window.location.pathname === "/forgot-password" || window.location.pathname === "/password-recovery/" ?
                        null
                        :
                        <React.Fragment>
                            <li style={{ listStyleType: "none", marginRight: "20px" }}>
                                {localStorage.getItem("admin_fit_id") || localStorage.getItem("admin_lim_fit_id") ?
                                    <h3 style={{ color: "#999", fontWeight: "bold" }}>
                                        {props?.user?.first_name ? props?.user?.first_name + " " + props?.user?.last_name : <Skeleton style={{ width: "150px" }} />}
                                    </h3>
                                    : null}
                            </li>
                            <li style={{ listStyleType: "none", marginRight: "20px" }}>
                                <IconButton
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    onClick={handleopen}
                                >
                                    <Avatar src={props.user?.avatar?.thumbnail} style={{ width: "30px", height: "30px", color: "#999" }} />
                                </IconButton>
                            </li>
                        </React.Fragment>
                    }
                </ul>
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                >
                    {localStorage.getItem("admin_fit_id") &&
                        <MenuIcon
                            routeAction={GoCourses}
                            text={"I miei corsi"}
                            Icon={<BookOutlined fontSize="small" />}
                        />
                    }
                    {localStorage.getItem("admin_fit_id") &&
                    <MenuIcon
                        routeAction={GoActivities}
                        text={"Le mie attività"}
                        Icon={<BarChart fontSize="small" />}
                    />
                    }
                     {localStorage.getItem("admin_fit_id") &&
                    <MenuIcon
                        routeAction={GoProfile}
                        text={"Il mio profilo"}
                        Icon={<PermIdentity fontSize="small" />}
                    />
                    }

                    {localStorage.getItem("admin_fit_id") && <MenuIcon routeAction={GoBackoffice} text={"Backoffice"} Icon={<Dashboard fontSize="small" />} />}
                    {localStorage.getItem("admin_lim_fit_id") && <MenuIcon routeAction={GoBackofficeLite} text={"Backoffice"} Icon={<Dashboard fontSize="small" />} />}

                    <Divider />

                    <MenuIcon
                        routeAction={Logout}
                        text={"Logout"}
                        textColor={"red"}
                        Icon={<ExitToApp style={{ color: "red" }} fontSize="small" />}
                    />

                </Menu>
                <div className="clear"></div>
            </React.Fragment>
        )
    }

}////Nav

export default Navbar