import React, { useEffect, useState, useRef } from "react";
import SearchInput from "../componentsAdmin/Search/SearchInput"
import RoundButton from "../componentsAdmin/Button/RoundButton"
import TableGruppi from '../componentsAdmin/Table/TableGruppi';
import TransitionsModal from "../componentsAdmin/Modal/Modal"
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import serviceAdmin from '../services/serviceAdmin';
import Fade from '@material-ui/core/Fade';
import Alert from '../componentsAdmin/CollapseAlert/Alert';
import { listMenu } from '../classes'
import useColor from "../context/themeContext";



const Group = (props) => {
    const { COLORS } = useColor()
    const useStyles = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        ...listMenu(COLORS),
    }));
    const classes = useStyles();
    const isMount = useRef(true);

    const [searchString, setSearchString] = React.useState("")
    const [buttons, setButtons] = useState(false);
    const [newopen, setNewopen] = useState(false);
    const [open, setOpen] = useState(false);
    const [reload, setReload] = useState(false);
    const [confirm, setConfirm] = useState(false)
    const [group_id, setGroup_id] = useState(null)
    const [loading, setLoading] = useState(false)
    const [openAlert, setOpenAlert] = useState({});

    const setopen = (input) => {
        setOpen(input)
    }

    const setCustomAlert = (value, two_value, three_value) => {
        setOpenAlert({
            ...openAlert,
            "state": value,
            "severity": two_value,
            "message": three_value
        })
    };

    const setconfirm = (input, group) => {
        setConfirm(input)
        setGroup_id(group.id)
    }

    const setreload = () => { setReload(!reload) };
    const setbuttons = () => { setButtons(!buttons) };
    const setclosebuttons = () => { setButtons(false) };
    const setnewopen = (input) => { setNewopen(input) };

    useEffect(() => {
        let url = "v1/groups/";
        const timeSearch = setTimeout(() => {
            if (searchString !== '') url = `v1/groups/?search=${searchString}`;
            serviceAdmin.getService(url)
                .then(res => {
                    if (isMount.current) {

                        setLoading(true)
                        props.setGroups(res.data.data)

                    }
                }).catch(err => {
                    console.log(err)
                })
        }, 250)

        return () => {
            clearTimeout(timeSearch)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload, searchString, reload])

    useEffect(() => {
        if (confirm && group_id) {
            serviceAdmin.deleteService(`v1/groups/${group_id}/`)
                .then(res => {
                    if (isMount.current) {
                        setreload();
                        setCustomAlert(true, "success", "Gruppo eliminato con successo!");
                        setOpen(false);
                        setConfirm(false)
                        setGroup_id(null)
                    }
                }).catch(err => {
                    console.log(err)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [group_id])


    useEffect(() => {

        return () => {
            isMount.current = false;
        }
    }, [])

    useEffect(() => {
        setNewopen(false);
    }, [props.closeBack])

    return (
        <React.Fragment>
            <Alert
                setOpenAlert={setCustomAlert}
                severity={openAlert?.severity}
                openAlert={openAlert?.state}
                message={openAlert?.message}
            />

            {!newopen && !open ?
                <div style={{ display: "flex", alignItems: "center" }}>

                    <SearchInput searchString={searchString} placeholder={"Cerca tra i gruppi..."} onChange={event => setSearchString(event.target.value)} />
                    <RoundButton add={true} setbuttons={setbuttons} />
                </div>
                : null}

            {!newopen ?
                <Fade in={loading} timeout={{ appear: 100, enter: 300, exit: 300 }}>
                    <div style={!open ? { paddingTop: "50px", maxWidth: "100%" } : { maxWidth: "100%" }}>
                        <TableGruppi
                            open={open}
                            setOpen={setopen}
                            setCustomAlert={setCustomAlert}
                            groups={props.groups || []}
                            setConfirm={setconfirm}
                            setReload={setreload}
                            closeBack={props.closeBack}
                        />
                    </div>
                </Fade>
                : null}

            {newopen ?
                <TransitionsModal
                    setCustomAlert={setCustomAlert}
                    open={newopen}
                    setOpen={setnewopen}
                    isNewGroup={true}
                    setReload={setreload}
                />
                : null}


            <Backdrop className={classes.backdrop} open={buttons} onClick={setclosebuttons}>
                <ul className={classes.listMenu}>
                    <li onClick={() => setnewopen(true)}>Nuovo gruppo</li>
                </ul>
            </Backdrop>
        </React.Fragment>
    );


}

export default Group;
