import ClientRequest from "../../services/services";

const defaultTheme = {
    colors: {
        primary: "#e66b63"
    },
    headers: {
        title: "Senza titolo",
        description: "Senza descrizione"
    },
    font: {
        name: 'Lato',
        url: 'https://fonts.googleapis.com/css?family=Lato:400,600,700'
    }
}

async function fetchPersonalizationTheme() {
    const slug = process.env.REACT_APP_SLUG_THEME
    try {
        let response = await ClientRequest.getService(`v1/welcome/themes/${slug}/`)
        return response.data.data
    } catch (error) {
        console.log("[switchLayouts.fetchPersonalizationTheme]", error)
        return defaultTheme
    }
}

export default {
    fetchPersonalizationTheme
}