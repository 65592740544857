import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import useColor from '../../context/themeContext'
import { tabsStyle } from '../../classes'
import FullTextField from '../StyledComponent/FullTextField'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import DoneAllIcon from '@material-ui/icons/DoneAll'

export default function LinkAddMedia(props) {
  
  const { COLORS } = useColor()
  const useStyles = makeStyles((theme) => (tabsStyle(COLORS)));
  const classes = useStyles();

  const inputRef = useRef();
  const [video, setVideo] = useState(null)
  const [inputs, setInputs] = useState({})

  useEffect(() => {
    setInputs({ ...inputs, "type": "VIDEO" })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setInputs({ ...inputs, [name]: value })

  };


  const addMedia = () => {
    if (inputs.type === "VIDEO") {
      let vid = video
      vid.append("name", inputs.name || "");
      props.setMediaLoad(vid)
      props.handleClose();
    } else {
      let formData = new FormData();
      formData.append("name", inputs.name);
      formData.append("type", inputs.type);
      formData.append("link", inputs.link);
      props.setMediaLoad(formData)
      props.handleClose();
    }
  }

  const setVideoRef = () => {
    inputRef.current.click()
  }

  //////VIDEO SELECT
  function videoSelected(event) {
    let formData = new FormData();
    let videotypes = ["video/mp4", "video/webm", "video/ogg"]
    const isVideo = file => videotypes.some(s => file["type"].includes(s))
    let input = event.target;
    if (input.files[0]) {
      if (isVideo(input.files[0])) {
        formData.append("file", input.files[0])
        formData.append("type", inputs.type || "")
        setVideo(formData)
      } else {
        props.setCustomAlert(true, "Formato risorsa errato prova con i seguenti formati: video/mp4, video/webm, video/ogg", "warning");
      }
    }
  }

  return (

    <div style={{ padding: "30px 20px" }}>

      <Grid container spacing={3}>

        <Grid item xs={6}>
          <InputLabel className={classes.label}>
            Nome Video
            </InputLabel>
          <FullTextField name="name" value={inputs.name || ""} onChange={handleChange} placeholder="Nome del media" variant="outlined" inputProps={{ className: classes.input }} />
        </Grid>

        <Grid item xs={6}>
          <InputLabel className={classes.label}>
            Tipo di video
            </InputLabel>
          <Select name="type" value={inputs.type || ""} onChange={handleChange} variant="outlined" className={classes.select}>

            <MenuItem className={classes.selected} value="YT"> YouTube </MenuItem>
            <MenuItem className={classes.selected} value="VIMEO"> Vimeo </MenuItem>
            <MenuItem className={classes.selected} value="VIDEO"> File locale </MenuItem>
          </Select>
        </Grid>

        {inputs.type === "VIDEO" ?
          <Grid item xs={12} lg={6} style={{ marginTop: "20px" }}>
            <InputLabel className={classes.label}>
              Video
            </InputLabel>
            <div onClick={setVideoRef} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
              <input accept="video/mp4, video/webm, video/ogg" ref={inputRef} style={{ visibility: "hidden", width: "1px" }} type="file" onChange={videoSelected} />
              {video || props.mediaType?.type === "VIDEO" ? "Cambia" : "Aggiungi"} video
              {!video ?
                <AddCircleIcon className={classes.color} style={{ cursor: "pointer", paddingLeft: "10px", width: "40px", height: "40px" }} />
                :
                <DoneAllIcon style={{ paddingLeft: "10px", width: "40px", height: "40px" }} className={classes.color} />
              }
            </div>
          </Grid>
          :
          <Grid item xs={12} lg={6} style={{ marginTop: "20px" }}>

            <InputLabel className={classes.label}>
              Link
          </InputLabel>
            <FullTextField name="link" value={inputs.link || ""} onChange={handleChange} placeholder="Link della lezione" variant="outlined" inputProps={{ className: classes.input_link }} />

          </Grid>
        }

        <Grid item xs={12} style={{ height: "36px" }}></Grid>

      </Grid>

      <Paper elevation={3} className={classes.save_modal}>

        <Button onClick={props.handleClose} className={classes.btn_del}>Annulla</Button>
        <Button disabled={inputs.name && inputs.type && (inputs.link || video) ? false : true} onClick={addMedia} className={classes.btn_save}>Salva</Button>

      </Paper>

    </div>
  );
}