import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableContainer, TablePagination, Paper, Backdrop, CircularProgress } from '@material-ui/core';
import TransitionModal from "../Modal/Modal"
import { useEffect } from 'react';
import useColor from '../../context/themeContext'
import EnhancedTableHead from './componentTable/EnhancedTableHead'
import EnhancedTableToolbar from './componentTable/EnhancedTableToolbar'
import { tableStyle } from '../../classes'
import CustomTableRow from './componentTable/CustomTableRow'
import moment from 'moment'
import 'moment/locale/it';

moment.locale('it')

export default function CustomTable(props) {
  const { COLORS } = useColor();
  const useStyles = makeStyles((theme) => (tableStyle(COLORS)));
  const classes = useStyles();


  const [selected, setSelected] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [rowDetail, setRowDetail] = React.useState({});
  const [value, setValue] = React.useState(null);
  const [rows, setRows] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null)

  const openExport = (e) => { if (anchorEl !== e.currentTarget) setAnchorEl(e.currentTarget); }
  const closeExport = () => { setAnchorEl(null) }
  const removeSelected = () => { setSelected([]); }

  useEffect(() => {
    props.setSelected(selected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  useEffect(() => {

    return () => {
      props.setAll(false)
      setSelected([])
      props.setSelected([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    ////SET TABLE ROWS
    setRows(props.rows)
  }, [props.rows])


  useEffect(() => {
    props.setRowsPage(rowsPerPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage])

  const setopen = (input) => { props.setOpen(input) }

  const handleDetail = (e, row) => {
    setRowDetail(row);
    e.stopPropagation();
    setValue(0);
    setopen(true);
  }

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.reduce((result, row) => {
        if (row.id !== undefined) {
          result.push(row.id)
        }
        return result;
      }, [])
      const allSelected = newSelecteds.concat(selected);
      const filterSelected = allSelected.filter(onlyUnique)
      setSelected(filterSelected);
      return;
    } else {
      const newSelecteds = rows.reduce((result, row) => {
        if (row.id !== undefined) {
          result.push(row.id)
        }
        return result;
      }, [])
      const filterSelected = selected.filter((el) => !newSelecteds.includes(el));
      setSelected(filterSelected)
    }
  };

  const handleCheck = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    props.setPageCount(newPage + 1)
    props.setBackdrop(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    props.setPage(0);
    props.setBackdrop(true);
  };

  return (
    <div className={classes.root}>
      {!props.open ?
        <Paper className={props.noPaper ? classes.no_paper : classes.paper}>
          {selected.length > 0 &&
            <EnhancedTableToolbar
              {...props}
              allusers={rows.length}
              numSelected={selected.length}
              removeSelected={removeSelected}
              anchorEl={anchorEl}
              openExport={openExport}
              closeExport={closeExport}
              labelSing={"utente"}
              labelPlu={"utenti"}
              labelArt={"gli utenti"}
              exportTable={props.exportTable}
            />
          }
          <TableContainer>

            <Backdrop style={{ zIndex: "2", bottom: "72px", position: "absolute", borderRadius: "20px", backgroundColor: "#fff", opacity: "0.4" }} open={props.backdrop}>
              <CircularProgress style={{ color: COLORS.primary }} />
            </Backdrop>

            <Table
              className={classes.table}
              size={'small'}
            >
              <EnhancedTableHead
                {...props}
                classes={classes}
                onSelectAllClick={handleSelectAllClick}
                rows={rows.map(row => row.id)}
                selected={selected}
                headCells={props.headCells}
              />
              <TableBody>
                {rows.map((row, index) => {
                  return (
                    <CustomTableRow
                      {...props}
                      key={index}
                      selected={selected}
                      headCells={props.headCells}
                      row={row}
                      handleDetail={handleDetail}
                      handleCheck={handleCheck}
                    />
                  );
                })}

              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[20, 30, 40]}
              component="div"
              count={props.count}
              rowsPerPage={rowsPerPage}
              page={props.page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              classes={{ menuItem: classes.selected }}
              labelRowsPerPage={"Righe per pagina: "}
            />
          </TableContainer>

        </Paper>
        :
        <TransitionModal
          {...props}
          setCustomAlert={props.setCustomAlert}
          courses={props.courses}
          rowDetail={rowDetail}
          open={props.open}
          setOpen={setopen}
          value={value}
          setValue={setValue}
          changeReloadUser={props.changeReloadUser}
        />
      }
    </div>
  );
}
