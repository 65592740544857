import React, { useEffect } from 'react';
import Progress from './Progress';
import OpenQuestion from './OpenQuestion';
import Single from './Single';
import Multiple from './Multiple';
import Likert from './Likert';
import Result from './Result';
import services from '../../services/services';
import StartSurvey from './StartSurvey';
import MaxRetries from './MaxRetries';
import socketClient from '../../services/socketClient'
import config from "../../config"


const Test = (props) => {

    const { is_preview, handleResize } = props;


    const [survey, setSurvey] = React.useState({})
    const [registry, setRegistry] = React.useState(null)
    const [percentage, setPercentage] = React.useState(0)
    const [activeStep, setActiveStep] = React.useState(0);
    const [left_tries, setLeftTries] = React.useState(1)
    const [singleLayout, setSingleLayout] = React.useState(false);
    const [response, setResponse] = React.useState(false);
    const [start, setStart] = React.useState(false);
    const [finish, setFinish] = React.useState(false);
    const [prevResponse, setPrevResponse] = React.useState({});
    const [allresponse, setAllResponse] = React.useState({});
    const [data, setData] = React.useState({});
    const [socket, setSocket] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const [is_passed, setIsPassed] = React.useState(false);

    const setfinish = () => {
        if (is_preview) {
            return;
        }
        const questions = Object.keys(data)
            .map(key => {

                if (typeof (data[key]) === "string") {
                    return {
                        "question_id": Number(key),
                        "answer_text": data[key]
                    }
                } else if (Array.isArray(data[key])) {
                    return {
                        "question_id": Number(key),
                        "answers_multiple_ids": data[key]
                    }
                } else {
                    return {
                        "question_id": Number(key),
                        "answer_id": data[key]
                    }
                }
            })

        let params = {
            "questions": questions
        }

        if (props.category === "SURVEY") {
            params.survey_id = survey.id
        } else {
            params.evaluation_id = survey.id
        }


        const type = props.category === "EVALUATION" ? 'evaluation-registry' : 'survey-registry';

        services.postService(`v1/users/me/course-modules/${props.module_id}/course-sections/${props.lesson_id}/${type}/`, params)
            .then(res => {
                if (res.status === 201) {
                    const resource = res.data.data;

                    if (props.category === "EVALUATION") {
                        setPercentage(resource?.percentage)
                        setLeftTries(resource?.left_tries)
                        setRegistry(resource)
                    }
                    setIsPassed(resource.is_passed)
                    setFinish(true)
                    socketClient.asyncDisconnect(socket, props.setReloadComplete())


                }
            }).catch(err => {
                console.log(err)
            })

    }

    const setRestart = () => {
        setData({})
        setActiveStep(0)
        setResponse(false)
        setPrevResponse({})
        setAllResponse({})
        setStart(false)
        setFinish(false)
    }

    const startSurvey = () => {
        setStart(true)
    }

    const setallresponse = (value, index) => {
        setAllResponse((prev) => {
            return { ...prev, [index]: value }
        })
    }

    const setdata = (input) => { setData(input) }

    const setactivestep = (input) => { setActiveStep(input) }

    const setresponse = (input) => { setResponse(input) }

    const setprevresponse = (input) => { setPrevResponse(input) }

    const justFinish = () => {
        startSurvey();
        setFinish(true);
    }

    const buildSocket = () => {
        return socketClient.asyncInit({ baseUrl: config.WS_URL, authToken: localStorage.getItem("bearer_fit_token"), lessonId: props.lesson_id }, (socket) => { })
            .then(res => {
                socketClient.asyncReadMessage(res)
                    .then(msg => {
                        if (!msg.meta.error) {
                            setSocket(res)
                        }
                    })
                return res
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        console.log("ASDS: ", props)
        setRestart()
        if (!is_preview) {
            buildSocket()
        }
        setIsPassed(props.is_passed)
        const type = props.category === "EVALUATION" ? 'evaluation' : 'survey';
        const ev_url = is_preview ?
            `v1/preview/course-sections/${props.lesson_id}/${type}/`
            :
            `v1/users/me/course-modules/${props.module_id}/course-sections/${props.lesson_id}/${type}/`;

        if (!props.is_passed) {

            services.getService(ev_url)
                .then(res => {
                    const resource = res.data.data;
                    if (resource) {
                        setSurvey(resource)
                        if (props.category === "EVALUATION") {
                            setLeftTries(resource?.left_tries)
                        }
                        setSingleLayout(!resource.is_sequential)
                        setLoading(true)
                        handleResize()
                    }
                }).catch(err => {
                    console.log(err)
                })
        } else {
            justFinish();
            if (props.category === "EVALUATION") {
                services.getService(ev_url)
                    .then(res => {
                        const resource = res.data.data;
                        if (resource) {
                            setSurvey(resource)
                            setLeftTries(resource?.left_tries)
                            setSingleLayout(!resource.is_sequential)
                            handleResize()
                        }
                    }).catch(err => {
                        console.log(err)
                    })
                    
                services.getService(`v1/users/me/course-modules/${props.module_id}/course-sections/${props.lesson_id}/${type}-registry/`)
                    .then(res => {
                        const resource = res.data.data;
                        if (resource) {
                            setRegistry(resource)
                            setPercentage(resource?.percentage)
                            setLoading(true)
                        }
                    }).catch(err => {
                        console.log(err)
                    })
            }

        }

        return () => {
            if (socket?.readyState) {
                socketClient.asyncDisconnect(socket, props.setReloadComplete())
            }
        }
    }, [props.lesson_id])

    useEffect(() => {

        window.onbeforeunload = () => {
            socketClient.asyncDisconnect(socket, props.setReloadComplete())
        };

        return () => { setLoading(false) }
    }, [])


    const getKey = (e) => {
        if (e.key === "Enter" && activeStep < Object.keys(survey.questions).length - 1) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
        }
    }

    return (
        <React.Fragment>
            {!start ?
                <div style={{ width: "100%" }}>
                    {(left_tries === 0 && props.category === "EVALUATION")
                        ?
                        <MaxRetries
                            loading={loading}
                            lesson_id={props.lesson_id}
                            has_button={props.has_button}
                            next={props.next}
                            module_id={props.module_id}
                            setLesson={props.setLesson}
                            left_tries={left_tries}
                            is_passed={is_passed}
                            setRestart={setRestart}
                        />
                        :
                        <StartSurvey
                            loading={loading}
                            next={props.next}
                            survey_name={survey.name}
                            startSurvey={startSurvey}
                            setLesson={props.setLesson}
                            module_id={props.module_id}
                            category={props.category}
                            lesson_id={props.lesson_id}
                        />

                    }

                </div>
                :

                <React.Fragment>
                    {finish ?
                        <div style={{ width: "100%" }}>
                            <Result
                                handleResize={handleResize}
                                registry={registry}
                                next={props.next}
                                setLesson={props.setLesson}
                                module_id={props.module_id}
                                lesson_id={props.lesson_id}
                                correct={2}
                                wrong={2}
                                has_button={props.has_button}
                                category={props.category}
                                percentage={percentage}
                                is_passed={is_passed}
                                is_mandatory={props.is_mandatory}
                                mandatory_section_id={props.mandatory_section_id}
                                is_propedeutic_to_others={props.is_propedeutic_to_others}
                                left_tries={left_tries}
                                setRestart={setRestart}
                            />
                        </div>
                        :
                        <div style={{ width: "100%" }}>
                            {survey ? Object.keys(survey.questions).map((question, index) => {
                                const questions = survey.questions;

                                switch (questions[question].question_config) {

                                    case "open":
                                        if ((index === activeStep) || singleLayout) return (

                                            <OpenQuestion
                                                setData={setdata}
                                                data={data}
                                                type={questions[question].question_config}
                                                singleLayout={singleLayout}
                                                key={index}
                                                index={String(questions[question].id)}
                                                setResponse={setresponse}
                                                setPrevResponse={setprevresponse}
                                                setallresponse={setallresponse}
                                                prevResponse={prevResponse}
                                                question={questions[question].text}
                                                getKey={getKey}
                                            />

                                        )
                                        break;
                                    case "multiple":
                                        if ((index === activeStep) || singleLayout) return (

                                            <Multiple
                                                setData={setdata}
                                                data={data}
                                                type={questions[question].question_config}
                                                key={index}
                                                index={String(questions[question].id)}
                                                setResponse={setresponse}
                                                setPrevResponse={setprevresponse}
                                                setallresponse={setallresponse}
                                                prevResponse={prevResponse}
                                                text={questions[question].text}
                                                answers={questions[question].answers}
                                                getKey={getKey}
                                                category={props.category}
                                                correct_answers_num={questions[question].correct_answers_num}
                                            />

                                        )
                                        break;
                                    case "single":
                                        if ((index === activeStep) || singleLayout) return (

                                            <Single
                                                setData={setdata}
                                                data={data}
                                                type={questions[question].question_config}
                                                key={index}
                                                index={String(questions[question].id)}
                                                setResponse={setresponse}
                                                setPrevResponse={setprevresponse}
                                                setallresponse={setallresponse}
                                                prevResponse={prevResponse}
                                                question={questions[question].text}
                                                answers={questions[question].answers}
                                                getKey={getKey}
                                            />

                                        )
                                        break;
                                    case "likert":
                                        if ((index === activeStep) || singleLayout) return (

                                            <Likert
                                                question={questions[question]?.text}
                                                setData={setdata}
                                                data={data}
                                                type={questions[question].question_config}
                                                key={index}
                                                index={String(questions[question].id)}
                                                setResponse={setresponse}
                                                setPrevResponse={setprevresponse}
                                                setallresponse={setallresponse}
                                                prevResponse={prevResponse}
                                                questions={questions[question].questions}
                                                answers={questions[question].answers}
                                                getKey={getKey}
                                            />

                                        )
                                        break;
                                    default:
                                        break;
                                }
                            }) : null}
                        </div>
                    }

                    <Progress
                        is_preview={is_preview}
                        questions={survey.questions}
                        allchecked={allresponse}
                        singleLayout={singleLayout}
                        steps={(survey.questions ? Object.keys(survey?.questions)?.length : 0)}
                        activeStep={activeStep}
                        setActiveStep={setactivestep}
                        response={response}
                        setFinish={setfinish}
                        finish={finish}
                    />

                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default Test;