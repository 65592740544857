import React, { useState, useEffect } from 'react';
import Sidebar from '../componentsAdmin/Sidebar/Sidebar';
import BackofficeLiteRoute from '../backofficeLiteRoutes';
import serviceAdmin from '../services/serviceAdmin';
import Alert from '../componentsAdmin/CollapseAlert/Alert';

const LiteLayout = (props) => {
  const [state, setState] = useState({ "left": true })
  const [closeBack, setcloseBack] = useState(false)
  const [openAlert, setOpenAlert] = useState({});
  const [me, setMe] = useState(null)

  useEffect(() => {
    serviceAdmin.getService("/v1/users/me/")
      .then(res => {
        if (res?.data?.data) {
          setMe(res?.data?.data)
        }
      }).catch(err => {
        console.log(err)
      })
  }, [])

  const setcloseback = () => { setcloseBack(!closeBack) }

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, "left": open });
  };

  const setCustomAlert = (value, two_value, three_value) => {
    setOpenAlert({
      ...openAlert,
      "state": value,
      "severity": two_value,
      "message": three_value
    })
  };

  return (

    <div className="container-admin">

      <Sidebar setCloseBack={setcloseback} userMe={me} noAdmin={true} state={state} toggleDrawer={toggleDrawer} history={props.hist} {...props} />

      <div className="content-admin" style={state.left ? { paddingLeft: "152px" } : { paddingLeft: "50px" }}>
        
        <Alert
          setOpenAlert={setCustomAlert}
          severity={openAlert?.severity}
          openAlert={openAlert?.state}
          message={openAlert?.message}
          noAutoHide
        />

        <BackofficeLiteRoute
          setCustomAlert={setCustomAlert}
          history={props.hist}
          closeBack={closeBack}
        />

      </div>

    </div>
  );
}

export default LiteLayout;