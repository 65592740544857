import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'


const TextAreaField = withStyles({
	
	root: {
			border: "none",
			width: "100%",
			'& .MuiInput-underline:after': {
					border: 'none',
			},
			'& .MuiOutlinedInput-root': {
					'& fieldset': {
							border: 'none',
					},
					'&:hover fieldset': {
							borderColor: 'yellow',
					},
					'&.Mui-focused fieldset': {
							borderColor: 'green',
					},
			},
	},
})(TextField);


export default TextAreaField;
