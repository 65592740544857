import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
    InputLabel, Paper, Grid, Select,
    MenuItem, ButtonGroup, Button, IconButton, InputAdornment
} from '@material-ui/core/'
import FromDate from '../Export/ExportFilter/FromDate'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import useColor from '../../context/themeContext'
import serviceAdmin from '../../services/serviceAdmin'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import InfoIcon from '@material-ui/icons/Info'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import AntSwitch from '../Switch/AntSwitch'
import { tabsStyle } from '../../classes'
import CssTextField from '../StyledComponent/CssTextField'
import CustomTooltip from '../StyledComponent/CustomTooltip'





const TabNewUser = (props) => {
    const { COLORS } = useColor();
    const useStyles = makeStyles(tabsStyle(COLORS));
    const classes = useStyles();

    const inputImageRef = useRef();

    const [input, setInput] = useState({})
    const [inputc, setInputC] = useState({})
    const [expiration, setExpiration] = useState({})
    const [checkedActive, setcheckedActive] = useState(false)
    const [checked, setChecked] = useState(false)
    const [image, setImage] = useState()
    const [roles, setRoles] = useState([])
    const [role, setRole] = useState(null)
    const [groups_ids, setGroups_ids] = useState([])
    const [groups, setGroups] = useState([])
    const [conflict, setConflict] = useState(false)
    const [passwordConflict, setPasswordConflict] = useState(false)
    const [passwordConfirm, setPasswordConfirm] = useState(false)
    const [emailConflict, setEmailConflict] = useState(false)
    const [values, setValues] = useState({ showPassword: false, showPasswordC: false });
    const [timeUpload, setTimeUpload] = useState(null);
    const [anchorEl, setAnchorEl] = useState(false)

    const open = () => {
        setAnchorEl(true)
    }
    const close = () => {
        setAnchorEl(false)
    }

    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    const setchecked = () => { setChecked(!checked) }

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (!regex.test(input.password) && input.password) {
                setPasswordConflict(true)
            } else {
                setPasswordConflict(false)
            }
            if (input.password !== inputc.passwordC) {
                setPasswordConfirm(true)
            } else {
                setPasswordConfirm(false)
            }
        }, 200)
        return () => {
            clearTimeout(timeout)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputc.passwordC, input.password])

    const options = {
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total)
            if (image) {
                setTimeUpload(`Upload immagine ${percent}%`);
            }
        }
    }

    const pastePasswordC = (e) => {
        e.preventDefault();
        props.setCustomAlert(true, "warning", "La funzione 'incolla' è disabilitata");
        return false;
    }

    const pastePassword = (e) => {
        e.preventDefault();
        props.setCustomAlert(true, "warning", "La funzione 'incolla' è disabilitata");
        return false;
    }

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleClickShowPasswordC = () => {
        setValues({ ...values, showPasswordC: !values.showPasswordC });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const setCheckedActive = (input) => { setcheckedActive(input) }

    const disableExpiration = () => {
        setExpiration({ "expiration_date": null })
    }

    const changeInput = (e) => {
        const name = e.target.name;

        if (name === "first_name" || name === "last_name" || name === "fiscal_code" || name === "email") {
            const value = e.target.value.toUpperCase();
            setInput({ ...input, [name]: value })
        } else {
            const value = e.target.value;
            setInput({ ...input, [name]: value })
        }
    }

    const changeRole = (e) => {
        setRole(e.target.value)
    }

    const changeExpiration = (value) => {
        const name = "expiration_date";
       
        setExpiration({ [name]: value });
    }

    const changeInputC = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setInputC({ ...input, [name]: value })
    }

    const setImageRef = () => {
        inputImageRef.current.click()
    }

    useEffect(() => {
        serviceAdmin.getService("v1/roles/")
            .then(res => {
                setRoles(res.data.data);
            }).catch(err => {
                console.log(err);
            })

        serviceAdmin.getService("v1/groups/")
            .then(res => {
                setGroups(res.data.data);
            }).catch(err => {
                console.log(err);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    ////////IMAGE SELECT

    function imageSelected(event) {
        const isImage = file => file["type"].includes("image")
        let input = event.target
        if (input.files && input.files[0]) {
            if (isImage(input.files[0])) {
                let reader = new FileReader()
                reader.onload = e => {
                    setImage(e.target.result)
                }
                reader.readAsDataURL(input.files[[0]])
            } else {
                alert('foto non caricata')
            }
        }
    }
    function clean(obj) {
        for (let propName in obj) {
            if (obj[propName] === "" || obj[propName] === undefined) {
                delete obj[propName];
            }
        }
    }


    const updateUser = () => {
        let params;

        if (role?.tier === "BASE_LOG" || role?.tier === "BO_LIM" || role?.tier === "BO_COM") {
            params = {
                ...input,
                "avatar": image,
                "role_id": role?.id,
                "is_active": checkedActive,
                "expiration_date": expiration?.expiration_date ? expiration?.expiration_date : null,
                "groups_ids": groups_ids,
                "tmp_password": checked
            }
        } else {
            params = {
                ...input,
                "avatar": image,
                "role_id": role?.id,
                "is_active": checkedActive,
                "expiration_date": expiration?.expiration_date ? expiration?.expiration_date : null,
                "groups_ids": groups_ids
            }
        }
        clean(params)

        serviceAdmin.postService('v1/users/', params, options)
            .then(res => {
                if (res.status === 201) {
                    props.setCustomAlert(true, "success", "Utente creato con successo!")
                    setConflict(false);
                    setPasswordConflict(false);
                    setEmailConflict(false);
                    props.setReload();
                    props.handleClose();
                }
            }).catch(err => {
                if (err.status === 409 || err.status === 400) {
                    if (err.data.reason.includes("User")) {
                        setConflict(true)
                        props.setCustomAlert(true, "warning", "L'username scelto è gia in uso!");
                    } else if (err.data.reason.includes("password")) {
                        setPasswordConflict(true)
                        props.setCustomAlert(true, "warning", "La password inserita non è corretta!");
                    } else if (err.data.reason.includes("email")) {
                        setEmailConflict(true)
                        props.setCustomAlert(true, "warning", "L'email inserita potrebbe essere già in uso!");
                    }
                } else {
                    setConflict(false)
                    setPasswordConflict(false)
                    setEmailConflict(false)
                }
            })
    }

    return (
        <React.Fragment>
            <h2 className={classes.title}>Crea un nuovo utente</h2>
            <Grid container spacing={3} style={{ paddingTop: "30px" }}>

                <Grid className="flex-center" item xs={12} md={6} lg={3}>
                    <InputLabel className={classes.label}>
                        Username*
                    </InputLabel>
                    <CssTextField autoComplete="new-user" error={conflict ? true : false} helperText={conflict ? "L'username inserito potrebbe essere già in uso" : ""} name="username" value={input.username || ""} onChange={changeInput} variant="outlined" inputProps={{ className: classes.input }} />
                </Grid>
                <Grid className="flex-center" item xs={12} md={6} lg={3}>
                    <InputLabel className={classes.label}>
                        Ruolo*
                    </InputLabel>
                    <Select value={role || ""} onChange={changeRole} variant="outlined" className={classes.select}>
                        {
                            roles?.map((role, index) => {
                                return <MenuItem className={classes.selected} key={index} value={role}>{role.name}</MenuItem>
                            })
                        }

                    </Select>
                </Grid>
                <Grid className="flex-center" item xs={12} md={6} lg={3}>
                    <InputLabel className={classes.label}>
                        Email*
                    </InputLabel>
                    <CssTextField error={emailConflict ? true : false} helperText={emailConflict ? "L'email inserita potrebbe essere già in uso" : ""} name="email" value={input.email || ""} onChange={changeInput} variant="outlined" inputProps={{ className: classes.input }} />
                </Grid>
                <Grid className="flex-center" item xs={12} md={6} lg={3}>
                    <InputLabel className={classes.label}>
                        Codice Fiscale*
                    </InputLabel>
                    <CssTextField name="fiscal_code" value={input.fiscal_code || ""} onChange={changeInput} variant="outlined" inputProps={{ className: classes.input }} />
                </Grid>
            </Grid>

            <Grid container spacing={3} style={{ paddingTop: "10px" }}>
                <Grid className="flex-center" item xs={12} md={6} lg={3}>
                    <InputLabel className={classes.label}>
                        Nome*
                    </InputLabel>
                    <CssTextField name="first_name" value={input.first_name || ""} onChange={changeInput} variant="outlined" inputProps={{ className: classes.input }} />
                </Grid>
                <Grid className="flex-center" item xs={12} md={6} lg={3}>
                    <InputLabel className={classes.label}>
                        Cognome*
                    </InputLabel>
                    <CssTextField name="last_name" value={input.last_name || ""} onChange={changeInput} variant="outlined" inputProps={{ className: classes.input }} />
                </Grid>
                <Grid className="flex-center" item xs={12} md={6} lg={3}>

                    <InputLabel className={classes.label}>
                        Password

                        <CustomTooltip
                            title="La password deve contenere almeno 8 caratteri tra cui almeno una maiuscola, un numero ed un carattere speciale"
                        >
                            <InfoIcon style={{ fontSize: "19px" }} />
                        </CustomTooltip>

                    </InputLabel>
                    <CssTextField
                        onPaste={pastePassword}
                        autoComplete="new-password"
                        variant="outlined"
                        type={values.showPassword ? 'text' : 'password'}
                        error={passwordConflict ? true : false}
                        helperText={passwordConflict ? "Password inserita non valida" : ""}
                        name="password"
                        value={input.password || ""}
                        onChange={changeInput}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                            className: classes.input_show
                        }}
                    />

                </Grid>
                <Grid className="flex-center" item xs={12} md={6} lg={3}>
                    <InputLabel className={classes.label}>
                        Conferma Password
                    </InputLabel>
                    <CssTextField
                        onPaste={pastePasswordC}
                        variant="outlined"
                        type={values.showPasswordC ? 'text' : 'password'}
                        error={passwordConfirm ? true : false}
                        helperText={passwordConfirm ? "Le password non corrispondono" : ""}
                        name="passwordC"
                        value={inputc.passwordC || ""}
                        onChange={changeInputC}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPasswordC}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {values.showPasswordC ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                            className: classes.input_show
                        }}
                    />
                </Grid>
                <Grid className="flex-center" item xs={12} md={6} lg={3}>

                    <InputLabel className={classes.label}>
                        Avatar
                    </InputLabel>
                    <div onClick={setImageRef} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                        {!timeUpload ?
                            <React.Fragment>
                                <input ref={inputImageRef} style={{ visibility: "hidden", width: "1px" }} type="file" onChange={imageSelected} />
                                {image ? "Cambia" : "Aggiungi"} Avatar
                                {!image ?
                                    <AddCircleIcon className={classes.color} style={{ cursor: "pointer", paddingLeft: "10px", width: "40px", height: "40px" }} />
                                    :
                                    <DoneAllIcon style={{ paddingLeft: "10px", width: "40px", height: "40px" }} className={classes.color} />
                                }
                            </React.Fragment>
                            :
                            <p>
                                {timeUpload}
                            </p>
                        }
                    </div>
                </Grid>
                <Grid className="flex-center" item xs={12} md={6} lg={3}>
                    <InputLabel className={classes.label}>
                        Gruppi
                    </InputLabel>
                    <Select
                        MenuProps={{ getContentAnchorEl: () => null }}
                        onClick={open}
                        multiple
                        open={Boolean(anchorEl)}
                        onClose={close}
                        value={groups_ids || []}
                        onChange={(e) => setGroups_ids(e.target.value)}
                        variant="outlined"
                        className={classes.select}>
                        {
                            groups?.map((group, index) => {
                                return <MenuItem className={classes.selected} key={index} value={group.id}>{group.name}</MenuItem>
                            })
                        }

                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button onClick={(e) => { e.preventDefault(); e.stopPropagation(); close(); }} style={{ marginRight: "5px", color: COLORS.primary }}>
                                ok
                            </Button>
                        </div>

                    </Select>
                </Grid>
            </Grid>


            <Grid container spacing={3} style={{ paddingTop: "50px", paddingBottom: "100px" }}>
                <Grid className="flex-center" item xs={12} md={6} lg={3}>
                    <InputLabel className={classes.label}>
                        Stato
                    </InputLabel>

                    <ButtonGroup variant="contained" color="primary">
                        <Button style={!checkedActive ? { background: "grey" } : { background: COLORS.primary }} onClick={() => setCheckedActive(true)}>Attivo</Button>
                        <Button style={checkedActive ? { background: "grey" } : { background: COLORS.primary }} onClick={() => setCheckedActive(false)}>Disattivo</Button>
                    </ButtonGroup>
                </Grid>

                <Grid className="flex-center" style={{ minWidth: "260px" }} item xs={12} md={6} lg={3}>

                    <InputLabel className={classes.label}>
                        Scadenza
                    </InputLabel>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <FromDate
                            inputProps={`${classes.input}`}
                            value={expiration.expiration_date}
                            handleChange={changeExpiration}
                        />
                        <IconButton onClick={disableExpiration}>
                            <HighlightOffIcon />
                        </IconButton>
                    </div>
                </Grid>


                {(role?.tier === "BASE_LOG" || role?.tier === "BO_LIM" || role?.tier === "BO_COM") &&
                    <Grid className="flex-center" item xs={12} md={6} lg={3}>
                        <InputLabel className={classes.label}>
                            Cambio password al primo accesso
                            <CustomTooltip
                                title="Al primo accesso sarà richiesto il cambio password"
                            >
                                <InfoIcon style={{ fontSize: "19px" }} />
                            </CustomTooltip>
                        </InputLabel>
                        <AntSwitch
                            checked={checked}
                            setChecked={setchecked}
                        />
                    </Grid>
                }

            </Grid>
            <Paper elevation={3} className={classes.save}>
                <Button onClick={props.handleClose} className={classes.btn_del}>Annulla</Button>
                <Button disabled={(regex.test(input.password) || (input.password === "" || input.password === undefined || input.password === null)) && role && input.username && input.first_name && input.last_name && input.fiscal_code ? false : true} onClick={updateUser} className={classes.btn_save}>Salva</Button>
            </Paper>
        </React.Fragment>
    )
}

export default TabNewUser;