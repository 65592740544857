import React from 'react'
import { MenuItem, ListItemIcon } from '@material-ui/core'
import clsx from 'clsx';

const SideItem = (props) => {
	

	const { selected, setLink, value, Icon, classes, text } = props

	return (
		<MenuItem onClick={setLink} selected={selected === value} className={"listItem " + clsx(classes.listItem)}>
			<ListItemIcon className={"list"}>
				{Icon ? Icon : null}
				<h3 className={"listText"}>{text}</h3>
			</ListItemIcon>
		</MenuItem>

	)
}

export default SideItem