import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, Table, TableContainer, TableBody, TableCell, TableRow, TablePagination } from '@material-ui/core/';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import useColor from '../../context/themeContext'
import DeleteDialog from '../Dialog/DeleteDialog';
import Avatar from '@material-ui/core/Avatar';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EnhancedTableHead from './componentTable/EnhancedTableHead'
import EnhancedTableToolbar from './componentTable/EnhancedTableToolbar'
import moment from 'moment'
import { tableStyle } from '../../classes'
import 'moment/locale/it';
moment.locale('it')




const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Nome corso', class: "" },
  { id: 'code', numeric: false, disablePadding: false, label: 'Codice corso', class: "" },
  { id: 'thumb', numeric: false, disablePadding: false, label: 'Thumbnail', class: "hide-column-md " },
  { id: 'category', numeric: false, disablePadding: false, label: 'Categoria', class: "hide-column-md " },
  { id: 'sub_category', numeric: false, disablePadding: false, label: 'Sottocategoria', class: "hide-column-md " },
  { id: 'state', numeric: false, disablePadding: false, label: 'Stato', class: "hide-column-xs " },
  { id: 'mandatory', numeric: false, disablePadding: false, label: 'Obbligatorio', class: "hide-column-md " },
  { id: 'created', numeric: false, disablePadding: false, label: 'Data creazione', class: "hide-column-md " },
  { id: 'sub', numeric: false, disablePadding: false, label: 'Iscrizioni totali', class: "hide-column-xxs " },
];

export default function TableCorsi(props) {
  const { COLORS } = useColor();
  const useStyles = makeStyles(tableStyle(COLORS));
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [deleteCourse, setDelete] = useState(false);
  const [course, setCourse] = useState({});
  const [selected, setSelected] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)

  const openExport = (e) => { if (anchorEl !== e.currentTarget) setAnchorEl(e.currentTarget); }
  const closeExport = () => { setAnchorEl(null) }
  const removeSelected = () => { setSelected([]); }

  useEffect(() => {
    props.setSelected(selected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  useEffect(() => {
    if (props.courses.length >= 0) {
      setRows(
        props.courses.map((course) => {
          return {
            "created": moment(course.created).format('DD-MM-YYYY'),
            "is_mandatory": course.is_mandatory,
            "subscriptions_count": course.subscriptions_count,
            "code": course.code,
            "thumbnail": course?.thumbnail?.thumbnail,
            "slug": course.slug,
            "name": course.name,
            "state": course.status,
            "id": course.id,
            "parent_category": course.category?.parent ? course.category.parent.name : course.category?.name,
            "category": course.category?.parent ? course.category?.name : null,
          }
        })
      )

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.courses])



  const setdelete = (input) => { setDelete(input) }
  const setopen = (input) => { props.setOpen(input) }



  const handleopenCourse = (e, row) => {
    props.setCourse(row);
    setCourse(row);
    e.stopPropagation();
    props.setValue(0);
    setopen(true);
  }


  const handleCloseMenu = () => {
    props.setOpen(false)
  }


  const handleChangePage = (event, newPage) => {
    props.setPageCourse(newPage + 1)
    props.setBackdrop(true);
  };

  const handleChangeRowsPerPage = (event) => {
    props.setRowsCourse(parseInt(event.target.value, 10));
    props.setPage(0);
    props.setBackdrop(true);
  };

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.reduce((result, row) => {
        if (row.id !== undefined) {
          result.push(row.id)
        }
        return result;
      }, [])
      const allSelected = newSelecteds.concat(selected);
      const filterSelected = allSelected.filter(onlyUnique)
      setSelected(filterSelected);
      return;
    } else {
      const newSelecteds = rows.reduce((result, row) => {
        if (row.id !== undefined) {
          result.push(row.id)
        }
        return result;
      }, [])
      const filterSelected = selected.filter((el) => !newSelecteds.includes(el));
      setSelected(filterSelected)
    }
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1 || props.all;
  return (
    <React.Fragment>
      {selected.length > 0 &&
        <EnhancedTableToolbar
          {...props}
          allusers={rows.length}
          numSelected={selected.length}
          removeSelected={removeSelected}
          anchorEl={anchorEl}
          openExport={openExport}
          closeExport={closeExport}
          labelSing={"corso"}
          labelPlu={"corsi"}
          labelArt={"i corsi"}
          exportTable={props.exportTable}
        />
      }
      <TableContainer className={classes.container}>
        <Backdrop style={{ zIndex: "2", position: "absolute", borderRadius: "20px", backgroundColor: "#fff", opacity: "0.4" }} open={props.backdrop}>
          <CircularProgress style={{ color: COLORS.primary }} />
        </Backdrop>
        <Table size={"small"} className={classes.table} aria-label="simple table">

          <EnhancedTableHead
            {...props}
            haveCheckbox
            classes={classes}
            onSelectAllClick={handleSelectAllClick}
            rows={rows.map(row => row.id) || []}
            selected={selected}
            headCells={headCells}
          />
          <TableBody>
            {rows.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  key={index}
                  className={"table-label-cell " + classes.rowSelected}
                  selected={isItemSelected}
                  role="checkbox"
                  aria-checked={isSelected}
                >
                  <TableCell className={classes.label_cell} padding="checkbox">
                    <Checkbox
                      disabled={props.all}
                      className={classes.check}
                      onClick={(event) => { handleClick(event, row.id) }}
                      checked={isItemSelected}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </TableCell>
                  <TableCell style={{cursor:"pointer"}} onClick={(e) => handleopenCourse(e, row)} className={classes.label_cell} align="left"><span style={{color:COLORS.primary}}>{row.name}</span></TableCell>
                  <TableCell className={classes.label_cell} align="left">{row.code}</TableCell>
                  <TableCell className={"hide-column-md " + classes.label_cell} align="left">
                    {row.thumbnail ?
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <Avatar src={row.thumbnail} variant="square" className={classes.square} />
                      </div>
                      : null}
                  </TableCell>
                  <TableCell className={"hide-column-md " + classes.label_cell} align="left">{row.parent_category}</TableCell>
                  <TableCell className={"hide-column-md " + classes.label_cell} align="left">{row.category}</TableCell>
                  <TableCell className={"hide-column-xs " + classes.label_cell} align="left">

                    {row.state === "RDY" && "Bozza"}
                    {row.state === "STR" && "Pubblicato"}
                    {row.state === "END" && "Terminato"}
                  </TableCell>
                  <TableCell className={"hide-column-md " + classes.label_cell} align="center">{row.is_mandatory ? <CheckCircleIcon style={{ color: COLORS.primary, marginLeft: "15px" }} /> : <HighlightOffIcon style={{ color: COLORS.primary, marginLeft: "15px" }} />}</TableCell>
                  <TableCell className={"hide-column-md " + classes.label_cell} align="left">{row.created}</TableCell>
                  <TableCell className={"hide-column-xxs " + classes.label_cell} align="center">{row.subscriptions_count}</TableCell>
                  <TableCell className={classes.label_cell} align="left">
                    <MoreHorizIcon onClick={(e) => handleopenCourse(e, row)} style={{ width: "30px", height: "30px", color: "#999", cursor: "pointer" }} />
                  </TableCell>
                </TableRow>
              )
            })
            }

            <DeleteDialog
              deleteLesson={deleteCourse}
              setDelete={setdelete}
              textDelete={"il corso"}
              handleCloseMenu={handleCloseMenu}
              setConfirm={props.setConfirm}
              lesson={course}
            />
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={props.count}
        rowsPerPage={props.rowsCourse}
        page={props.page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        classes={{ menuItem: classes.selected }}
        labelRowsPerPage={"Righe per pagina: "}
      />
    </React.Fragment>
  );
}