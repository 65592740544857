import React, { createContext, useContext, useEffect, useState } from 'react'


export const themeContext = createContext();


export const useLogo = () => {
	const [themeOptions, setThemeOptions] = useContext(themeContext);
	return {
		logoUrl: themeOptions?.logoUrl,
		brand_logo: themeOptions?.brand_logo,
		brand_logo_nav: themeOptions?.brand_logo_nav,
	}
}

export const useContent = () => {
	const [themeOptions, setThemeOptions] = useContext(themeContext);
	const Content = themeOptions.content;

	return {
		Content
	}
}

const useColor = () => {
	const [themeOptions, setThemeOptions] = useContext(themeContext);

	const COLORS = themeOptions;

	return {
		COLORS
	}
}
export default useColor


