import React from 'react';
import { Redirect, Route } from 'react-router-dom';


const PrivateCourse = ({ component: Component, ...rest }) => {

    const isAuthenticatedUser = localStorage.getItem("bearer_fit_token")
    const hasCourseId = localStorage.getItem("courses_fit_id")
    
    return (
        <Route
            {...rest}
            render={(props) => isAuthenticatedUser && hasCourseId ? <Component {...props} /> : <Redirect to="/login" />}
        />
    )
}
export default PrivateCourse