import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ModalSubscription from '../Modal/ModalSubscription'
import ModalSession from '../Modal/ModalSession'
import DeleteDialog from '../Dialog/DeleteDialog';
import useColor from '../../context/themeContext'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import moment from 'moment'
import 'moment/locale/it';
import serviceAdmin from '../../services/serviceAdmin';
moment.locale('it')



export default function TableCorsi(props) {
  
  const { COLORS } = useColor();
  const useStyles = makeStyles({
    menu_table: {
      color: COLORS.primary + "!important"
    },
    menu_table_del: {
      color: "red"
    }
  });
  const classes = useStyles();

  const [rows, setRows] = useState([])
  const [openSession, setOpenSession] = useState(false);
  const [sub, setSub] = useState({});
  const [deleteSub, setDelete] = useState(false);
  const [etiquette, setEtiquette] = useState(null)
  const [arrayEtiquette, setArrayEtiquette] = useState({})

  const setdelete = (input) => { setDelete(input) }

  useEffect(() => {
    serviceAdmin.getService(`/custom/v1/configurations/${process.env.REACT_APP_ETIQUETTE}/`)
      .then(res => {
        const etiquettes = Object.keys(res.data.data).map((key) => { return { [key]: res.data.data[key] } });
        setEtiquette(res.data.data)

        setArrayEtiquette(etiquettes)
      }).catch(err => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    if (props.courses.length >= 0) {
      setRows(
        props.courses.map((course) => {
          const duration = course?.duration?.split('.')[0];
          return {
            "name": course.course.name,
            "state": course.status_etiquette,
            "course_name": course?.course,
            "subscription": moment(course.created).format('DD-MM-YYYY'),
            "scadenza": course.expiration_date,
            "complete": course.is_passed,
            "active": course.is_active,
            "sub_id": course.id,
            "completion_date": course.completion_date,
            "duration": duration,
            "is_in_history": course.is_in_history,
            "progress": course.progress,
            "course_duration": course.course_duration,
            "total_watched_duration": course.total_watched_duration
          }
        })
      )

    }
  }, [props.courses])


  const setopen = (input) => {
    props.setOpen(input)
  }
  const setopensession = (input) => {
    setOpenSession(input)
  }

  const modifyCourse = (event, row) => {
    props.setOpen(true);
    setSub(row)
  }

  const default_etiquette = {
    "RDY": "DA AVVIARE",
    "RUN": "IN CORSO",
    "OFF": "SOSPESO",
    "END": "FINITO"
  }

  return (
    <TableContainer>
      <Table size={"small"} className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell width="30%" className={"table-label"} align="left">Nome corso</TableCell>
            <TableCell className={"table-label hide-column-xs"} align="left">Stato utente</TableCell>
            <TableCell width="20%" className={"table-label hide-column-md"} align="left">Data iscrizione</TableCell>
            <TableCell className={"table-label hide-column"} align="left">Scadenza</TableCell>
            <TableCell width="20%"  className={"table-label"} align="center">Completato</TableCell>
            <TableCell className={"table-label hide-column-md"} align="left">Data completamento</TableCell>
            <TableCell className={"table-label hide-column"} align="left">Tempo totale</TableCell>
            <TableCell className={"table-label hide-column-md"} align="left">Tempo effettivo</TableCell>
            <TableCell className={"table-label hide-column-md"} align="center">Percentuale completamento</TableCell>
            <TableCell className={"table-label hide-column-md"} align="center">Attivo</TableCell>
            <TableCell className={"table-label hide-column-md"} align="center">Storico</TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow hover key={row.name}>
              <TableCell align="left">{row.name}</TableCell>
              <TableCell className={"hide-column-xs"} align="left">
                {etiquette ? etiquette[row.state] : default_etiquette[row.state]}

              </TableCell>
              <TableCell className={"hide-column-md"} align="left">{row.subscription}</TableCell>
              <TableCell className={"hide-column"} align="left">{row.scadenza ? moment(row?.scadenza).format('DD-MM-YYYY') : null}</TableCell>
              <TableCell align="center">{row.complete ? <CheckCircleIcon style={{ color: COLORS.primary }} /> : <HighlightOffIcon style={{ color: COLORS.primary }} />}</TableCell>
              <TableCell className={"hide-column-md"} align="left">{row.completion_date ? moment(row?.completion_date).format('DD-MM-YYYY') : null}</TableCell>
              <TableCell className={"hide-column"} align="left">{row.duration}</TableCell>
              <TableCell className={"hide-column-md"} align="left">{row.total_watched_duration ? moment.utc(row.total_watched_duration * 1000).format('HH:mm:ss') : "00:00:00"}</TableCell>
              <TableCell className={"hide-column-md"} align="center">{row.progress ? row.progress + "%" : null}</TableCell>
              <TableCell className={"hide-column-md"} align="center">{row.active ? <CheckCircleIcon style={{ color: COLORS.primary }} /> : <HighlightOffIcon style={{ color: COLORS.primary }} />}</TableCell>
              <TableCell className={"hide-column-md"} align="center">{row.is_in_history ? <CheckCircleIcon style={{ color: COLORS.primary }} /> : <HighlightOffIcon style={{ color: COLORS.primary }} />}</TableCell>
              <TableCell width="100px" align="right">
                <MoreHorizIcon onClick={(e) => modifyCourse(e, row)} style={{ width: "30px", height: "30px", color: "#999", cursor: "pointer" }} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <DeleteDialog
          deleteLesson={deleteSub}
          setDelete={setdelete}
          textDelete={"l'iscrizione al corso"}
          handleCloseMenu={() => { }}
          setConfirm={props.setConfirm}
          lesson={sub}
        />
      </Table>
      <ModalSubscription etiquette={arrayEtiquette} setDelete={setdelete} setCustomAlert={props.setCustomAlert} setReload={props.setReload} course={sub.course_name} sub={sub} setOpen={setopen} open={props.open} />
      <ModalSession setCustomAlert={props.setCustomAlert} setReload={props.setReload} sub={sub} setOpen={setopensession} open={openSession} />
    </TableContainer>
  );
}
