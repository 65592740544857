import React, { useEffect, useState, useRef } from 'react'
import {
	Grid, TextField, Typography, IconButton,
	Accordion, AccordionSummary, AccordionDetails, Checkbox, FormControlLabel
} from '@material-ui/core'
import { Container, Draggable } from "react-smooth-dnd"
import AddIcon from '@material-ui/icons/Add'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Dialog from "../Dialog/DialogD"
import AnswersBuilder from './AnswersBuilder'
import QuestionLikertBuilder from './QuestionBuilder'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined'
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import helpers from './helpers'
import arrayMove from "array-move"
import { builderClass } from '../../classes'
import useColor from '../../context/themeContext'
import QuestionTextField from './UtilityComponent/QuestionTextField'



const WhiteCheckbox = withStyles({
	
	root: {
		color: "#fff",
		'&$checked': {
			color: "#fff",
		},
	},
	checked: {},
})((props) => <Checkbox color="default" {...props} />);

const Builder = (props) => {

	const { COLORS } = useColor()
	const useStyles = makeStyles(() => (builderClass(COLORS)))
	const classes = useStyles();

	const { dataBuilder, isEvaluation, fetchDataDetails } = props
	const timerRef = useRef(null);
	const [questions, setQuestions] = useState([])
	const [input, setInput] = useState({})
	const [questionId, setQuestionId] = useState(null)
	const [deleteDialog, setDeleteDialog] = useState(false)
	const [show, setShow] = useState(false)
	const [questionSelected, setQuestionSelected] = useState({})
	const [expanded, setExpanded] = useState(false);

	const handleClickAccordion = (panel) => (e) => {
		setExpanded(panel === expanded ? false : panel);
	};

	useEffect(() => {
		setQuestionSelected({ ...questionSelected, "question_config": "open" });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const resource = dataBuilder?.questions
		if (resource) {
			let questions = []
			let questionsMapped = {}
			questions = Object.keys(resource).map(key => {
				return {
					"key": key,
					"text": resource[key]?.text,
					"id": resource[key]?.id,
					"answers": resource[key]?.answers,
					"question_config": resource[key]?.question_config,
					"is_active": resource[key]?.is_active,
					"is_at_least_one_correct": resource[key]?.is_at_least_one_correct,
				}
			})
			Object.keys(resource).map(key => {
				return questionsMapped[key] = {
					"text": resource[key]?.text,
					"id": resource[key]?.id,
				}
			})
			setQuestions(questions)
			setInput(questionsMapped)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataBuilder])

	const changeObject = (e, key) => {
		const value = e.target.value;
		const name = e.target.name;
		setQuestionSelected({ ...questionSelected, [name]: value, "key": key })
	}

	const hideObject = () => {
		setShow(false)
		setQuestionSelected({})
	}

	const saveQuestion = () => {
		if (show) {
			helpers.createQuestion(dataBuilder?.id, questionSelected, isEvaluation)
				.then(res => {
					setShow(false);
					setQuestionSelected({});
					reloadQuestions();
					props.setCustomAlert(true, "success", "Domanda aggiunta!");
				})
		} else {
			setShow(true)
		}
	}

	const reloadQuestions = () => {
		fetchDataDetails(dataBuilder?.id)
	}

	const openDialog = (id) => {
		setQuestionId(id);
		setDeleteDialog(true);
	}

	const handleClose = () => {
		setDeleteDialog(false);
	}


	////Eliminazione domanda
	const deleteQuestion = () => {
		helpers.removeQuestion(questionId, isEvaluation)
			.then(res => {
				reloadQuestions();
				handleClose();
				props.setCustomAlert(true, "success", "Domanda eliminata!");
			}).catch(err => console.log(err))
	}


	////Salva automaticamente la modifica di una domanda al cambiare della stessa
	const handleChange = (e) => {
		const value = e.target.value;
		const name = e.target.name;

		setInput({ ...input, [name]: { ...input[name], "text": value } })

		if (timerRef.current) clearTimeout(timerRef.current);

		timerRef.current = setTimeout(() => {
			helpers.updateQuestion(input[name]?.id, value, isEvaluation)
				.then(res => {
					props.setCustomAlert(true, "success", "Domanda aggiornata!");
				}).catch(err => console.log(err))
		}, 1000)
	}


	const handleDrop = ({ removedIndex, addedIndex }) => {
		const news = arrayMove(questions, removedIndex, addedIndex);
		if (JSON.stringify(news) !== JSON.stringify(questions)) {
			setQuestions(news);
			if (news.length > 1) {
				news.map((item, index) => {
					return helpers.orderQuestion(item?.id, index, isEvaluation)
						.then(res => {
							if (res?.status === 200) {
								props.setCustomAlert(true, "success", "Ordinamento aggiornato!");
							}
						}).catch(err => {
							console.log(err)
						})
				})
			}
		}
	}

	////Crea la domanda inserita premendo invio
	const addKeyQuestion = (e) => {
		if (e.key === "Enter") {
			saveQuestion();
		}
	};


	const switchTypology = (config) => {
		switch (config) {
			case "open":
				return "Domanda a risposta aperta"
			case "multiple":
				return "Domanda a risposta multipla"
			case "single":
				return "Domanda a risposta singola"
			case "likert":
				return "Scala di Likert"
			default:
				return ""
		}
	}

	const handleChangeUpdate = (e, id) => {
		const checked = e.target.checked
		const name = e.target.name;
		helpers.activeQuestion(id, name, checked)
			.then(res => {
				if (name === "is_active") {
					props.setCustomAlert(true, "success", `${checked ? "Domanda attivata!" : "Domanda disattivata!"}`);
				} else {
					props.setCustomAlert(true, "success", "Domanda aggiornata!");
				}
				reloadQuestions();
			}
			)
	}


	const handleChangeCheck = (e) => {
		const name = e.target.name
		setQuestionSelected({ ...questionSelected, [name]: e.target.checked })
	}

	///Renderizza il pulsante per aggiungere domande
	const QuestionAddButton = () => {
		return (
			<React.Fragment>
				{!show &&
					<div className={classes.question_add} onClick={saveQuestion}>
						Aggiungi Domanda
							<IconButton disabled={show && !questionSelected.text ? true : false} color="inherit">
							<AddIcon style={{ fontSize: "25px" }} color="inherit" />
						</IconButton>
					</div>
				}
			</React.Fragment>
		)
	}


	return (
		<div style={{ marginTop: "20px" }}>

			<Container
				orientation="vertical"
				onDrop={handleDrop}
				lockAxis={"y"}
				dragHandleSelector=".column-drag-handle"
				dropPlaceholder={{
					animationDuration: 150,
					showOnTop: true,
					className: classes.preview
				}}
				dropPlaceholderAnimationDuration={200}
			>

				{
					questions.map((item, index) => {
						const id = item?.id
						const config = item?.question_config
						const answers = item?.answers
						const is_one_correct = isEvaluation === 1 && config !== "single";

						return (
							<Draggable style={{ background: "#fff", borderRadius: "20px" }} key={id}>
								<Accordion expanded={(expanded === 'panel' + index && (config !== "open" || isEvaluation === 1))} className={classes.summaryAccordion}>
									<div className={classes.question_modify_list}>
										<AccordionSummary
											className={classes.summaryAccordion}
											expandIcon={(config !== "open" || isEvaluation === 1) && <ExpandMoreIcon onClick={handleClickAccordion('panel' + index)} style={{ color: "#ffffff" }} />
											}>

											<div className={"column-drag-handle grab " + classes.cont_drag_icon}>
												<DragIndicatorIcon className={classes.icon_drag} color="inherit" />
											</div>
											<Typography className={classes.text_q} color={"inherit"}>
												{switchTypology(config)}
											</Typography>
											<QuestionTextField
												color={COLORS.primary}
												multiline
												autoComplete={"off"}
												value={input[item?.key]?.text || ""}
												onChange={handleChange}
												name={item?.key}
												variant={"outlined"}
											/>
											<div className={classes.container_icon_button}>
												<IconButton style={(config === "open" && isEvaluation === 0) ? { marginRight: "34px" } : {}} onClick={() => openDialog(id)} className={classes.icon} color="inherit">
													<ClearOutlinedIcon color="inherit" />
												</IconButton>
											</div>

										</AccordionSummary>

										{(expanded === 'panel' + index && (config !== "open" || isEvaluation === 1)) &&
											<div className={classes.optional_question}>
												{isEvaluation === 1 &&

													<FormControlLabel
														style={{ minWidth: "200px" }}
														value="Domanda attiva"
														control={
															<WhiteCheckbox
																name={"is_active"}
																data-id={String(id)}
																checked={item?.is_active}
																onChange={(e) => handleChangeUpdate(e, id)}
																color="primary"
															/>
														}
														label="Domanda attiva"
													/>
												}
												{is_one_correct &&
													<div style={{ display: "block" }}>
														<FormControlLabel
															style={{ minWidth: "200px" }}
															value="Almeno una risposta corretta per il superamento"
															control={
																<WhiteCheckbox
																	name={"is_at_least_one_correct"}
																	data-id={String(id)}
																	checked={item?.is_at_least_one_correct}
																	onChange={(e) => handleChangeUpdate(e, id)}
																	color="primary"
																/>
															}
															label="Almeno una risposta corretta per il superamento"
														/>
													</div>
												}
											</div>
										}
									</div>

									<AccordionDetails key={id} style={{ display: "block" }} >
										<Grid container style={{ width: "90%", margin: "0 auto", }}>
											<Grid item md={config === "likert" ? 6 : 12}>
												<AnswersBuilder
													multiline
													setCustomAlert={props.setCustomAlert}
													question_id={id}
													config={config}
													answers={answers}
													setReload={reloadQuestions}
													isEvaluation={isEvaluation}
												/>
											</Grid>
											<Grid item md={6}>
												{config === "likert" &&
													<QuestionLikertBuilder
														setCustomAlert={props.setCustomAlert}
														survey_id={dataBuilder.id}
														question_id={id}
														config={config}
														answers={answers}
														setReload={reloadQuestions}
													/>
												}
											</Grid>
										</Grid>
									</AccordionDetails>

								</Accordion>
							</Draggable>
						)
					})

				}

				{show &&
					<Draggable key={questions.length}>
						<div className={classes.question_list + " column-drag-handle grab"} onKeyDown={addKeyQuestion}>
							<Select className={classes.select} onChange={(e) => changeObject(e, questions.length)} value={questionSelected.question_config || "open"} name="question_config" variant="outlined">

								<MenuItem className={classes.selected} value={"open"}>Domanda aperta</MenuItem>
								{!isEvaluation &&
									<MenuItem className={classes.selected} value={"likert"}>Scala likert</MenuItem>
								}
								<MenuItem className={classes.selected} value={"multiple"}>Risposta multipla</MenuItem>
								<MenuItem className={classes.selected} value={"single"}>Risposta singola</MenuItem>

							</Select>

							{isEvaluation === 1 &&
								<FormControlLabel
									style={{ minWidth: "200px" }}
									value="Domanda attiva"
									control={
										<WhiteCheckbox
											name={"is_active"}
											checked={questionSelected.is_active}
											onChange={handleChangeCheck}
											color="primary"
										/>
									}
									label="Domanda attiva"
								/>
							}
							{(isEvaluation === 1 && questionSelected.question_config !== "single") &&
								<FormControlLabel
									style={{ minWidth: "200px" }}
									value="Almeno una risposta corretta per il superamento"
									control={
										<WhiteCheckbox
											name={"is_at_least_one_correct"}
											checked={questionSelected.is_at_least_one_correct}
											onChange={handleChangeCheck}
											color="primary"
										/>
									}
									label="Almeno una risposta corretta per il superamento"
								/>
							}

							<QuestionTextField
								color={COLORS.primary}
								autoComplete={"off"}
								value={questionSelected?.text || ""}
								onChange={(e) => changeObject(e, questions.length)}
								name={"text"}
								variant={"outlined"}
								multiline
							/>
							<IconButton onClick={saveQuestion} className={classes.icon} color="inherit">
								<SaveOutlinedIcon color="inherit" />
							</IconButton>
							<IconButton onClick={hideObject} className={classes.icon} color="inherit">
								<ClearOutlinedIcon color="inherit" />
							</IconButton>
						</div>
					</Draggable>
				}

				<Dialog
					textDelete={"la domanda"}
					deleteDialog={deleteDialog}
					handleClose={handleClose}
					callback={deleteQuestion}
				/>
			</Container>

			<QuestionAddButton />
		</div >
	)
}

export default Builder;