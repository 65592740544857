import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import TableTeachingMaterial from '../Table/TableTeachingMaterial';
import { useEffect } from 'react';
import serviceAdmin from '../../services/serviceAdmin'
import useColor from '../../context/themeContext'
import ModalTeachingMaterial from '../Modal/ModalTeachingMaterial'




const TeachingMaterial = (props) => {
    const { COLORS } = useColor();
    const useStyles = makeStyles({
        title: {
            color: "#4c23f1",
            fontSize: "20px",
            fontWeight: "bold",
            paddingBottom: "10px"
        },
        subtitle: {
            fontWeight: "bold",
            paddingBottom: "10px"
        },
        select: {
            width: "100%",
            maxWidth: "235px",
            margin: "30px 0"
        },
        color: {
            color: COLORS.primary + "!important"
        }

    });
    const classes = useStyles();


    const [openMaterial, setOpenMaterial] = useState(false)
    const [material, setMaterial] = useState([])
    const [reload, setReload] = useState(true)
    const [confirm, setConfirm] = useState(false)
    const [material_id, setMaterial_id] = useState(null)

    const setconfirm = (input, material) => {
        setConfirm(input)
        setMaterial_id(material.id)
    }

    const setreload = () => {
        setReload(true)
    }
    const setopenmaterial = () => {
        setOpenMaterial(true)
    }

    useEffect(() => {
        if (reload) {
            serviceAdmin.getService(`v1/courses/${props.course.id}/teaching-materials/`)
                .then(res => {
                    if (res.status === 200) {
                        setMaterial(res.data.data)
                        setReload(false)
                    }
                }).catch(err => {
                    console.log(err)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload])

    useEffect(() => {
        if (confirm && material_id) {
            serviceAdmin.deleteService(`v1/courses/${props.course.id}/teaching-materials/${material_id}/`)
                .then(res => {

                    if (res.status === 200) {
                        props.setCustomAlert(true, "success", "Materiale eliminato correttamente!")
                        setReload(true);
                        setConfirm(false)
                        setMaterial_id(null)
                    }

                }).catch(err => {
                    console.log(err)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [material_id])

    return (
        <div>

            <h2 className="title_corsi_admin">Materiale del corso <span className={classes.color}>{props.course.name}</span></h2>
            <div className={"subscription_course " + classes.color}>
                Aggiungi materiale didattico <AddCircleIcon onClick={setopenmaterial} className={"subscription_icon " + classes.color} />
            </div>

            <TableTeachingMaterial setCustomAlert={props.setCustomAlert} setReload={setreload} setConfirm={setconfirm} materials={material} />

            <ModalTeachingMaterial
                setCustomAlert={props.setCustomAlert}
                course_id={props.course.id}
                open={openMaterial}
                setOpen={setOpenMaterial}
                setReload={setreload}
            />

        </div>
    )

}

export default TeachingMaterial;