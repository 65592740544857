import React, { useState, useEffect, useRef } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
    InputLabel, Button, Select, MenuItem, Paper,
    InputAdornment, IconButton, Tooltip, Fade
} from '@material-ui/core'
import FromDate from '../Export/ExportFilter/FromDate'
import serviceAdmin from '../../services/serviceAdmin'
import useColor from '../../context/themeContext'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import DeleteDialog from '../Dialog/DeleteDialog'
import InfoIcon from '@material-ui/icons/Info'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import CssTextField from '../StyledComponent/CssTextField'
import { tabsStyle } from '../../classes'
import Switch from '../Switch/Switch'
import moment from 'moment'
import 'moment/locale/it'
moment.locale('it')



const CustomTooltip = withStyles({
    tooltip: {
        fontSize: "14px"
    }
})(Tooltip);




const Utente = (props) => {
    const { COLORS } = useColor();
    const useStyles = makeStyles(tabsStyle(COLORS))
    const classes = useStyles();

    const inputImageRef = useRef();
    const isMount = useRef(true);

    const [input, setinput] = useState({})
    const [groups, setGroups] = useState([])
    const [inputc, setInputC] = useState({})
    const [expiration, setExpiration] = useState({})
    const [roles, setRoles] = useState([])
    const [image, setImage] = useState()
    const [checkedActive, setcheckedActive] = useState(false)
    const [conflict, setConflict] = useState(false)
    const [passwordConflict, setPasswordConflict] = useState(false)
    const [emailConflict, setEmailConflict] = useState(false)
    const [passwordConfirm, setPasswordConfirm] = useState(false)
    const [values, setValues] = useState({ showPassword: false, showPasswordC: false });
    const [deleteUser, setDelete] = useState(false);
    const [confirm, setConfirm] = useState(false)
    const [user_id, setUser_id] = useState(null)
    const [loading, setLoading] = useState(false)
    const [anchorEl, setAnchorEl] = useState(false)

    const open = () => {
        setAnchorEl(true)
    }
    const close = () => {
        setAnchorEl(false)
    }

    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    const pastePasswordC = (e) => {
        e.preventDefault();
        props.setCustomAlert(true, "warning", "La funzione 'incolla' è disabilitata");
        return false;
    }

    const pastePassword = (e) => {
        e.preventDefault();
        props.setCustomAlert(true, "warning", "La funzione 'incolla' è disabilitata");
        return false;
    }

    const setconfirm = (input, user) => {
        setConfirm(input)
        setUser_id(user.id)
    }

    const disableExpiration = () => {
        setExpiration({ "expiration_date": null })
    }

    const setdelete = (input) => { setDelete(input) }

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (!regex.test(input.new_password) && input.new_password) {
                setPasswordConflict(true)
            } else {
                setPasswordConflict(false)
            }
            if (input.new_password !== inputc.passwordC) {
                setPasswordConfirm(true)
            } else {
                setPasswordConfirm(false)
            }
        }, 200)
        return () => {
            clearTimeout(timeout)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputc.passwordC, input.password])

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleClickShowPasswordC = () => {
        setValues({ ...values, showPasswordC: !values.showPasswordC });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const setCheckedActive = (input) => { setcheckedActive(input) }

    const changeInput = (e) => {
        const name = e.target.name;
        if (name === "first_name" || name === "last_name" || name === "fiscal_code" || name === "email") {
            const value = e.target.value.toUpperCase();
            setinput({ ...input, [name]: value })
        } else {
            const value = e.target.value;
            setinput({ ...input, [name]: value })
        }
    }

    const changeExpiration = (value) => {
        const name = "expiration_date";
        
        setExpiration({ [name]: value });
    }

    const changeInputC = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setInputC({ ...input, [name]: value })
    }

    const setImageRef = () => {
        inputImageRef.current.click()
    }

    const updateUser = () => {
        const params = {
            ...input,
            "is_active": checkedActive,
            "avatar": image,
            "expiration_date": expiration?.expiration_date ? expiration?.expiration_date : null
        }
        serviceAdmin.putService(`v1/users/${props.user?.otherInfo?.id}/`, params)
            .then(res => {
                if (res.status === 200) {
                    props.setCustomAlert(true, "success", "Utente aggiornato!");
                    props.changeReloadUser();
                }
            }).catch(err => {
                if (err.status === 409 || err.status === 400) {
                    if (err.data.reason.includes("username")) {
                        setConflict(true)
                        props.setCustomAlert(true, "warning", "L'username scelto è gia in uso!");
                    } else if (err.data.reason.includes("password")) {
                        setPasswordConflict(true)
                        props.setCustomAlert(true, "warning", "La password inserita non è corretta!");
                    } else if (err.data.reason.includes("email")) {
                        setEmailConflict(true)
                        props.setCustomAlert(true, "warning", "L'email inserita potrebbe essere già in uso!");
                    }
                } else {
                    setConflict(false)
                    setPasswordConflict(false)
                }
            })
    }

    useEffect(() => {
        if (confirm && user_id) {
            serviceAdmin.deleteService(`v1/users/${user_id}/`)
                .then(res => {
                    if (isMount.current) {
                        props.setCustomAlert(true, "success", "Utente eliminato correttamente!")
                        props.changeReloadUser();
                        props.handleClose();
                        setConfirm(false)
                        setUser_id(null)
                    }
                }).catch(err => {
                    console.log(err)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user_id])

    useEffect(() => {

        serviceAdmin.getService(`v1/users/${props.user?.id}/`)
            .then(res => {
                if (isMount.current) {
                    if (res.status === 200) {
                        const user = res.data.data;

                        setinput({
                            "username": user?.username,
                            "email": user?.email,
                            "first_name": user?.first_name,
                            "last_name": user?.last_name,
                            "fiscal_code": user?.fiscal_code,
                            "role_id": user?.role?.id,
                            "groups_ids": user?.groups.map(group => group.id)
                        });
                        setExpiration({ "expiration_date": user?.expiration_date ? moment(user?.expiration_date).format('YYYY-MM-DD') : null, })
                        setCheckedActive(user?.is_active);
                        setLoading(true);
                    }
                }
            }).catch(err => {
                console.log(err);
            })


        serviceAdmin.getService("v1/roles/")
            .then(res => {
                if (isMount.current) {
                    setRoles(res.data.data);
                }
            }).catch(err => {
                console.log(err);
            })

        serviceAdmin.getService("v1/groups/")
            .then(res => {
                if (isMount.current) {
                    setGroups(res.data.data);
                }
            }).catch(err => {
                console.log(err);
            })


        return () => {
            isMount.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    ////////IMAGE SELECT

    function imageSelected(event) {
        const isImage = file => file["type"].includes("image")
        let input = event.target
        if (input.files && input.files[0]) {
            if (isImage(input.files[0])) {
                let reader = new FileReader()
                reader.onload = e => {
                    setImage(e.target.result)
                }
                reader.readAsDataURL(input.files[[0]])
            } else {
                alert('foto non caricata')
            }
        }
    }


    return (

        <Fade in={loading}>
            <div>
                <Grid container spacing={3} style={{ paddingTop: "30px" }}>

                    <Grid className="flex-center" item xs={12} md={6} lg={3}>
                        <InputLabel className={classes.label}>
                            Username
                        </InputLabel>
                        <CssTextField error={conflict ? true : false} helperText={conflict ? "L'username inserito potrebbe essere già in uso" : ""} name="username" value={input.username || ""} onChange={changeInput} variant="outlined" inputProps={{ className: classes.input }} />
                    </Grid>
                    <Grid className="flex-center" item xs={12} md={6} lg={3}>
                        <InputLabel className={classes.label}>
                            Ruolo
                        </InputLabel>
                        <Select value={input?.role_id || ""} name="role_id" onChange={changeInput} variant="outlined" className={classes.select}>
                            {
                                roles?.map((role, index) => {
                                    return <MenuItem className={classes.selected} key={index} value={role.id}>{role.name}</MenuItem>
                                })
                            }

                        </Select>
                    </Grid>
                    <Grid className="flex-center" item xs={12} md={6} lg={3}>
                        <InputLabel className={classes.label}>
                            Email
                        </InputLabel>
                        <CssTextField error={emailConflict ? true : false} helperText={emailConflict ? "L'email inserita potrebbe essere già in uso" : ""} name="email" value={input?.email || ""} onChange={changeInput} variant="outlined" inputProps={{ className: classes.input }} />
                    </Grid>
                    <Grid className="flex-center" item xs={12} md={6} lg={3}>
                        <InputLabel className={classes.label}>
                            Codice Fiscale
                        </InputLabel>
                        <CssTextField name="fiscal_code" value={input.fiscal_code || ""} onChange={changeInput} variant="outlined" inputProps={{ className: classes.input }} />
                    </Grid>
                </Grid>

                <Grid container spacing={3} style={{ paddingTop: "10px" }}>
                    <Grid className="flex-center" item xs={12} md={6} lg={3}>
                        <InputLabel className={classes.label}>
                            Nome
                        </InputLabel>
                        <CssTextField name="first_name" value={input.first_name || ""} onChange={changeInput} variant="outlined" inputProps={{ className: classes.input }} />
                    </Grid>
                    <Grid className="flex-center" item xs={12} md={6} lg={3}>
                        <InputLabel className={classes.label}>
                            Cognome
                        </InputLabel>
                        <CssTextField name="last_name" value={input.last_name || ""} onChange={changeInput} variant="outlined" inputProps={{ className: classes.input }} />
                    </Grid>
                    <Grid className="flex-center" item xs={12} md={6} lg={3}>

                        <InputLabel className={classes.label}>
                            Password

                            <CustomTooltip
                                title="La password deve contenere almeno 8 caratteri tra cui almeno una maiuscola, un numero ed un carattere speciale"
                            >
                                <InfoIcon style={{ fontSize: "19px" }} />
                            </CustomTooltip>

                        </InputLabel>
                        <CssTextField
                            onPaste={pastePassword}
                            variant="outlined"
                            type={values.showPassword ? 'text' : 'password'}
                            error={passwordConflict ? true : false}
                            helperText={passwordConflict ? "Password inserita non valida" : ""}
                            name="new_password"
                            value={input.new_password || ""}
                            onChange={changeInput}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                className: classes.input_show
                            }}
                        />

                    </Grid>
                    <Grid className="flex-center" item xs={12} md={6} lg={3}>
                        <InputLabel className={classes.label}>
                            Conferma Password
                        </InputLabel>
                        <CssTextField
                            onPaste={pastePasswordC}
                            variant="outlined"
                            type={values.showPasswordC ? 'text' : 'password'}
                            error={passwordConfirm ? true : false}
                            helperText={passwordConfirm ? "Le password non corrispondono" : ""}
                            name="passwordC"
                            value={inputc.passwordC || ""}
                            onChange={changeInputC}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPasswordC}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {values.showPasswordC ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                className: classes.input_show
                            }}
                        />
                    </Grid>
                    <Grid className="flex-center" item xs={12} md={6} lg={3}>
                        <InputLabel className={classes.label}>
                            Avatar
                        </InputLabel>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <input ref={inputImageRef} style={{ visibility: "hidden", width: "1px" }} type="file" onChange={imageSelected} />
                            Aggiungi Avatar
                            <AddCircleIcon className={classes.color} onClick={setImageRef} style={{ cursor: "pointer", paddingLeft: "10px", width: "40px", height: "40px" }} />
                        </div>
                    </Grid>
                    <Grid className="flex-center" item xs={12} md={6} lg={3}>
                        <InputLabel className={classes.label}>
                            Gruppi
                        </InputLabel>
                        <Select
                            name={"groups_ids"}
                            MenuProps={{ getContentAnchorEl: () => null }}
                            onClick={open}
                            multiple
                            open={Boolean(anchorEl)}
                            onClose={close}
                            value={input.groups_ids || []}
                            onChange={changeInput}
                            variant="outlined"
                            className={classes.select}>
                            {
                                groups?.map((group, index) => {
                                    return <MenuItem className={classes.selected} key={index} value={group.id}>{group.name}</MenuItem>
                                })
                            }
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Button onClick={(e) => { e.preventDefault(); e.stopPropagation(); close(); }} style={{ marginRight: "5px", color: COLORS.primary }}>
                                    ok
                            </Button>
                            </div>
                        </Select>
                    </Grid>
                </Grid>

                <Grid container spacing={3} style={{ paddingTop: "10px", marginBottom: "80px" }}>
                    <Grid className="flex-center" item xs={12} md={6} lg={3}>
                        <InputLabel className={classes.label}>
                            Stato
                        </InputLabel>
                        <Switch disableLabel={"Disattivo"} enableLabel={"Attivo"} checked={checkedActive} handleToggleChecked={() => setCheckedActive(!checkedActive)} />
                    </Grid>
                    <Grid className="flex-center" style={{ minWidth: "260px" }} item xs={12} md={6} lg={3}>

                        <InputLabel className={classes.label}>
                            Scadenza
                        </InputLabel>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <FromDate
                                inputProps={`${classes.input}`}
                                value={expiration.expiration_date}
                                handleChange={changeExpiration}
                            />
                            <IconButton style={{ padding: "0px" }} onClick={disableExpiration}>
                                <HighlightOffIcon />
                            </IconButton>
                        </div>
                    </Grid>
                </Grid>

                <DeleteDialog
                    deleteLesson={deleteUser}
                    setDelete={setdelete}
                    textDelete={"l'utente"}
                    handleCloseMenu={() => { }}
                    setConfirm={setconfirm}
                    lesson={props.user}
                />
                <Paper elevation={3} className={classes.save}>
                    <Button onClick={() => setdelete(true)} className={classes.btn_del_user}>Elimina</Button>
                    <Button onClick={props.handleClose} className={classes.btn_del}>Annulla</Button>
                    <Button disabled={(regex.test(input.new_password) || !input.new_password) && input.role_id && input.username && input.first_name && input.last_name && input.fiscal_code ? false : true} onClick={updateUser} className={classes.btn_save}>Salva</Button>
                </Paper>
            </div>
        </Fade>

    )

}

export default Utente;