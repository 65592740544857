import React from "react";
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

const CustomAlert = ({ severity, setOpenAlert, noAutoHide, openAlert, message, duration }) => {

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false)
    }
    
    return (
        <React.Fragment>
            {severity &&
                <Snackbar
                    open={openAlert}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    style={{ top: "50px" }}
                    autoHideDuration={duration || 1700}
                    onClose={handleClose}
                >
                    <Alert
                        elevation={6}
                        variant="filled"
                        onClose={handleClose}
                        severity={severity}>
                        {message}
                    </Alert>
                </Snackbar>
            }
        </React.Fragment>
    )
}

export default CustomAlert;