import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const Image = (props) => {

    const imageStyle = {
        background: 'linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)),url(' + props.src + ') 50% 75% no-repeat',
        backgroundSize: 'cover',
        height: props.height
    };
    
    const imageStyleBlack = {
        background: '#030303',
        backgroundSize: 'cover',
        height: props.height
    };

    return (

        <div className="image-container" style={props.title ? imageStyle : imageStyleBlack}>
            <p>{props.module}</p>
            <h1>{props.title}</h1>
            <p className="subtitle">{props.subtext}</p>
            <span className={props?.span} onClick={props?.history?.goBack}>{props.backtext ? <ArrowBackIcon /> : null}{props.backtext}</span>
            <p className="text">{props.text}</p>

            {props.children}

        </div>

    )


}

export default Image

