import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import useColor from '../../context/themeContext'

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        justifyContent: "left",
        marginBottom: "20px",
        marginLeft: "40px",
        padding: "40px 0 40px 20px"
    },
}));


const Multiple = (props) => {
    const { COLORS } = useColor()
    const BlueCheckbox = withStyles({
        root: {
            color: COLORS.primary,
            '&$checked': {
                color: COLORS.primary,
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    const classes = useStyles();

    const [response, setresponse] = React.useState({})


    const { correct_answers_num } = props

    const handleChange = (e) => {
        const name = e.target.name;
        const data = {
            "answers": {
                ...response?.answers,
                [name]: !response.answers[name]
            }
        }
        setresponse(data);
        props.setPrevResponse({
            ...props.prevResponse,
            [props.index]: data
        })

        const keys = Object.keys(data.answers);
        const filterData = keys.filter(key => data.answers[key] === true)
        const numbers = filterData.map((n) => {
            return Number(n)
        })
        props.setData({ ...props.data, [props.index]: numbers })
    }

    useEffect(() => {
        if (props.prevResponse[props.index]?.answers) {
            setresponse({ "answers": props.prevResponse[props.index]?.answers })
            props.setPrevResponse({
                ...props.prevResponse,
                [props.index]: { "answers": props.prevResponse[props.index]?.answers }
            })
        } else {
            let data;
            for (let i in props.answers) {
                data = { ...data, [props.answers[i].id]: false }
            }
            setresponse({
                "answers": data
            })
        }

        return () => {
            props.setResponse(false);
        }
    }, [])

    useEffect(() => {
        let result = false;

        for (let i in props.prevResponse[props.index]?.answers) {
            if (props.prevResponse[props.index]?.answers[i] === true) {
                result = true;
                break;
            }
        }
        if (!result) {
            props.setPrevResponse({
                ...props.prevResponse,
                [props.index]: {}
            })
        }
    }, [response])

    useEffect(() => {
        if (props.prevResponse[props.index]?.answers) {
            props.setResponse(true);
            props.setallresponse(true, props.index)
        } else {
            props.setResponse(false);
            props.setallresponse(false, props.index)
        }
    }, [props.prevResponse])

    const disableCheck = () => {
        if (response.answers) {
            const keys = Object.keys(response.answers)
            const correctAnswers = keys.filter(key => response.answers[key])
            if ((correctAnswers?.length === correct_answers_num)) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    //const correctAnswers = response?.answers ?  response?.answers.filter(answer => answer) : 0;
    return (

        <Paper style={{ minHeight: "400px", borderRadius: "0px", paddingBottom: "20px" }}>

            <h3 style={{ padding: "40px", textAlign: "left" }}>{props?.text}</h3>

            <div className={classes.root} onKeyDown={props.getKey}>

                <FormGroup>
                    {
                        props.answers.map((answer, index) => {
                            const is_correct = response.answers ? response?.answers[answer.id] : false;
                            const is_evaluation = props.category === "EVALUATION"
                            return (
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <BlueCheckbox
                                            value={answer.id}
                                            disabled={(disableCheck(answer.id) && !is_correct) && is_evaluation}
                                            checked={is_correct}
                                            onChange={handleChange}
                                            name={String(answer.id)}
                                        />
                                    }
                                    label={answer.text}
                                />
                            )
                        })
                    }

                </FormGroup>

            </div>
        </Paper>

    )

}
export default Multiple;