import React, { useState } from 'react'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import InfoIcon from '@material-ui/icons/Info';
import GetAppIcon from '@material-ui/icons/GetApp';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import MultipleSelect from './ExportFilter/MultipleSelect'
import FromDate from './ExportFilter/FromDate'
import ResetFilter from './ExportFilter/ResetFilter'
import { SyncExport } from './Utilsfunctions'
import BorderTextField from '../StyledComponent/BorderTextField'
import { borderRadius } from '../../classes'
import moment from 'moment'
import 'moment/locale/it';
import useColor from '../../context/themeContext';
moment.locale('it')

const SubscriptionExport = (props) => {
  
  const { COLORS } = useColor()

  const CustomTooltip = withStyles({
    tooltip: {
      fontSize: "14px",
      backgroundColor: COLORS.primary,
      color: "#fff",
      textAlign: "center",
      borderRadius: "10px",
      fontWeight: "bolder"
    }
  })(Tooltip);

  const useStyles = makeStyles((theme) => ({
    tooltip: {
      fontSize: "28px",
    },
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      padding: "0 20px"
    },
    buttonSuccess: {
      backgroundColor: COLORS.primary,
      '&:hover': {
        backgroundColor: COLORS.primary,
      },
    },
    buttonProgress: {
      color: "#fff",
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    input: {
      padding: "12px 20px"
    }
  }));
  const classes = useStyles();

  const [input, setInput] = useState({});
  const [sub, setSub] = useState("");
  const [completeSub, setCompleteSub] = useState(false);

  const handleChangeDate = (date) => {
    setInput({ ...input, "from_date": date })
  }
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (Array.isArray(value)) {
      if (!value?.find(el => el === "ALL")) {
        setInput({ ...input, [name]: value })
      } else {
        return;
      }
    } else {
      setInput({ ...input, [name]: value })
    }
  }

  const resetChange = (name) => {
    setInput({ ...input, [name]: [] })
  }
  const disableDate = () => {
    setInput({ ...input, "from_date": "" })
  }

  const exportSub = () => {

    props.setLoadingSub(true);
    let data;
    if (props.userMe?.role?.tier === "BO_COM") {
      data = {
        annotation: input.annotation,
        audit_filters: {
          "filters": []
        },
        filters: {
          "groups_ids": cleanIds(input?.groups_ids),
          "courses_ids": cleanIds(input?.courses_ids),
          "roles_ids": cleanIds(input?.roles_ids),
          "from_date": input?.from_date
        }
      }
    } else {
      data = {
        annotation: input.annotation,
        audit_filters: {
          "filters": []
        },
        filters: {
          "groups_ids": [2],
          "courses_ids": cleanIds(input.courses_ids),
          "from_date": input?.from_date
        }
      }
    }

    if (input?.groups_ids?.length > 0) {
      let groups_name = "";
      for (let i in input?.groups_ids) {
        const g = props.groups.find(group => group.id === input?.groups_ids[i].id);
        groups_name += g.name + ", "
      }
      data.audit_filters.filters.push({ "value": groups_name, "label": "Filtrato per gruppi: " })
    }
    if (input?.roles_ids?.length > 0) {
      let roles_name = "";
      for (let i in input?.roles_ids) {
        const r = props.roles.find(role => role.id === input?.roles_ids[i].id);
        roles_name += r.name + ", "
      }
      data.audit_filters.filters.push({ "value": roles_name, "label": "Filtrato per ruoli: " })
    }
    if (input?.courses_ids?.length > 0) {
      let courses_name = "";
      for (let i in input?.courses_ids) {
        const c = props.courses.find(course => course.id === input?.courses_ids[i].id);
        courses_name += c.name + ", "
      }
      data.audit_filters.filters.push({ "value": courses_name, "label": "Filtrato per corsi: " })
    }

    clean(data.filters);

    SyncExport(`/custom/v1/exports/subscriptions/`, data,
      setSub, props.setCustomAlert, setCompleteSub, props.setLoadingSub, props.setReloadSub);
  }

  function cleanIds(array) {
    if (Array.isArray(array)) {
      return array.map(a => a.id);
    } else {
      return array;
    }
  }


  function clean(obj) {
    for (let propName in obj) {
      if (obj[propName] === "NO" || obj[propName] === "" || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
  }

  return (
    <Paper elevation={2} style={{ minHeight: "100%", borderRadius: borderRadius, padding: "50px 20px" }}>
      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
        <p style={{ color: COLORS.primary, paddingLeft: "20px" }} className={"label-attivita"}>Report utenti/corsi</p>
        <InputLabel style={{ padding: "20px", margin: "10px auto 15px", fontSize: "20px" }}>
          Scarica il report aggiornato sullo stato delle iscrizioni degli utenti ai corsi
        </InputLabel>

        <Grid spacing={3} item container style={{ marginBottom: "20px" }}>

          <ResetFilter
            setInput={setInput}
            input={input}
          />

          <Grid item xs={12} md={12} xl={12} style={{ textAlign: "center" }}>
            <InputLabel style={{ margin: "0 auto", fontSize: "17px" }}>
              Etichetta export:
            </InputLabel>
            <BorderTextField
              variant="outlined"
              name="annotation"
              onChange={handleChange}
              value={input?.annotation || ""}
              inputProps={{ className: classes.input }}
            />
          </Grid>

          <MultipleSelect
            name={"courses_ids"}
            all_label={"Tutti i corsi"}
            label={"Filtra per corsi:"}
            data={props.courses}
            handleChange={handleChange}
            resetChange={resetChange}
            input={input?.courses_ids}
          />

          <FromDate
            export
            label={"Genera dal:"}
            value={input.from_date}
            handleChange={handleChangeDate}
            disableDate={disableDate}
          />


          {props.userMe?.role?.tier === "BO_COM" ?
            <React.Fragment>
              <MultipleSelect
                name={"groups_ids"}
                label={"Filtra per gruppi:"}
                all_label={"Tutti i gruppi"}
                data={props.groups}
                handleChange={handleChange}
                resetChange={resetChange}
                input={input?.groups_ids}
              />


              <MultipleSelect
                data={props.roles}
                name={"roles_ids"}
                all_label={"Tutti i ruoli"}
                label={"Filtra per ruoli: "}
                handleChange={handleChange}
                resetChange={resetChange}
                input={input?.roles_ids}
              />
            </React.Fragment>
            : null}
        </Grid>

        <div className={classes.wrapper}>
          {!completeSub ?
            <Button
              style={{ padding: "10px 5px", borderRadius: "20px", background: COLORS.primary, width: "200px" }}
              variant="contained"
              color="primary"
              disabled={(!props.loadingSurvey && !props.loadingSub && !props.loadingSession) ? false : true}
              onClick={exportSub}
            >
              Genera CSV
              </Button>
            :
            <ButtonGroup color="primary" style={{ borderRadius: "20px" }} variant="contained" >
              <Button
                style={{ padding: "10px 15px", borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px", background: "#3aac4d" }}
                startIcon={<GetAppIcon />}
                disabled={(props.loadingSub || props.loadingSurvey) || props.loadingSession}
                onClick={() => {
                  setCompleteSub(false);
                }}
                download
                href={sub}
              >
                Scarica CSV
                </Button>
              <Button
                style={{ padding: "10px 15px", borderTopRightRadius: "20px", borderBottomRightRadius: "20px", background: COLORS.primary }}
                onClick={() => {
                  setCompleteSub(false);
                }}
              >
                Annulla
                </Button>
            </ButtonGroup>
          }
          {(props.loadingSurvey || props.loadingSession) &&
            <div style={{ display: "flex", alignItems: "center" }}>
              <CustomTooltip className={classes.tooltip} title="Attendere il completamento delle altre operazioni per utilizzare il servizio" placement="top-end">
                <InfoIcon style={{ marginLeft: "10px", color: COLORS.primary }} />
              </CustomTooltip>
            </div>
          }
          {props.loadingSub && <CircularProgress size={24} className={classes.buttonProgress} />}

        </div>
        {props.loadingSub && <p style={{ paddingTop: "15px", color: COLORS.primary, fontSize: "15px", textAlign: "center" }}>Si prega di attendere, l'operazione potrebbe richiedere alcuni minuti</p>}
      </div>
    </Paper>
  )
}

export default SubscriptionExport;