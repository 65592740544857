import React from "react"
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { Grid, InputLabel, Paper, TextField, Button, IconButton, InputAdornment, Tooltip } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import useColor from "../context/themeContext"
import useAlert from "../hooks/useAlert"
import serviceAdmin from "../services/serviceAdmin"
import { Visibility, VisibilityOff } from "@material-ui/icons"
import { recoveryPasswordClass } from "../classes"



const CssTextField = withStyles({
    root: {
        border: "none",
        width: "100%",
        '& .MuiInput-underline:after': {
            border: 'none',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none',
            },
            '&:hover fieldset': {
                borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'green',
            },
        },
    },
})(TextField)

const CustomTooltip = withStyles({
    tooltip: {
        fontSize: "14px"
    }
})(Tooltip)

export default function RecoveryPassword(props) {

    const { COLORS } = useColor()
    const [options, setAlert] = useAlert()
    const useStyles = makeStyles((theme) => (recoveryPasswordClass(COLORS)))

    const classes = useStyles()
    const [newPassword, setNewPassword] = React.useState("")
    const [confirmNewPassword, setConfirmNewPassword] = React.useState("")
    const [silentToken, setSilentToken] = React.useState("")
    const [showPassword, setShowPassword] = React.useState(false)
    const [anauthorized, setAnauthorized] = React.useState(false)

    React.useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const token = urlParams.get('token')
        setSilentToken(token)
    }, [])

    const changePassword = () => {
        const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
        if (!regex.test(newPassword)) {
            setAnauthorized(true)
        } else {
            serviceAdmin.putService("v1/auth/recover/password/", {
                password: newPassword,
                pwd_recovery_token: silentToken
            })
                .then(res => {
                    setAlert(true, "Password aggiornata!", "success")
                    props.history.push("/login")
                })
                .catch(err => {
                    console.error('[RecoveryPassword.changePassword]', err)
                })
        }
    }

    const handleChangeNewPassword = (event) => {
        setNewPassword(event.target.value)
    }

    const handleChangeConfirmNewPassword = (event) => {
        setConfirmNewPassword(event.target.value)
    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleChangeNewPassword()
        }
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    return (
        <div className={classes.container}>
            <Paper className={classes.paper}>
                <h2 className={classes.title}>Reimposta password</h2>
                <Grid container spacing={3}>
                    <Grid item xs={12} style={{ padding: "0 100px" }}>
                        <InputLabel className={classes.label}>
                            Nuova password
                            <CustomTooltip
                                title="La password deve contenere almeno 8 caratteri tra cui almeno una maiuscola, un numero ed un carattere speciale"
                            >
                                <InfoIcon style={{ fontSize: "19px" }} />
                            </CustomTooltip>
                        </InputLabel>
                        <CssTextField
                            autoComplete="on"
                            variant="outlined"
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            value={newPassword || ""}
                            onChange={handleChangeNewPassword}
                            placeholder={"nuova password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                className: classes.input_show
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ padding: "0 100px" }}>
                        <InputLabel className={classes.label}>
                            Conferma nuova password
                            </InputLabel>
                        <CssTextField
                            autoComplete="on"
                            variant="outlined"
                            type={showPassword ? 'text' : 'password'}
                            error={anauthorized ? true : false}
                            helperText={anauthorized ? "Controlla di aver rispettato i criteri" : ""}
                            name="password"
                            value={confirmNewPassword || ""}
                            onChange={handleChangeConfirmNewPassword}
                            placeholder={"conferma nuova password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                className: classes.input_show
                            }}
                        />
                    </Grid>
                </Grid>
                <div className={classes.containerWithPadding}>
                    <Button tabIndex="0" disabled={newPassword.length <= 8 || newPassword !== confirmNewPassword} onClick={changePassword} className={classes.btn_custom}>Reimposta</Button>
                </div>
            </Paper>
        </div>
    )
}