import React from 'react';
import { Button, Grid, Fade, Select, InputLabel, MenuItem } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import useColor from '../../context/themeContext'


const Filters = (props) => {
  const { COLORS } = useColor()
  
  const useStyles = makeStyles((theme) => ({
    color_label: {
      '&.Mui-selected': {
        color: COLORS.primary + "!important"
      }
    },
    label: {
      textAlign: "center",
      width: "100%",
      fontSize: "15px",
      marginBottom: "10px",
      "&.Mui-focused": {
        color: COLORS.primary + "!important"
      }
    },
    underline: {
      '&.MuiInput-underline:after': {
        borderBottom: "2px solid " + COLORS.primary + "!important"
      }
    },
    select: {
      '& .MuiSelect-root': {
        padding: "0px+!important",
        minWidth: "120px",
        borderRadius: "20px",
        backgroundColor: "#fff!important",
      }
    }, flex: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    checkbox: {
      color: COLORS.primary,
      "&.MuiIconButton-colorSecondary": {
        color: COLORS.primary,
      },
      "& .MuiSvgIcon-root": {
        color: COLORS.primary,
        '&:hover': {
          color: COLORS.primary,
          backgroundColor: "transparent"
        },
      },
      '&:hover': {
        color: COLORS.primary,
        backgroundColor: "transparent"
      },
    },
    button: {
      border: "2px solid" + COLORS.primary,
      height: "30px",
      color: "#0000008A",
      '&.Mui-disabled': {
        border: "2px solid" + COLORS.primary + "50",
      }
    }

  }));
  const classes = useStyles();

  return (
    <Fade timeout={{ appear: 400, enter: 400, exit: 0 }} in={props.show} style={!props.show ? { height: 0, margin: "30px 0px" } : { margin: "20px 0px" }}>

      <Grid container spacing={3} justify={"flex-start"}>

        {props.setGroup_id &&
          <Grid item xs={6} md={6} lg={2} className={classes.flex}>

            <InputLabel className={classes.label}>
              Filtra per gruppi:
            </InputLabel>
            <Select
              autoWidth
              MenuProps={{ getContentAnchorEl: () => null }}
              className={classes.select}
              onChange={(event) => {
                props.setPageUser(1);
                props.setGroup_id(event.target.value);
              }}
              value={props.group_id || ""}
              name="group_id"
              variant="outlined"
            >
              <MenuItem className={classes.color_label} value={""}>Tutti gli utenti</MenuItem>
              <MenuItem className={classes.color_label} value={"NO_GROUP"}>Utenti senza gruppo</MenuItem>
              {
                props.groups?.map((group, index) => {
                  return <MenuItem className={classes.color_label} key={index} value={group.id}>{group.name}</MenuItem>
                })
              }

            </Select>
          </Grid>
        }

        {props.setRole_id &&
          <Grid item xs={6} md={6} lg={2} className={classes.flex}>

            <InputLabel className={classes.label}>
              Filtra per ruolo:
                </InputLabel>
            <Select variant="outlined" autoWidth className={classes.select} onChange={(event) => { props.setPageUser(1); props.setRole_id(event.target.value); }} value={props.role_id || ""} name="role_id">
              <MenuItem className={classes.color_label} value={""}>Tutti i ruoli</MenuItem>
              {
                props.roles?.map((role, index) => {
                  return <MenuItem className={classes.color_label} key={index} value={role.id}>{role.name}</MenuItem>
                })
              }
            </Select>

          </Grid>
        }

        {props.setCheckedMandatory &&
          <Grid item xs={6} md={6} lg={2} className={classes.flex}>

            <InputLabel className={classes.label}>
              Corsi obbligatori
                </InputLabel>
            <Select variant="outlined" autoWidth className={classes.select} onChange={(event) => { props.setPageUser(1); props.setCheckedMandatory(event.target.value); }} value={props.checkedMandatory || ""} name="checkedMandatory">
              <MenuItem className={classes.color_label} value={""}>Tutti i corsi</MenuItem>
              <MenuItem className={classes.color_label} value={"false"}>Corsi Facoltativi</MenuItem>
              <MenuItem className={classes.color_label} value={"true"}>Corsi Obbligatori</MenuItem>
            </Select>

          </Grid>
        }
        {props.setCheckedCertification &&
          <Grid item xs={6} md={6} lg={2} className={classes.flex}>

            <InputLabel className={classes.label}>
              Corsi con attestato
                </InputLabel>
            <Select variant="outlined" autoWidth className={classes.select} onChange={(event) => { props.setPageUser(1); props.setCheckedCertification(event.target.value); }} value={props.checkedCertification || ""} name="checkedCertification">
              <MenuItem className={classes.color_label} value={""}>Tutti i corsi</MenuItem>
              <MenuItem className={classes.color_label} value={"false"}>Corsi senza attestato</MenuItem>
              <MenuItem className={classes.color_label} value={"true"}>Corsi con attestato</MenuItem>
            </Select>

          </Grid>
        }
        {props.setCheckedActive &&
          <Grid item xs={6} md={6} lg={2} className={classes.flex}>

            <InputLabel className={classes.label}>
              {props.course ? "Filtra per stato corso:" : "Filtra per stato utente:"}
            </InputLabel>
            <Select variant="outlined" autoWidth className={classes.select} onChange={(event) => { props.setCheckedActive(event.target.value) }} value={props.checkedActive || ""}>
              <MenuItem className={classes.color_label} value={""}>{props.course ? "Tutti i corsi" : "Tutti gli utenti"}</MenuItem>
              <MenuItem className={classes.color_label} value={"true"}>{props.course ? "Corsi attivi" : "Utenti attivi"}</MenuItem>
              <MenuItem className={classes.color_label} value={"false"}>{props.course ? "Corsi non attivi" : "Utenti non attivi"}</MenuItem>
            </Select>

          </Grid>
        }

        <Grid item xs={6} md={6} lg={2} className={classes.flex}>

          <InputLabel className={classes.label}>
            Ordina per:
                </InputLabel>
          {props.course ?
            <Select variant="outlined" autoWidth className={classes.select} onChange={(event) => { props.setPageUser(1); props.setOrdering(event.target.value); }} value={props.ordering || ""} name="ordering">
              <MenuItem className={classes.color_label} value={""}>Nessun ordinamento</MenuItem>
              <MenuItem className={classes.color_label} value={"name"}>Nome: crescente</MenuItem>
              <MenuItem className={classes.color_label} value={"-name"}>Nome: decrescente</MenuItem>
              <MenuItem className={classes.color_label} value={"created"}>Data creazione: crescente</MenuItem>
              <MenuItem className={classes.color_label} value={"-created"}>Data creazione: decrescente</MenuItem>
            </Select>
            :
            <Select variant="outlined" autoWidth className={classes.select} onChange={(event) => { props.setPageUser(1); props.setOrdering(event.target.value); }} value={props.ordering || ""} name="ordering">
              <MenuItem className={classes.color_label} value={""}>Nessun ordinamento</MenuItem>
              <MenuItem className={classes.color_label} value={"username"}>Username: crescente</MenuItem>
              <MenuItem className={classes.color_label} value={"-username"}>Username: decrescente</MenuItem>
              <MenuItem className={classes.color_label} value={"first_name"}>Nome: crescente</MenuItem>
              <MenuItem className={classes.color_label} value={"-first_name"}>Nome: decrescente</MenuItem>
              <MenuItem className={classes.color_label} value={"last_name"}>Cognome: crescente</MenuItem>
              <MenuItem className={classes.color_label} value={"-last_name"}>Cognome: decrescente</MenuItem>
              <MenuItem className={classes.color_label} value={"created"}>Data creazione: crescente</MenuItem>
              <MenuItem className={classes.color_label} value={"-created"}>Data creazione: decrescente</MenuItem>
              <MenuItem className={classes.color_label} value={"last_login"}>Data ultimo accesso: crescente</MenuItem>
              <MenuItem className={classes.color_label} value={"-last_login"}>Data ultimo accesso: decrescente</MenuItem>
            </Select>
          }

        </Grid>

        <Grid container item alignItems="center" xs={12} md={12} lg={2} className={classes.flex}>
          <Button disabled={!props.showReset} onClick={props.resetFilter} className={classes.button}>
            Azzera Filtri
                </Button>
        </Grid>

      </Grid>
    </Fade>
  )
}

export default Filters;