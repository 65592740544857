import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton'

const CardsSkeleton = () => {

    
    return (
        <React.Fragment>

            <div style={{ margin: "40px 0", display: "flex", justifyContent: "center" }}>
                <div style={{ padding: "10px" }}>
                    <Skeleton width={250} height={280} variant="rect" />
                </div>
                <div style={{ padding: "10px" }}>
                    <Skeleton width={250} height={280} variant="rect" />
                </div>
                <div style={{ padding: "10px" }}>
                    <Skeleton width={250} height={280} variant="rect" />
                </div>
                <div style={{ padding: "10px" }}>
                    <Skeleton width={250} height={280} variant="rect" />
                </div>
            </div>

            <div style={{ margin: "40px 0", display: "flex", justifyContent: "center" }}>
                <div style={{ padding: "10px" }}>
                    <Skeleton width={250} height={280} variant="rect" />
                </div>
                <div style={{ padding: "10px" }}>
                    <Skeleton width={250} height={280} variant="rect" />
                </div>
                <div style={{ padding: "10px" }}>
                    <Skeleton width={250} height={280} variant="rect" />
                </div>
                <div style={{ padding: "10px" }}>
                    <Skeleton width={250} height={280} variant="rect" />
                </div>
            </div>

        </React.Fragment>
    )


}
export default CardsSkeleton;