import React, { useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import Paper from '@material-ui/core/Paper'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import Grid from "@material-ui/core/Grid"
import Button from '@material-ui/core/Button'
import serviceAdmin from '../../services/serviceAdmin'
import { tabsStyle } from '../../classes'
import TextAreaField from '../StyledComponent/TextAreaField'
import CssTextField from '../StyledComponent/CssTextField'
import Switch from "../../componentsAdmin/Switch/Switch"
import useColor from '../../context/themeContext'



const TabNewTypology = (props) => {
    const { COLORS } = useColor();
    const useStyles = makeStyles({
        ...tabsStyle(COLORS),
        arrow: {
            width: "100%",
            height: "48px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            cursor: "pointer"
        },
        arrowicon: {
            color: "#999"
        }
    });

    const classes = useStyles();



    const [input, setInput] = useState({})

    let slugRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setInput({ ...input, [name]: value })
    }

    function clean(obj) {
        for (let propName in obj) {
            if (obj[propName] === "" || obj[propName] === undefined) {
                delete obj[propName];
            }
        }
    }


    const createTypology = () => {
        clean(input);

        serviceAdmin.postService("v1/course-typologies/", input)
            .then(res => {
                if (res.status === 201) {
                    props.setCustomAlert(true, "success", "Tipologia creata correttamente!");
                    props.setReload();
                    props.handleClose();
                }
            }).catch(err => {
                console.log(err)
            })
    }

    return (
        <React.Fragment>
            <Paper className={classes.root}>
                <div className={classes.arrow} onClick={props.handleClose}>
                    <ArrowBackIosIcon className={classes.arrowicon} />
                </div>
                <div className={classes.content}>
                    <h2 className={classes.title}>Crea una nuova tipologia</h2>
                    <div style={{ paddingTop: "20px" }}>
                        <Grid container spacing={3}>
                            <Grid spacing={3} container item xs={12} lg={6}>

                                <Grid className="flex-center" item xs={12} md={4}>
                                    <InputLabel className={classes.label}>
                                        Nome*
                                    </InputLabel>
                                    <CssTextField name="name" value={input.name || ""} onChange={handleChange} placeholder="Nome della tipologia" variant="outlined" inputProps={{ className: classes.input }} />
                                </Grid>
                                <Grid className="flex-center" item xs={12} md={4}>
                                    <InputLabel className={classes.label}>
                                        Slug
                                    </InputLabel>
                                    <CssTextField name="slug" value={input.slug || ""} onChange={handleChange} placeholder="Slug della tipologia" variant="outlined" inputProps={{ className: classes.input }} />
                                </Grid>

                                <Grid className="flex-center" item xs={12} md={4}>
                                    <InputLabel className={classes.label}>
                                        Ordinamento corsi
                                    </InputLabel>
                                    <Switch name={"is_ascending_course_ordering"} enableLabel={"CRESCENTE"} disableLabel={"DECRESCENTE"} checked={input.is_ascending_course_ordering || false} handleToggleChecked={() => setInput({...input, is_ascending_course_ordering: !input.is_ascending_course_ordering})} />
                                </Grid>

                                <Grid className="flex-center" item xs={12} style={{ paddingTop: "20px" }}>
                                    <InputLabel className={classes.label}>
                                        Descrizione
                                    </InputLabel>
                                    <TextAreaField name="description" value={input.description || ""} onChange={handleChange} placeholder="Descrizione della tipologia" multiline rows={5} variant="outlined" inputProps={{ className: classes.textarea }} />
                                </Grid>

                            </Grid>

                        </Grid>
                    </div>

                    <div className={classes.container_btn}>
                        <Button onClick={props.handleClose} className={classes.btn_del}>Annulla</Button>
                        <Button disabled={(slugRegex.test(input.slug) || input.slug === "") && input.name ? false : true} onClick={createTypology} className={classes.btn_save}>Crea</Button>
                    </div>
                </div>
            </Paper>
        </React.Fragment>
    )
}

export default TabNewTypology;