import React, { useMemo, useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import BorderTextField from '../StyledComponent/BorderTextField'
import {
  Popover,Breadcrumbs,Button, Paper, Table, TableContainer, TableBody, TableCell, TableHead,
  TableRow, TablePagination, IconButton, Tabs, Tab, Grid, CircularProgress,Tooltip
} from '@material-ui/core/';
import useColor from '../../context/themeContext'
import Backdrop from '@material-ui/core/Backdrop';
import { HighlightOff, CheckCircle, DeleteForever, BallotOutlined, Create, FileCopy } from '@material-ui/icons';
import serviceAdmin from '../../services/serviceAdmin';
import Builder from '../QuestionBuilder'
import Dialog from "../Dialog/DialogD"
import PropTypes from 'prop-types';
import moment from 'moment'
import 'moment/locale/it';
import useAlert from '../../hooks/useAlert';
import { tableStyle, tabsStyle } from '../../classes'
import Optional from '../TabNewTest/Optional';
moment.locale('it')



function TabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div p={3}>
          {children}
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function TableImport(props) {
  const { COLORS } = useColor();
  const useStyles = makeStyles({
    ...tableStyle(COLORS),
    paper: {
      padding: "20px",
      background: "#fff",
      borderRadius: "20px",
      overflow: "auto"
    },
    tabs: {
      width: "95%",
      float: "left",
      "& .MuiTabs-indicator": {
        backgroundColor: COLORS.primary + "!important"
      }
    },
    arrow: {
      width: "5%",
      float: "left",
      height: "48px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer"
    },
    arrowicon: {
      color: "#999"
    },
    tab: {
      color: "#999",
      fontWeight: "bold",
      fontSize: "20px",

      "&.Mui-selected": {
        color: COLORS.primary + "!important"
      }
    },
    content: {
      clear: "both"
    }
    , link: {
      display: "flex"
    },
    icon_style:{
      margin:"0px 5px"
    },
    container_pop:{
      padding:"5px 20px",
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      flexDirection:"column"
    },
    btn_pop:{
      backgroundColor:"#3fbe54",
      color:"#ffffff",
      "&:hover": {
        backgroundColor:"#3fbe54",
        color:"#ffffff",
      },
      "&:focus": {
        backgroundColor:"#3fbe54",
        color:"#ffffff",
      },
    },
    load_circle:{
      color:"#ffffff",
      opacity: .6,
      fontSize:18
    }
  });
  const classes = useStyles();

  const [options, setAlert] = useAlert()
  const timer = useRef(null)
  const { editor, setEditor } = props;
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [surveyId, setSurveyId] = useState(null)
  const [loadClone, setLoadClone] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [input, setInput] = useState({})
  const [clone_name, setCloneName] = useState("")
  const [clone_id, setCloneId] = useState(null)


  const updateQuestion = (input) => {
    let params = {}
    if (props.value) {
      // EVALUATIONS
      params = input
      delete params.created
      delete params.updated
      delete params.questions
      if (params?.max_retries) {
        params.max_retries = Number(params.max_retries)
      }
      if (params?.question_bank_question_number) {
        params.question_bank_question_number = Number(params.question_bank_question_number)
      }
    } else {
      // SURVEYS
      params = {
        name: input.name,
        is_editable: input.is_editable,
        is_sequential: input.is_sequential
      }
    }
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      const endpoint = props.value ? 'evaluations' : 'surveys'
      if (editor?.id) {
        serviceAdmin.putService(`v1/${endpoint}/${editor?.id}/`, params)
          .then(res => {
            if (res.status === 200) {
              props.setReload()
            }
          }).catch(err => {
            console.log(err)
          })
      }
    }, 400)
  }

  const openDialog = (id) => {
    setSurveyId(id);
    setDeleteDialog(true);
  }

  const handleClose = () => {
    setDeleteDialog(false);
  }

  const handleClosePop = () => {
    setAnchorEl(null);
  };


  const handleChangePage = (event, newPage) => {
    props.setPageSurveys(newPage + 1)
    props.setBackdrop(true);
  };

  const handleChangeRowsPerPage = (event) => {
    props.setRows(parseInt(event.target.value, 10));
    props.setPage(0);
    props.setBackdrop(true);
  };

  const deleteSurvey = () => {
    const endpoint = props.value ? 'evaluations' : 'surveys'
    if (surveyId) {
      serviceAdmin.deleteService(`v1/${endpoint}/${surveyId}/`)
        .then(res => {
          if (res.status === 200) {
            props.setReload();
            handleClose();
          }
        }).catch(err => {
          console.log(err)
        })
    }
  }

  const fetchDataDetails = (id) => {
    const endpoint = props.value ? 'evaluations' : 'surveys'
    serviceAdmin.getService(`v1/${endpoint}/${id}/`)
      .then(res => setEditor(res.data.data))
      .catch(err => console.log(err))
  }


  const openPop=(event,id)=>{
    setCloneId(id)
    setAnchorEl(event.currentTarget)
  }

  const handleChangeCloneName=(e)=>{
    const value= e.target.value
    setCloneName(value)
  }

  const cloneSurvey=()=>{
    setLoadClone(true)
    serviceAdmin.postService(`v1/surveys/${clone_id}/clone/`,{
      "name": clone_name
    })
    .then(res => {
      if(res){
        setLoadClone(false)
        props.setReload()
        setCloneName("")
        setAnchorEl(null)
        setAlert(true, "Operazione avvenuta con successo!", "success")
      }
    })
    .catch(err => setLoadClone(false))
  }

  const handleChange = (event, newValue) => {
    props.setValue(newValue);
  };

  const handleChangeInput = (e) => {
    const name = e.target.name;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const isOnlyNumber = name === ("max_retries" || "question_bank_question_number")

    if (isOnlyNumber) {
      const reg = /^[0-9\b]+$/;
      if (!reg.test(value) && value !== "") {
        value = "0"
      }
    }

    if(name == "retries_not_limited")
    {
      if(value)
      {
        setInput({ ...input, [name]: value, "max_retries": -1 })
        updateQuestion({ ...input, "max_retries": -1 })
      }
      else
      {
        setInput({ ...input, [name]: value })
      }
      return
    }


    setInput({ ...input, [name]: value })
    if (value === "" && isOnlyNumber) value = "0";
    updateQuestion({ ...input, [name]: value })
  }

  const handleChangeSlide = (e, value) => {
    setInput({ ...input, "perc_correct_questions": value })
    updateQuestion({ ...input, "perc_correct_questions": value })
  }

  useEffect(() => {
    setInput({ ...input, ...editor })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor])

  const open = Boolean(anchorEl);

  const n_questions = input.questions ? Object.keys(input?.questions)?.length : 0;

  const table = useMemo(() => {
    return (
      <React.Fragment>
        {!editor ?
          <Paper className={classes.root}>
            <Tabs
              className={classes.tabs}
              value={props.value}
              onChange={handleChange}
              scrollButtons="auto"
              variant="scrollable"
            >
              <Tab className={classes.tab} label="Sondaggi" {...a11yProps(0)} />
              <Tab className={classes.tab} label="Verifiche" {...a11yProps(1)} />
            </Tabs>
            <TableContainer className={classes.container}>
              <Backdrop style={{ zIndex: "2", position: "absolute", borderRadius: "20px", backgroundColor: "#fff", opacity: "0.4" }} open={props.backdrop}>
                <CircularProgress style={{ color: COLORS.primary }} />
              </Backdrop>
              <Table size={"small"} className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {props.headcell.map((row, index) => (
                      <TableCell key={index} className={classes.table_label} align="center">{row}</TableCell>
                    ))
                    }
                    <TableCell className={classes.table_label} align="center" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    props.dataTable.map((row, index) => {
                      return (
                        <TableRow hover key={index}>
                          <TableCell className={classes.label_cell} align="center">{row?.name}</TableCell>
                          <TableCell className={classes.label_cell} align="center">{moment(row?.created).format("DD/MM/YY")}</TableCell>
                          <TableCell className={classes.label_cell} align="center">
                            {row.is_editable ? <CheckCircle style={{ color: COLORS.primary, marginLeft: "15px" }} /> : <HighlightOff style={{ color: COLORS.primary, marginLeft: "15px" }} />}
                          </TableCell>
                          <TableCell style={{display:"flex"}} className={classes.label_cell} align="right">
                           <IconButton style={{marginRight:"20%"}} className={classes.icon_style} onClick={() => fetchDataDetails(row.id)}>
                              <Create />
                            </IconButton>
                            { !props.value &&
                            <Tooltip placement={"top"} title="Clona sondaggio" aria-label="Clona sondaggio">
                              <IconButton className={classes.icon_style} onClick={(e) => openPop(e,row.id)}>
                                <FileCopy />
                              </IconButton>
                            </Tooltip>
                            }
                            <IconButton className={classes.icon_style} onClick={() => openDialog(row.id)}>
                              <DeleteForever />
                            </IconButton>
                          
                           
                          </TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
                           <Popover
                              id={"clone-survey"}
                              open={open}
                              anchorEl={anchorEl}
                              onClose={handleClosePop}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'top',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                              }}
                            >
                              <div className={classes.container_pop}>
                                <BorderTextField value={clone_name} onChange={handleChangeCloneName} variant="outlined"/>
                                <Button onClick={cloneSurvey} className={classes.btn_pop}>
                                  {loadClone ? 
                                    <CircularProgress size={23} className={classes.load_circle} />
                                    :
                                    "Crea"
                                  }
                                </Button>
                              </div>
                            </Popover>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={props.count}
              rowsPerPage={props.rows}
              page={props.page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage={"Righe per pagina: "}
              classes={{ menuItem: classes.selected }}
            />
          </Paper>
          :
          <div>
            <Paper className={classes.optionsView}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href={"#"} onClick={() => setEditor(false)} className={classes.link}>
                  <BallotOutlined className={classes.icon} />
                  <span className={classes.span}>{props.value === 0 ? "Sondaggi" : "Verifiche"}</span>
                </Link>
                <Link color="inherit" className={classes.link_active}>
                  <span className={classes.span}>
                    {input?.name}
                  </span>
                </Link>
              </Breadcrumbs>
              <div style={{ paddingTop: "20px" }}>
                <Grid container spacing={3}>
                  <Grid container spacing={3} item xs={12}>
                    <Optional n_questions={n_questions} isEvaluation={props.value} handleChangeSlide={handleChangeSlide} handleChangeInput={handleChangeInput} input={input} />
                  </Grid>
                </Grid>
              </div>
            </Paper>
            <Paper className={classes.root}>
              <Builder
                setReload={props.setReload}
                dataBuilder={editor}
                setCustomAlert={props.setCustomAlert}
                isEvaluation={props.value}
                fetchDataDetails={fetchDataDetails}
              />
            </Paper>
          </div>
        }
        <Dialog
          textDelete={"il sondaggio"}
          deleteDialog={deleteDialog}
          handleClose={handleClose}
          callback={deleteSurvey}
        />
      </React.Fragment>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataTable, props.rows, props.page, editor, deleteDialog, props.value, input, classes,open])

  return table
}