import React from "react"
import useAlert from './useAlert'
import { Container, Draggable } from "react-smooth-dnd";
import arrayMove from "array-move";


const useDrag = (drag_class, array, setArray, fetchUpdate, reload = () => { }) => {

	const [options, setAlert] = useAlert()

	const handleDrop = (dropResult) => {
		const newRows = arrayMove(array, dropResult.removedIndex, dropResult.addedIndex);

		if (JSON.stringify(newRows) !== JSON.stringify(array)) {
			setArray(newRows)
			if (newRows.length > 1) {
				newRows.map((row, index) => {
					fetchUpdate(row, index)
						.then(async res => {
							if (res) {
								if (newRows.length === index + 1) {
									reload()
								}
								setAlert(true, "Ordinamento aggiornato!", "success")
							}
						}).catch(err => {
							console.log("{ORDER update}", err);
						})
				})
			}
		}
	};

	const DropContainer = ({ children }) => {
		return (
			<Container
				lockAxis="y"
				dragHandleSelector={"." + drag_class}
				onDrop={handleDrop}
				dropPlaceholder={{
					animationDuration: 150,
					showOnTop: true
				}}
				dropPlaceholderAnimationDuration={200}
			>
				{children}
			</Container>
		)
	}

	const DropElement = ({ children, key }) => {
		return (
			<Draggable key={key}>
				{children}
			</Draggable>
		)
	}


	return {
		DropContainer,
		DropElement,
		drag_class,
		handleDrop
	}

}

export const DropElement = ({ children, key }) => {
	return (
		<Draggable key={key}>
			{children}
		</Draggable>
	)
}

export const DropContainer = ({ children, handleDrop, drag_class }) => {
	return (
		<Container
			lockAxis="y"
			dragHandleSelector={"." + drag_class}
			onDrop={handleDrop}
			dropPlaceholder={{
				animationDuration: 150,
				showOnTop: true
			}}
			dropPlaceholderAnimationDuration={200}
		>
			{children}
		</Container>
	)
}

export default useDrag;