import React from "react"
import { darken, makeStyles } from '@material-ui/core/styles'
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import { Button, Typography } from "@material-ui/core";
import socketClient from "../../services/socketClient";
import helpers from "./helpers";


const useStyles = makeStyles({
    root: {
        position: "relative",
        minHeight: "400px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        background: "#ffffff"
    },
    title: {
        marginBottom: "25px"
    },
    DescriptionIcon: {
        height: "130px",
        width: "100px",
        color: "#3fbe54"
    },
    buttonDownload: {
        background: "#3fbe54",
        color: "#ffffff",
        '&:hover': {
            background: darken("#3fbe54", 0.10),
            color: "#ffffff",
        }
    }
});

const FileSession = (props) => {
    const classes = useStyles();

    const { src, name, lesson_id, duration, onEnd, is_preview} = props;

    const onDownloadFile = () => {
        if(!is_preview){
            helpers.buildSessionSocket(lesson_id, evt => {})
            .then(res => {
                socketClient.sendPlayMedia(res)
                .then(() => {
                    sendStopMediaEndSectionAndDisconnect(res)
                })
            })
        }
    }

    const sendStopMediaEndSectionAndDisconnect = (socket) => {
        if(!is_preview){
            const time = helpers.convertTimeStringToSeconds(duration)
            socketClient.sendStopMedia(socket, time)
            .then(() => {
                socketClient.sendEndSection(socket, time)
                .then(() => {
                    socketClient.asyncDisconnect(socket)
                    .then(() => {
                        onEnd()
                    })
                })
            })
        }
    }

    return (
        <div className={classes.root}>

            <DescriptionIcon className={classes.DescriptionIcon} />
            <Typography className={classes.title} variant={"h6"}>{name || "Senza nome"}</Typography>


            <Button href={src} target="_blank" className={classes.buttonDownload} onClick={() => onDownloadFile()}>
                SCARICA FILE
			</Button>

        </div>
    )
}

export default FileSession;