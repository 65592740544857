import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import TableLikert from './TableLikert'


const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        justifyContent: "center",
    }
}));


const Likert = (props) => {
    const classes = useStyles();
    const [width, setwidth] = React.useState(window.innerWidth)

    const getWidth = () => window.innerWidth;

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        props.setPrevResponse({
            ...props.prevResponse,
            [props.index]: { [name]: value }
        })
    }

    useEffect(() => {
        if (props.prevResponse[props.index]) {
            props.setResponse(true);
            props.setallresponse(true, props.index)
        } else {
            props.setResponse(false);
            props.setallresponse(false, props.index)
        }

        return () => {
            props.setResponse(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.prevResponse])

    useEffect(() => {

        const resizeListener = () => {
            setwidth(getWidth())
        };
        window.addEventListener("resize", resizeListener)

        return () => {
            window.removeEventListener("resize", resizeListener)
        }
    }, [])

    return (

        <Paper style={{ minHeight: "400px", borderRadius: "0px", paddingBottom: "20px" }}>

            {props?.question ?
                <h3 style={{ padding: "80px 40px 10px", textAlign: "left" }}>
                    {props?.question}
                </h3>
                :
                null
            }

            <div style={width > 600 ? { padding: "30px 40px 0px" } : { padding: "30px 10px 0px" }} className={classes.root} >

                <TableLikert
                    data={props.data}
                    setData={props.setData}
                    prevResponse={props.prevResponse}
                    setPrevResponse={props.setPrevResponse}
                    answers={props.answers}
                    questions={props.questions}
                    handleChange={handleChange}
                    index={props.index}
                />

            </div>
        </Paper>

    )

}
export default Likert;