import { config } from "dotenv"

config()

const WS_URL = process.env.REACT_APP_WS_URL || "ws://localhost/ws-api/"
const API_URL = process.env.REACT_APP_BASE_URL || "/api/"


export default {
    WS_URL,
    API_URL
}