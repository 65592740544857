import React, { useEffect, useState } from 'react';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Service from '../services/services';
import { Redirect } from 'react-router-dom';


const Control = (props) => {

    const [basic, setBasic] = useState(null)
    const [isSingleCourse, setIsSingleCourse] = useState(false)

    const queryParams = new URLSearchParams(window.location.search)
    const token = queryParams?.get("token");
    const course_id = queryParams?.get("corso")

    if (course_id && token) {
        if (!basic) { setBasic(token) }

    } else if (token) {
        if (!basic) { setBasic(token) }
    } else if (localStorage.getItem("course_fit_id")) {
        props.history.push("/corso");

    } else {
        props.history.push("/corsi");
    }

    useEffect(() => {
        if (basic) {
            const params = { token: basic };
            Service.postService("v1/auth/silent-login/", params)
                .then(res => {
                    const resource = res.data.data;
                    if (resource.user.role.tier === "BO_COM") {
                        localStorage.clear();
                        localStorage.setItem("time_fit_token", new Date().getTime());
                        localStorage.setItem("bearer_fit_token", resource.auth.token);
                        localStorage.setItem("refresh_fit_token", resource.auth.refresh);
                        localStorage.setItem("courses_fit_id", true);
                        localStorage.setItem("admin_fit_id", true);

                        if (resource.user.trigger_change_password) {
                            if (course_id) {
                                localStorage.setItem("course_fit_id", true);
                                props.history.push(`/reset/?next=${course_id}`)
                            } else {
                                props.history.push("/reset")
                            }
                        } else {
                            if (course_id) {
                                localStorage.setItem("course_fit_id", true);
                                setIsSingleCourse(true)
                            } else {
                                props.history.push("/backoffice/users")
                            }
                        }

                    } else if (resource.user.role.tier === "BASE_LOG" || resource.user.role.tier === "BASE_NO_LOG") {
                        localStorage.clear();
                        localStorage.setItem("time_fit_token", new Date().getTime());
                        localStorage.setItem("bearer_fit_token", resource.auth.token);
                        localStorage.setItem("refresh_fit_token", resource.auth.refresh);
                        if (resource.user.trigger_change_password) {
                            if (course_id) {
                                localStorage.setItem("courses_fit_id", true);
                                props.history.push(`/reset/?next=${course_id}`)
                            } else {
                                localStorage.setItem("courses_fit_id", true);
                                props.history.push("/reset")
                            }
                        } else {
                            if (course_id) {
                                localStorage.setItem("course_fit_id", true);
                                setIsSingleCourse(true)
                            } else {
                                localStorage.setItem("courses_fit_id", true);
                                props.history.push("/corsi")
                            }
                        }

                    } else if (resource.user.role.tier === "BO_LIM") {
                        localStorage.clear();
                        localStorage.setItem("time_fit_token", new Date().getTime());
                        localStorage.setItem("bearer_fit_token", resource.auth.token);
                        localStorage.setItem("refresh_fit_token", resource.auth.refresh);
                        localStorage.setItem("courses_fit_id", true);
                        localStorage.setItem("admin_lim_fit_id", true);

                        if (resource.user.trigger_change_password) {
                            if (course_id) {
                                localStorage.setItem("course_fit_id", true);
                                props.history.push(`/reset/?next=${course_id}`)
                            } else {
                                props.history.push("/reset")
                            }
                        } else {
                            if (course_id) {
                                localStorage.setItem("course_fit_id", true);
                                setIsSingleCourse(true)
                            } else {
                                props.history.push("/backoffice-lite/export")
                            }
                        }
                    } else {
                        localStorage.clear();
                        props.history.push("/no-access")
                    }

                }).catch(err => {

                    props.history.push("/no-access")

                }).finally(()=>{
                    props.setReload()
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [basic])

    return (
        <React.Fragment>

            <div className="spinner_load">
                <AutorenewIcon className="spinner_animate" />
            </div>

            {isSingleCourse ?
                <Redirect to={{ pathname: "/corso", state: { course_id: course_id } }} />
                :
                null
            }
        </React.Fragment>

    )

}

export default Control;