import React from 'react';
import Image from '../components/Image/Image';
import Logo from '../assets/img/Section.jpg';
import { Grid, Container } from '@material-ui/core';
import { useEffect, useState, useRef } from 'react';
import Service from '../services/services'
import CardsSkeleton from '../components/Cards/CardsSkeleton';
import CardCourse from '../components/Cards/CardCourse';
import courseHelpers from "./helpers/courses"
import useColor from '../context/themeContext';
import SearchInput from '../componentsAdmin/Search/SearchInput';


const Courses = (props) => {
    const { COLORS } = useColor()

    const isMount = useRef(true);

    const [subscriptions, setSubscriptions] = useState([])
    const [isWithTypology, setIsWithTypology] = useState(false)
    const [searchString, setSearchString] = useState("")
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        Service.getService("v1/users/me/")
            .then(res => {
                if (isMount.current) {
                    if (res.data.data) {
                        if (res.data.data.trigger_change_password === true) {
                            props.history.push("/reset")
                        }
                    }
                }
            }).catch(err => {
                console.log(err);
            });

        return () => {
            isMount.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (isMount.current) {
            courseHelpers.orderSubscriptionsByTypologies(searchString)
                .then(orderedSubs => {
                    console.log(orderedSubs)
                    setIsWithTypology(orderedSubs.withTypology)
                    setSubscriptions(orderedSubs.subscriptions)
                    setLoading(false)
                })
        }
    }, [searchString])

    const handleChangeSearchString = (event) => {
        setSearchString(event.target.value)
    }

    const renderCardCourse = (subscriptions) => {
        if (subscriptions) {
            return subscriptions.map((course, index) => {
                return (
                    <div key={index} className={"container_course"} style={{ background: '#EEE' }}>
                        <CardCourse
                            has_expired={course?.has_expired}
                            expiration={course?.expiration_date}
                            course_has_expired={course?.course?.has_expired}
                            course_expiration={course?.course?.expiration_date}
                            is_passed={course?.is_passed}
                            title={course?.course?.name}
                            id={course?.course?.id}
                            url={course?.course?.thumbnail?.thumbnail}
                        />
                    </div>
                )
            })
        } else {
            return <span>Non ci sono corsi</span>
        }
    }

    return (
        <React.Fragment>
            <div>
                <Image
                    src={Logo}
                    height="250px"
                    alt="logo"
                    size="contain"
                    title="I miei corsi"
                    subtext="I corsi a cui sei iscritto"
                />

                <Container maxWidth="lg" style={window.innerWidth < 600 ? { marginBottom: "30px", padding: 0 } : { marginBottom: "30px" }}>
                    <Grid container spacing={0} style={{ paddingTop: "24px" }}>
                        <Grid item xs={12} style={{ paddingBottom: "60px", paddingTop: "20px" }}>
                            <SearchInput searchString={searchString} placeholder={"Cerca tra i corsi"} onChange={handleChangeSearchString} />
                        </Grid>
                        <Grid item xs={12}>

                            {loading && window.innerWidth > 650 ?

                                <CardsSkeleton />

                                :

                                <React.Fragment>
                                    {isWithTypology ?
                                        subscriptions.map((sub, index) => {
                                            const existSub = sub.subscriptions.length > 0;
                                            return (
                                                <div key={index}>
                                                    <span className={"title-typology"} style={{ color: COLORS.primary }}>{sub.name ? sub.name : (existSub ? "Nessuna tipologia" : null)}</span>
                                                    <div className={"card-container"}>
                                                        {renderCardCourse(sub.subscriptions)}
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        renderCardCourse(subscriptions)
                                    }

                                </React.Fragment>

                            }

                        </Grid>
                    </Grid>
                </Container>

            </div>
        </React.Fragment >
    )


}

export default Courses