import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteDialog from '../Dialog/DeleteDialog';
import ModalTypology from '../Modal/ModalTypology';
import { tableStyle } from '../../classes'
import useColor from '../../context/themeContext'
import useDrag, { DropElement, DropContainer } from '../../hooks/useDrag';
import serviceAdmin from '../../services/serviceAdmin';


export default function TableCoursesTypologies(props) {

  const { COLORS } = useColor()
  const useStyles = makeStyles({
    ...tableStyle(COLORS),
    
    head: {
      clear: "both",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      padding: "30px 0 10px",
      '& div': {
        marginRight: "80px",
        minWidth: "25%"
      }
    },
    row: {
      clear: "both",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      padding: "5px 0",
      borderTop: "1px solid #ccc",
      '& div': {
        minWidth: "25%"
      }
    }
  });
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [rows, setRows] = React.useState([]);
  const [searchString, setSearchString] = React.useState("")
  const [deleteCourse, setDelete] = useState(false);
  const [course, setCourse] = useState({});
  const [open, setOpen] = useState(false);

  const { drag_class, handleDrop } = useDrag("typology-handle-drag", rows, setRows, handleDropUpdate, props.setReloadTypo)

  React.useEffect(() => {
    setSearchString(props.filter)
  }, [props.filter])


  React.useEffect(() => {
    if (props.typologies.length >= 0) {
      setRows(
        props.typologies.map((typology) => {

          return {
            "slug": typology.slug,
            "name": typology.name,
            "description": typology.description,
            "is_ascending_course_ordering": typology.is_ascending_course_ordering,
            "id": typology.id,
          }
        })
      )

    }
  }, [props.typologies])

  async function handleDropUpdate(el, index) {
    try {
      const data = await serviceAdmin.putService(`/v1/course-typologies/${el.id}/`, {
        "order": index,
        "name": el.name,
      })
      return data
    } catch (err) {
      console.log(err)
    }
  }

  const items = rows.filter(item => {
    return () => {
      if (!searchString) {
        return item
      } else if (item.code.includes(searchString)) {
        return item
      }
    }
  })

  const setdelete = (input) => { setDelete(input) }
  const setopen = (input) => { setOpen(input) }

  const modifyCourse = (e, row) => {
    setAnchorEl(e.currentTarget)
    setCourse(row)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleopen = (e) => {
    e.stopPropagation();
    setopen(true);
    setAnchorEl(null);
  }


  return (
    <React.Fragment>

      <div className={classes.container}>
        <div className={classes.table} aria-label="simple table">
          <div className={classes.head}>
            <div className={classes.table_label}>Nome tipologia</div>
            <div className={classes.table_label+" hide-column-xs"}>Slug</div>
            <div className={classes.table_label+" hide-column-md"}>Descrizione</div>
            <div className={classes.table_label}></div>
          </div>
          <div>
            <DropContainer handleDrop={handleDrop} drag_class={drag_class}>
              {items.map((row, index) => (
                <DropElement key={index}>
                  <div className={classes.row + " grab " + drag_class} >
                    <div className={classes.label_cell}>{row.name}</div>
                    <div className={classes.label_cell+" hide-column-xs"}>{row.slug}</div>
                    <div className={classes.label_cell+" hide-column-md"}>
                      {
                        ((row.description)?.length > 40) ?
                          (false ? row.description : ((row.description)?.substring(0, 40 - 3) + "...")) :
                          row.description
                      }
                    </div>
                    <div style={{marginLeft:"auto"}} className={classes.label_cell}>
                      <MoreHorizIcon onClick={(e) => modifyCourse(e, row)} style={{ display:"flex",marginLeft:"auto",width: "30px",marginRight:20, height: "30px", color: "#999", cursor: "pointer" }} />
                    </div>
                  </div>
                </DropElement>
              ))}
            </DropContainer>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              <MenuItem className={classes.menu_table} value={4} onClick={handleopen}>Modifica tipologia</MenuItem>
              <MenuItem className={classes.menu_table_del} onClick={() => setdelete(true)}>Elimina tipologia</MenuItem>
            </Menu>
            <TableRow style={{ padding: "20px" }}>
              <TableCell style={{ borderBottom: "none" }} s>Totali {rows.length}</TableCell>
            </TableRow>
            <DeleteDialog
              deleteLesson={deleteCourse}
              setDelete={setdelete}
              textDelete={"la tipologia"}
              handleCloseMenu={handleCloseMenu}
              setConfirm={props.setConfirm}
              lesson={course}
            />
          </div>
        </div>
      </div>

      <ModalTypology setReload={props.setReloadTypo} typology={course} setOpen={setopen} open={open} />
    </React.Fragment >
  );
}