import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import serviceAdmin from '../../services/serviceAdmin'
import { tabsStyle } from '../../classes'
import BorderTextField from '../StyledComponent/BorderTextField'
import useColor from '../../context/themeContext'


const TabsnewGroup = (props) => {
    const { COLORS } = useColor();
    const useStyles = makeStyles({
        ...tabsStyle(COLORS),
        arrow: {
            width: "100%",
            height: "48px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            cursor: "pointer"
        },
        arrowicon: {
            color: "#999"
        }
    });
    const classes = useStyles();

    const [input, setInput] = useState({})
    const [conflict, setConflict] = useState(false)

    let slugRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setInput({ ...input, [name]: value })
    }

    function clean(obj) {
        for (let propName in obj) {
            if (obj[propName] === "" || obj[propName] === undefined) {
                delete obj[propName];
            }
        }
    }

    const createGroup = () => {
        const params = {
            ...input,
        }
        clean(params);

        serviceAdmin.postService("v1/groups/", params)
            .then(res => {
                if (res.status === 201) {
                    props.setReload();
                    props.handleClose();
                    props.setCustomAlert(true, "success", "Gruppo creato con successo!")
                    setConflict(false)
                }
            }).catch(err => {
                if (err.status === 409 || err.status === 400) {
                    if (err.data.reason.includes("slug")) {
                        props.setCustomAlert(true, "warning", "Lo slug scelto è gia in uso!");
                        setConflict(true)
                    }
                }
            })
    }

    return (
        <React.Fragment>
            <Paper className={classes.root}>
                <div className={classes.arrow} onClick={props.handleClose}>
                    <ArrowBackIosIcon className={classes.arrowicon} />
                </div>
                <div className={classes.content}>
                    <h2 className={classes.title}>Crea un nuovo gruppo</h2>
                    <div style={{ paddingTop: "20px" }}>
                        <Grid container spacing={3}>

                            <Grid className="flex-center" item xs={12} md={6} lg={3}>
                                <InputLabel className={classes.label}>
                                    Nome
                                </InputLabel>
                                <BorderTextField name="name" value={input.name || ""} onChange={handleChange} placeholder="Nome del gruppo" variant="outlined" inputProps={{ className: classes.input }} />
                            </Grid>

                            <Grid className="flex-center" item xs={12} md={6} lg={3}>
                                <InputLabel className={classes.label}>
                                    Slug
                                </InputLabel>
                                <BorderTextField error={conflict ? true : false} helperText={conflict ? "Lo slug scelto è già in uso" : ""} name="slug" value={input.slug || ""} onChange={handleChange} placeholder="Slug del gruppo" variant="outlined" inputProps={{ className: classes.input }} />
                            </Grid>


                            <Grid className="flex-center" item xs={12} md={7}>
                                <InputLabel className={classes.label}>
                                    Descrizione
                                </InputLabel>
                                <BorderTextField name="description" value={input.description || ""} onChange={handleChange} placeholder="Descrizione del gruppo" multiline rows={5} variant="outlined" inputProps={{ className: classes.textarea }} />
                            </Grid>


                        </Grid>
                    </div>

                    <div className={classes.container_btn}>
                        <Button onClick={props.handleClose} className={classes.btn_del}>Annulla</Button>
                        <Button disabled={(slugRegex.test(input.slug) || input.slug === "") && input.name ? false : true} onClick={createGroup} className={classes.btn_save}>Crea</Button>
                    </div>
                </div>
            </Paper>
        </React.Fragment>
    )
}

export default TabsnewGroup;