import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  progress: {
    height: "200px!important",
    width: "200px!important",
    '& svg .MuiCircularProgress-circleStatic': {
      transition: "stroke-dashoffset 1200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms!important"
    }
  },
});


function CircularProgressWithLabel(props) {
  const classes = useStyles();
  const { is_passed } = props;
  return (
    <Box style={{ height: "200px", width: "200px" }} position="relative" display="inline-flex">
      <CircularProgress style={is_passed ? {color: "#3fbe54"} : {color: "#FF0000"}} className={classes.progress} variant="static" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography style={{ fontSize: "30px" }} variant="caption" component="div" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function CircularStatic(props) {
  const [progress, setProgress] = React.useState(0);

  useLayoutEffect(() => {
    if(props.percentage){
      setTimeout(() => {
        setProgress(props.percentage)
      }, 400)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.percentage])

  return <CircularProgressWithLabel is_passed={props.is_passed} value={progress} />;
}