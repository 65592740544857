import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Radio from '@material-ui/core/Radio';
import { useEffect } from 'react';
import useColor from '../../context/themeContext'


const useStyles = makeStyles({
  padding: {
    padding: "5px!important"
  }
});



export default function SimpleTable(props) {

  const { COLORS } = useColor()
  const BlueRadio = withStyles({
    root: {
      color: COLORS.primary,
      '&$checked': {
        color: COLORS.primary,
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);
  const classes = useStyles();

  const [values, setValues] = React.useState(props?.prevResponse[props.index]?.answers || {});

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value })
    props.setData({ ...props.data, [name]: Number(value) })
  }

  useEffect(() => {
    if (Object.keys(values)?.length === props.questions.length) {
      props.setPrevResponse({
        ...props.prevResponse,
        [props.index]: {
          "answers": values
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {
              props.answers.map((answer, index) => {

                return <TableCell className={classes.padding} style={{ fontWeight: "bold" }} key={index} align="center">{answer.text}</TableCell>
              })
            }
          </TableRow>
        </TableHead>
        <TableBody>

          {
            props.questions.map((question, index) => {
              return (
                <TableRow key={index}>
                  <TableCell className={classes.padding} style={{ maxWidth: "300px" }} component="th" scope="row" align="left">
                    {question.text}
                  </TableCell>

                  {props.answers.map((answer, id) => {
                    return (
                      <TableCell className={classes.padding} key={id} align="center">
                        <BlueRadio name={String(question.id)} value={answer.id} checked={values[String(question.id)] === String(answer.id)} onChange={handleChange} color="primary" />
                      </TableCell>
                    )
                  })
                  }

                </TableRow>
              )
            })
          }

        </TableBody>
      </Table>
    </TableContainer>
  );
}