import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import IconButton from '@material-ui/core/IconButton'
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import Slider from "@material-ui/core/Slider";
import VolumeUp from "@material-ui/icons/VolumeUp";
import VolumeDown from "@material-ui/icons/VolumeDown";
import VolumeMute from "@material-ui/icons/VolumeOff";
import FullScreen from "@material-ui/icons/Fullscreen";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";


const useStyles = makeStyles((theme) => ({
  
  controlsWrapper: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    height: "70px",
    background: "rgba(0,0,0,0.4)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  controlIcons: {
    color: "#fff",
    fontSize: 22,
    transform: "scale(0.9)",
    "&:hover": {
      color: "#fff",
      transform: "scale(1)",
    },
  },
  volumeSlider: {
    width: 100,
  },
}));

function ValueLabelComponent(props) {
  const { children } = props;
  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
}

const CustomControls = (props) => {
  const classes = useStyles();
  const { is_passed } = props;

  const pressSpace = (event) => {
    if (event.key !== undefined) {
      if (event.key === 32 || event.char === 32) {
        document.getElementById("iconKeyPlayPause").click();
      }
    } else if (event.keyIdentifier !== undefined) {
      if (event.keyIdentifier === 32 || event.char === 32) {
        document.getElementById("iconKeyPlayPause").click();
      }
    } else if (event.keyCode !== undefined) {
      if (event.keyCode === 32 || event.char === 32) {
        document.getElementById("iconKeyPlayPause").click();
      }
    }
  }

  useEffect(() => {
    window.addEventListener("keypress", pressSpace)
    return () => {
      window.removeEventListener("keypress", pressSpace)
    }
  }, [])

  const format = (seconds) => {
    if (isNaN(seconds)) {
      return `00:00`;
    }
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, "0");
    if (hh) {
      return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
    }
    return `${mm}:${ss}`;
  };

  return (
    <Fade in={!props.hidden}>
      <div onClick={(e) => e.stopPropagation()} className={classes.controlsWrapper}>
        <Container>
          <Grid container alignItems={"center"} justify={"flex-start"} style={{ height: "40px" }}>

            <IconButton
              id={"iconKeyPlayPause"}
              onClick={props.onPlayPause}
              className={classes.controlIcons}
              aria-label="play"
            >
              {props.playing ? (
                <PauseIcon fontSize="inherit" />
              ) : (
                  <PlayArrowIcon fontSize="inherit" />
                )}
            </IconButton>

            <Typography style={{ color: "#fff" }}>
              {format(props.currentTime) + " / " + format(props.duration)}
            </Typography>

            <IconButton
              onClick={props.onMute}
              className={classes.controlIcons}
              aria-label="volume"
            >
              {props.muted ? (
                <VolumeMute fontSize="inherit" />
              ) : props.volume > 0.5 ? (
                <VolumeUp fontSize="inherit" />
              ) : (
                    <VolumeDown fontSize="inherit" />
                  )}
            </IconButton>

            <Slider
              min={0}
              max={100}
              value={props.muted ? 0 : (props.volume ? props.volume * 100 : 0)}
              onChange={props.onVolumeChange}
              aria-labelledby="input-slider"
              className={classes.volumeSlider}
            />
            <div style={{ display: "flex", justifyContent: "flex-end", width: "auto", margin: "0 0 0 auto" }}>
              <IconButton
                onClick={props.onToggleFullScreen}
                className={classes.controlIcons}
              >
                <FullScreen fontSize="inherit" />

              </IconButton>
            </div>
          </Grid>
          <Grid container alignItems={"center"} justify={"flex-start"}>
            <div style={{ width: "95%" }}>

              <Slider
                style={{ margin: "0 10px", width: "100%", color: "#fff", cursor: "default" }}
                value={props.played * 100}
                aria-labelledby="input-slider"
                min={0}
                max={100}
                ValueLabelComponent={(props) => {
                  if (is_passed) {
                    return <ValueLabelComponent {...props} />
                  } else {
                    return null
                  }
                }}
                onChange={props.onSeek}
                onMouseDown={props.onSeekMouseDown}
                onChangeCommitted={props.onSeekMouseUp}
              />

            </div>
          </Grid>

        </Container>
      </div>
    </Fade>
  )
}

export default CustomControls;