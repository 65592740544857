import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ListModuls from '../List/ListModuls';
import ModalModule from '../Modal/ModalModule'
import useColor from '../../context/themeContext'
import serviceAdmin from '../../services/serviceAdmin'


const Moduls = (props) => {
    
    const { COLORS } = useColor();
    const useStyles = makeStyles({
        title: {
            color: "#4c23f1",
            fontSize: "20px",
            fontWeight: "bold",
            paddingBottom: "10px"
        },
        subtitle: {
            fontWeight: "bold",
            paddingBottom: "10px"
        },
        color: {
            color: COLORS.primary + "!important"
        },
        backToTheme:{
            marginBottom: 20,
            display:"flex",
            alignItems:"center",
            fontSize: 16,
            fontWeight: 600,
            cursor: "pointer"
        }

    });
    const classes = useStyles();

    const { modules } = props

    const [open, setOpen] = useState(false)
    const [openLesson, setOpenLesson] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [module_id, setModule_id] = useState()
    const [openModify, setOpenModify] = useState(false);
    const [reload, setReload] = useState(false)

    const setreload = ()=>{
        setReload(!reload)
    }

    const setopen = () => {
        setOpen(true)
    }

    const setopenlesson = (input) => {
        setOpenLesson(input)
    }
    const setopenmodify = (input) => {
        setOpenModify(input);
    }
    const setconfirm = (input, module) => {
        setConfirm(input)
        setModule_id(module.id)
    }

    useEffect(() => {
        if (confirm && module_id) {
            serviceAdmin.deleteService(`v1/courses/${props.course.id}/modules/${module_id}/`)
                .then(res => {
                    if (res.status === 200) {
                        props.setCustomAlert(true, "success", "Modulo eliminato correttamente!");
                        setreload()
                        setOpenModify(false);
                        setConfirm(false)
                        setModule_id(null)
                    }
                }).catch(err => {
                    console.log(err)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [module_id])


    const backToThematics=()=>{
        props.setModules([])
        props.setShowModules(false)
    }


    useEffect(()=>{
        serviceAdmin.getService(`v1/course-themes/${props.course_theme_id}/schema/`).then((res)=>{
            if(res?.data?.data){
                props.setModules(res.data.data)
            }
        }).catch((err)=>{
            console.error(err)
        })
    },[reload])


    return (
        <div>
            
            <div onClick={backToThematics}  className={classes.backToTheme+" "+classes.color}>
                <ArrowBackIos color='white'/> Torna alle tematiche 
            </div>

            
            <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                <h2 className="title_corsi_admin">Gli argomenti della tematica <span className={classes.color}>{props.courseTheme.name}</span></h2>  
            </div>
            
            <div className={"subscription_course " + classes.color}>
                Aggiungi un argomento <AddCircleIcon onClick={setopen} className={"subscription_icon " + classes.color} />
            </div>

            <ListModuls 
                openLesson={openLesson} 
                setOpenLesson={setopenlesson} 
                setReload={setreload} 
                openModify={openModify} 
                setOpenModify={setopenmodify} 
                setCustomAlert={props.setCustomAlert} 
                setConfirm={setconfirm} 
                moduls={modules} 
                course={props.course} 
            />

            <ModalModule
                courseThemeId={props.course_theme_id}
                setCustomAlert={props.setCustomAlert}
                setReload={setreload}
                course={props.course}
                open={open}
                setOpen={setOpen}
            />
        </div>
    )

}

export default Moduls;