import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import useColor from '../../context/themeContext'
import TransitionsModal from '../Modal/Modal';
import { tableStyle } from '../../classes'
import moment from 'moment'
import 'moment/locale/it';
moment.locale('it')


export default function TableCorsi(props) {

    const { COLORS } = useColor();
    const useStyles = makeStyles(tableStyle(COLORS));
    const classes = useStyles();

    const [rows, setRows] = useState([])
    const [deleteGroup, setDelete] = useState(false);
    const [group, setGroup] = useState({});
    const [reload, setReload] = useState(false)
    const [value, setValue] = useState(0)

    const setvalue = (input) => { setValue(input) }
    const setdelete = (input) => { setDelete(input) }

    const setreload = () => {
        setReload(!reload)
    }
    

    useEffect(() => {
        if (props.groups.length >= 0) {
            setRows(
                props.groups.map((group) => {

                    return {
                        "name": group?.name,
                        "description": group?.description,
                        "users_count": group?.users_count,
                        "slug": group?.slug,
                        "created": moment(group?.created).format('DD-MM-YYYY'),
                        "id": group?.id,
                        "updated": group?.updated,
                        "count": group?.count

                    }
                })
            )

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.groups])

    useEffect(() => {
        props.setOpen(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.closeBack])


    const modifyCourse = (event, row) => {
        setValue(0);
        setGroup(row)
        event.stopPropagation();
        props.setOpen(true);
    }


    const handleCloseMenu = () => {
        props.setOpen(false)
    }

    return (
        <div style={{ width: "100%" }}>
            {!props.open ?
                <TableContainer style={{ boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)" }} className={classes.container}>
                    <Table size={"small"} className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className={"table-label " + classes.table_label} align="left">Nome</TableCell>
                                <TableCell className={"table-label " + classes.table_label} align="center">ID gruppo</TableCell>
                                <TableCell className={"table-label hide-column-md " + classes.table_label} align="left">Descrizione</TableCell>
                                <TableCell className={"table-label hide-column-md " + classes.table_label} align="left">Slug</TableCell>
                                <TableCell className={"table-label hide-column-xxs " + classes.table_label} align="left">Data creazione</TableCell>
                                <TableCell className={"table-label hide-column-xxs " + classes.table_label} align="center">Numero Utenti</TableCell>
                                <TableCell className={"table-label " + classes.table_label} align="left"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => (
                                <TableRow hover key={index} className={"table-label-cell"}>
                                    <TableCell style={{cursor:"pointer"}} onClick={(e) => modifyCourse(e, row)} className={classes.label_cell} align="left"><span style={{color:COLORS.primary}}>{row.name}</span></TableCell>
                                    <TableCell className={classes.label_cell} align="center">{row.id}</TableCell>
                                    <TableCell className={"hide-column-md " + classes.label_cell} align="left">
                                        {
                                            ((row.description)?.length > 40) ?
                                                (false ? row.description : ((row.description)?.substring(0, 40 - 3) + "...")) :
                                                row.description
                                        }
                                    </TableCell>
                                    <TableCell className={"hide-column-md " + classes.label_cell} align="left">{row.slug}</TableCell>
                                    <TableCell className={"hide-column-xxs " + classes.label_cell} align="left">{row.created}</TableCell>
                                    <TableCell className={"hide-column-xxs " + classes.label_cell} align="center">{row.users_count}</TableCell>
                                    <TableCell className={classes.tablecell} align="left">
                                        <MoreHorizIcon onClick={(e) => modifyCourse(e, row)} style={{
                                            width: "30px",
                                            height: "30px",
                                            color: "#999",
                                            cursor: "pointer"
                                        }} />
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow style={{ padding: "20px" }}>
                                <TableCell style={{ borderBottom: "none" }} align="left">Totali {rows.length}</TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>

                </TableContainer>
                :
                <TransitionsModal
                    deleteGroup={deleteGroup}
                    textDelete={"il gruppo"}
                    handleCloseMenu={handleCloseMenu}
                    setConfirm={props.setConfirm}
                    lesson={group}
                    isModifyGroup={true}
                    value={value}
                    setValue={setvalue}
                    setDelete={setdelete}
                    setCustomAlert={props.setCustomAlert}
                    reload={reload} setreload={setreload}
                    setReload={props.setReload}
                    group={group}
                    setOpen={props.setOpen}
                    open={props.open}

                />
            }
        </div>
    );
}
