import serviceAdmin from '../services/serviceAdmin'

const sseBase = process.env.REACT_APP_SSE_URL;

export const usersStatusEventSource = (sse_url, setResponse, importDone, importFail) => {
    const url = sseBase+sse_url;
    const evSource = new EventSource(url);

    evSource.onmessage = async (msg) => {
        const data = JSON.parse(msg.data)
        setResponse(data?.message);

        if (data.status === "END" && data.error === false) {
            importDone();
            evSource.close();
        }
        if(data.error === true) {
            importFail(data);
            evSource.close();

        }
    };
    evSource.onerror = (err) => {
        importFail();
        evSource.close();
    }
}

export const usersStatusExportEventSource = (id, sse_url, setCustomAlert, setLoading, setData, setComplete, setReload) => {
    const url = sseBase+sse_url;
    const evSource = new EventSource(url);
    evSource.onmessage = async (msg) => {
        const data = JSON.parse(msg.data)

        if (data.status === "END" && data.error === false) {
            serviceAdmin.getService(`/custom/v1/exports/${id}/`)
                .then(res => {
                    if (res.data.data) {
                        
                        setData(res.data.data?.file_output);
                        setLoading(false);
                        setComplete(true);
                        setReload();
                        setCustomAlert(true, "success", "Export generato con successo!");
                        evSource.close();
                    }
                }).catch(err => {
                    console.log(err)
                    evSource.close();
                })
        }
        if (data.error === true) {
            setLoading(false)
            setComplete(false);
            setCustomAlert(true, "warning", "Ops, qualcosa è andato storto!");
            evSource.close();
        }
    };


    evSource.onerror = (err) => {
        setCustomAlert(true, "warning", "Ops, qualcosa è andato storto!");
        setLoading(false)
        setComplete(false);
        evSource.close();
    }
}

export const usersStatusDetailEventSource = (id, sse_url, setCustomAlert, setLoading, setData) => {
    const url = sseBase+sse_url;
    const evSource = new EventSource(url);
    evSource.onmessage = async (msg) => {
        const data = JSON.parse(msg.data)

        if (data.status === "END" && data.error === false) {
            serviceAdmin.getService(`/custom/v1/exports/${id}/`)
                .then(res => {
                    if (res.data.data) {
                        setCustomAlert(true, "success", "Export generato con successo!");
                        setData(res.data.data?.file_output);
                        setLoading(false)
                        evSource.close();
                    }
                }).catch(err => {
                    console.log(err)
                    evSource.close();
                })
        }
        if (data.error === true) {
            setCustomAlert(true, "warning", "Ops, qualcosa è andato storto!");
            setLoading(false)
            evSource.close();
        }
    };

    evSource.onerror = (err) => {
        setCustomAlert(true, "warning", "Ops, qualcosa è andato storto!");
        setLoading(false)
        evSource.close();
    }
}