import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PrivateVideo from './components/Privateroute/PrivateVideo'
import Privateroute from './components/Privateroute/Privateroute'
import PrivateCourse from './components/Privateroute/PrivateCourse'
import Courses from './views/Courses';
import Activities from './views/Activities';
import Profile from './views/Profile';
import PageNotFound from './views/PageNotFound';
import NoAccess from './views/NoAccess';
import Control from './views/Control';
import Login from './views/Login'
import LoginRoute from './components/Privateroute/LoginRoute'
import Certificate from './views/Certificate';
import ChangePassword from './views/ChangePassword';
import SectionView from './views/SectionView';
import ForgotPassword from './views/ForgotPassword';
import RecoveryPassword from './views/RecoveryPassword';
import SectionPreview from './views/SectionPreview';

const routes = (props) => {

    const queryString = window.location.search || ""
    const { setReload } = props
    
    return (
        <Switch>
            <Privateroute exact path="/profilo" user={props.user} component={(props) => <Profile     {...props} />} />
            <Privateroute exact path="/attivita" user={props.user} component={(props) => <Activities     {...props} />} />
            <PrivateCourse exact path="/corsi" user={props.user} component={(props) => <Courses     {...props} />} />
            <PrivateCourse exact path="/corsi/:id" user={props.user} component={(props) => <SectionView {...props} isMoreCourses />} />
            <PrivateCourse exact path="/preview/corsi/:id" user={props.user} component={(props) => <SectionPreview {...props} isMoreCourses />} />
            <Route exact path="/reset" component={(props) => <ChangePassword {...props} />} />
            <Route exact path="/" render={(props) => <Control {...props} setReload={setReload} />} />
            <Route exact path="/forgot-password" component={(props) => <ForgotPassword     {...props} />} />
            <Route exact path="/password-recovery" component={(props) => <RecoveryPassword     {...props} />} />
            <Route exact path="/certificate/:id" render={(props) => <Certificate {...props} />} />
            <LoginRoute exact path="/login" setReload={props.setReload} user={props.user} component={(props) => <Login  {...props} />} />
            <PrivateVideo exact path="/corso" component={(props) => <SectionView {...props} />} />
            <Route exact path="/no-access" render={() => <NoAccess    {...props} />} />
            <Route exact path="/outfile">
                <Redirect to={"/backoffice/outfile" + queryString} />
            </Route>
            <Route component={PageNotFound} />
        </Switch>

    )

}

export default routes