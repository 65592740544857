import React from 'react'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles'; import useColor from '../../../context/themeContext';


const ResetFilter = (props) => {

    const { COLORS } = useColor()
    const useStyles = makeStyles(() => ({
        button: {
            border: "2px solid" + COLORS.primary,
            height: "30px",
            color: "#0000008A",
            '&.Mui-disabled': {
                border: "2px solid" + COLORS.primary + "50",
            }
        }
    }));

    
    const classes = useStyles();

    return (
        <Grid container justify="center" alignItems="center" item xs={12}>
            <Button disabled={Object.keys(props.input).length === 0} onClick={() => props.setInput({})} className={classes.button}>
                Azzera Filtri
            </Button>
        </Grid>
    )
}
export default ResetFilter;
