import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'
import serviceAdmin from '../../services/serviceAdmin'
import Paper from '@material-ui/core/Paper';
import { modalClass } from '../../classes';
import useColor from '../../context/themeContext';
import useAlert from '../../hooks/useAlert'
import FullTextField from '../StyledComponent/FullTextField'


export default function ModalModifyTheme(props) {

    const [option, setAlert] = useAlert()
    const { COLORS } = useColor()
    const useStyles = makeStyles((theme) => (modalClass(COLORS)));
    const classes = useStyles();

    const [input, setInput] = useState({});

    let slugRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;

    useEffect(() => {
        if (props.theme) {
            setInput({
                ...input,
                "name": props.theme?.name,
                "description": props.theme?.description,
                "slug": props.theme?.slug,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.theme])

    const changeValue = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setInput({ ...input, [name]: value })
    }
    function clean(obj) {
        for (let propName in obj) {
            if (obj[propName] === "" || obj[propName] === undefined) {
                delete obj[propName];
            }
        }
    }

    const modifyTheme = () => {
        const params = {
            ...input
        }
        clean(params);

        serviceAdmin.putService(`/v1/courses/${props.course.id}/course-themes/${props.theme.id}/`, params)
            .then(res => {
                setAlert(true, "Tematica aggiornata con successo!", "success")
                props.setReload();
                handleClose();
            }).catch(err => {
                console.log(err)
            })
    }

    const handleClose = () => {
        props.setOpen(false);
    };
    return (

        <Drawer
            anchor={"right"}
            open={props.open}
            onClose={handleClose}
        >
            <div className={"drawer-container"}>
                <div className={classes.closeicon + " title-drawer-container"}>
                    <CloseIcon style={{ cursor: "pointer", height: "30px", width: "30px" }} onClick={handleClose} />
                    <h2 className={classes.title}> Modifica tematica </h2>
                </div>
                <div className={"drawer-content-container"} style={{ padding: "20px 30px" }}>

                    <Grid container spacing={3}>

                        <Grid item xs={6}>
                            <InputLabel className={classes.label}>
                                Nome tematica
                            </InputLabel>
                            <FullTextField name="name" onChange={changeValue} value={input.name || ""} placeholder="Nome della tematica" variant="outlined" inputProps={{ className: classes.input }} />
                        </Grid>

                        <Grid item xs={6}>

                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel className={classes.label}>
                                Descrizione
                            </InputLabel>
                            <FullTextField name="description" onChange={changeValue} value={input.description || ""} placeholder="Descrizione tematica" multiline rows={3} variant="outlined" inputProps={{ className: classes.textarea }} />
                        </Grid>
                    </Grid>

                </div>
                <Paper elevation={3} className={classes.save + " button-container"}>

                    <Button onClick={() => { props.setDelete(true) }} className={classes.btn_del_user}>Elimina</Button>
                    <Button onClick={handleClose} className={classes.btn_del}>Annulla</Button>
                    <Button disabled={slugRegex.test(input.slug) && input.name && input.slug ? false : true} onClick={modifyTheme} className={classes.btn_save}>Modifica</Button>

                </Paper>
            </div>
        </Drawer>
    );
}