
export function switchRoute(setSelected) {
	
	switch (window.location.pathname) {
		case "/backoffice/users":
			setSelected(1)
			break;
		case "/backoffice/group":
			setSelected(2)
			break;
		case "/backoffice/courses":
			setSelected(3)
			break;
		case "/backoffice/surveys":
			setSelected(4)
			break;
		case "/backoffice-lite/export":
			setSelected(5)
			break;
		case "/backoffice-lite/profile":
			setSelected(6)
			break;
		case "/backoffice/export":
			setSelected(7)
			break;
		case "/backoffice/profile":
			setSelected(8)
			break;
		case "/backoffice/test":
			setSelected(9)
			break;
		default:
			setSelected(0)
			break;
	}
}