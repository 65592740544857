import React from 'react';
import SkeletonRelated from '../VideoCourse/SkeletonRelated';
import Paper from '@material-ui/core/Paper'
import Avatar from '@material-ui/core/Avatar'


const HolderBox = (props) => {


    return (
        <Paper className="box" style={{ borderRadius: "0", minHeight: "150px", margin: 0 }}>

            <div style={{ display: "flex", alignItems: "center", padding: "15px", borderBottom: "1px solid #f2f3f4" }}>
                <h4 className="box-title">Docenti</h4>
            </div>
            <ul className="docent">
                {props.loading ?

                    <SkeletonRelated white={true} />

                    :
                    <React.Fragment>

                        <li style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                            <Avatar src={props.teachers?.avatar?.thumbnail}></Avatar>
                            <div style={{ paddingLeft: "10px" }}>
                                <h6>{props.teachers?.first_name} {props.teachers?.last_name}</h6>
                                <p>{props.teachers?.additional_info}</p>
                            </div>
                        </li>


                    </React.Fragment>
                }
            </ul>

        </Paper>

    )

}

export default HolderBox