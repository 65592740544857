import { darken, makeStyles } from '@material-ui/core'
import React from 'react'
import RadialLegendBox from './RadialLegendBox'
import useColor from '../../../context/themeContext'


const RadialLegend = (props) => {
	const { COLORS } = useColor()

	
	const useStyles = makeStyles({
		radial_legend: {
			display: "flex",
			flexDirection: "column",
			alignItems: "flex-start",
			justifyContent: "center",
			height: "100%"
		},
		radial_legend_box: {
			display: "flex",
			flexDirection: "column",
			alignItems: "flex_start",
			'& h3': {
				fontSize: "30px",
				fontWeight: "bold"
			},
			'& span': {
				fontSize: "20px",
				fontWeight: "bold"
			},
			'& p': {
				fontSize: "12px",
				textTransform: "uppercase"
			}
		},
		flex_align: {
			display: "flex",
			alignItems: "baseline"
		}
	})

	const classes = useStyles()
	const { data, total } = props

	const passed = data?.subscriptions_achievements?.passed;
	const percentagePasses = Number(((passed * 100) / total).toFixed(1)) || 0;
	const running = data?.subscriptions_achievements?.running;
	const percentageRunning = Number(((running * 100) / total).toFixed(1)) || 0
	const to_start = data?.subscriptions_achievements?.to_start;
	const percentageToStart = Number(((to_start * 100) / total).toFixed(1)) || 0;

	return (
		<div className={classes.radial_legend}>
			<RadialLegendBox color={darken(COLORS.primary, 0.50)} count={passed} percentageCount={percentagePasses} label={"completati"} classes={classes} />
			<RadialLegendBox color={darken(COLORS.primary, 0.25)} count={running || 0} percentageCount={percentageRunning || 0} label={"in corso"} classes={classes} />
			<RadialLegendBox color={darken(COLORS.primary, 0)} count={to_start || 0} percentageCount={percentageToStart || 0} label={"Da iniziare"} classes={classes} />
		</div>
	)
}

export default RadialLegend