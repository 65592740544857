import React, { useEffect, useState, useRef } from 'react'
import { Button, TextField, IconButton } from '@material-ui/core'
import serviceAdmin from '../../services/serviceAdmin'
import { Container, Draggable } from "react-smooth-dnd";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import arrayMove from "array-move";
import AddIcon from '@material-ui/icons/Add';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined'
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { questionClass } from '../../classes'
import useColor from '../../context/themeContext';
import QuestionTextField from './UtilityComponent/QuestionTextField'

const QuestionLikertBuilder = (props) => {
    
    const { COLORS } = useColor()
    const useStyles = makeStyles(() => (questionClass(COLORS)))
    const classes = useStyles();



    const { answers } = props;
    const timerRef = useRef(null);
    const [newAnswers, setNewAnswers] = useState([])
    const [input, setInput] = useState({})
    const [object, setObject] = useState({})
    const [show, setShow] = useState(false)

    useEffect(() => {
        serviceAdmin.getService(`/v1/surveys/${props.survey_id}/questions/${props.question_id}/likert-questions/`)
            .then(res => {
                if (res?.status === 200) {
                    const array = res.data.data.map((answer, index) => {
                        return {
                            "text": answer.text,
                            "id": answer.id,
                            "order": index
                        }
                    })
                    setInput(res.data.data)
                    setNewAnswers(array)
                    setShow(false);
                    setObject({});
                }
            }).catch(err => {
                console.log(err)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [answers])

    const objectChange = (e, order) => {
        const value = e.target.value;
        setObject({ ...object, "text": value, "order": order });
    }

    const hideObject = () => {
        setShow(false)
        setObject({})
    }

    const objectSaveDelete = () => {
        if (!show) {
            setShow(true)
        } else {
            serviceAdmin.postService(`v1/surveys/${props.survey_id}/questions/`, {
                "likert_question_id": props.question_id,
                "text": object.text,
                "order": object.order + 1,
                "question_config": "likert_question"
            }).then(res => {
                if (res?.status === 201) {
                    props.setReload();
                    props.setCustomAlert(true, "success", "Domanda likert creata con successo!");
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }

    const deleteAnswer = (id) => {
        serviceAdmin.deleteService(`v1/surveys/${props.survey_id}/questions/${id}/`)
            .then(res => {
                if (res?.status === 200) {
                    props.setReload()
                    props.setCustomAlert(true, "success", "Domanda likert eliminata!");
                }
            }).catch(err => {
                console.log(err)
            })
    }

    const handleChange = (e, key) => {
        const value = e.target.value;
        setInput({ ...input, [key]: { ...input[key], "text": value } })

        if (timerRef.current) clearTimeout(timerRef.current);

        timerRef.current = setTimeout(() => {
            serviceAdmin.putService(`v1/surveys/${props.survey_id}/questions/${input[key].id}/`, {
                "text": value
            }).then(res => {
                if (res?.status === 200) {
                    props.setCustomAlert(true, "success", "Domanda likert aggiornata!");
                }
            }).catch(err => {
                console.log(err)
            })
        }, 700)
    }

    const onQuestionDrop = ({ removedIndex, addedIndex }) => {
        const news = arrayMove(newAnswers, removedIndex, addedIndex);
        if (JSON.stringify(news) !== JSON.stringify(newAnswers)) {
            setNewAnswers(news)

            if (news?.length > 1) {
                news.map((question, index) => {
                    serviceAdmin.putService(`v1/surveys/${props.survey_id}/questions/${question.id}/`, {
                        "text": question.text,
                        "order": index
                    })
                        .then(res => {
                            if (res?.status === 200) {
                                props.setCustomAlert(true, "success", "Domanda likert aggiornata!");
                            }
                        }).catch(err => {
                            console.log(err)
                        })
                })
            }
        }
    }

    const addKeyQuestionLikert = (e) => {
        if (e.key === "Enter") {
            objectSaveDelete();
        }
    };

    return (
        <div style={{ width: "100%", padding: "10px 20px" }}>

            <Container
                orientation="vertical"
                onDrop={onQuestionDrop}
                dragHandleSelector=".column-drag-handle"
                style={{
                    minHeight: 0,
                }}
            >


                {newAnswers.map(answer => {
                    return (
                        <Draggable key={answer.id}>
                            <div className={classes.answer}>
                                <div className={"column-drag-handle"}>
                                    <DragIndicatorIcon className={classes.icon_drag} color="inherit" />
                                </div>
                                <QuestionTextField
                                    color={COLORS.primary}
                                    autoComplete={"off"}
                                    value={input[answer?.order]?.text || ""}
                                    onChange={(e) => handleChange(e, answer.order)}
                                    variant={"outlined"}
                                    title={input[answer?.order]?.text || ""}
                                    multiline
                                />
                                <IconButton onClick={() => deleteAnswer(answer.id)} style={{ marginLeft: "5px" }} className={classes.icon} color="inherit">
                                    <ClearOutlinedIcon className={classes.icon_font} color="inherit" />
                                </IconButton>
                            </div>
                        </Draggable>
                    )
                })
                }
                {show &&
                    <Draggable key={newAnswers.length}>
                        <div className={"column-drag-handle " + classes.answer} onKeyDown={addKeyQuestionLikert}>
                            <QuestionTextField
                                color={COLORS.primary}
                                multiline
                                autoComplete={"off"}
                                value={object?.text || ""}
                                onChange={(e) => objectChange(e, newAnswers.length)}
                                variant={"outlined"}
                            />
                            <IconButton onClick={objectSaveDelete} style={{ marginLeft: "5px" }} className={classes.icon} color="inherit">
                                <SaveOutlinedIcon className={classes.icon_font} color="inherit" />
                            </IconButton>
                            <IconButton onClick={hideObject} style={{ marginLeft: "5px" }} className={classes.icon} color="inherit">
                                <ClearOutlinedIcon className={classes.icon_font} color="inherit" />
                            </IconButton>
                        </div>
                    </Draggable>
                }
            </Container>
            {(props.config !== "open" && props.config !== "likert_question") && !show &&
                <div className={classes.container_add}>

                    <Button
                        disabled={show && !object?.text ? true : false}
                        onClick={objectSaveDelete}
                        className={classes.button_add_answer}
                        color="inherit"
                        variant="contained"
                        endIcon={!show ? <AddIcon className={classes.icon_font} color="inherit" /> : <SaveOutlinedIcon className={classes.icon_font} color="inherit" />}
                    >
                        {!show ?
                            "Aggiungi domanda"
                            :
                            'Salva domanda'
                        }
                    </Button>

                </div>
            }
        </div>
    )
}

export default QuestionLikertBuilder;