import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(() => ({
    
    root: {
        display: "flex",
        justifyContent: "center",
        marginTop: "50px"
    },
    textarea: {
        width: "100%",
        backgroundColor: "#f1f1f1",
        borderRadius: "20px",
        padding: "12px 20px"
    },
}));

const OpenQuestion = (props) => {
    const classes = useStyles();

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        props.setPrevResponse({ ...props.prevResponse, [name]: value })
        props.setData({ ...props.data, [name]: value })
    }

    useEffect(() => {
        if (props.prevResponse[props.index]) {
            props.setResponse(true);
            props.setallresponse(true, props.index)
        } else {
            props.setResponse(false);
            props.setallresponse(false, props.index)
        }

        return () => {
            props.setResponse(false);
        }
    }, [props.prevResponse])

    return (

        <Paper style={{ minHeight: "400px", borderRadius: "0px", paddingBottom: "20px" }}>

            <h3 style={{ padding: "40px", textAlign: "left" }}>{props?.question}</h3>

            <div className={classes.root} onKeyDown={props.getKey}>

                <TextField
                    value={props.prevResponse[props.index] || ""}
                    name={props.index}
                    onChange={handleChange}
                    style={{ width: "90%" }}
                    autoFocus={props.singleLayout ? false : true}
                    multiline
                    rows={5}
                    variant="outlined"
                    inputProps={{ className: classes.textarea }}
                />

            </div>
        </Paper>

    )

}
export default OpenQuestion;