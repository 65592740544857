import React,{useState, useEffect} from 'react'
import ListThematics from '../List/ListThematics';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ModalTheme from '../Modal/ModalTheme'
import { Button } from '@material-ui/core';
import useColor from '../../context/themeContext'
import serviceAdmin from '../../services/serviceAdmin'



export default function Thematics(props){
    
    const { COLORS } = useColor();
    const useStyles = makeStyles({
        title: {
            color: "#4c23f1",
            fontSize: "20px",
            fontWeight: "bold",
            paddingBottom: "10px"
        },
        subtitle: {
            fontWeight: "bold",
            paddingBottom: "10px"
        },
        color: {
            color: COLORS.primary + "!important"
        }

    });
    const classes = useStyles();

    const [open, setOpen] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [themes_id, setThemesID] = useState()
    const [openModify, setOpenModify] = useState(false);
    const [thematics, setThematics] = useState([])
    const [reload, setReload] = useState(false)

    const setreload = () => { setReload(!reload) }

    const setopen = () => {
        setOpen(true)
    }

    const setopenmodify = (input) => {
        setOpenModify(input);
    }
    const setconfirm = (input, theme) => {
        setConfirm(input)
        setThemesID(theme.id)
    }

    useEffect(() => {
        if (confirm && themes_id) {
            serviceAdmin.deleteService(`v1/courses/${props.course.id}/course-themes/${themes_id}/`)
                .then(res => {
                    if (res.status === 200) {
                        props.setCustomAlert(true, "success", "Tematica eliminata correttamente!");
                        setreload();
                        setOpenModify(false);
                        setConfirm(false)
                        setThemesID(null)
                    }
                }).catch(err => {
                    console.log(err)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [themes_id])

    useEffect(() => {
        serviceAdmin.getService(`v1/courses/${props.course?.id}/course-themes/`)
            .then(res => {
                if (res.data.data) {
                    
                    setThematics(res.data.data)

                }
            }).catch(err => {
                console.log(err)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload])

    /** REDIRECT TO PREVIEW COURSE PAGE */
    const loadPreview = () =>{
        props.history.push(`/preview/corsi/${props.course.id}`)
    }

    return (
        <div>
            <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                <h2 className="title_corsi_admin">Tematiche del corso <span className={classes.color}>{props.course.name}</span></h2>
                
               
                
                <Button onClick={loadPreview} style={{backgroundColor:"#3AAC4D", color:"#ffffff"}}>
                    Anteprima corso
                </Button>
                
                
            </div>
            
            <div className={"subscription_course " + classes.color}>
                Aggiungi una tematica <AddCircleIcon onClick={setopen} className={"subscription_icon " + classes.color} />
            </div>


                <ListThematics
                    setCourseThemeId={props.setCourseThemeId}
                    setCourseTheme={props.setCourseTheme}
                    setShowModules={props.setShowModules}
                    setModules={props.setModules}
                    setReload={setreload}
                    openModify={openModify}
                    setOpenModify={setopenmodify}
                    setCustomAlert={props.setCustomAlert}
                    setConfirm={setconfirm}
                    thematics={thematics}
                    course={props.course} 
                />
          
            <ModalTheme
                setCustomAlert={props.setCustomAlert}
                setReload={setreload}
                course={props.course}
                open={open}
                setOpen={setOpen}
            />
        </div>
    )

}
