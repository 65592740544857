
import React from 'react'


const RadialLegendBox = (props) => {
	
	const { classes, count, percentageCount, label, color } = props

	return (
		<div className={classes.radial_legend_box}>
			<div className={classes.flex_align}>
				<h3 style={{ color: color }}>{count} &nbsp;</h3>
				<span style={{ color: color }}> ({percentageCount}%)</span>
			</div>
			<p style={{ color: color }}>{label}</p>
		</div>

	)
}
export default RadialLegendBox