import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Percentage from './Percentage';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Zoom from '@material-ui/core/Zoom';
import NextButton from './NextButton'
import ShowQuestionsResult from './ShowQuestionsResult';

const useStyles = makeStyles({
    check: {
        width: "150px",
        height: "150px",
        color: "#3fbe54!important",
        '&:active': {
            color: "#3fbe54!important",
        }
    }
});



const Result = (props) => {
    const { handleResize } = props;
    const classes = useStyles();
    const [checked, setChecked] = useState(false)
    const [category, setCategory] = useState("")

    const { setRestart, is_passed, has_button, left_tries, setLesson, next, module_id, lesson_id, is_mandatory, mandatory_section_id, is_propedeutic_to_others } = props;

    useEffect(() => {
        if (props.category === "SURVEY") {
            setTimeout(() => {
                setChecked(true)
                handleResize()
            }, 100)
        }
    }, [])

    useEffect(() => {
        setCategory(props.category)
    }, [props.category])


    return (
        <React.Fragment>
            {category !== "SURVEY" ?

                <Paper style={{ minHeight: "380px", borderRadius: "0px", paddingBottom: "20px" }}>
                    <div style={{ display: "flex", justifyContent: "center", minHeight: "400px", alignItems: "center" }}>
                        <Percentage percentage={props.percentage} is_passed={props.is_passed} />
                    </div>
                    {props.is_passed ?
                        <p style={{ textAlign: "center", fontSize: "20px", padding: "20px 30px" }}>
                            Complimenti, hai superato la verifica!
                        </p>
                        :
                        <p style={{ textAlign: "center", fontSize: "20px", padding: "20px 30px" }}>
                            Non hai superato la verifica!
                        </p>
                    }

                    <NextButton
                        category={category}
                        setRestart={setRestart}
                        is_passed={is_passed}
                        left_tries={left_tries}
                        has_button={has_button}
                        setLesson={setLesson}
                        next={next}
                        module_id={module_id}
                        lesson_id={lesson_id}
                        is_mandatory={is_mandatory}
                        mandatory_section_id={mandatory_section_id}
                        is_propedeutic_to_others={is_propedeutic_to_others}
                    />

                    {props?.registry?.evaluation?.has_show_correct_answers && <ShowQuestionsResult {...props} />}
                </Paper>

                :

                <Paper style={{ borderRadius: "0px", paddingBottom: "20px" }}>
                    <div style={{ display: "flex", justifyContent: "center", minHeight: "350px", alignItems: "center" }}>
                        <Zoom in={checked}>
                            <CheckCircleIcon className={classes.check} />
                        </Zoom>
                    </div>
                    <p style={{ textAlign: "center", fontSize: "20px", padding: "20px 30px" }}>
                        Grazie, hai completato il sondaggio!
                    </p>
                    <NextButton has_button={has_button} category={category} setRestart={setRestart} is_passed={is_passed} left_tries={left_tries} setLesson={setLesson} next={next} module_id={module_id} lesson_id={lesson_id} />
                </Paper>

            }

        </React.Fragment>
    )
}
export default Result;