import React from 'react';



const PageNotFound = () => {

    return (
        <div className="login-form">
            <div
                style={{
                    height: "300px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "30px",
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "#5c5c5c"
                }}>

                <div>Error 404</div>

            </div>
        </div>
    )


}

export default PageNotFound