import React from 'react';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import LinkIcon from '@material-ui/icons/Link';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import SkeletonRelated from './SkeletonRelated';
import Paper from '@material-ui/core/Paper';
import useColor from '../../context/themeContext'

const TeachingMaterial = (props) => {
    
    const { COLORS } = useColor()

    return (
        <Paper className="teaching-material" style={{ borderRadius: "0" }}>
            <div className={"teaching-title"}>
                <h3 style={{ color: COLORS.primary, textAlign: "center", width: "100%" }}>
                    MATERIALE DI APPROFONDIMENTO
                </h3>
            </div>
            <ul>
                {props.loading ?

                    <SkeletonRelated />
                    :
                    props.documents.map((document, index) => {
                        if (document.type === "LINK") {
                            return (
                                <li key={index}>

                                    <a href={document?.link} target="_blank" rel="noopener noreferrer">
                                        <LinkIcon className="svg-download" style={{ color: COLORS.primary }} />
                                        <span>{document?.name}</span>
                                    </a>

                                </li>
                            )
                        } else if (document.type === "FILE") {

                            return (
                                <li key={index}>

                                    <a href={document?.file} target="_blank" rel="noopener noreferrer">
                                        <CloudDownloadIcon className="svg-download" style={{ color: COLORS.primary }} />
                                        <span>{document?.name}</span>
                                    </a>

                                </li>
                            )
                        } else {
                            return (
                                <li key={index}>

                                    <a href={document?.file} target="_blank" rel="noopener noreferrer">
                                        <FolderOpenIcon className="svg-download" style={{ color: COLORS.primary }} />
                                        <span>{document?.name}</span>
                                    </a>

                                </li>
                            )
                        }
                    })
                }
            </ul>

        </Paper>
    );
};

export default TeachingMaterial;