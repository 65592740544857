import React from "react"
import Table from "../../componentsAdmin/Table/Table"
import services from "../../services/services"
import moment from "moment"

export default function Courses() {
    
    const [tabItems, setTabItems] = React.useState([])
    const [etiquette, setEtiquette] = React.useState({})
    const [tabCounts, setTabCounts] = React.useState(0)
    const [isLoading, setIsLoading] = React.useState(true)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [page, setPage] = React.useState(0)
    const [pageCourses, setPageCourses] = React.useState(1)

    ////SET NAME ROW AND VALUE CELL {label => id}
    const headCells = [
        { id: 'code_course', numeric: false, disablePadding: false, label: "Codice del corso", class: "", align: "left" },
        { id: 'course_name', numeric: false, disablePadding: false, label: "Nome corso", class: "hide-column-xs ", align: "left" },
        { id: 'status', status_etiquette: true, numeric: false, disablePadding: false, label: "Stato", class: "", align: "center" },
        { id: 'signed', numeric: false, disablePadding: false, label: "Iscritto", class: "hide-column-xxs ", align: "center" },
        { id: 'expiration_date', numeric: false, disablePadding: false, label: "Data di scadenza", class: "", align: "center" },
        { id: 'course_completion', numeric: false, disablePadding: false, label: "Completamento corso", class: "hide-column-md ", align: "center" },
        { id: 'duration', numeric: false, disablePadding: false, label: "Tempo totale", class: "hide-column-md ", align: "center" },
        { id: 'score', numeric: false, disablePadding: false, alt: "Percentuale di completamento", label: "Percentuale di completamento", class: "hide-column-md ", align: "center" }
    ];

    React.useEffect(() => {
        services.getService(`/custom/v1/configurations/${process.env.REACT_APP_ETIQUETTE}/`)
            .then(res => {
                setEtiquette(res.data.data)
            }).catch(err => {
                console.log(err)
            })
    }, [])

    React.useEffect(() => {
        services.getService("v1/users/me/subscriptions/")
            .then(response => {
                const subResponse = response.data.data
                const paginationTable = response.data.meta
                const mappedSubscription = subResponse.map(item => {
                    return {
                        code_course: item?.course?.code,
                        course_name: item?.course?.name,
                        status: item.status_etiquette,
                        signed: item.created ? moment(item.created).format("DD/MM/YYYY") : "-",
                        expiration_date: item.expiration_date ? moment(item.expiration_date).format("DD/MM/YYYY") : "-",
                        course_completion: item.completion_date ? moment(item.completion_date).format("DD/MM/YYYY") : "-",
                        duration: item.duration ? item.duration.substring(0, 8) : "-",
                        score: item.progress + "%"
                    }
                })
                setTabCounts(paginationTable.pagination.items_count)
                setTabItems(mappedSubscription)
                setIsLoading(false)
                setPage(pageCourses - 1)
            })
            .catch(err => {
                console.log(err)
            })
    }, [pageCourses, rowsPerPage])

    return (
        <div>
            <Table
                noPaper
                setSelected={() => { }}
                setAll={() => { }}
                headCells={headCells}
                rows={tabItems}
                backdrop={isLoading}
                setBackdrop={setIsLoading}
                count={tabCounts}
                setRowsPage={setRowsPerPage}
                page={page}
                setPage={setPage}
                setPageCount={setPageCourses}
                etiquette={etiquette}
            />
        </div>
    )
}