import Service from "../../services/services"

async function orderSubscriptionsByTypologies(searchString) {
    try {
        let response;
        if (searchString) {
            response = await Service.getService(`v1/users/me/subscriptions/course-typologies/?search=${searchString}`)
        } else {
            response = await Service.getService("v1/users/me/subscriptions/course-typologies/")
        }
        const sortedSubscriptions = response.data.data.sorted;
        const unsortedSubscriptions = response.data.data.unsorted;

        if (sortedSubscriptions.length === 0) {
            return {
                withTypology: false,
                subscriptions: [...unsortedSubscriptions]
            }
        } else {
            return {
                withTypology: true,
                subscriptions: [...sortedSubscriptions, { subscriptions: unsortedSubscriptions }]
            }
        }
    } catch (error) {
        console.log('[courses.orderSubscriptionsByTypologies]', error?.message)
        return []
    }
}

export default {
    orderSubscriptionsByTypologies
}