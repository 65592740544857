import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import React from 'react'

const CssTextField = withStyles({
	
	root: {
		border: "none",
		padding: "10px 12px",
		width: "100%",
		margin: "0",
		borderRadius: "10px",
		'& .MuiInputBase-root': {
			color: "#fff",
		},
		'& .MuiInput-underline:after': {
			border: 'none',
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: 'none',
			},
			'&:hover fieldset': {
				borderColor: 'yellow',
			},
			'&.Mui-focused fieldset': {
				borderColor: 'green',
			},
		},
	},
})(TextField)

const QuestionTextField = (props) => {

	return (
		<CssTextField style={{ background: props.color }} {...props} />
	)
}


export default QuestionTextField;