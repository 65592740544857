import React, { useEffect, useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import serviceAdmin from '../../services/serviceAdmin';
import Grid from "@material-ui/core/Grid";
import { modalClass } from '../../classes';
import useColor from '../../context/themeContext';
import useAlert from '../../hooks/useAlert'
import FullTextField from '../StyledComponent/FullTextField'
import DialogDelete from '../Dialog/DialogD';


export default function ModalGroup(props) {

  const [options, setAlert] = useAlert()

  const { COLORS } = useColor();
  const useStyles = makeStyles((theme) => (modalClass(COLORS)));
  const classes = useStyles()

  const [openDialog, setOpenDialog] = useState(false)
  const [input, setInput] = useState({})
  const [section, setSection] = useState({})
  const [sectionDuration, setSectionDuration] = useState("")
  const [loading, setLoading] = useState(false);
  const [isEvaluation, setIsEvaluation] = useState(false)


  const handleClose = () => {
    props.setOpen(false);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInput({ ...input, [name]: value })
  }

  useEffect(() => {
    if (props.open) {

      serviceAdmin.getService(`/v1/subscriptions/${props.sub_id}/sessions/${props.session_id}/`)
        .then(res => {
          if (res.data.data) {
            const section = res.data.data;
            setSection(section?.section)
            if (section.section?.category === "LESSON") {
              setInput({
                ...input,
                "duration": section?.duration?.split('.')[0],
                "watched_media_duration": section?.watched_media_duration,
                "section_step_time": section?.section_step_time.split('.')[0],
                "section_step_number": section?.section_step_number
              })

              setSectionDuration(res.data.data?.section?.duration);
              setLoading(true)
              setIsEvaluation(false)
            } else {

              setInput({
                ...input,
                "duration": section?.duration?.split('.')[0],
                "deletable": true
              })
              setLoading(true)
              setIsEvaluation(true)
            }
          }
        }).catch(err => {
          if (err.status === 404) {
            setAlert(true, "Sessione non trovata!", "warning");
          }
          console.log(err);
        })
    }
    return () => {
      setInput({});
      setSectionDuration("");
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sub_id, props.open])

  const modifyGroup = () => {
    serviceAdmin.putService(`/v1/subscriptions/${props.sub_id}/sessions/${props.session_id}/`, input)
      .then(res => {
        if (res.status === 200) {
          serviceAdmin.putService(`/custom/v1/subscriptions/${props.sub_id}/`, props.input)
            .then(res => {
              if (res.status === 200) {
                setAlert(true, "Sessione utente aggiornata correttamente!", "success");
                props.setReload();
                props.setReloadSub();
                handleClose();
              }
            }).catch(err => {
              console.log(err)
            })
        }
      }).catch(err => {
        setAlert(true, "Ops, qualcosa è andato storto!", "warning");
        console.log(err)
      })
  }

  const onResetDone = () => {
    serviceAdmin.deleteService(`/v1/subscriptions/${props.sub_id}/sessions/${props.session_id}/`)
      .then(res => {
        setOpenDialog(false)
        handleClose()
      }).catch(err => {
        setAlert(true, "Ops, qualcosa è andato storto!", "warning");
        console.log(err)
      })
    ///DO RESET
    //alert("RESET SESSIONE - ModalModifySessions row -> 115")
  }

  const handleCloseReset = () => {
    ///ON RESET CANCEL
    setOpenDialog(false)
  }

  const handleOpenReset = () => {
    ///Open reset confirm 
    setOpenDialog(true)
  }


  const name_type = section.category == "EVALUATION" ? "Verifica" : "Sondaggio";

  return (
    <Fragment>
      <Drawer
        anchor={"right"}
        open={props.open}
        onClose={handleClose}
      >

        <div className={"drawer-container"}>

          <div className={classes.closeicon + " title-drawer-container"}>
            <CloseIcon style={{ cursor: "pointer", height: "30px", width: "30px" }} onClick={handleClose} />
            <h2 className={classes.title}> Modifica sessione </h2>
          </div>
          {loading &&
            <div className={"drawer-content-container"} style={{ padding: "20px 30px" }}>

              <Grid container spacing={3}>
                <Grid item xs={12} >
                  <h2 className={classes.title_course}>{` Corso: ${props.course?.name}` || ""} </h2>
                </Grid>
                {sectionDuration &&
                  <Grid item xs={12}>
                    <InputLabel className={classes.label}>
                      Durata necessaria per il superamento della sezione
                    </InputLabel>
                    <p>{sectionDuration}</p>
                  </Grid>
                }

                <Grid item xs={12} md={6}>
                  <InputLabel className={classes.label}>
                    Durata Sessione
                  </InputLabel>
                  <FullTextField disabled={isEvaluation} name="duration" value={input.duration || ""} onChange={handleChange} variant="outlined" inputProps={{ className: classes.input }} />
                </Grid>

                {input.deletable &&
                  <Grid item xs={12} md={12} style={{ display: "flex" }} alignItems="flex-end" justify='start'>
                    <Button style={{ margin: "30px 0" }} onClick={handleOpenReset} className={classes.btn_reset}>
                      Reset {name_type}
                    </Button>
                  </Grid>
                }


                {input.watched_media_duration &&
                  <Grid item xs={12} md={6}>
                    <InputLabel className={classes.label}>
                      Durata Visione
                    </InputLabel>
                    <FullTextField disabled={isEvaluation} name="watched_media_duration" value={input.watched_media_duration || ""} onChange={handleChange} variant="outlined" inputProps={{ className: classes.input }} />
                  </Grid>
                }

                {input.section_step_time &&
                  <Grid item xs={12} md={6}>
                    <InputLabel className={classes.label}>
                      Tempo Attuale
                    </InputLabel>
                    <FullTextField disabled={isEvaluation} name="section_step_time" value={input.section_step_time || ""} onChange={handleChange} variant="outlined" inputProps={{ className: classes.input }} />
                  </Grid>
                }
              </Grid>
            </div>
          }
          <div className={classes.container_btn + " button-container"}>

            <Button onClick={handleClose} className={classes.btn_del}>Annulla</Button>
            <Button onClick={modifyGroup} className={classes.btn_save}>Aggiorna</Button>

          </div>

        </div>

      </Drawer>

      <DialogDelete
        textDelete="la sessione del test"
        deleteDialog={openDialog}
        callback={onResetDone}
        handleClose={handleCloseReset}
      />

    </Fragment>

  );
}