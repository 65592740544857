import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

const CustomTooltip = withStyles({
  
  tooltip: {
    fontSize: "14px"
  }
})(Tooltip);

export default CustomTooltip;