import React, { useEffect, useState, useRef } from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import TableCorsiUtente from '../Table/TableCorsiUtente';
import serviceAdmin from '../../services/serviceAdmin';
import { makeStyles } from '@material-ui/core/styles';
import useColor from '../../context/themeContext'
import ModalNewSubscription from '../Modal/ModalNewSubscription'
import SelectFilter from '../Select/SelectFilter';

const is_in_history_data = [
    { name: "Iscrizioni storiche", id: "True" },
    { name: "Iscrizioni non storiche", id: "False" },
]

const Corsi = (props) => {

    const { COLORS } = useColor();
    const useStyles = makeStyles({
        color: {
            color: COLORS.primary + "!important"
        }
    })
    const classes = useStyles();

    const isMount = useRef(true);

    const [is_in_history_filter, setIsInHistoryFilter] = useState(null)
    const [courses, setCourses] = useState([])
    const [open, setOpen] = useState(false);
    const [openSub, setOpenSub] = useState(false);
    const [reload, setReload] = useState(false);
    const [confirm, setConfirm] = useState(false)
    const [sub_id, setSub_id] = useState(null)
    const [loading, setLoading] = useState(false)


    const setconfirm = (input, sub) => {
        setConfirm(input)
        setSub_id(sub.sub_id)
    }

    const setreload = () => {
        setReload(!reload)
    }

    const setopen = (input) => {
        setOpen(input)
    }

    const setopensub = (input) => {
        setOpenSub(input)
    }

    const handleChangeHistoryFilter = (e) => {
        const value = e.target.value;
        if (value != "ALL") {
            setIsInHistoryFilter(value)
        }
        else {
            setIsInHistoryFilter(null)
        }

    }

    const resetChangeString = () => {
        setIsInHistoryFilter(null)
    }

    useEffect(() => {
        serviceAdmin.getService(`v1/users/${props.user.otherInfo.id}/subscriptions/`)
            .then(res => {
                if (isMount.current) {
                    if (res.status === 200) {
                        setCourses(res.data.data)
                        setLoading(true)
                    }
                }
            }).catch(err => {
                console.log(err)
            })

        return () => {
            isMount.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        let url = `v1/subscriptions/?user=${props.user.otherInfo.id}`;
        if(is_in_history_filter != null)
            url += `&is_in_history=${is_in_history_filter === "True"}`;
        serviceAdmin.getService(url)
            .then(res => {
                if (isMount.current) {
                    if (res.status === 200) {
                        setCourses(res.data.data)
                    }
                }
            }).catch(err => {
                console.log(err)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload, is_in_history_filter])

    useEffect(() => {
        if (confirm && sub_id) {
            serviceAdmin.deleteService(`v1/subscriptions/${sub_id}/`)
                .then(res => {
                    if (isMount.current) {
                        setreload();
                        setOpen(false);
                        setOpenSub(false);
                        props.setCustomAlert(true, "success", "Iscrizione al corso rimossa correttamente!");
                        setConfirm(false)
                        setSub_id(null)
                    }
                }).catch(err => {
                    console.log(err)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sub_id])

    return (
        <div>
            <h2 className="title_corsi_admin">Corsi a cui è iscritto <span className={classes.color}>{props.user?.firstname + " " + props.user?.lastname}</span></h2>

            <div className={"subscription_course " + classes.color}>
                Iscrivi ad un corso <AddCircleIcon onClick={() => { setopen(true) }} className={"subscription_icon " + classes.color} />
            </div>


            <SelectFilter
                style={{ width: 200 }}
                name={"is_in_history_filter"}
                label={"Filtra per iscrizioni:"}
                all_label={"Tutte le iscrizioni"}
                data={is_in_history_data}
                handleChange={handleChangeHistoryFilter}
                resetChange={resetChangeString}
                input={is_in_history_filter}
            />

            {loading &&
                <TableCorsiUtente open={openSub} setOpen={setopensub} setCustomAlert={props.setCustomAlert} setConfirm={setconfirm} setReload={setreload} courses={courses} />
            }

            <ModalNewSubscription
                setCustomAlert={props.setCustomAlert}
                setReload={setreload}
                reload={reload}
                user_id={props.user.otherInfo.id}
                courses={props.courses}
                open={open}
                setOpen={setopen}
            />

        </div>
    )
}
export default Corsi;