import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid'
import VideoAddMedia from '../Media/VideoAddMedia'
import VideoMedia from '../Media/VideoMedia'
import FileMedia from '../Media/FileMedia'
import FileAddMedia from '../Media/FileAddMedia'
import Alert from '../../componentsAdmin/CollapseAlert/Alert';
import { modalClass } from '../../classes';
import useColor from '../../context/themeContext';
import useAlert from '../../hooks/useAlert'


export default function ModalMedia(props) {

  const [opt, setAlert] = useAlert()
  
  const { COLORS } = useColor();
  const useStyles = makeStyles((theme) => (modalClass(COLORS)));
  const classes = useStyles();
  const [type, setType] = useState("VIDEO")
  const [openAlert, setOpenAlert] = useState({});


  const setCustomAlert = (value, two_value, three_value) => {
    setOpenAlert({
      ...openAlert,
      "state": value,
      "severity": two_value,
      "message": three_value
    })
  };

  useEffect(() => {
    if (openAlert.state) {
      const timer = setTimeout(() => {
        setOpenAlert({ "state": false })
      }, 5000)

      return () => {
        clearTimeout(timer);
      }
    }
  }, [openAlert.state])

  const handleClose = () => {
    props.setOpen(false);
  };

  const settype = (input) => {
    if (!input.includes(props.mediaType?.type) && props.mediaType) {
      setAlert(true, `Hai selezionato il formato ${input === "VIDEO" ? "Video" : "File"}, se salvato andrà a sostituire la risorsa già esistente`, "warning")
      setType(input);
    } else {
      setType(input);
    }
  }

  useEffect(() => {
    if (props.mediaType) {
      if (props.mediaType.type === "VIDEO") {
        setType("VIDEO")
      } else {
        setType("FILE")
      }
    }
  }, [props.mediaType])


  return (

    <Drawer
      anchor={"right"}
      open={props.open}
      onClose={handleClose}
    >
      <div className={"drawer-container"}>
        <Alert
          setOpenAlert={setCustomAlert}
          severity={openAlert?.severity}
          openAlert={openAlert?.state}
          message={openAlert?.message}
        />

        <div className={classes.closeicon}>
          <CloseIcon style={{ cursor: "pointer", height: "30px", width: "30px" }} onClick={handleClose} />
          <h2 className={classes.title}> Aggiungi Video/File </h2>
        </div>

        <div className={"drawer-content-container"} style={{ padding: "20px 30px" }}>

          <Grid container spacing={3}>
            <Grid item xs={12} container justify="center">
              <ButtonGroup>
                <Button className={type === "FILE" ? classes.btn_active : classes.btn_not_active} onClick={() => { settype("FILE") }}>FILE</Button>
                <Button className={type === "VIDEO" ? classes.btn_active : classes.btn_not_active} onClick={() => { settype("VIDEO") }}>VIDEO</Button>
              </ButtonGroup>
            </Grid>
            {!props.add ?
              <Grid item xs={12}>
                {type === "VIDEO" ?
                  <VideoMedia  {...props} />
                  :
                  <FileMedia {...props} />
                }
              </Grid>
              :
              <Grid item xs={12}>
                {type === "VIDEO" ?
                  <VideoAddMedia  {...props} />
                  :
                  <FileAddMedia {...props} />
                }
              </Grid>
            }
          </Grid>


        </div>

      </div>
    </Drawer>

  );
}