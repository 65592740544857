import React, { useState, useEffect } from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DeleteDialog from '../Dialog/DeleteDialog'
import TablePagination from '@material-ui/core/TablePagination';
import serviceAdmin from '../../services/serviceAdmin'
import useColor from '../../context/themeContext'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ModalSubscription from '../Modal/ModalSubscription'
import ModalSession from '../Modal/ModalSession'
import Checkbox from '@material-ui/core/Checkbox'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import EnhancedTableHead from './componentTable/EnhancedTableHead'
import EnhancedTableToolbar from './componentTable/EnhancedTableToolbar'
import { tableStyle } from '../../classes'
import moment from 'moment'
import 'moment/locale/it';
moment.locale('it')


const headCells = [
  { id: 'firstname', numeric: false, disablePadding: false, label: 'Nome', class: "" },
  { id: 'lastname', numeric: false, disablePadding: false, label: 'Cognome', class: "hide-column-xs " },
  { id: 'stato', numeric: false, disablePadding: false, label: 'Stato', class: "hide-column-xs " },
  { id: 'subscription_date', numeric: false, disablePadding: false, label: 'Data iscrizione', class: "hide-column-md " },
  { id: 'complete', numeric: false, disablePadding: false, label: 'Completato', class: "" },
  { id: 'complete_date', numeric: false, disablePadding: false, label: 'Data completamento', class: "hide-column " },
  { id: 'total_time', numeric: false, disablePadding: false, label: 'Tempo totale', class: "hide-column " },
  { id: 'effetive_time', numeric: false, disablePadding: false, label: 'Tempo effettivo', class: "hide-column-md " },
  { id: 'percentage', numeric: false, disablePadding: false, label: 'Percentuale completamento', class: "hide-column-md " },
  { id: 'active', numeric: false, disablePadding: false, label: 'Attivo', class: "hide-column-md " },
  { id: 'is_in_history', numeric: false, disablePadding: false, label: 'Storico', class: "hide-column-md " },
];

export default function TableCourseUsers(props) {
  const { COLORS } = useColor();
  const useStyles = makeStyles(tableStyle(COLORS));
  const classes = useStyles();

  const [openSession, setOpenSession] = useState(false);
  const [sub, setSub] = useState({});
  const [rows, setRows] = useState([])
  const [deleteUser, setDelete] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [etiquette, setEtiquette] = useState(null)
  const [arrayEtiquette, setArrayEtiquette] = useState({})
  const [selected, setSelected] = useState([])
  const [anchorEl, setAnchorEl] = React.useState(null)

  const openExport = (e) => { if (anchorEl !== e.currentTarget) setAnchorEl(e.currentTarget); }
  const closeExport = () => { setAnchorEl(null) }
  const removeSelected = () => { setSelected([]); }

  const setdelete = (input) => { setDelete(input) }

  useEffect(() => {
    props.setSelected(selected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  useEffect(() => {
    props.setRowsUser(rowsPerPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage])

  useEffect(() => {
    props.setPageUser(page + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    serviceAdmin.getService(`/custom/v1/configurations/${process.env.REACT_APP_ETIQUETTE}/`)
      .then(res => {
        const etiquettes = Object.keys(res.data.data).map((key) => { return { [key]: res.data.data[key] } });
        setEtiquette(res.data.data)

        setArrayEtiquette(etiquettes)
      }).catch(err => {
        console.log(err)
      })
  }, [])

  useEffect(() => {

    if (props.users) {
      const usersArray = [...Array(rowsPerPage * page), ...props.users]
      console.log(usersArray)

      setRows(
        usersArray.map((user, index) => {
          if (user?.id) {
            const duration = user?.duration?.split('.')[0];
            console.log(user)
            return {
              "first_name": user?.user.first_name,
              "last_name": user?.user.last_name,
              "subscription": moment(user.created).format('DD-MM-YYYY'),
              "info": user?.additional_info,
              "id": user.id,
              "completion_date": user.completion_date,
              "duration": duration,
              "progress": user.progress,
              "is_in_history": user.is_in_history,
              "course_duration": user.course_duration,
              "complete": user.is_passed,
              "active": user.is_active,
              "state": user.status_etiquette,
              "sub_id": user?.id,
              "course_name":user.course,
              "total_watched_duration": user?.total_watched_duration,
              "course": user.course
            }
          } else {
            return { "first_name": "", }
          }

        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.users])

  const setopensession = (input) => {
    setOpenSession(input)
  }

  const modifyUser = (event, row) => {
    props.setOpenModify(true)
    setSub(row)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const default_etiquette = {
    "RDY": "DA AVVIARE",
    "RUN": "IN CORSO",
    "OFF": "SOSPESO",
    "END": "FINITO"
  };

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.reduce((result, row) => {
        if (row.id !== undefined) {
          result.push(row.id)
        }
        return result;
      }, [])
      const allSelected = newSelecteds.concat(selected);
      const filterSelected = allSelected.filter(onlyUnique)
      setSelected(filterSelected);
      return;
    } else {
      const newSelecteds = rows.reduce((result, row) => {
        if (row.id !== undefined) {
          result.push(row.id)
        }
        return result;
      }, [])
      const filterSelected = selected.filter((el) => !newSelecteds.includes(el));
      setSelected(filterSelected)
    }
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1 || props.all;
  return (
    <React.Fragment>
      {selected.length > 0 &&
        <EnhancedTableToolbar
          {...props}
          allusers={rows.length}
          numSelected={selected.length}
          removeSelected={removeSelected}
          anchorEl={anchorEl}
          openExport={openExport}
          closeExport={closeExport}
          labelSing={"utente"}
          labelPlu={"utenti"}
          labelArt={"gli utenti"}
          exportTable={props.exportTable}
        />
      }
      <TableContainer>
        <Table size={'small'} className={classes.table} aria-label="simple table">
          <EnhancedTableHead
            {...props}
            haveCheckbox
            classes={classes}
            onSelectAllClick={handleSelectAllClick}
            rows={rows.map(row => row.id)}
            selected={selected}
            headCells={headCells}
          />

          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {

                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    role="checkbox"
                    aria-checked={isSelected}
                    selected={isItemSelected}
                    className={classes.rowSelected}
                    hover
                    key={index}>

                    <TableCell className={classes.label_cell} padding="checkbox">
                      <Checkbox
                        disabled={props.all}
                        className={classes.check}
                        onClick={(event) => { handleClick(event, row.id) }}
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>
                    <TableCell align="left">{row.first_name}</TableCell>
                    <TableCell className={"hide-column-xs"} align="left">{row.last_name}</TableCell>
                    <TableCell className={"hide-column-xs"} align="left">
                      {etiquette ? etiquette[row.state] : default_etiquette[row.state]}
                    </TableCell>
                    <TableCell className={"hide-column-md"} align="left">{row.subscription}</TableCell>
                    <TableCell align="center">{row.complete ? <CheckCircleIcon style={{ color: COLORS.primary }} /> : <HighlightOffIcon style={{ color: COLORS.primary }} />}</TableCell>
                    <TableCell className={"hide-column"} align="left">{row.completion_date ? moment(row?.completion_date).format('DD-MM-YYYY') : null}</TableCell>
                    <TableCell className={"hide-column"} align="left">{row.duration}</TableCell>
                    <TableCell className={"hide-column-md"} align="left">{row.total_watched_duration ? moment.utc(row.total_watched_duration * 1000).format('HH:mm:ss') : "00:00:00"}</TableCell>
                    <TableCell className={"hide-column-md"} align="center">{row.progress ? row.progress + "%" : null}</TableCell>
                    <TableCell className={"hide-column-md"} align="center">{row.active ? <CheckCircleIcon style={{ color: COLORS.primary }} /> : <HighlightOffIcon style={{ color: COLORS.primary }} />}</TableCell>
                    <TableCell className={"hide-column-md"} align="center">{row.is_in_history ? <CheckCircleIcon style={{ color: COLORS.primary }} /> : <HighlightOffIcon style={{ color: COLORS.primary }} />}</TableCell>
                    <TableCell align="left">
                      <MoreHorizIcon onClick={(e) => modifyUser(e, row)} style={{ width: "30px", height: "30px", color: "#999", cursor: "pointer" }} />
                    </TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={props.count}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          classes={{ menuItem: classes.selected }}
          labelRowsPerPage={"Righe per pagina: "}
        />
        <DeleteDialog
          deleteLesson={deleteUser}
          setDelete={setdelete}
          textDelete={"l'utente da questo corso"}
          handleCloseMenu={() => { }}
          lesson={sub}
          setConfirm={props.setConfirm}
        />
        <ModalSubscription etiquette={arrayEtiquette} setDelete={setdelete} setCustomAlert={props.setCustomAlert} setReload={props.setReload} sub={sub} course={sub.course} setOpen={props.setOpenModify} open={props.openModify} />
        <ModalSession setCustomAlert={props.setCustomAlert} setReload={props.setReload} course={sub.course} sub={sub} setOpen={setopensession} open={openSession} />
      </TableContainer>
    </React.Fragment>
  );
}