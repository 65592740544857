import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button';
import useColor from '../../context/themeContext'
import serviceAdmin from '../../services/serviceAdmin';
import Grid from "@material-ui/core/Grid";
import ModalGroupUser from './ModalGroupUser'
import ModalGroupUserDelete from './ModalGroupUserDelete'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import PeopleIcon from '@material-ui/icons/PeopleOutline';
import DeleteDialog from '../Dialog/DeleteDialog'
import { modalGroup } from '../../classes'
import FullTextField from '../StyledComponent/FullTextField'

function TabPanel(props) {
  
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



export default function ModalGroup(props) {
  const { COLORS } = useColor();
  const useStyles = makeStyles((theme) => (modalGroup(COLORS)));
  const classes = useStyles();
  const [input, setInput] = useState({})
  const [user, setUser] = useState(0)
  const [reload, setReload] = useState(false)

  const setreload = () => { setReload(!reload) }

  let slugRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleChangeValue = (event, newValue) => {
    props.setValue(newValue);
  };


  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInput({ ...input, [name]: value })
  }

  useEffect(() => {
    serviceAdmin.getService(`v1/groups/${props.group.id}/`)
      .then(res => {
        setUser(res.data.users_count)
      }).catch(err => {
        console.log(err)
      })
    if (props.group) {
      setInput({
        ...input,
        "name": props.group.name,
        "slug": props.group.slug,
        "description": props.group.description
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.group, reload])



  const modifyGroup = () => {
    serviceAdmin.putService(`v1/groups/${props.group.id}/`, input)
      .then(res => {
        props.setReload();
        handleClose();
      }).catch(err => {
        console.log(err)
      })
  }

  return (

    <React.Fragment>
      <Paper className={classes.root}>

        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href={"#"} onClick={handleClose} className={classes.link}>
            <PeopleIcon className={classes.icon} />
            <span className={classes.span}>Gruppi</span>
          </Link>
          <Link color="inherit" className={classes.link_active}>
            <span className={classes.span}>
              {props.group?.name}
            </span>
          </Link>
        </Breadcrumbs>

        <Tabs
          className={classes.tabs}
          value={props.value}
          onChange={handleChangeValue}
          scrollButtons="auto"
          variant="scrollable"
        >
          <Tab className={classes.tab} label="Dettaglio gruppo" {...a11yProps(0)} />
          <Tab className={classes.tab} label="Lista utenti" {...a11yProps(1)} />
          <Tab className={classes.tab} label="Aggiungi utenti" {...a11yProps(2)} />
        </Tabs>
      </Paper>
      <div className={"content-menu"}>
        <TabPanel value={props.value} index={0}>
          <div style={{ width: "100%", marginBottom: "80px" }}>

            <Grid container spacing={3}>

              <Grid item xs={12} md={6} lg={3}>
                <InputLabel className={classes.label}>
                  Nome
                </InputLabel>
                <FullTextField name="name" value={input.name || ""} onChange={handleChange} placeholder="nome del gruppo" variant="outlined" inputProps={{ className: classes.input }} />
              </Grid>

              <Grid item xs={12} style={{ paddingTop: "20px" }}>
                <InputLabel className={classes.label}>
                  Descrizione
                </InputLabel>
                <FullTextField name="description" value={input.description || ""} onChange={handleChange} placeholder="Descrizione del gruppo" multiline rows={5} variant="outlined" inputProps={{ className: classes.textarea }} />
              </Grid>
            </Grid>
          </div>

          <Paper elevation={3} className={classes.save}>

            <Button onClick={() => { props.setDelete(true) }} className={classes.btn_del_user}>Elimina</Button>
            <Button onClick={handleClose} className={classes.btn_del}>Annulla</Button>
            <Button disabled={slugRegex.test(input.slug) && input.name && input.slug ? false : true} onClick={modifyGroup} className={classes.btn_save}>Modifica</Button>

          </Paper>

        </TabPanel>

        <TabPanel value={props.value} index={1}>

          <ModalGroupUserDelete
            setReloadCount={setreload}
            user={user}
            setCustomAlert={props.setCustomAlert}
            reload={props.reload}
            setReload={props.setreload}
            setreload={props.setReload}
            group={props.group}
            setOpen={props.setOpen}
          />

        </TabPanel>

        <TabPanel value={props.value} index={2}>

          <ModalGroupUser
            setReloadCount={setreload}
            user={user}
            setCustomAlert={props.setCustomAlert}
            reload={props.reload}
            setReload={props.setreload}
            setreload={props.setReload}
            group={props.group}
            setOpen={props.setOpen}
          />

        </TabPanel>
      </div>
      <DeleteDialog
        deleteLesson={props.deleteGroup}
        setDelete={props.setDelete}
        textDelete={"il gruppo"}
        handleCloseMenu={props.handleCloseMenu}
        setConfirm={props.setConfirm}
        lesson={props.group}
      />
    </React.Fragment>

  );
}