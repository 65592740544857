import React, {Fragment} from "react";
import { Grid, InputLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import DateFnsUtils from '@date-io/date-fns';
import { it } from 'date-fns/locale'
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import useColor from "../../../context/themeContext";
import moment from "moment"


const FromDate = (props) => {
    
    const { COLORS } = useColor()
    const useStyles = makeStyles((theme) => ({
        color_label: {
            '&.Mui-selected': {
                color: COLORS.primary + "!important"
            }
        },
        input: {
            backgroundColor: "#fff!important",
            border: "1px solid #ccc",
            borderRadius: "20px",
            padding: "12px 10px",
            textAlign: "center",
        },
        textfield: {
            maxWidth: "300px",
            width: "100%",
            margin: "20px 0",
        }
    }));
    const classes = useStyles();


    const dateCallback = (data)=>{
   
        const isoData = moment(data).format("YYYY-MM-DDT00:00:00.000") + "Z";
        props.handleChange(isoData)
    }

    return (
        <>
        {
        !props.export ? 
                <MuiPickersUtilsProvider locale={it} utils={DateFnsUtils}>
                    <DateTimePicker
                        inputProps={{ className: props.inputProps}}
                        ampm={false}
                        clearLabel={false}
                        cancelLabel={"Annulla"}
                        error={false}
                        format="dd/MM/yyyy HH:mm"
                        helperText={false}
                        inputVariant="outlined"
                        value={props.value || null}
                        onChange={dateCallback}
                    />
                </MuiPickersUtilsProvider>
        
        :

        <Grid style={{ textAlign: "center"}} item xs={12} md={6} xl={6} >
            <InputLabel style={{ paddingBottom: 20}}>
                {props.label}
            </InputLabel>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>

                <MuiPickersUtilsProvider locale={it} utils={DateFnsUtils}>
                    <DateTimePicker
                        className={props.classTextField}
                        inputProps={{
                            className: classes.input
                        }}
                        ampm={false}
                        clearLabel={false}
                        cancelLabel={"Annulla"}
                        error={false}
                        format="dd/MM/yyyy HH:mm"
                        helperText={false}
                        inputVariant="outlined"
                        value={props.value || null}
                        onChange={dateCallback}
                    />
                </MuiPickersUtilsProvider>
                

                <IconButton style={{ padding: "0px", height: "24px" }} onClick={props.disableDate} >
                    <HighlightOffIcon style={{ color: "#0000008A" }} />
                </IconButton>

            </div>
        </Grid>
        }
        </>
    )
}

export default FromDate;