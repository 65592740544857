import React, { useState, useEffect } from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import Zoom from '@material-ui/core/Zoom';
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import useColor from '../../context/themeContext'


export default function ProgressMobileStepper(props) {

  const { COLORS } = useColor()

  const BlueTooltip = withStyles({
    tooltip: {
      fontSize: "14px",
      color: "#fff",
      backgroundColor: COLORS.primary
    }
  })(Tooltip);

  const useStyles = makeStyles({
    root: {
      background: "#fff",
      flexGrow: 1,
      padding: "8px 0px",
      '& .MuiMobileStepper-progress': {
        width: "100%",
        '& .MuiLinearProgress-barColorPrimary': {
          background: COLORS.primary,
        },
        '&:active': {
          color: "#FFD700!important"
        }
      }
    }
  });
  const classes = useStyles();
  const [width, setWidth] = useState(0)

  const classetStyle = makeStyles({
    bar: {
      width: "100%",
      backgroundColor: COLORS.primary,
      "& .MuiLinearProgress-bar": {
        transform: "translateX(" + width + "%)!important",
        backgroundColor: lighten(COLORS.primary, 0.8),
      }
    }
  });
  const classet = classetStyle();

  useEffect(() => {
    const number = props.date1 > props.date2 || !props.date1 ? (props.relatedsComplete / props.relateds) * 100 : 100;
    setWidth(number)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.relatedsComplete, props.relateds])


  return (
    <React.Fragment>
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <h3>{props?.course}</h3>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <BlueTooltip TransitionComponent={Zoom} title={!props.complete ? "Per completare il corso devi superare tutte le sezioni obbligatorie" : "Bravo, hai completato tutte le sezioni obbligatorie"}>
              <EmojiEventsIcon style={props.complete ? { color: "#FFD700" } : { color: "#999" }} />
            </BlueTooltip>
          </div>
          <div style={{ display: "flex" }}>
            <MobileStepper
              className={classes.root}
              LinearProgressProps={{ className: classet.bar }}
              variant="progress"
              steps={props.relateds}
              position="static"
              activeStep={0}
            />
         
          </div>
          {
            !props.complete && !props.is_preview ?
              <p style={{ padding: "10px 0" }}>{props.relatedsComplete}/{props.relateds} sezioni completate</p>
              :
              null
          }
        </div>
    </React.Fragment>
  );
}