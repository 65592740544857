import React, { useEffect, useState } from "react";
import { Container, Grid, Paper, MenuItem, Select,Chip  } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import useColor from '../context/themeContext'
import serviceAdmin from '../services/serviceAdmin';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import Alert from '../componentsAdmin/CollapseAlert/Alert';
import {DropzoneArea} from 'material-ui-dropzone'
import TableImport from '../componentsAdmin/Table/TableImport'
import { usersStatusEventSource } from '../services/sseClient'
import { select, buttonSuccess, buttonProgress, colorLabel, borderRadius} from '../classes'
import moment from 'moment'
import 'moment/locale/it';
moment.locale('it')



const Import = (props) => {
    const { COLORS } = useColor();
    const useStyles = makeStyles((theme) => ({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        input: {
            display: 'none',
        },
        buttonProgress,
        buttonSuccess,
        wrapper: {
            marginTop:"30px",
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
        },
        dropZone:{
            maxWidth: "70%",
            margin: "0 auto",
            
            "& .MuiDropzoneArea-icon":{
                color:"#3AAC4D",
                width:"120px",
                height:"120px"
            }
        },
        ...colorLabel(COLORS),
        select,
        chip:{
            color:"#fff",
            backgroundColor:COLORS.primary,
            margin:"0 5px",
            fontSize:"16px"
        }
    }));
    const classes = useStyles();

    const [file, setFile] = useState(null)
    const [data, setData] = useState("")
    const [imports, setImports] = useState([])
    const [count, setCount] = useState(0)
    const [fileSelected, setFileSelected] = useState(false)
    const [loadingOne, setLoadingOne] = useState(null)
    const [openAlert, setOpenAlert] = useState({});
    const [backdrop, setBackdrop] = useState(false);
    const [rowsImport, setRowsImport] = useState(10);
    const [pageImport, setPageImport] = useState(1);
    const [page, setPage] = useState(0);
    const [reload, setReload] = useState(false);
    const [ordering, setOrdering] = useState("")


    const setreload = ()=>{setReload(!reload)};
    const setbackdrop =(input)=>{ setBackdrop(input)};
    const setrowsimport =(input)=>{ setRowsImport(input)};
    const setpage =(input)=>{ setPage(input)};
    const setpageimport =(input)=>{ setPageImport(input)};

    useEffect(()=>{
        let course_code = props.history.location.state.course_code
        let deletion_batch_codes_url = `/custom/v1/synchronization/deletion/${course_code}/?page=${pageImport}&page_items=${rowsImport}`
        let registration_batch_codes_url = `/v1/synchronization/data-imports/batch-codes/?page=${pageImport}&page_items=${rowsImport}&batch_code__note=registration`
        let url = props.history.location.state.delete ? deletion_batch_codes_url : registration_batch_codes_url
        if(ordering) url += `&ordering=${ordering}`;

        serviceAdmin.getService(url)
        .then(res=>{
            if(res.data.data){
                setImports(res.data.data.map((i)=>{
                    return {
                      "id": i.value,
                      "user": i?.user?.first_name+" "+i?.user?.last_name,
                      "created": moment(i?.created).format('DD-MM-YYYY HH:mm'),
                      "file": i?.exit_jobs_file,
                      "success": i?.queue_operation_error === false && i?.success && i?.ended ? true : false
                    }
                  }));
                setBackdrop(false)
                setPage(pageImport-1)
                setCount(res.data.meta?.pagination?.items_count)
            }
        }).catch(err=>{
            console.log(err);
        })
    },[pageImport, rowsImport, reload, ordering])

    const setCustomAlert = (value,two_value,three_value) =>{
        setOpenAlert({...openAlert,
          "state": value,
          "severity":two_value,
          "message":three_value
        })
      };


    const setAlert=(info,otherInfo,file)=>{
        if(otherInfo !== "success" && otherInfo !== "error"){
            setCustomAlert(true, otherInfo, info)
        }else if(otherInfo === "error"){
            setCustomAlert(true, otherInfo,"Errore, tipo di file non supportato!")
        }
    }
    const deleteFile = ()=>{
        setFile(null)
        setFileSelected(false)
    }

    ////////FILE SELECT

    const selectFile = (file) => {
        if (file[0]) {
            let formData = new FormData();
            const isFileCsv = file => file["type"].includes("csv")

            if(isFileCsv(file[0])){formData.append("import_fmt","csv")}
            else{formData.append("import_fmt","excel")}    
           
            formData.append("import_file", file[0]);
            if (props.history.location.state.delete){
                formData.append("course_code", props.history.location.state.course_code);
            }

            setFile(formData);
            setFileSelected(true)
        }
    }

    const importDone =()=>{
        props.setCustomAlert(true, "success", "Importazione avvenuta con successo!");
        setLoadingOne(false)
        setData("")
        setreload();
    }

    const importFail =(data)=>{
        props.setCustomAlert(true, "warning", (`${data?.message}\n${data?.reason}`) || "Ops, qualcosa è andato storto!");
        setLoadingOne(false)
        setData("")
        setreload();
    }

    const importUsers = (deletion=false)=>{
        // props.history.location.state.delete
        if (file) {

            serviceAdmin.multipartService(deletion ? "/custom/v1/synchronization/deletion/" : "/custom/v1/synchronization/registrations/", file)
                .then((res) => {
                    if (res.data.data) {
                        const sse_url = res.data.data.sse_url
                        setLoadingOne(true);
                        usersStatusEventSource(sse_url, setData, importDone, importFail)
                    }
                }).catch(err => {
                    setLoadingOne(false)
                    if(err?.status === 400){
                        props.setCustomAlert(true,"warning","Ops, qualcosa è andato storto, il file potrebbe non essere corretto!");
                    }
                })
        }
    }

    return (
        <div>
            <Alert
              setOpenAlert={setCustomAlert}
              severity={openAlert?.severity}
              openAlert={openAlert?.state}
              message={openAlert?.message}
              duration={5000}
            />
            <Container maxWidth={"xl"} style={{ marginTop: "30px" }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper elevation={2} style={{ minHeight: "100%", borderRadius: borderRadius, padding: "50px 10px" }}>
                            
                            <p style={{display:"flex",alignItems:"center",color:COLORS.primary,paddingLeft:"20px"}} className={"label-attivita"}>
                                Importa Utenti {props.history.location.state.delete ? "da eliminare" : ""}
                            </p>
                            <InputLabel style={{ textAlign:"center",fontSize:"20px",padding:"20px",margin:"10px auto 0px"}}>
                               File supportati: 
                            
                            </InputLabel>
                            <InputLabel style={{ textAlign:"center",marginBottom:"20px"}}>
                                <Chip className={classes.chip} size="small" label=".xls" />
                                <Chip className={classes.chip} size="small" label=".xlsx" />
                                <Chip className={classes.chip} size="small" label=".csv" />
                            </InputLabel>
                                
                                <React.Fragment>
                                    <DropzoneArea
                                     onChange={selectFile}
                                     onDelete={deleteFile}
                                     onAlert={setAlert}
                                     acceptedFiles={['.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
                                     showPreviews={false}
                                     dropzoneText={"Trascina il file o cercalo sul tuo dispositivo"}
                                     filesLimit={1}
                                     showAlerts={false}
                                     showFileNames
                                     dropzoneClass={classes.dropZone}
                                    />
                                </React.Fragment>

                            <div className={classes.wrapper}>
                                {fileSelected &&
                                <React.Fragment>
                                    <Button
                                        onClick={()=> importUsers(props.history.location.state.delete)}
                                        style={{ padding: "10px 15px", borderRadius: borderRadius, background: "rgb(58, 172, 77)" }}
                                        variant="contained"
                                        color="primary"
                                        component={"span"}
                                    >
                                        {data || "Importa file" }
                                    </Button>
                                 
                                </React.Fragment>
                                }
                                {loadingOne && <CircularProgress size={24} className={classes.buttonProgress} />}

                            </div>
                        </Paper>
                    </Grid>

                   <Grid item xs={12}>
                        <Paper style={{borderRadius:borderRadius,padding:"20px"}}>
                            <div style={{display:"flex",alignItems:"flex-start",padding:"20px 0px 40px 20px"}}>
                                <h2 style={{color:COLORS.primary}} className={"label-attivita"}>Lista Import</h2>
                                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "30px" }}>
                                    <InputLabel style={{ width: "100%", textAlign: "center", fontSize: "17px", marginBottom: "15px" }}>
                                        Ordina per:
                                    </InputLabel>
                                    <Select variant="outlined" value={ordering || ""} onChange={(event) => {setPageImport(1);setOrdering(event.target.value);}} className={classes.select} name="ordering">
                                        <MenuItem className={classes.colorLabel} value={""}>Nessun ordinamento</MenuItem>     
                                        <MenuItem className={classes.colorLabel} value={"created"}>Data creazione: crescente</MenuItem>
                                        <MenuItem className={classes.colorLabel} value={"-created"}>Data creazione: decrescente</MenuItem>
                                    </Select>
                                </div>
                            </div>
                            <TableImport
                                headcell={[{name:""},{name:"Utente"}, {name:"Data creazione",class:"hide-column-md "}, {name:"Esito operazione"}, {name:"Download esito"}]}
                                imports={imports}
                                count={count}
                                setBackdrop={setbackdrop}
                                backdrop={backdrop}
                                setRowsImport={setrowsimport} 
                                rowsImport={rowsImport}
                                page={page}
                                setPage={setpage}
                                pageImport={pageImport}
                                setPageImport={setpageimport}
                                detail
                                import
                            />
                        </Paper>
                    </Grid>
                    
                </Grid>
            </Container>
        </div>
    )
}

export default Import;

