import React from "react"
import useColor from '../../context/themeContext'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid, TextField, Checkbox, InputLabel, Slider } from "@material-ui/core"
import useAlert from "../../hooks/useAlert";

const CssTextField = withStyles({
    root: {
        border: "none",
        '& .MuiInput-underline:after': {
            border: 'none',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none',
            },
            '&:hover fieldset': {
                borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'green',
            },
        },
    },
})(TextField);






function valuetext (value) {
    return `${value}%`;
}

const OptionsGeneral = (props) => {
    const { COLORS } = useColor();
    const { handleChangeInput, input, isEvaluation } = props


    const BlueCheckbox = withStyles({
        root: {
            color: COLORS.primary,
            '&$checked': {
                color: COLORS.primary,
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    const useStyles = makeStyles({
        input: {
            backgroundColor: "#f1f1f1",
            borderRadius: "20px",
            padding: "12px 20px",
            maxWidth: "195px!important"
        },
        label: {
            paddingBottom: "20px",
            color: COLORS.primary,
            fontWeight: "bold",
            fontSize: "19px"
        },
        slider: {
            margin: "0 auto",
        },
        checkbox: {
            '&:hover': {
                backgroundColor: 'transparent',
            },
            ' .Mui-checked': {
                color: COLORS.primary
            },
            color: COLORS.primary
        }
    })

    const classes = useStyles();
    return (
        <React.Fragment>
            <Grid className="flex-center" item xs={12} sm={12} md={4} lg={3} xl={2}>
                <InputLabel className={classes.label}>
                    {isEvaluation ? 'Nome Verifica' : 'Nome Sondaggio'}
                </InputLabel>
                <CssTextField
                    name={"name"}
                    placeholder={isEvaluation ? 'Nome verifica' : 'Nome sondaggio'}
                    value={input.name || ""}
                    onChange={handleChangeInput}
                    variant="outlined"
                    inputProps={{ className: classes.input }}
                />
            </Grid>
            {/*<Grid className="flex-center" item xs={12} sm={12} md={4} lg={3} xl={2}>
                <InputLabel className={classes.label}>
                    Editabile
                </InputLabel>
                <BlueCheckbox
                    name={"is_editable"}
                    className={classes.checkbox}
                    checked={input.is_editable || false}
                    onChange={handleChangeInput}
                />
            </Grid>*/}
            <Grid className="flex-center" item xs={12} sm={12} md={4} lg={3} xl={2}>
                <InputLabel className={classes.label}>
                    Presentazione sequenziale
                </InputLabel>
                <BlueCheckbox
                    name={"is_sequential"}
                    className={classes.checkbox}
                    checked={input.is_sequential || false}
                    onChange={handleChangeInput}
                />
            </Grid>
        </React.Fragment>
    )
}

const OptionsEvaluations = (props) => {
    const [options, setAlert] = useAlert()
    const { COLORS } = useColor();
    const { handleChangeInput, handleChangeSlide, input, n_questions} = props

    const handleChangeQuestionBank = (e) => {
        if(e.target.value <= n_questions || !e.target.value)
        {       
            handleChangeInput(e);
        }else{
            ///ERROR 
            setAlert(true,`Il valore massimo consentito è: ${n_questions}`, "warning");
        }
    }

    const useStyles = makeStyles({
        input: {
            backgroundColor: "#f1f1f1",
            borderRadius: "20px",
            padding: "12px 20px",
            maxWidth: "195px!important"
        },
        label: {
            paddingBottom: "20px",
            color: COLORS.primary,
            fontWeight: "bold",
            fontSize: "19px"
        },
        slider: {
            margin: "0 auto",
        },
        checkbox: {
            '&:hover': {
                backgroundColor: 'transparent',
            },
            ' .Mui-checked': {
                color: COLORS.primary
            },
            color: COLORS.primary
        }
    })

    const BlueSlider = withStyles(theme => ({
        thumb: {
            color: COLORS.primary
        },
        root: {
            color: COLORS.primary,
            marginTop: "30px"
        }
    }))(Slider);

    const BlueCheckbox = withStyles({
        root: {
            color: COLORS.primary,
            '&$checked': {
                color: COLORS.primary,
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    const classes = useStyles();
    return (
        (
            <React.Fragment>
                <Grid className="flex-center" item xs={12} sm={12} md={4} lg={3} xl={2}>
                    <InputLabel className={classes.label}>
                        Ordinamento random
                    </InputLabel>
                    <BlueCheckbox
                        name={"has_random_order"}
                        className={classes.checkbox}
                        checked={input.has_random_order || false}
                        onChange={handleChangeInput}
                    />
                </Grid>

                <Grid className="flex-center" item xs={12} sm={12} md={4} lg={3} xl={2}>
                    <InputLabel className={classes.label}>
                        Tentativi illimitati
                    </InputLabel>
                    <BlueCheckbox
                        name={"retries_not_limited"}
                        className={classes.checkbox}
                        checked={input.retries_not_limited || false}
                        onChange={handleChangeInput}
                    />
                </Grid>
                {!input.retries_not_limited && 
                <Grid className="flex-center" item xs={12} sm={12} md={4} lg={3} xl={2}>
                    <InputLabel className={classes.label}>
                        Numero di tentativi
                    </InputLabel>
                    <CssTextField
                        name={"max_retries"}
                        placeholder={"Numero di tentativi"}
                        value={input.max_retries}
                        onChange={handleChangeInput}
                        variant="outlined"
                        inputProps={{ className: classes.input }}
                    />
                </Grid>
                }   
                <Grid className="flex-center" item xs={12} sm={12} md={4} lg={3} xl={2}>
                    <InputLabel className={classes.label}>
                        Mostra risposte corrette
                </InputLabel>
                    <BlueCheckbox
                        name={"has_show_correct_answers"}
                        className={classes.checkbox}
                        checked={input.has_show_correct_answers || false}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid className="flex-center" item xs={12} sm={12} md={4} lg={3} xl={2}>
                    <InputLabel className={classes.label}>
                        Scelta casuale tra le domande
                </InputLabel>
                    <BlueCheckbox
                        name={"is_question_bank_randomize_choice"}
                        className={classes.checkbox}
                        checked={input.is_question_bank_randomize_choice || false}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid className="flex-center" item xs={12} sm={12} md={4} lg={3} xl={2}>
                    <InputLabel className={classes.label}>
                        Numero di domande tra cui scegliere
                    </InputLabel>
                    <CssTextField
                        name={"question_bank_question_number"}
                        placeholder={"Numero question bank"}
                        value={input.question_bank_question_number}
                        onChange={handleChangeQuestionBank}
                        variant="outlined"
                        inputProps={{ className: classes.input }}
                    />
                </Grid>
                <Grid className="flex-center" item xs={8} className={classes.slider}>
                    <InputLabel className={classes.label}>
                        Percentuale completamento
                    </InputLabel>

                    <BlueSlider
                        value={input.perc_correct_questions}
                        onChange={handleChangeSlide}
                        getAriaValueText={valuetext}
                        defaultValue={60}
                        step={1}
                        valueLabelDisplay="auto"
                        marks
                        min={10}
                        max={100}
                        valueLabelDisplay="on"
                    />

                </Grid>
            </React.Fragment>
        )
    )
}

const Optional = (props) => {

    return (
        <React.Fragment>
            <OptionsGeneral isEvaluation={props.isEvaluation} handleChangeInput={props.handleChangeInput} handleChangeSlide={props.handleChangeSlide} input={props.input} />
            {props.isEvaluation ? <OptionsEvaluations n_questions={props.n_questions} handleChangeInput={props.handleChangeInput} handleChangeSlide={props.handleChangeSlide} input={props.input} /> : null}
        </React.Fragment>
    )
}

export default Optional