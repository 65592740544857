import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { lighten, makeStyles } from '@material-ui/core/styles'
import {
  MenuItem, Select, InputLabel, Checkbox, Typography, Table, TableBody, TableCell, TableContainer,
  TableHead, TablePagination, TableRow, Toolbar, Button, Grid, Backdrop, CircularProgress
} from '@material-ui/core/'
import { useEffect } from 'react'
import AntSwitch from '../Switch/AntSwitch'
import { tableStyle } from '../../classes'
import useColor from '../../context/themeContext'


const headCells = [
  { id: 'username', numeric: false, disablePadding: true, label: 'Username' },
  { id: 'first_name', numeric: false, disablePadding: false, class:"hide-column-md ", label: 'Nome' },
  { id: 'last_name', numeric: false, disablePadding: false, class:"hide-column-md ", label: 'Cognome' },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
];


function EnhancedTableHead(props) {
  const { COLORS } = useColor();
  const { onSelectAllClick, order, orderBy, selected, rows } = props;
  const allPage = rows.every(r => selected.includes(r));


  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            style={{ color: COLORS.primary }}
            indeterminate={allPage}
            checked={allPage}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'seleziona tutti gli utenti' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className={headCell?.class}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}

          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  selected: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const EnhancedTableToolbar = (props) => {
  const { COLORS } = useColor();

  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      paddingTop: "15px",
      paddingBottom: "15px"
    }, highlight:
      theme.palette.type === 'light'
        ? {
          color: COLORS.primary,
          backgroundColor: lighten(COLORS.primary, 0.85),
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    title: {
      flex: '1 1 100%',
    },
    select: {
      '& .MuiSelect-root': {
        backgroundColor: "#fff!important",
        borderRadius: "20px!important",
        minWidth: "150px",
        border: "1px solid #ccc"
      }
    },
    input: {
      backgroundColor: "#f1f1f1",
      borderRadius: "20px",
      padding: "12px 10px",
    },
    menuItem: {
      "&.Mui-selected": {
        color: COLORS.primary + "!important"
      }
    }
  }));
  const classes = useToolbarStyles();
  const { close, open, anchorEl, groups, group, filter, numSelected, roles, role, handleChangeRoles, handleChangeGroups, setChecked, setCheckedActive, checkedActive, checked, setAllGroups, allusers, } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected === 1 ? `${numSelected} utente selezionato` : (numSelected === allusers ? `Tutti gli utenti di questa pagina sono stati selezionati` : `${numSelected} utenti selezionati`)}
        </Typography>
      ) : (
          null
        )}
      {filter &&
        <Grid container spacing={1} style={{ marginBottom: "50px", marginTop: "30px" }}>

          <Grid item xs={6} lg={3}>
            <InputLabel disabled={checked} style={{ padding: "10px 20px" }}>
              Filtra per ruolo:
            </InputLabel>
            <Select disabled={checked} autoWidth variant="outlined" className={classes.select} name="role_id" value={role || ""} onChange={handleChangeRoles}>
              <MenuItem className={classes.menuItem} value={""}>Nessun ruolo</MenuItem>
              {
                roles.map((role, index) => {
                  return <MenuItem className={classes.menuItem} key={index} value={role.id}>{role.name}</MenuItem>
                })
              }

            </Select>
          </Grid>
          <Grid item xs={6} lg={3}>
            <InputLabel disabled={checked} style={{ padding: "10px 20px" }}>
              Filtra per gruppi:
            </InputLabel>
            <Select
              onClick={open}
              MenuProps={{ getContentAnchorEl: () => null }}
              multiple
              open={Boolean(anchorEl)}
              onClose={close}
              disabled={checked}
              variant="outlined"
              className={classes.select}
              name="group_id"
              value={group || undefined}
              onChange={handleChangeGroups} >
              <MenuItem className={classes.menuItem} value={"ALL"} onClick={setAllGroups}>Tutti i gruppi</MenuItem>
              {
                groups.map((group, index) => {
                  return <MenuItem className={classes.menuItem} key={index} value={group.id}>{group.name}</MenuItem>
                })
              }
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button onClick={(e) => { e.preventDefault(); e.stopPropagation(); close(); }} style={{ marginRight: "5px", color: COLORS.primary }}>
                  ok
                  </Button>
              </div>
            </Select>
          </Grid>
          <Grid item xs={6} lg={3}>
            <InputLabel disabled={checked} style={{ padding: "10px 20px" }}>
              Filtra per stato utente:
            </InputLabel>
            <Select disabled={checked} autoWidth variant="outlined" className={classes.select} name="role_id" value={checkedActive || ""} onChange={setCheckedActive}>
              <MenuItem className={classes.menuItem} value={""}>Tutti gli utenti</MenuItem>
              <MenuItem className={classes.menuItem} value={"true"}>Utenti attivi</MenuItem>
              <MenuItem className={classes.menuItem} value={"false"}>Utenti non attivi</MenuItem>
            </Select>
          </Grid>

          <Grid container alignItems="center" item xs={6} lg={3}>
            <InputLabel disabled={checked} style={{ padding: "10px 20px" }}>
              Tutti gli utenti
            </InputLabel>
            <AntSwitch checked={checked} setChecked={() => setChecked(!checked)} />
          </Grid>

        </Grid>
      }

    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number?.isRequired,
  allusers: PropTypes.number?.isRequired,
  handleChangeRoles: PropTypes.func?.isRequired,
  handleChangeGroups: PropTypes.func?.isRequired,
  setAllGroups: PropTypes.func?.isRequired,
  setChecked: PropTypes.func?.isRequired,
  close: PropTypes.func?.isRequired,
  open: PropTypes.func?.isRequired,
  setCheckedActive: PropTypes.func?.isRequired,
  checkedActive: PropTypes.bool?.isRequired,
  checked: PropTypes.bool?.isRequired,
  filter: PropTypes.bool?.isRequired,
  anchorEl: PropTypes.bool?.isRequired,
  role: PropTypes.string?.isRequired,
  roles: PropTypes.array?.isRequired,
  groups: PropTypes.array?.isRequired,
  group: PropTypes.array?.isRequired,
};

const useStyles = makeStyles((theme) => (tableStyle));

export default function EnhancedTable(props) {
  const { COLORS } = useColor()
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(false)

  const open = () => {
    setAnchorEl(true)
  }
  const close = () => {
    setAnchorEl(false)
  }

  useEffect(() => {
    props.setUsersIds(props.selected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selected])

  useEffect(() => {
    props.setRowsUser(rowsPerPage)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage])

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.reduce((result, row) => {
        if (row.id !== undefined) {
          result.push(row.id)
        }
        return result;
      }, [])
      const allSelected = newSelecteds.concat(props.selected);
      const filterSelected = allSelected.filter(onlyUnique)
      props.setSelected(filterSelected);
      return;
    }
    props.setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = props.selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(props.selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(props.selected.slice(1));
    } else if (selectedIndex === props.selected.length - 1) {
      newSelected = newSelected.concat(props.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        props.selected.slice(0, selectedIndex),
        props.selected.slice(selectedIndex + 1),
      );
    }

    props.setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    props.setBackdrop(true)
    props.setPageUser(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    props.setPage(0);
    props.setBackdrop(true)
  };


  const isSelected = (id) => props.selected.indexOf(id) !== -1;

  function clean(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "nan") {
        delete obj[propName];
      }
    }
  }

  function createData(username, first_name, last_name, email, id) {
    const data = { username, first_name, last_name, email, id };
    clean(data);
    return data;
  }

  const rows = props.users.map(user => {
    return createData(user?.username, user?.first_name, user?.last_name, user?.email, user?.id)
  })



  return (
    <div style={{			marginBottom:50}} className={classes.root}>
      <EnhancedTableToolbar
        allusers={rows.length}
        numSelected={props.selected.length}
        filter={props.filter}
        roles={props.roles}
        groups={props.groups}
        role={props.role}
        group={props.group}
        handleChangeGroups={props.handleChangeGroups}
        handleChangeRoles={props.handleChangeRoles}
        setAllGroups={props.setAllGroups}
        setChecked={props.setChecked}
        setCheckedActive={props.setCheckedActive}
        checkedActive={props.checkedActive}
        checked={props.checked}
        close={close}
        open={open}
        anchorEl={anchorEl}
      />
      {!props.filter &&
        <React.Fragment>
          <TableContainer className={classes.container}>

            <Backdrop style={{ zIndex: "2", bottom: "40px", position: "absolute", borderRadius: "20px", backgroundColor: "#fff", opacity: "0.4" }} open={props.backdrop}>
              <CircularProgress style={{ color: COLORS.primary }} />
            </Backdrop>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              size={'small'}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={props.selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={rows.length}
                rows={rows.map(row => row?.id)}
                selected={props.selected}
              />
              <TableBody>
                {rows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      className={classes.highselected}
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          style={isItemSelected ? { color: COLORS.primary } : {}}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.username}
                      </TableCell>
                      <TableCell className="hide-column-md" align="left">{row.first_name}</TableCell>
                      <TableCell className="hide-column-md"  align="left">{row.last_name}</TableCell>
                      <TableCell align="left">{row.email ? row.email : null}</TableCell>
                    </TableRow>
                  );
                })}

              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={props.count}
            rowsPerPage={rowsPerPage}
            page={props.page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </React.Fragment>
      }
    </div>
  );
}