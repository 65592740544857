import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FromDate from '../Export/ExportFilter/FromDate';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import serviceAdmin from '../../services/serviceAdmin';
import AntSwitch from '../Switch/AntSwitch'
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import { modalClass } from '../../classes';
import useColor from '../../context/themeContext';
import useAlert from '../../hooks/useAlert';
import FullTextField from '../StyledComponent/FullTextField'


const ModalNewSubscription = (props) => {


  const [options, setAlert] = useAlert()
  const { COLORS } = useColor();
  const useStyles = makeStyles((theme) => (modalClass(COLORS)));
  const classes = useStyles();

  const [checked, setChecked] = useState(false)
  const [isInHistory, setIsInHistory] = useState(false)
  const [input, setInput] = useState({})
  const [sub, setSub] = useState([])
  const [courses, setCourses] = useState([])
  const [expiration, setExpiration] = useState({})
  const [additionalData, setAdditionalData] = useState("")

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInput({ ...input, [name]: value })
  };

  const handleExpiration = (value) => {
    const name = "expiration_date";
    setExpiration({ [name]: value })
  };

  const handleAdditional = (e) => {
    const value = e.target.value;

    setAdditionalData(value)
  }

  const disableExpiration = () => {
    setExpiration({ "expiration_date": null })
  };

  const setchecked = (input) => {
    setChecked(input)
  };

  useEffect(()=>{
    return ()=>{
      ///ON ANMOUNT
      setchecked(false)
      setIsInHistory(false)
    }
  },[])

  const newSubscription = () => {
    const params = {
      ...input,
      "user_id": props.user_id,
      "is_active": checked,
      "is_in_history": isInHistory,
      "expiration_date": expiration?.expiration_date ? expiration?.expiration_date : null,
      "additional_data": additionalData
    }
    serviceAdmin.postService("v1/subscriptions/", params)
      .then(res => {
        if (res.status === 201) {
          setAlert(true, "Utente iscritto al corso correttamente!", "success");
          props.setReload();
          handleClose();
        }
      }).catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    serviceAdmin.getService(`v1/users/${props.user_id}/subscriptions/`)
      .then(res => {
        setSub(res.data.data)
      }).catch(err => {
        console.log(err);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reload])

  useEffect(() => {
    const subsArrayIds = sub.map(
      s => {
        return s?.course?.id;
      }
    );
    const filterArray = props.courses.filter(
      course => !subsArrayIds.includes(course.id)
    );

    setInput({ ...input, "course_id": filterArray[0]?.id })
    setExpiration({ "expiration_date": null })
    setCourses(filterArray)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sub])

  return (

    <Drawer
      anchor={"right"}
      open={props.open}
      onClose={handleClose}
    >

      <div className={"drawer-container"}>
        <div className={classes.closeicon + " title-drawer-container"}>
          <CloseIcon style={{ cursor: "pointer", height: "30px", width: "30px" }} onClick={handleClose} />
          <h2 className={classes.title}>
            {courses.length > 0 ? "Iscrivi ad un corso" : "Non ci sono corsi a cui puoi iscrivere l'utente"}
          </h2>
        </div>
        {courses.length > 0 &&
          <div className={"drawer-content-container"} style={{ padding: "20px 30px" }}>
            <Grid container spacing={3}>

              <Grid item xs={12} md={6}>
                <InputLabel className={classes.label}>
                  Corsi
                </InputLabel>

                <Select name="course_id" value={input.course_id || ""} onChange={handleChange} variant="outlined" className={classes.select}>
                  {courses.map((course, index) => {

                    return <MenuItem className={classes.selected} key={index} value={course.id}>{course.name}</MenuItem>

                  })}
                </Select>

              </Grid>

              <Grid item xs={12} md={6}>
                <InputLabel className={classes.label}>
                  Scadenza
                </InputLabel>
                <div style={{ display: "flex", alignItems: "center" }}>
                <FromDate
                  inputProps={`${classes.input}`}
                  value={expiration.expiration_date}
                  handleChange={handleExpiration}
                />
                  <IconButton onClick={disableExpiration}>
                    <HighlightOffIcon />
                  </IconButton>
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                <InputLabel className={classes.label}>
                  Attivo-disattivo
                </InputLabel>
                <ButtonGroup variant="contained" color="primary">
                  <Button style={!checked ? { background: "grey" } : { background: COLORS.primary }} onClick={() => setchecked(true)}>Attivo</Button>
                  <Button style={checked ? { background: "grey" } : { background: COLORS.primary }} onClick={() => setchecked(false)}>Disattivo</Button>
                </ButtonGroup>
              </Grid>

              <Grid item xs={12} md={6}>
                <InputLabel className={classes.label}>
                  Storico
                </InputLabel>
                <AntSwitch
                  name={"isInHistory"}
                  checked={isInHistory}
                  setChecked={()=> setIsInHistory(!isInHistory)}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputLabel className={classes.label}>
                  Dati aggiuntivi
                </InputLabel>
                <FullTextField
                  name="additional_data"
                  value={additionalData || ""}
                  onChange={handleAdditional}
                  multiline
                  variant="outlined"
                  inputProps={{ className: classes.input }}
                />
              </Grid>
              
            </Grid>
          </div>
        }
        <div className={classes.container_btn + " button-container"}>
          <Button onClick={handleClose} className={classes.btn_del}>Annulla</Button>
          {courses.length > 0 && <Button onClick={newSubscription} className={classes.btn_save}>Crea</Button>}
        </div>
      </div>

    </Drawer>
  )
}

export default ModalNewSubscription;