import React, { useEffect, useState } from 'react';
import SearchInput from "../componentsAdmin/Search/SearchInput";
import RoundButton from "../componentsAdmin/Button/RoundButton";
import Table from "../componentsAdmin/Table/Table";
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import useColor from '../context/themeContext'
import TransitionsModal from "../componentsAdmin/Modal/Modal";
import Fade from '@material-ui/core/Fade';
import Filters from '../componentsAdmin/Filter/Filters'
import { Grid } from '@material-ui/core';
import Alert from '../componentsAdmin/CollapseAlert/Alert';
import Zoom from '@material-ui/core/Zoom';
import FilterListIcon from '@material-ui/icons/FilterList';
import Tooltip from '@material-ui/core/Tooltip';
import { listMenu, filterIcon, filterIconActive } from '../classes'
import moment from 'moment'
import 'moment/locale/it';
moment.locale('it')



////SET NAME ROW AND VALUE CELL {label => id}
const headCells = [
  { id: 'checkbox', ischeckbox: true, label: '', class: "", align: "center" },
  { id: 'username', numeric: false, disablePadding: false, label: 'Username', class: "", align: "left" },
  { id: 'firstname', numeric: false, disablePadding: false, label: 'Nome', class: "hide-column-xs ", align: "left" },
  { id: 'lastname', numeric: false, disablePadding: false, label: 'Cognome', class: "hide-column-md ", align: "left" },
  { id: 'stato', boolean: true, numeric: false, disablePadding: false, label: 'Attivo', class: "hide-column-xxs ", align: "left" },
  { id: 'last_login', numeric: false, alt: "Data ultimo accesso", disablePadding: false, label: 'Data ultimo accesso', class: "hide-column-md ", align: "left" },
  { id: 'creation', numeric: false, disablePadding: false, label: 'Data creazione', class: "hide-column-md ", align: "left" },
  { id: 'detail', isdetail: true, label: '', class: "", align: "center" },
];

const User = (props) => {
  const { COLORS } = useColor();
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    ...listMenu(COLORS),
    filterIcon,
    ...filterIconActive(COLORS)
  }));

  const CustomTooltip = withStyles({
    tooltip: {
      fontSize: "14px",
      backgroundColor: COLORS.primary,
      color: "#fff",
      textAlign: "center",
      borderRadius: "10px",
      fontWeight: "bolder"
    }
  })(Tooltip);

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [newopen, setNewopen] = useState(false);
  const [buttons, setButtons] = useState(false);
  const [show, setShow] = useState(false);
  const [openAlert, setOpenAlert] = useState({});


  const setCustomAlert = (value, two_value, three_value) => {
    setOpenAlert({
      ...openAlert,
      "state": value,
      "severity": two_value,
      "message": three_value
    })
  };

  const publishClick = () => {
    props.history.push({pathname:"/backoffice/import", state: { "delete": false  }})
  }

  const setnewopen = (input) => { setNewopen(input) };
  const setclosebuttons = () => { setButtons(false) };
  const setbuttons = () => { setButtons(!buttons) };

  const showFilter = () => {
    setShow(!show);
  }

  useEffect(() => {
    setnewopen(false);
    setOpen(false);
    return () => {
      props.setPageUser(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.closeBack, props.role_id, props.group_id, props.ordering, props.checkedActive, props.checkedStaff, props.checkedGenerate])

  useEffect(() => {
    return () => {
      props.setSearchString("");
      props.setAnnotation("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.closeBack])

  useEffect(() => {
    setRows(
      props.users.map((user, index) => {
        return {
          "username": user.username,
          "firstname": user.first_name,
          "lastname": user.last_name,
          "lastDate": moment(user.updated).format('DD-MM-YYYY'),
          "creation": moment(user.created).format('DD-MM-YYYY'),
          "last_login": user.last_login ? moment(user.last_login).format('DD-MM-YYYY') : null,
          "stato": user.is_active,
          "id": user.id,
          "role": user?.role?.name,
          "email": user?.email,
          "otherInfo": {
            "id": user.id,
            "avatar": user.avatar,
            "role": user.role,
            "fiscal_code": user.fiscal_code,
            "expiration_date": user.expiration_date,
          }
        }
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.users])


  return (
    <React.Fragment>
      <Alert
        setOpenAlert={setCustomAlert}
        severity={openAlert?.severity}
        openAlert={openAlert?.state}
        message={openAlert?.message}
      />
      { !open && !newopen ?
        <React.Fragment>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Grid container spacing={3}>

              <Grid item style={{ display: "flex", alignItems: "center" }}>
                <SearchInput searchString={props.searchString} placeholder={"Cerca fra gli utenti..."} onChange={(event) => { props.setPageUser(1); props.setSearchString(event.target.value); }} />
                <Zoom in={true} timeout={{ appear: 400, enter: 500, exit: 500 }}>
                  <CustomTooltip className={classes.tooltip} title={show ? "Nascondi filtri" : "Mostra filtri"} placement="top">
                    <FilterListIcon onClick={showFilter} className={show ? classes.filterIconActive : classes.filterIcon} />
                  </CustomTooltip>
                </Zoom>

              </Grid>

            </Grid>
            <RoundButton
                    publishClick={publishClick}
                    add={true}
                    get={true}
                    setbuttons={setbuttons}
                  />
          </div>

          <Filters
            showReset={props.showReset}
            resetFilter={props.resetFilter}
            setAllGroup={props.setAllGroup}
            setDeleteGroup={props.setDeleteGroup}
            checkedGenerate={props.checkedGenerate}
            setCheckedGenerate={props.setCheckedGenerate}
            checkedActive={props.checkedActive}
            setCheckedActive={props.setCheckedActive}
            checkedStaff={props.checkedStaff}
            setCheckedStaff={props.setCheckedStaff}
            show={show}
            setPageUser={props.setPageUser}
            setGroup_id={props.setGroup_id}
            setRole_id={props.setRole_id}
            setOrdering={props.setOrdering}
            group_id={props.group_id}
            role_id={props.role_id}
            ordering={props.ordering}
            roles={props.roles}
            groups={props.groups}

          />

        </React.Fragment>
        : null
      }

      {!newopen ?

        <Fade in={props.loading} timeout={{ appear: 100, enter: 300, exit: 300 }}>
          <div style={{ maxWidth: "100%" }}>

            <Table
              haveCheckbox
              isUser/// MODAL USER DETAIL
              headCells={headCells}
              setAll={props.setAll}
              backdrop={props.backdrop}
              setBackdrop={props.setBackdrop}
              setCustomAlert={setCustomAlert}
              count={props.count}
              setPageCount={props.setPageUser}
              pageUser={props.pageUser}
              setRowsPage={props.setRowsUser}
              courses={props.courses}
              changeReloadUser={props.changeReloadUser}
              rows={rows}
              open={open}
              setOpen={setOpen}
              filter={props.searchString}
              loading={props.loading}
              page={props.page}
              setPage={props.setPage}
              {...props}
            />

          </div>
        </Fade>

        : null}

      {newopen ?
        <TransitionsModal
          setCustomAlert={setCustomAlert}
          open={newopen}
          setOpen={setnewopen}
          isNewUser={true}
          setReload={props.changeReloadUser}
        />
        : null}

      <Backdrop className={classes.backdrop} open={buttons} onClick={setclosebuttons}>
        <ul className={classes.listMenu}>
          <li onClick={() => setnewopen(true)}>Nuovo utente</li>
        </ul>
      </Backdrop>

    </React.Fragment>
  )

}

export default User;
