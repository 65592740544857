import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import serviceAdmin from '../../services/serviceAdmin'
import Paper from '@material-ui/core/Paper'
import FullTextField from '../StyledComponent/FullTextField'
import { tabsStyle } from '../../classes'
import useColor from '../../context/themeContext'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import CircularProgression from '../Progress/CircularProgression'

export default function FileMedia(props) {
	
	const { COLORS } = useColor()
	const useStyles = makeStyles((theme) => (tabsStyle(COLORS)));
	const classes = useStyles();

	const inputRef = useRef();
	const [file, setFile] = useState(null)
	const [inputs, setInputs] = useState({})
	const [loadFile, setFileLoad] = useState(false)
	const [timeUpload, setTimeUpload] = useState(String);


	useEffect(() => {
		setInputs({ ...inputs, "type": "FILE" })

		if (props.mediaType?.type === "FILE" && props.mediaType) {
			setInputs({
				...inputs,
				"type": props.mediaType?.type,
				"name": props.mediaType?.name,
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;

		setInputs({ ...inputs, [name]: value })

	};

	const setFileRef = () => {
		inputRef.current.click()
	}

	const options = {
		onUploadProgress: (progressEvent) => {
			const { loaded, total } = progressEvent;
			let percent = Math.floor((loaded * 100) / total)
			setTimeUpload(percent);
		}
	}

	const addMedia = () => {
		let formData;
		setFileLoad(true)
		formData = file
		formData.append("name", inputs.name || "");

		serviceAdmin.multipartService(`v1/course-sections/${props.lesson.id}/media/`, formData, options)
			.then(res => {
				if (res.status === 201) {
					props.setCustomAlert(true, "success", "Video aggiunto correttamente!");
					setFileLoad(false)
					props.setReload();
					props.handleClose();
				}
			}).catch(err => {
				console.log(err)
				setFileLoad(false)
			})
	}


	function fileSelected(event) {
		let formData = new FormData();
		let filetypes = ["mp4", "webm", "ogg", "avi", "wmv"]
		const isWrongFile = file => filetypes.some(s => file["type"].includes(s))
		let input = event.target;
		if (input.files[0]) {
			if (!isWrongFile(input.files[0])) {
				formData.append("file", input.files[0])
				formData.append("type", inputs.type || "")
				setFile(formData)
			} else {
				props.setCustomAlert(true, "warning", "Formato risorsa errato, stai cercando di caricare un video")
			}
		}
	}

	return (

		<div style={{ padding: "30px 20px" }}>

			<Grid container spacing={3}>

				<Grid item xs={6}>
					<InputLabel className={classes.label}>
						Nome File
            </InputLabel>
					<FullTextField name="name" value={inputs.name || ""} onChange={handleChange} placeholder="Nome del file" variant="outlined" inputProps={{ className: classes.input }} />
				</Grid>


				<Grid item xs={12} lg={6}>
					<InputLabel className={classes.label}>
						File
            </InputLabel>
					{!loadFile ?
						<div onClick={setFileRef} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
							<input ref={inputRef} style={{ visibility: "hidden", width: "1px" }} type="file" onChange={fileSelected} />
							{file || props.mediaType?.type === "FILE" ? "Cambia" : "Aggiungi"} file
                  {!file ?
								<AddCircleIcon className={classes.color} style={{ cursor: "pointer", paddingLeft: "10px", width: "40px", height: "40px" }} />
								:
								<DoneAllIcon style={{ paddingLeft: "10px", width: "40px", height: "40px" }} className={classes.color} />
							}
						</div>
						:
						<div style={{ display: "flex", alignItems: "center" }}>
							<CircularProgression timeUpload={timeUpload} />
						</div>
					}
				</Grid>


				<Grid item xs={12} style={{ height: "36px" }}></Grid>

			</Grid>

			<Paper elevation={3} className={classes.save}>

				<Button onClick={props.handleClose} className={classes.btn_del}>Annulla</Button>
				<Button disabled={inputs.name && inputs.type && file ? false : true} onClick={addMedia} className={classes.btn_save}>Salva</Button>

			</Paper>

		</div>
	);
}