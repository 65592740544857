import { darken } from "@material-ui/core"
import Service from "../../services/services"

async function fetchDashboardMe() {
	try {
		const dashData = await Service.getService("/custom/v1/dashboard/me/")
		return dashData.data.data
	} catch (error) {
		console.log('Activities.fetchDasboardMe]', error?.message)
	}
}

const getMonth = (monthNumber) => {
	const months = ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"]
	return months[monthNumber - 1]
}

const formatSubsAchievement = (data, color) => {
	let formattedSubsAchievement = [];
	for (let key in data) {
		if (key !== "total") {
			formattedSubsAchievement.push({
				"value": data[key],
				"fill": switchColor(key, color),
				"order": switchOrder(key)
			})
		}
	}
	formattedSubsAchievement = formattedSubsAchievement.sort((a, b) => (a.order < b.order) ? -1 : 1);
	return formattedSubsAchievement;
}

const switchColor = (type, color) => {
	switch (type) {
		case "passed":
			return darken(color, 0.5);

		case "running":
			return darken(color, 0.25);

		case "to_start":
			return darken(color, 0);

		default:
			return darken(color, 0);
	}
}

const switchOrder = (type) => {
	switch (type) {
		case "passed":
			return 3;

		case "running":
			return 2;

		case "to_start":
			return 1;

		default:
			return 1;
	}
}

export default {
	fetchDashboardMe,
	getMonth,
	formatSubsAchievement
}