import React, { useEffect, useState, useRef } from 'react';
import { Paper, Grid, Avatar, InputLabel, TextField, Button, IconButton, InputAdornment, Tooltip, Badge, CircularProgress } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import serviceAdmin from '../services/serviceAdmin';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Fade from '@material-ui/core/Fade';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Alert from '../componentsAdmin/CollapseAlert/Alert';
import InfoIcon from '@material-ui/icons/Info';
import { profileClass } from '../classes';
import CssTextField from '../componentsAdmin/StyledComponent/CssTextField'
import Contact from '../components/Profile/Contact'
import SocialBox from '../components/Profile/SocialBox'
import useColor, { useContent } from '../context/themeContext'

const CustomTooltip = withStyles({
	tooltip: {
		fontSize: "14px"
	}
})(Tooltip);

const StyledBadge = withStyles((theme) => ({
	badge: {
		right: 15,
		top: 90,
		border: `2px solid ${theme.palette.background.paper}`,
		padding: '0 4px',
		backgroundColor: 'green',
		height: 25,
		width: 25,
		borderRadius: 100,
		cursor: "pointer",
		color: 'white'
	},
}))(Badge);

const Profile = () => {
	const { Content } = useContent()
	const { COLORS } = useColor()
	const useStyles = makeStyles((theme) => (profileClass(COLORS)));
	const classes = useStyles()

	const isMount = useRef(true);

	const [userMe, setUserMe] = useState(null)
	const [loading, setLoading] = useState(false)
	const [showChange, setshowChange] = useState(false)
	const [passwordConflict, setPasswordConflict] = useState(false)
	const [passwordConfirm, setPasswordConfirm] = useState(false)
	const [values, setValues] = useState({ showPassword: false, showPasswordC: false, showNewPassword: false })
	const [input, setInput] = useState({})
	const [inputc, setInputC] = useState({})
	const [openAlert, setOpenAlert] = useState({});
	const [avatar, setAvatar] = useState(null);

	const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

	const setCustomAlert = (value, two_value, three_value) => {
		setOpenAlert({
			...openAlert,
			"state": value,
			"severity": two_value,
			"message": three_value
		})
	};


	const pastePasswordC = (e) => {
		e.preventDefault();
		setCustomAlert(true, "warning", "La funzione 'incolla' è disabilitata");
		return false;
	}

	const pastePassword = (e) => {
		e.preventDefault();
		setCustomAlert(true, "warning", "La funzione 'incolla' è disabilitata");
		return false;
	}

	const noShow = () => { setshowChange(false) };

	const showchange = () => {
		setshowChange(!showChange)
	};

	const changeInput = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		setInput({ ...input, [name]: value })
	}

	const changeInputC = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		setInputC({ ...input, [name]: value })
	}

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleClickShowNewPassword = () => {
		setValues({ ...values, showNewPassword: !values.showNewPassword });
	};

	const handleClickShowPasswordC = () => {
		setValues({ ...values, showPasswordC: !values.showPasswordC });
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (!regex.test(input.password) && input.password) {
				setPasswordConflict(true)
			} else {
				setPasswordConflict(false)
			}
			if (input.new_password !== inputc.passwordC) {
				setPasswordConfirm(true)
			} else {
				setPasswordConfirm(false)
			}
		}, 200)
		return () => {
			clearTimeout(timeout)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputc.passwordC, input.new_password])

	useEffect(() => {
		serviceAdmin.getService(`v1/users/me/`)
			.then(res => {
				if (isMount.current) {
					setLoading(true)
					setUserMe(res.data.data)
					setAvatar(res.data.data?.avatar?.thumbnail || "")
					setInput({
						"email": res.data.data?.email,
						"username": res.data.data?.username,
						"id": res.data.data?.id
					})
				}
			}).catch(err => {
				console.log(err)
			})

		return () => {
			isMount.current = false;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const updatePassword = () => {
		const params = {
			"old_password": input.old_password,
			"new_password": input.new_password
		}
		serviceAdmin.putService(`v1/users/${input.id}/`, params)
			.then(res => {

				if (res.status === 200) {
					showchange();
					setInput({});
					setInputC({});
				}
			}).catch(err => {
				setPasswordConflict(true)
			})
	}

	const changeAvatar = (event) => {
		setAvatar("load")
		const isImage = (file) => file['type'].includes('image')
		const input = event.target
		if (input.files && input.files[0]) {
			if (isImage(input.files[0])) {
				let reader = new FileReader()
				reader.onload = () => {
					const imgBase64 = reader.result
					serviceAdmin.putService(`v1/users/${userMe.id}/`, {
						avatar: imgBase64 || ""
					})
						.then(res => {
							setLoading(true)
							setUserMe(res.data.data)
							setAvatar(res.data.data?.avatar?.thumbnail || "")
						}).catch(err => {
							console.log(err)
						})
				}
				reader.readAsDataURL(input.files[0])
			}
		}
	};


	return (
		<React.Fragment>
			<Alert
				setOpenAlert={setCustomAlert}
				severity={openAlert?.severity}
				openAlert={openAlert?.state}
				message={openAlert?.message}
			/>
			<Paper elevation={3} className={classes.root}>

				<h2 className={classes.title}>
					Il tuo profilo
        </h2>
				<Fade in={loading} timeout={200}>
					<Grid xs={12} container spacing={3}>
						<Grid item container xs={12} justify="center">
							<input type="file" onChange={changeAvatar} id="upload" accept="image/*" style={{ display: "none" }} />
							<label htmlFor="upload">
								{avatar === "load" ?
									<Avatar className={classes.avatar}>
										<CircularProgress color={"green"} />
									</Avatar>
									:
									<StyledBadge badgeContent={"+"}>
										<Avatar alt={userMe?.first_name} src={avatar} className={classes.avatar} style={{ cursor: "pointer" }} />
									</StyledBadge>
								}
							</label>
							<label htmlFor="avatar" />
						</Grid>

						<Grid item container xs={12} justify="center">
							<p className={classes.subName}>
								{userMe && userMe?.first_name + " " + userMe?.last_name}
							</p>
						</Grid>
						{(userMe?.role?.tier !== "BASE_NO_LOG") &&
							<Grid item container xs={12} justify="center">
								<Grid item container xs={12} md={8} lg={8} xl={8} justify="center" spacing={3}>
									{
										showChange ?
											<React.Fragment>
												<Grid className={classes.flex_align} xs={10} item>
													<InputLabel className={classes.label}>
														La tua Password
                        	</InputLabel>
													<CssTextField
														variant="outlined"
														type={values.showPassword ? 'text' : 'password'}
														error={passwordConflict ? true : false}
														helperText={passwordConflict ? "Password errata, controlla di vaer scritto bene" : ""}
														name="old_password"
														value={input.old_password || ""}
														onChange={changeInput}
														InputProps={{
															endAdornment: (
																<InputAdornment position="start">
																	<IconButton
																		aria-label="toggle password visibility"
																		onClick={handleClickShowPassword}
																		onMouseDown={handleMouseDownPassword}
																	>
																		{values.showPassword ? <Visibility /> : <VisibilityOff />}
																	</IconButton>
																</InputAdornment>
															),
															className: classes.input_show
														}}
													/>

												</Grid>

												<Grid className={classes.flex_align} xs={10} item>

													<InputLabel className={classes.label}>
														Password

                            <CustomTooltip
															title="La password deve contenere almeno 8 caratteri tra cui almeno una maiuscola, un numero ed un carattere speciale"
														>
															<InfoIcon style={{ fontSize: "19px" }} />
														</CustomTooltip>

													</InputLabel>
													<CssTextField
														onPaste={pastePassword}
														variant="outlined"
														type={values.showNewPassword ? 'text' : 'password'}
														name="new_password"
														value={input.new_password || ""}
														onChange={changeInput}
														InputProps={{
															endAdornment: (
																<InputAdornment position="start">
																	<IconButton
																		aria-label="toggle password visibility"
																		onClick={handleClickShowNewPassword}
																		onMouseDown={handleMouseDownPassword}
																	>
																		{values.showNewPassword ? <Visibility /> : <VisibilityOff />}
																	</IconButton>
																</InputAdornment>
															),
															className: classes.input_show
														}}
													/>

												</Grid>

												<Grid className={classes.flex_align} xs={10} item >

													<InputLabel className={classes.label}>
														Conferma Password
                          </InputLabel>
													<CssTextField
														onPaste={pastePasswordC}
														variant="outlined"
														type={values.showPasswordC ? 'text' : 'password'}
														error={passwordConfirm ? true : false}
														helperText={passwordConfirm ? "Le password non corrispondono" : ""}
														name="passwordC"
														value={inputc.passwordC || ""}
														onChange={changeInputC}
														InputProps={{
															endAdornment: (
																<InputAdornment position="start">
																	<IconButton
																		aria-label="toggle password visibility"
																		onClick={handleClickShowPasswordC}
																		onMouseDown={handleMouseDownPassword}
																	>
																		{values.showPasswordC ? <Visibility /> : <VisibilityOff />}
																	</IconButton>
																</InputAdornment>
															),
															className: classes.input_show
														}}
													/>

												</Grid>
												<Button onClick={updatePassword} disabled={passwordConfirm || input.new_password?.length < 8 || input.old_password?.length < 8 || !input.new_password || !input.old_password} variant="contained" className={classes.button}>
													Salva Password
                        						</Button>
												<Button style={{ background: "#3AAC4D", width: "30px", marginLeft: "5px" }} onClick={noShow} variant="contained" className={classes.button}>

													<ArrowDropUpIcon />

												</Button>

											</React.Fragment>
											:

											<Button onClick={showchange} variant="contained" className={classes.button}>
												Cambia Password
                      						</Button>

									}


								</Grid>
							</Grid>
						}
					</Grid>
				</Fade>
			</Paper>

			{Content?.contacts || Content?.socials ?
				<Paper elevation={3} className={classes.root}>
					<Grid container>
						<Grid item xs={12} sm={6}>
							<Contact content={Content} />
						</Grid>
						<Grid item xs={12} sm={6}>
							<SocialBox />
						</Grid>
					</Grid>
				</Paper> : null}
		</React.Fragment>
	)
}
export default Profile;