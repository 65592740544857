import React from 'react';
import { Redirect, Route } from 'react-router-dom';



const PrivateVideo = ({ component: Component, ...rest }) => {

    return (
        <Route
            {...rest}
            render={props =>
                localStorage.getItem("bearer_fit_token") && localStorage.getItem("course_fit_id") ? (
                    <Component {...props} />
                ) : (
                        <Redirect to="/no-access" />
                    )

            }


        />
    )
}
export default PrivateVideo
