import React from "react";
import { Grid, InputLabel, Select, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import useColor from '../../context/themeContext'


const SelectFilter = (props) => {
    const { COLORS } = useColor()
    const useStyles = makeStyles((theme) => ({
        root: {
            maxWidth: "300px",
            width: "100%",
            margin: "20px auto",
            '& .MuiSelect-root': {
                borderRadius: "20px",
                backgroundColor: "#fff!important",
                border: "1px solid #ccc"
            }
        },
        color_label: {
            '&.Mui-selected': {
                color: COLORS.primary + "!important"
            }
        }
    }));
    const classes = useStyles();

    return (
        <Grid item xs={12} md={6} xl={6} style={{ ...props.style, textAlign: "left" }}>
            <Select
                value={props?.input || "ALL"}
                name={props.name}
                onChange={props.handleChange}
                variant="outlined"
                className={classes.root}
            >
                <MenuItem value={"ALL"} onClick={() => props.resetChange(props.name)} className={classes.color_label}>{props.all_label}</MenuItem>
                {
                    props.data?.map((d, index) => {
                        return <MenuItem className={classes.color_label} key={index} value={d.id}>{d.name}</MenuItem>
                    })
                }

            </Select>
        </Grid>
    )
}

export default SelectFilter;