import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemIcon, ListItemText, Avatar } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ListLessons from './ListLessons'
import ModalModifyModule from "../Modal/ModalModifyModule"
import serviceAdmin from '../../services/serviceAdmin';
import DeleteDialog from '../Dialog/DeleteDialog'
import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useColor from '../../context/themeContext';
import useDrag, { DropElement, DropContainer } from '../../hooks/useDrag'



export default function TableModuls(props) {
  
  const { COLORS } = useColor()

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    label_cell: {
      flex: "0 0 auto",
      color: "#fff!important",
      fontSize: "15px!important",
      paddingLeft: "15px",
    },
    label_cell_icon: {
      marginLeft: "auto",
      color: "#fff!important",
      fontSize: "15px!important",
    },
    check: {
      paddingRight: "20px!important"
    },
    menu_table: {
      color: COLORS.primary + "!important"
    },
    menu_table_del: {
      color: "red"
    },
    list: {
      borderBottom: `1px solid ${COLORS.primary}`,
    },
    list_moduls: {
      background: COLORS.primary,
      borderRadius: "12px",
      margin: "10px 0",
      "& .MuiAccordionSummary-content": {
        margin: 0
      }
    },
    listItem: {
      "& span": {
        fontSize: "14px",
        fontWeight: "bold"
      }
    }, expansion: {
      width: "100%"
    },
    preview: {
      backgroundColor: "rgba(150, 150, 200, 0.1)",
      border: "1px dashed #abc",
      borderRadius: "12px"
    }
  }));

  const CustomTooltip = withStyles({
    tooltip: {
      fontSize: "14px",
      backgroundColor: COLORS.primary,
      color: "#fff",
      textAlign: "center",
      borderRadius: "10px",
      fontWeight: "bolder"
    }
  })(Tooltip);

  const classes = useStyles();

  const [rows, setRows] = React.useState([]);
  const [module, setModule] = React.useState({});
  const [deleteModule, setDelete] = React.useState(false);

  const { drag_class, handleDrop } = useDrag("module-drag-handle", rows, setRows, handleDropUpdate, props.setReload)

  const setdelete = (input) => { setDelete(input) }


  useEffect(() => {
    if (props.moduls?.length > 0) {
      setRows(props.moduls)
    }
  }, [props.moduls])


  const setopen = (input) => { props.setOpenModify(input) }

  const handleOpenMenu = (event, row) => {
    event.stopPropagation();
    setModule(row);
    setopen(true)
  };

  const openLesson = (event, row) => {
    event.stopPropagation();
    setModule(row)
    props.setOpenLesson(true);
  }

  async function handleDropUpdate(el, index) {
    try {
      const data = await serviceAdmin.putService(`v1/course-modules/${el.id}/`,
        {
          "course_id": props.course.id,
          "order": index,
          "name": el.name
        }
      )
      return data;
    } catch (err) {
      console.log(err)
    }
  }



  return (
    <div className={classes.root}>
      <React.Fragment>

        <List>
          <DropContainer handleDrop={handleDrop} drag_class={drag_class}>
            {rows.map((row, index) => {

              return (
                <DropElement key={row.id}>
                  <Accordion defaultExpanded={row.sections?.length === 0 ? true : (index === 0 ? true : false)}>
                    <AccordionSummary className={classes.list_moduls + " table-label-cell"} expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}>
                      <ListItem
                        className={"grab " + drag_class}
                        style={{
                          marginLeft: "auto",
                          display: "flex",
                          justifyContent: "flex-start"
                        }}
                      >
                        <ListItemText className={classes.label_cell}>
                          {row?.thumbnail &&
                            <Avatar src={row?.thumbnail} variant="square" className={classes.square} />
                          }
                        </ListItemText>

                        <ListItemText className={classes.label_cell}>
                          {row.name}
                        </ListItemText>

                        <ListItemIcon className={classes.label_cell_icon}>
                          <CustomTooltip className={classes.tooltip} title="Modifica argomento" placement="top">
                            <IconButton onClick={(e) => { handleOpenMenu(e, row) }}>
                              <CreateIcon style={{ width: "30px", height: "30px", color: "#fff" }} />
                            </IconButton>
                          </CustomTooltip>
                        </ListItemIcon>

                        <ListItemIcon>
                          <CustomTooltip className={classes.tooltip} title="Aggiungi lezioni" placement="top">
                            <IconButton onClick={(e) => { openLesson(e, row) }}>
                              <AddIcon style={{ width: "30px", height: "30px", color: "#fff" }} />
                            </IconButton>
                          </CustomTooltip>
                        </ListItemIcon>

                      </ListItem>
                    </AccordionSummary>
                    <AccordionDetails className={classes.expansion}>

                      <ListLessons
                        setReload={props.setReload}
                        openLesson={props.openLesson}
                        setOpenLesson={props.setOpenLesson}
                        sections={row.sections}
                        module_id={row.id}
                        setCustomAlert={props.setCustomAlert}
                        moduls={props.moduls}
                        module={module}
                        course={props.course}

                      />

                    </AccordionDetails>
                  </Accordion>
                </DropElement>
              );
            })}

          </DropContainer>
        </List>



        <Table>
          <TableBody>
            <TableRow className="table-label-cell" style={{ width: "100%" }}>
              <TableCell style={{ borderBottom: "none" }} align="left">Totali {rows.length}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <DeleteDialog
          deleteLesson={deleteModule}
          setDelete={setdelete}
          textDelete={"il modulo"}
          handleCloseMenu={() => { }}
          lesson={module}
          setConfirm={props.setConfirm}
        />
      </React.Fragment>

      <ModalModifyModule
        setDelete={setdelete}
        setCustomAlert={props.setCustomAlert}
        module={module}
        setReload={props.setReload}
        course={props.course}
        open={props.openModify}
        setOpen={props.setOpenModify}
      />
    </div>
  );
}
