import React from 'react'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import NextButton from './NextButton'

const useStyles = makeStyles({
    root: {
        position: "relative",
        minHeight: "400px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
    },
    title: {
        fontSize: "25px",
        marginBottom: "25px"
    }
});


const MaxRetries = (props) => {
    const classes = useStyles();

    const { setRestart, loading, has_button, left_tries, setLesson, next, module_id, lesson_id,is_passed } = props;

    return (
        <Paper className={classes.root}>
            <h2 className={classes.title}>
				Hai esaurito i tentativi
		    </h2>
           {loading && <NextButton setRestart={setRestart} has_button={has_button} left_tries={left_tries} is_passed={is_passed} setLesson={setLesson} next={next} module_id={module_id} lesson_id={lesson_id}/> }
        </Paper>
    )
}

export default MaxRetries;